import { TDates } from 'shared/types';
import { RangePicker } from 'shared/ui';
import { IDatesProps } from '../interfaces';

export const Dates = (props: IDatesProps) => {
  const changeDatesHandler = (dates: TDates) => {
    props.onChange(dates);
  };

  return (
    <RangePicker
      value={props.value}
      handleSetNewDate={changeDatesHandler}
    />
  );
};
