import { calculateDailyChange } from 'entities/exchange/helpers/calculate-daily-change';


export  const getDailyChange = (params: Record<'exchange' | 'snapshot', Record<'usdt' | 'btc', number>>) => {
  const usdtDailyChange = calculateDailyChange(params.exchange.usdt, params.snapshot.usdt);
  const btcDailyChange = calculateDailyChange(params.exchange.btc, params.snapshot.btc);

  return {
    usdtDailyChange,
    btcDailyChange,
  };
};
