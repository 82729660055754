import { Stack } from '@mui/material';
import { Modal } from 'antd';
import { ModalPanel } from 'pages/trading-bots/configurator-v2/views/configure-risk-managment/components/panel/views';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { LabelInput, MainButton } from 'shared/ui';
import { IBotNameProps } from '../interfaces';
import { BUTTON, modal, modalChildren, WRAPPER } from '../styles';

export const BotName = (props: IBotNameProps) => {
  const {
    isModalOpened,
    handleNameModalState,
    createBotHandler,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    watch,
    setValue,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();

  const bot = watch('bot');
  const name = watch('name', bot?.name);

  const handleClose = () => {
    handleNameModalState(false);
  };

  const onChangeHandler = (value: string) => {
    setValue('name', value);
  };

  const buttonHandler = async () => {
    try {
      setIsLoading(true);
      await createBotHandler();
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      open={isModalOpened}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      width='100%'
      centered={true}
    >
      <Stack sx={WRAPPER}>
        <ModalPanel />

        <LabelInput
          label={(
            <Label title={t('modal.confirmCreateBot.name.title')} />
          )}
          value={name}
          placeholder={t('modal.confirmCreateBot.name.placeholder')}
          onChange={onChangeHandler}
        />

        <MainButton
          type='primary'
          styles={BUTTON}
          onClick={buttonHandler}
          disabled={!name}
          loading={isLoading}
        >
          {t('modal.confirmCreateBot.button')}
        </MainButton>
      </Stack>
    </Modal>
  );
};
