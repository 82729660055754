import { Box, Stack } from '@mui/material';
import { Empty, Segmented } from 'antd';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'shared/hooks';
import { ConnectNewExchange, MainButton } from 'shared/ui';
import { IChartWrapper } from '../interfaces';
import { inner, wrapper } from '../styles';

export const ChartWrapper = (props: IChartWrapper) => {
  const {
    action,
    segments,
    children,
    isEmpty,
    selectedSegment,
    setSelectedSegment,
    exchanges,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    handlers,
  } = useAuth();
  const {
    t, 
  } = useTranslation();

  const modalHandler = () => {
    setIsModalOpen((prev) => !prev);
  };
  
  const changeSegmentHandler = (value: string) => {
    setSelectedSegment(value);
  };
  
  const getContent = () => {
    if (isEmpty) {
      return (
        <Stack gap='24px'>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

          {(exchanges?.length === 0) && (
            <Fragment>
              <Stack
                direction='row'
                gap='8px'
                justifyContent='center'
              >
                <MainButton
                  type='primary'
                  size='large'
                  onClick={modalHandler}
                >
                  {t('emptyChart.button.connect')}
                </MainButton>
                
                <MainButton
                  type='primary'
                  ghost={true}
                  size='large'
                  onClick={handlers.enableDemoAccountHandler}
                >
                  {t('emptyChart.button.switch')}
                </MainButton>
              </Stack>
    
              <ConnectNewExchange 
                isModalOpen={isModalOpen} 
                setIsModalOpen={modalHandler} 
              />
            </Fragment>
          )}
        </Stack>
      );
    }

    return children(selectedSegment);
  };

  return (
    <Box sx={wrapper}>
      <Box sx={inner}>
        <div>
          <Segmented
            value={selectedSegment}
            defaultValue={segments[0].value}
            options={segments}
            onChange={changeSegmentHandler}
            size='large'
            block
          />
        </div>

        {action}
      </Box>

      {getContent()}
    </Box>
  );
};
