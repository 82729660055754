import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { getBotDealsFromTerminal } from './get-bot-deals-from-terminal';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const botUpdateTrade = async (smartTradeUuid: string) => {
  const response = await axios.request({
    url: '/bot/getByFilterDeal',
    data: {
      skyrexUserUuids: [getSkyrexUuid()!],
      smartTradeUuids: [smartTradeUuid],
      page: 1,
    },
  });

  if (!response?.data?.data?.deals?.length) {
    return;
  }

  const [deal] = response?.data?.data?.deals;

  const smartTrades = await getBotDealsFromTerminal({
    smartTradeUuids: [deal.smartTradeUuid], 
  });

  if (!smartTrades?.length) {
    return;
  }

  const [smartTrade] = smartTrades;

  const result = {
    ...smartTrade,
    position: deal.position,
    maxSafetyOrders: deal.maxSafetyOrders,
    botUuid: deal.botUuid,
  };
  
  return result;
};
