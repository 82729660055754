import { Box, Stack } from '@mui/material';
import { TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { getTextType } from 'pages/my-accounts/connected-exchange-account-single/helpers/get-text-type';
import { statuses } from 'shared/consts';
import { dateSorter, numberSorter, pairSorter, stringSorter } from 'shared/helpers/sorters';
import { History } from 'shared/icons';
import { Icon, SortIcon, Text } from 'shared/ui';

export const columns = (t: any, openModal: (tradeUuid: string) => () => void): TableColumnsType => {
  return [{
    title: t('terminal.diary.table.columns.pair'),
    dataIndex: 'pair',
    key: 'pair',
    sorter: (a, b) => pairSorter({
      aFirstPair: a.pair.firstPair,
      aSecondPair: a.pair.secondPair,
      bFirstPair: b.pair.firstPair,
      bSecondPair: b.pair.secondPair,
    }),
    width: 100,
    render: (value: any) => (
      <Box
        display='flex'
        alignItems='center'
        gap={0.5}
      >
        <Text>
          {value?.firstPair}
        </Text>

        <Text type='secondary'>
          {value?.secondPair}
        </Text>
      </Box>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('terminal.diary.table.columns.account'),
    dataIndex: 'account',
    key: 'account',
    sorter: (a, b) => stringSorter(a.account, b.account),
    width: 100,
    render: (value: any) => (
      <Text
        styles={{
          display: 'block',
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {value}
      </Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('terminal.diary.table.columns.date.create'),
    dataIndex: 'createDate',
    key: 'create-adate',
    sorter: (a, b) => dateSorter(a.createDate, b.createDate),
    width: 140,
    render: (value: any) => (
      <Text type='secondary'>
        {dayjs(value).format('DD.MM.YYYY, HH:mm:ss')}
      </Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
    defaultSortOrder: 'descend',
  }, {
    title: t('terminal.diary.table.columns.date.close'),
    dataIndex: 'closeDate',
    key: 'close-adate',
    sorter: (a, b) => dateSorter(a.closeDate, b.closeDate),
    width: 140,
    render: (value: any) => (
      <Text type='secondary'>
        {value ? dayjs(value).format('DD.MM.YYYY, HH:mm:ss') : '-'}
      </Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('terminal.diary.table.columns.status'),
    dataIndex: 'status',
    key: 'status',
    sorter: (a, b) => stringSorter(a.status, b.status),
    width: 100,
    render: (value: any) => {
      const status = statuses[value];
  
      return (
        <Text type={status?.color || undefined}>
          {status?.text || ''}
        </Text>
      );
    },
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('terminal.diary.table.columns.total'),
    dataIndex: 'total',
    key: 'total',
    sorter: (a, b) => numberSorter(a.total, b.total),
    width: 80,
    render: (value: any) => (
      <Text>{value}</Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('terminal.diary.table.columns.price.entry'),
    dataIndex: 'entryPrice',
    key: 'entry-price',
    width: 100,
    render: (value: any) => (
      <Text>{value}</Text>
    ),
    showSorterTooltip: false,
  }, {
    title: t('terminal.diary.table.columns.price.close'),
    dataIndex: 'closePrice',
    key: 'close-price',
    width: 100,
    render: (value: any) => (
      <Text>
        {value}
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: t('terminal.diary.table.columns.return'),
    dataIndex: 'return',
    key: 'return',
    sorter: (a, b) => numberSorter(a.return_pr, b.return_pr),
    width: 135,
    render: (value: any, record) => {
      const numericValue = parseFloat(value);
      const textType = getTextType(numericValue);
  
      return (
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          gap='8px'
        >
          <Text type={textType}>
            {value} ({record?.return_pr || 0}%)
          </Text>

          <Icon
            width={14}
            action={openModal(record.smartTradeUuid)}
          >
            {History}
          </Icon>
        </Stack>
      );
    },
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }];
};
