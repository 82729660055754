import { SxProps, Theme } from '@mui/material';

export const EMPTY_WRAPPER: SxProps<Theme> = {
  gap: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  '&>img': {
    display: 'block',
    maxWidth: 550,
  },
};
