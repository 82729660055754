import { TLanguage, TTranslation } from 'shared/types';

export const validationFunds = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      funds: {
        price: {
          trigger: {
            required: 'This field is required',
            number: 'Value should be a number',
            min: 'Minimum value is {{priceMin}}',
            max: 'Maximum value is {{priceMax}}',
          },
          origin: {
            required: 'This field is required',
            number: 'Value should be a number',
            min: 'Minimum value is {{priceMin}}',
            max: 'Maximum value is {{priceMax}}',
          },
        },
        units: {
          required: 'This field is required',
          number: 'Value should be a number',
          min: 'Minimum value is {{lotMin}}',
          max: 'Maximum value is {{lotMax}}',
        },
        total: {
          required: 'This field is required',
          balance: 'Insufficient balance',
          number: 'Value should be a number',
          min: 'Minimum value is {{minNotional}}',
          max: 'Maximum value is {{maxNotional}}',
        },
        slider: {
          min: 'Should be more than 0%',
          max: 'Cannot be more than 100%',
        },
      },
    },
    ru: {
      funds: {
        price: {
          trigger: {
            required: 'Поле необходимо',
            number: 'Должно быть числом',
            min: 'Минимальное значение {{priceMin}}',
            max: 'Максимальное значение {{priceMax}}',
          },
          origin: {
            required: 'Поле необходимо',
            number: 'Должно быть числом',
            min: 'Минимальное значение {{priceMin}}',
            max: 'Максимальное значение {{priceMax}}',
          },
        },
        units: {
          required: 'Поле необходимо',
          number: 'Должно быть числом',
          min: 'Минимальное значение {{lotMin}}',
          max: 'Максимальное значение {{lotMax}}',
        },
        total: {
          required: 'Поле необходимо',
          balance: 'Недостаточно средств',
          number: 'Должно быть числом',
          min: 'Минимальное значение {{minNotional}}',
          max: 'Максимальное значение {{maxNotional}}',
        },
        slider: {
          min: 'Должно быть больше 0%',
          max: 'Не может быть больше 100%',
        },
      },
    },
  };

  return transaltion[type].funds;
};
