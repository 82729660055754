import { formatReturnPercent } from 'pages/manual-trading/trading-terminal/helpers/active-trades-utils';
import { Order, SmartTrade } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';
import { ProgressMarkProps, SliderMarksReturn } from '../types/active-trade.types';

export const getCurrentExchangePrice = (trade: SmartTrade): number => {
  if (trade.status === 'completed') {
    return parseFloat(trade.executedQuoteSecond) / parseFloat(trade.executedBaseSecond);
  }
    
  return parseFloat(trade.currentExchangePrice);
};
  
const getPriceFromOrder = (order: Order): number => {
  if (order.viewType === 'force_order_step_second') {
    return parseFloat(order.limitPrice || '0');
  }
  
  if (order.viewType === 'force_order_step_first') {
    return parseFloat(order.executionPrice || '0');
  }

  return order.orderType === 'LIMIT' || order.orderType === 'CONDITIONAL_LIMIT'
    ? parseFloat(order.limitPrice)
    : parseFloat(order.triggerPrice ?? '0');
};
  
export const getSliderValues = (trade: SmartTrade, showOnProgressBar: boolean): number[] => {
  if (!showOnProgressBar) {
    return [];
  }

  const x = getCurrentExchangePrice(trade);
  const y = getEntryPrice(trade);

  if (isNaN(x) || y === null || isNaN(y)) {
    return [];
  }

  return [x, y];
};

export const getEntryPrice = (trade: SmartTrade): number | null => {
  const baseOrder = trade.orders.find(order => order.viewType === 'base_order');
  const forceOrderStepFirst = trade.orders.find(order => order.viewType === 'force_order_step_first');

  if (!baseOrder) {
    return null;
  }
  
  if (baseOrder.orderType === 'MARKET' && (parseFloat(trade.executedQuoteFirst) === 0 || parseFloat(trade.executedBaseFirst) === 0)) {
    return null;
  }
  
  if (baseOrder.status === 'cancelled' || baseOrder.status === 'completed') {
    // в случае, если у нас открывается FORCE ORDER, нам неоткуда брать цену entry до момента, пока он не будет исполнен
    // поэтому мы берем цену из baseOrder
    if (forceOrderStepFirst && (forceOrderStepFirst?.status !== 'completed')) {
      return parseFloat(baseOrder.limitPrice ?? '0');
    }
    return parseFloat(trade.executedQuoteFirst) / parseFloat(trade.executedBaseFirst);
  }

  if (baseOrder.orderType === 'LIMIT' || baseOrder.orderType === 'CONDITIONAL_LIMIT') {
    return parseFloat(baseOrder.limitPrice);
  }

  return parseFloat(baseOrder.triggerPrice ?? '0');
};

export const getSliderMarks = (trade: SmartTrade, t: any): SliderMarksReturn => {
  const marks: Record<number, ProgressMarkProps> = {};
  const entryHeight = 12;
  let belowEntryHeight = entryHeight + 10;

  const labelMap: Record<string, string> = {
    additional_base_order: t('terminal.trades.progress.marks.ae'),
    stop_loss: t('terminal.trades.progress.marks.sl'),
    take_profit: t('terminal.trades.progress.marks.tp'),
    force_order_step_second: 'Force close',
  };

  const entryPrice = getEntryPrice(trade);
  const currentExchangePrice = getCurrentExchangePrice(trade);
  
  const allPrices = [currentExchangePrice];
  
  if (entryPrice !== null) {
    allPrices.push(entryPrice);
    marks[entryPrice] = {
      value: entryPrice,
      label: t('terminal.trades.progress.marks.entry'),
      distance: entryHeight,
      isExchangePrice: false,
      isEntryPrice: true,
    };
  }
  
  const filteredOrders = trade.orders.filter(order => 
    order.viewType !== 'base_order' &&
    order.viewType !== 'force_order_step_first' &&
    (order.status === 'new' || order.status === 'active' || order.status === 'await_send'),
  );

  const tpOrders = filteredOrders.filter(order => order.viewType === 'take_profit');
  const aeOrders = filteredOrders.filter(order => order.viewType === 'additional_base_order');
  
  const sortedTp = tpOrders.sort((a, b) => getPriceFromOrder(a) - getPriceFromOrder(b));
  const firstTpPrice = sortedTp.length > 0 ? getPriceFromOrder(sortedTp[0]) : null;
  const lastTpPrice = sortedTp.length > 0 ? getPriceFromOrder(sortedTp[sortedTp.length - 1]) : null;
  
  const sortedAe = aeOrders.sort((a, b) => getPriceFromOrder(a) - getPriceFromOrder(b));
  const firstAePrice = sortedAe.length > 0 ? getPriceFromOrder(sortedAe[0]) : null;
  const lastAePrice = sortedAe.length > 0 ? getPriceFromOrder(sortedAe[sortedAe.length - 1]) : null;

  filteredOrders.forEach(order => {
    const price = getPriceFromOrder(order);
    allPrices.push(price);
    const isTP = order.viewType === 'take_profit';
    const isAE = order.viewType === 'additional_base_order';
    
    const isFirstOrLastTP = isTP && (price === firstTpPrice || price === lastTpPrice);
    const isFirstOrLastAE = isAE && (price === firstAePrice || price === lastAePrice);

    const isRegularMark = !isTP && !isAE;
    const isVisibleTakeProfit = isTP && (
      tpOrders.length === 1 || // видимый если единственный
      (tpOrders.length >= 2 && isFirstOrLastTP) // или первый/последний если их много
    );
    const isVisibleAdditionalEntry = isAE && (
      aeOrders.length === 1 || // видимый если единственный
      (aeOrders.length >= 2 && isFirstOrLastAE) // или первый/последний если их много
    );

    const isVisible = isRegularMark || isVisibleTakeProfit || isVisibleAdditionalEntry;

    marks[price] = {
      value: price,
      label: labelMap[order.viewType] || order.viewType,
      distance: belowEntryHeight,
      isExchangePrice: false,
      visible: isVisible,
    };

    if (isVisible) {
      belowEntryHeight += 10;
    }
  });

  const lowest = Math.min(...allPrices);
  const highest = Math.max(...allPrices);

  marks[currentExchangePrice] = {
    value: currentExchangePrice,
    label: (formatReturnPercent(trade.returnPercent) || '0'),
    distance: 25,
    isExchangePrice: true,
    highestPrice: highest,
    lowestPrice: lowest,
    isHighestPrice: currentExchangePrice === highest,
    isLowestPrice: currentExchangePrice === lowest,
  };

  if (entryPrice !== null) {
    marks[entryPrice] = {
      ...marks[entryPrice],
      isExchangePrice: false,
      isHighestPrice: entryPrice === highest,
      isLowestPrice: entryPrice === lowest,
      isEntryPrice: true,
      highestPrice: highest,
      lowestPrice: lowest,
    };
  }
  
  Object.keys(marks).forEach(price => {
    const numPrice = Number(price);
    if (numPrice === lowest) {
      marks[numPrice] = {
        ...marks[numPrice],
        isLowestPrice: true,
      };
    }
    if (numPrice === highest) {
      marks[numPrice] = {
        ...marks[numPrice],
        isHighestPrice: true,
      };
    }
  });
  
  return {
    marks, 
    lowest, 
    highest, 
    entryMark: entryPrice, 
  };
};
