import { TLanguage, TTranslation } from 'shared/types';

export const errorExchange = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      exchange: {
        connect: {
          'Only one active demo account is available per user': 'Only one active demo account is available per user',
          'There is no active skyrex subscription': 'There is no active Skyrexio subscription',
          'Maximum number of active accounts reached. Please upgrade subscription': 'Maximum connected exchange accounts is reached',
          'failed to create account': 'failed to create account',
        },
        demo: {
          enable: {
            title: 'Cannot enable demo mode',
          },
          disable: {
            title: 'Failed to disable demo mode',
          },
          create: {
            title: 'Cannot create demo account',
          },
          delete: {
            title: 'Cannot delete account',
          },
          reset: {
            title: 'Cannot reset demo account',
          },
        },
        create: {
          title: 'Failed to connect',
          message: 'Check API keys, permissions, IP whitelist or contact support',
        },
        update: {
          title: 'Failed to update',
          message: {
            'Cannot update API keys because they belong to other exchange account': 'API keys belong to another exchange account',
          },
        },
        list: {
          failed: {
            title: 'Connection failed',
            message: {
              'failed to create account': 'failed to create account',
              'Maximum number of active accounts reached. Please upgrade subscription': 'Maximum number of active accounts reached. Please upgrade subscription',
            },
            button: 'Okay',
          },
          delete: {
            title: 'Deletion failed',
            message: {
              'Cannot delete account because bots are not deleted': 'Cannot delete account because bots are not deleted',
              'Cannot delete account because there are active positions': 'Cannot delete account because there are active positions',
            },
            button: 'Okay',
          },
        },
        connected: {
          empty: {
            title: 'Account is empty',
            text: {
              bybit: 'Convert to Unified account, update API or deposit',
              other: 'Update API keys or deposit funds to continue trading',
            },
          },
        },
      },
    },
    ru: {
      exchange: {
        connect: {
          'Only one active demo account is available per user':
            'Допустим только один демо-аккаунт на пользователя',
          'There is no active skyrex subscription':
            'Нет активной подписки на Skyrexio',
          'Maximum number of active accounts reached. Please upgrade subscription':
            'Лимит аккаунтов достигнут. Обновите подписку',
          'failed to create account': 'Не удалось создать аккаунт',
        },
        demo: {
          enable: {
            title: 'Невозможно включить демо-режим',
          },
          disable: {
            title: 'Не удалось отключить демо-режим',
          },
          create: {
            title: 'Не удалось создать демо-аккаунт',
          },
          delete: {
            title: 'Не удалось удалить аккаунт',
          },
          reset: {
            title: 'Не удалось сбросить демо-аккаунт',
          },
        },
        create: {
          title: 'Не удалось подключиться',
          message: 'Проверьте ключи API, разрешения, белый список IP или обратитесь в поддержку',
        },
        update: {
          title: 'Не удалось обновить',
          message: {
            'Cannot update API keys because they belong to other exchange account':
              'API-ключи принадлежат другому аккаунту',
          },
        },
        list: {
          failed: {
            title: 'Ошибка подключения',
            message: {
              'failed to create account': 'Не удалось создать аккаунт',
              'Maximum number of active accounts reached. Please upgrade subscription':
                'Лимит аккаунтов достигнут. Обновите подписку',
            },
            button: 'Ок',
          },
          delete: {
            title: 'Ошибка удаления',
            message: {
              'Cannot delete account because bots are not deleted':
                'Нельзя удалить аккаунт: боты не удалены',
              'Cannot delete account because there are active positions':
                'Нельзя удалить аккаунт: есть активные позиции',
            },
            button: 'Ок',
          },
        },
        connected: {
          empty: {
            title: 'Аккаунт пуст',
            text: {
              bybit: 'Перейдите на Unified, обновите API или пополните баланс',
              other: 'Обновите ключи или пополните баланс для продолжения торговли',
            },
          },
        },
      },
    },
  };

  return transaltion[type].exchange;
};
