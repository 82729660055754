import { SegmentedLabeledOption } from 'antd/es/segmented';

export const spotSegments = (t: any): SegmentedLabeledOption<string>[] => {
  return [{
    label: t('terminal.panel.switch.buy.spot'),
    value: 'buy',
  }, {
    label: t('terminal.panel.switch.sell.spot'),
    value: 'sell',
  }];
};
