import { TLanguage, TTranslation } from 'shared/types';

export const introduction = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      introduction: {
        button: {
          create: 'Create trade',
          open: 'Open terminal',
        },
        welcome: {
          title: 'Welcome to Smart terminal',
          automate: {
            title: 'Automate routine',
            text: 'Set up multiple entries, take profit targets, and concurrent stop loss with conditional orders',
          },
          manage: {
            title: 'Manage positions',
            text: 'Live trade control, quick edits, manual actions and real-time statistic',
          },
          share: {
            title: 'Share trades',
            text: 'Easily share and manage multi-order positions to your community',
          },
          control: {
            title: 'Control downturns',
            text: 'Auto-sell and rebuy at lower prices during drops even at spot markets',
          },
        },
        steps: {
          1: 'Choose trading pair',
          2: 'Set entry conditions',
          3: 'Set close conditions',
          4: 'Manage trades in real time',
        },
        positions: {
          title: 'Create positions and relax',
          text: 'Place multi-order positions across major exchanges using a powerful and convenient order panel—no need to track charts anymore!',
        },
        perfomance: {
          title: 'Performed by our users',
          items: {
            trades: {
              placed: 'Trades placed',
              executed: 'Trades executed',
            },
            orders: 'Orders shared',
            volume: 'Volume traded',
          },
        },
        control: {
          title: 'Smart control over trades',
          text: 'Full real time management of active positions with progress bar and action buttons: control size, adjust \n risk management, close or cancel trade any time',
        },
        effortless: {
          title: 'Effortless Trade Sharing',
          text: 'Share multi-order position to your community with a link, allowing them to copy preset in a single click',
        },
        ending: {
          title: 'Take control with Smart terminal and beat the market',
          text: 'Leverage powerful features like multi-order execution, real-time analytics, and dynamic trade management to stay ahead of market trends and maximize your profits',
        },
      },
    },
    ru: {
      introduction: {
        button: {
          create: 'Создать сделку',
          open: 'Открыть терминал',
        },
        welcome: {
          title: 'Добро пожаловать в Умный терминал',
          automate: {
            title: 'Автоматизируйте рутину',
            text: 'Настраивайте множественные входы, цели по прибыли и одновременные стоп-лоссы с условными ордерами',
          },
          manage: {
            title: 'Управляйте позициями',
            text: 'Контроль сделок в реальном времени, редактирование на лету, ручное управление и мгновенная статистика',
          },
          share: {
            title: 'Делитесь сделками',
            text: 'Легко делитесь и управляйте мультиордерными позициями в своём сообществе',
          },
          control: {
            title: 'Контролируйте просадки',
            text: 'Автоматически продавайте и покупайте дешевле во время падений, даже на спотовых рынках',
          },
        },
        steps: {
          1: 'Выберите торговую пару',
          2: 'Задайте условия входа',
          3: 'Задайте условия закрытия',
          4: 'Управляйте сделками в реальном времени',
        },
        positions: {
          title: 'Создавайте позиции и отдыхайте',
          text: 'Размещайте мультиордерные позиции на ведущих биржах через мощную и удобную панель ордеров — больше не нужно следить за графиками!',
        },
        perfomance: {
          title: 'Результаты наших пользователей',
          items: {
            trades: {
              placed: 'Создано сделок',
              executed: 'Исполнено сделок',
            },
            orders: 'Поделились ордерами',
            volume: 'Торговый объём',
          },
        },
        control: {
          title: 'Умный контроль сделок',
          text: 'Полный контроль над активными позициями в реальном времени: следите за прогрессом, управляйте размером, настраивайте риски, закрывайте или отменяйте сделки в любой момент.',
        },
        effortless: {
          title: 'Поделиться сделкой - легко!',
          text: 'Делитесь мультиордерными позициями через ссылку, позволяя вашему сообществу копировать настройки в один клик',
        },
        ending: {
          title: 'Держите рынок под контролем с Умным терминалом',
          text: 'Используйте мощные функции: мультиордерное исполнение, аналитику в реальном времени и динамическое управление сделками, чтобы опережать рынок и увеличивать прибыль',
        },
      },
    },
  };

  return transaltion[type].introduction;
};
