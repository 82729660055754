import dayjs from 'dayjs';
import { changeMode } from 'entities/exchange/api';
import { createDemoExchangeAccount } from 'entities/exchange/api/create-demo-exchange-account';
import { getSnapshotsByDate } from 'entities/exchange/api/get-snapshots-by-date';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { deleteExchange } from 'features/delete-exchange/api/delete-exchange';
import { getDailyChange } from 'pages/home/components/aggregate/helpers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateExchange } from 'widgets/exchanges/api/update-exchange';
import { formatter, getExchangeAccountsUuids } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { IAccountListPageProps } from '../interfaces';

type IUseMyAccountsList = IAccountListPageProps;
export const useMyAccountsList = (props: IUseMyAccountsList) => {
  const {
    exchanges,
    refetchAccounts,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [modifiedExchanges, setModifiedExchanges] = useState<ExchangeAccount[]>([]);
  const [selectedExchange, setSelectedExchange] = useState<ExchangeAccount | null>(null);

  const isDemoMode = useSelector(getIsDemoMode);

  const {
    t, 
  } = useTranslation();

  const deleteExchangeHandler = async (uuid: string) => {
    try {
      const deleteExchangeResponse = await deleteExchange(uuid);
      if (!deleteExchangeResponse.success) {
        toast.error(t(`error.exchange.list.delete.message.${deleteExchangeResponse?.data?.message}`) as string);
        return;
      }

      toast.success(t('success.exchange.list.delete.title') as string);
      setModifiedExchanges((prev) => prev.filter((item) => item.exchangeAccountUuid !== uuid));

      if (!isDemoMode) {
        return;
      }
      
      const demoAccountResponse = await createDemoExchangeAccount();
      if (!demoAccountResponse?.data?.fields) {
        toast.error(t('error.exchange.demo.reset.title') as string);
        return;
      }
      
      refetchAccounts();
    } finally {
      
    }
  };

  const handleUpdateExchange = async (exchangeAccountUuid: string) => {
    setIsLoading(true);
    try {
      const responseForUpdatedExchange = await updateExchange(exchangeAccountUuid);
      if (responseForUpdatedExchange.success) {
        const accountForUpdate = responseForUpdatedExchange.data.accounts;
        if (!accountForUpdate?.length) {
          return;
        }
        
        setModifiedExchanges((prev) => {
          return prev.map((currentExchange) => {
            if (currentExchange.exchangeAccountUuid === exchangeAccountUuid) {
              return {
                ...currentExchange,
                ...accountForUpdate[0],
              };
            }

            return currentExchange;
          });
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const changeModeHandler = async (params: Record<'exchangeAccountUuid' | 'marginMode', string>) => {
    try {
      setIsLoading(true);
      const resposne = await changeMode({
        exchangeAccountUuid: params.exchangeAccountUuid,
        marginMode: params.marginMode,
      });
      if (!resposne.success) {
        return;
      }

      setModifiedExchanges((prev) => {
        return prev.map((currentExchange) => {
          if (currentExchange.exchangeAccountUuid === params.exchangeAccountUuid) {
            return {
              ...currentExchange,
              marginMode: params.marginMode as any,
            };
          }

          return currentExchange;
        });
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSnapshots = async () => {
    try {
      setIsLoading(true);
      
      const prevDate = dayjs().subtract(1, 'day');
      const prevDateFrom = prevDate.startOf('day');
      const prevDateTo = prevDate.endOf('day');

      const allAccountsSnapshots = await getSnapshotsByDate({
        skyrexUserUuid: getSkyrexUuid()!,
        exchangeAccountUuids: getExchangeAccountsUuids(exchanges),
        dates: [prevDateFrom, prevDateTo],
      });

      const accounts = allAccountsSnapshots?.data?.accounts as any[];
      
      const snapshotsArray = accounts.map((snapshot: any) => ({
        date: snapshot.createdDate,
        btcValue: snapshot.totalBtc,
        usdtValue: snapshot.totalUsdt,
      }));
      
      const mergedAccounts = exchanges.map((account: ExchangeAccount) => {
        const snapshot = accounts.find((snapshotAccount) => {
          return snapshotAccount.exchangeAccountUuid === account.exchangeAccountUuid;
        });
      
        if (snapshot) {
          const {
            usdtDailyChange,
            btcDailyChange,
          } = getDailyChange({
            exchange: {
              usdt: +formatter(account?.totalUsdt),
              btc: +formatter(account?.totalBtc),
            },
            snapshot: {
              usdt: +formatter(snapshot?.totalUsdt),
              btc: +formatter(snapshot?.totalBtc),
            },
          });
      
          return {
            ...account,
            usdtDailyChangeValue: formatter(usdtDailyChange.value),
            usdtDailyChangePercent: formatter(usdtDailyChange.percent),
            btcDailyChangeValue: formatter(btcDailyChange.value),
            btcDailyChangePercent: formatter(btcDailyChange.percent),
            snapshotsArray,
          };
        }

        return account;
      });

      setModifiedExchanges(mergedAccounts);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!exchanges.length) {
      return;
    }

    fetchSnapshots();
  }, [exchanges]);

  return {
    values: {
      isLoading,
      modifiedExchanges,
      selectedExchange,
    },
    handlers: {
      deleteExchangeHandler,
      setSelectedExchange,
      handleUpdateExchange,
      changeModeHandler,
    },
  };
};
