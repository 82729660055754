import { Box, Stack } from '@mui/material';
import { Segmented } from 'antd';
import { newTerminalActions } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'widgets';
import { Input, LabelInput, SingleSelect, Slider } from 'shared/ui';
import { useOrderPrice } from '../../active-trades/components/actions-modal-content/hooks/use-order-price';
import { ErrorMessage } from '../../error-message';
import { segments, triggerPriceSegments } from '../../fields/consts';
import { IFields } from '../../fields/interfaces';
import { action, inner, sliderWrapper, wrapper } from '../../fields/styles';
//@TODO CREATE NEW COMMON TYPE
export const BaseOrderSell = (props: IFields) => {
  const {
    isSkipBaseOrder,
    onOrderPriceChange,
    onTotalChange,
    onUnitsChange,
    onSliderChange,
    onTriggerPriceChange,
    onSkipBaseSliderChange,
    onBoughtPriceChange,
    onSkipBaseUnitsChange,
    onSkipBaseTotalChange,
  } = props;

  const {
    control,
    formState: {
      errors,
    },
    trigger,
    watch,
    setValue,
  } = useFormContext();

  const {
    t, 
  } = useTranslation();
  
  const dispatch = useDispatch();

  const currentSymbol = watch('currentSymbol');
  const quoteAsset = watch('quoteAsset');
  const orderType = watch('orderType');
  const conditionalOrderType = watch('conditionalOrderType');
  const selectedSide = watch('selectedSide');

  const orderPrice = watch('orderPrice');
  const triggerPrice = watch('triggerPrice');
  const boughtPrice = watch('boughtPrice');
  const skipBase = watch('skipBaseOrder');
  
  const changeSegmentHandler = (value: string) => {
    setValue('orderType', value);
  };

  const changeTriggerPriceSegmentHandler = (value: string) => {
    setValue('conditionalOrderType', value);
  };
  
  const basePriceFromStore = useSelector((state: any) => state.newTerminal.basePrice);
  
  const lastDispatchedPriceRef = useRef<number | null>(null);
  const prevBasePriceRef = useRef<number | null>(null);
  
  useEffect(() => {
    let newBasePrice: number | null = null;
    
    if (skipBase) {
      newBasePrice = Number(boughtPrice);
      dispatch(newTerminalActions.setBasePrice(newBasePrice));
    } else if (orderType === 'limit' || (orderType === 'conditional' && conditionalOrderType === 'limit')) {
      newBasePrice = Number(orderPrice);
      dispatch(newTerminalActions.setBasePrice(newBasePrice));
    }
    
    if (newBasePrice !== null) {
      lastDispatchedPriceRef.current = newBasePrice;
    }
  }, [
    orderPrice,
    skipBase,
    orderType, 
    conditionalOrderType,
    boughtPrice,
  ]);
  
  // useEffect для обработки triggerPrice
  useEffect(() => {
    if (!skipBase && orderType === 'conditional' && conditionalOrderType === 'market') {
      const newBasePrice = Number(triggerPrice);
      dispatch(newTerminalActions.setBasePrice(newBasePrice));
      lastDispatchedPriceRef.current = newBasePrice;
    }
  }, [
    triggerPrice,
    skipBase,
    orderType, 
    conditionalOrderType,
  ]);
  
  useEffect(() => {
    // Проверяем, изменилась ли цена в Redux
    const priceChanged = basePriceFromStore !== prevBasePriceRef.current;
      
    // Обновляем предыдущее значение
    prevBasePriceRef.current = basePriceFromStore;
      
    // Обновляем форму если:
    // 1. Цена изменилась
    // 2. И либо цена соответствует последней отправленной, либо
    //    предыдущая цена не соответствовала (значит обновление пришло извне, например из графика)
    if (priceChanged && basePriceFromStore) {
      const shouldUpdate = 
          lastDispatchedPriceRef.current === null || 
          basePriceFromStore === lastDispatchedPriceRef.current ||
          prevBasePriceRef.current !== lastDispatchedPriceRef.current;
        
      if (shouldUpdate) {
        if (skipBase) {
          setValue('boughtPrice', basePriceFromStore.toString());
          onBoughtPriceChange(basePriceFromStore.toString());
        }
        if (orderType === 'conditional' && conditionalOrderType === 'market') {
          setValue('triggerPrice', basePriceFromStore.toString());
          onTriggerPriceChange(basePriceFromStore.toString());
        }
        if (orderType === 'limit' || (orderType === 'conditional' && conditionalOrderType === 'limit')) {
          setValue('orderPrice', basePriceFromStore.toString());
          onOrderPriceChange(basePriceFromStore.toString());
        }
      }
    }
  }, [
    basePriceFromStore, 
    orderType, 
    conditionalOrderType, 
    skipBase,
  ]);
  
  useOrderPrice({ 
    currentSymbol, 
    onOrderPriceChange,
    forceUpdating: false,
  });
  
  const renderOrderPriceField = () => {
    if (orderType === 'conditional' && conditionalOrderType === 'market') {
      return null;
    }

    return (
      <Controller
        name='orderPrice'
        control={control}
        rules={{
          required: t('validation.terminal.required'),
          validate: (value) => {
            const numValue = Number(value);
            if (isNaN(numValue)) {
              return t('validation.terminal.number');
            }
        
            if (numValue < Number(currentSymbol.priceMin)) {
              return t('validation.terminal.min', {
                min: getTrailedZeroCutted(currentSymbol.priceMin),
              });
            }
            if (numValue > Number(currentSymbol.priceMax)) {
              return t('validation.terminal.max', {
                max: getTrailedZeroCutted(currentSymbol.priceMax),
              });
            }
            return true;
          },
        }}
        render={({
          field,
        }) => (
          <LabelInput
            label={(
              <Label title={t('terminal.panel.fields.price.origin')} />
            )}
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onOrderPriceChange(value);
              }
              trigger('orderPrice');
              trigger('total');
              trigger('units');
              trigger('slider');
            }}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
              field.onChange(formattedValue);
              onOrderPriceChange(formattedValue);
              trigger('orderPrice');
              trigger('slider');
              trigger('total');
              trigger('units');
            }}
            icon={currentSymbol.quoteAsset}
            disabled={orderType === 'market' && !isSkipBaseOrder}
            status={errors.orderPrice ? 'error' : undefined}
            error={errors?.orderPrice?.message?.toString()}
          />
        )}
      />
    );
  };

  const renderBoughtPriceField = () => (
    <Controller
      name='boughtPrice'
      control={control}
      rules={{
        required: t('validation.terminal.required'),
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) {
            return t('validation.terminal.number');
          }
        
          if (numValue < Number(currentSymbol.priceMin)) {
            return t('validation.terminal.min', {
              min: getTrailedZeroCutted(currentSymbol.priceMin),
            });
          }
          if (numValue > Number(currentSymbol.priceMax)) {
            return t('validation.terminal.max', {
              max: getTrailedZeroCutted(currentSymbol.priceMax),
            });
          }
          return true;
        },
      }}
      render={({
        field,
      }) => (
        <LabelInput
          label={(
            <Label title={t('terminal.panel.fields.price.skip.sell')} />
          )}
          value={field.value}
          onChange={(value) => {
            if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
              field.onChange(value);
              onBoughtPriceChange(value);
            }
            trigger('boughtPrice');
          }}
          onBlur={() => {
            const formattedValue = formatByPrecisionAndTrim(
              field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
            field.onChange(formattedValue);
            onBoughtPriceChange(formattedValue);
            trigger('boughtPrice');
          }}
          icon={currentSymbol.quoteAsset}
          status={errors.boughtPrice ? 'error' : undefined}
          error={errors?.boughtPrice?.message?.toString()}
        />
      )}
    />
  );

  const renderSkipBaseUnitsField = () => (
    <Controller
      name='skipBaseUnits'
      control={control}
      rules={{
        required: t('validation.terminal.required'),
      }}
      render={({
        field,
      }) => (
        <LabelInput
          label={(
            <Label title={t('terminal.panel.fields.units')} />
          )}
          value={field.value}
          onChange={(value) => {
            if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
              field.onChange(value);
              onSkipBaseUnitsChange(value);
            }
            trigger('skipBaseUnits');
          }}
          onBlur={() => {
            const formattedValue = formatByPrecisionAndTrim(
              field.value, currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
            field.onChange(formattedValue);
            onSkipBaseUnitsChange(formattedValue);
            trigger('skipBaseUnits');
          }}
          icon={currentSymbol.baseAsset}
          status={errors.skipBaseUnits ? 'error' : undefined}
          error={errors?.skipBaseUnits?.message?.toString()}
        />
      )}
    />
  );

  const renderUnitsField = () => (
    <Controller
      name='units'
      control={control}
      rules={{
        required: t('validation.terminal.required'),
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) {
            return t('validation.terminal.number');
          }
        
          if (numValue < Number(currentSymbol.lotMin)) {
            return t('validation.terminal.min', {
              min: getTrailedZeroCutted(currentSymbol.lotMin),
            });
          }
          if (numValue > Number(currentSymbol.lotMax)) {
            return t('validation.terminal.max', {
              max: getTrailedZeroCutted(currentSymbol.lotMax),
            });
          }
          return true;
        },
      }}
      render={({
        field,
      }) => (
        <LabelInput
          label={(
            <Label title={t('terminal.panel.fields.units')} />
          )}
          value={field.value}
          onChange={(value) => {
            if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
              field.onChange(value);
              onUnitsChange(value);
            }
            trigger('total');
            trigger('units');
            trigger('slider');
          }}
          onBlur={() => {
            const formattedValue = formatByPrecisionAndTrim(
              field.value, currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
            field.onChange(formattedValue);
            onUnitsChange(formattedValue);
            trigger('units');
            trigger('total');
            trigger('slider');
          }}
          icon={currentSymbol.baseAsset}
          status={errors.units ? 'error' : undefined}
          error={errors?.units?.message?.toString()}
        />
      )}
    />
  );

  const renderSkipBaseTotalField = () => (
    <Controller
      name='skipBaseTotal'
      control={control}
      rules={{
        required: t('validation.terminal.required'),
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) {
            return t('validation.terminal.number');
          }
        
          if (numValue < Number(currentSymbol.minNotional)) {
            return t('validation.terminal.min', {
              min: getTrailedZeroCutted(currentSymbol.minNotional),
            });
          }
          if (numValue > Number(currentSymbol.maxNotional)) {
            return t('validation.terminal.max', {
              max: getTrailedZeroCutted(currentSymbol.maxNotional),
            });
          }
          return true;
        },
      }}
      render={({
        field,
      }) => (
        <LabelInput
          label={(
            <Label title={t('terminal.panel.fields.total')} />
          )}
          value={field.value}
          onChange={(value) => {
            if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
              field.onChange(value);
              onSkipBaseTotalChange(value);
            }
            trigger('skipBaseTotal');
          }}
          onBlur={() => {
            const formattedValue = formatByPrecisionAndTrim(
              field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
            field.onChange(formattedValue);
            onSkipBaseTotalChange(formattedValue);
            trigger('skipBaseTotal');
          }}
          icon={currentSymbol.quoteAsset}
          status={errors.skipBaseTotal ? 'error' : undefined}
          error={errors?.skipBaseTotal?.message?.toString()}
        />
      )}
    />
  );

  const renderTotalField = () => (
    <Controller
      name='total'
      control={control}
      rules={{
        required: t('validation.terminal.required'),
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) {
            return t('validation.terminal.number');
          }
        
          if (numValue < Number(currentSymbol.minNotional)) {
            return t('validation.terminal.min', {
              min: getTrailedZeroCutted(currentSymbol.minNotional),
            });
          }
          if (numValue > Number(currentSymbol.maxNotional)) {
            return t('validation.terminal.max', {
              max: getTrailedZeroCutted(currentSymbol.maxNotional),
            });
          }
          return true;
        },
      }}
      render={({
        field,
      }) => (
        <LabelInput
          label={(
            <Label title={t('terminal.panel.fields.total')} />
          )}
          value={field.value}
          onChange={(value) => {
            if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
              field.onChange(value);
              onTotalChange(value);
            }
            trigger('total');
            trigger('slider');
          }}
          onBlur={() => {
            const formattedValue = formatByPrecisionAndTrim(
              field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
            field.onChange(formattedValue);
            onTotalChange(formattedValue);
            trigger('total');
            trigger('units');
            trigger('slider');
          }}
          icon={quoteAsset}
          status={errors.total ? 'error' : undefined}
          error={errors?.total?.message?.toString()}
        />
      )}
    />
  );

  const renderSliderForSkipBaseOrder = () => (
    <Box sx={sliderWrapper}>
      <Controller
        name='skipBaseSlider'
        control={control}
        render={({
          field,
        }) => (
          <Slider
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              onSkipBaseSliderChange(value);
              trigger('skipBaseSlider');
            }}
          />
        )}
      />

      <Controller
        name='skipBaseSlider'
        control={control}
        render={({
          field,
        }) => (
          <Input
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              onSkipBaseSliderChange(parseFloat(value));
              trigger('skipBaseSlider');
            }}
            placeholder='100%'
            maxWidth={58}
            icon='%'
          />
        )}
      />
    </Box>
  );

  const renderSlider = () => (
    <Stack height={53}>
      <Box sx={sliderWrapper}>
        <Controller
          name='slider'
          control={control}
          rules={{
            max: {
              value: 100,
              message: t('validation.terminal.slider.max'),
            },
            min: {
              value: 1,
              message: t('validation.termnial.slider.min'),
            },
          }}
          render={({
            field,
          }) => (
            <Slider
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                onSliderChange(value);
                trigger('slider');
                trigger('total');
                trigger('units');
              }}
            />
          )}
        />

        <Controller
          name='slider'
          control={control}
          render={({
            field,
          }) => (
            <Input
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onSliderChange(parseFloat(value));
                  trigger('slider');
                  trigger('total');
                  trigger('units');
                }
              }}
              placeholder='100%'
              maxWidth={58}
              icon='%'
              status={errors.slider ? 'error' : undefined}
            />
          )}
        />
      </Box>

      <ErrorMessage message={errors?.slider?.message} />
    </Stack>
  );

  const renderConditionalFields = () => (
    <>
      <Box sx={action}>
        <Controller
          name='triggerPrice'
          control={control}
          rules={{
            required: t('validation.terminal.required'),
            validate: (value) => {
              const numValue = Number(value);
              if (isNaN(numValue)) {
                return t('validation.terminal.number');
              }
        
              if (numValue < Number(currentSymbol.priceMin)) {
                return t('validation.terminal.min', {
                  min: getTrailedZeroCutted(currentSymbol.priceMin),
                });
              }
              if (numValue > Number(currentSymbol.priceMax)) {
                return t('validation.terminal.max', {
                  max: getTrailedZeroCutted(currentSymbol.priceMax),
                });
              }
              return true;
            },
          }}
          render={({
            field,
          }) => (
            <LabelInput
              label={(
                <Label title={t('terminal.panel.fields.triggerPrice.title')} />
              )}
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onTriggerPriceChange(value);
                  trigger('triggerPrice');
                }
              }}
              onBlur={() => {
                const formattedValue = formatByPrecisionAndTrim(
                  field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                field.onChange(formattedValue);
                onTriggerPriceChange(formattedValue);
                trigger('triggerPrice');
              }}
              icon={quoteAsset}
              status={errors.triggerPrice ? 'error' : undefined}
              error={errors?.triggerPrice?.message?.toString()}
            />
          )}
        />

        <Controller
          name='triggerPriceType'
          control={control}
          render={({
            field,
          }) => (
            <SingleSelect
              maxWidth='max-content'
              select={{
                value: field.value,
                placeholder: 'Last',
                onChange: (value) => {
                  field.onChange(value);
                  trigger('triggerPriceType');
                },
              }}
              options={[{
                label: t('terminal.panel.fields.types.last'),
                value: 'last',
              }, {
                label: t('terminal.panel.fields.types.bid'),
                value: 'bid',
              }, {
                label: t('terminal.panel.fields.types.ask'),
                value: 'ask',
              }]}
            />
          )}
        />
      </Box>

      <Controller
        name='conditionalOrderType'
        control={control}
        render={({
          field,
        }) => (
          <Segmented
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              changeTriggerPriceSegmentHandler(value);
            }}
            options={triggerPriceSegments(t)}
            block={true}
          />
        )}
      />
    </>
  );

  const getContent = () => {
    if (isSkipBaseOrder) {
      return (
        <>
          {renderBoughtPriceField()}
          {renderSkipBaseUnitsField()}
          {renderSkipBaseTotalField()}
          {renderSliderForSkipBaseOrder()}
        </>
      );
    }

    // TODO: Здесь надо отследить тип spot или futures и поменять поля местами
    if (selectedSide === 'short') {
      return (
        <>
          {renderOrderPriceField()}
          {renderUnitsField()}
          {renderTotalField()}
          {renderSlider()}
        </>  
      );
    }
    
    return (
      <>
        {renderOrderPriceField()}
        {renderTotalField()}
        {renderUnitsField()}
        {renderSlider()}
      </>
    );
  };

  return (
    <Box sx={wrapper}>
      {!isSkipBaseOrder && (
        <Segmented
          value={orderType}
          onChange={changeSegmentHandler}
          options={segments(t)}
          block={true}
          size='large'
        />
      )}

      {orderType === 'conditional' && !isSkipBaseOrder && renderConditionalFields()}

      <Box sx={inner}>
        {getContent()}
      </Box>
    </Box>
  );
};

export default BaseOrderSell;
