import { IRoute } from '../interfaces';

interface IGenerateOauthParams {
  platform: string;
  isUpdate?: boolean;
}
export const generateOauthParams = (params: IGenerateOauthParams): IRoute => {
  if (params.isUpdate) {
    return {
      path: `/oauth/update/${params.platform}`,
      navigate: () => `/oauth/update/${params.platform}`,
    };
  }

  return {
    path: `/oauth/create/${params.platform}`,
    navigate: () => `/oauth/create/${params.platform}`,
  };
};
