import { TLanguage, TTranslation } from 'shared/types';

export const successRegister = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      register: {
        title: 'We`ve sent you an email!',
        message: 'Almost there! Check your email to activate account',
      },
    },
    ru: {
      register: {
        title: 'Мы отправили вам письмо!',
        message: 'Почти готово! Проверьте почту для активации',
      },
    },
  };

  return transaltion[type].register;
};
