import { appPath } from 'shared/config/route-config/app-routes/consts';
import { TAppPages } from 'shared/config/route-config/types/app-pages';
import { Candles, History, Info } from 'shared/icons';
import { IMenu } from '../interfaces';

export const termnialMenu = (t: any) => {
  const item: IMenu<TAppPages> = {
    key: 'manual_trading_trading_terminal',
    label: t('header.menu.terminal.title'),
    children: [{
      key: 'manual_trading_trading_terminal',
      icon: Candles,
      info: {
        label: t('header.menu.terminal.items.terminal.title'),
        text: t('header.menu.terminal.items.terminal.text'),
      },
      path: appPath.manual_trading_trading_terminal.path,
    }, {
      key: 'manual_trading_trading_history',
      icon: History,
      info: {
        label: t('header.menu.terminal.items.diary.title'),
        text: t('header.menu.terminal.items.diary.text'),
      },
      path: appPath.manual_trading_trading_history.path,
    }, {
      key: 'manual_trading_introduction',
      icon: Info,
      info: {
        label: t('header.menu.terminal.items.introduction.title'),
        text: t('header.menu.terminal.items.introduction.text'),
      },
      path: appPath.manual_trading_introduction.path,
    }],
  };

  return item;
};
