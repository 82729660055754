import { Stack } from '@mui/material';
import { Input } from 'antd';
import { ChangeEvent } from 'react';
import { Text } from 'shared/ui';
import { ILabelInputProps } from '../interfaces';
import { ERROR, input, labelText, WRAPPER } from '../styles';

export const LabelInput = (props: ILabelInputProps) => {
  const {
    label,
    value,
    onChange,
    placeholder,
    type,
    autoComplete,
    suffix,
    prefix,
    mask,
    status,
    disabled,
    icon,
    onBlur,
    onPaste,
    style,
    error,
    addonAfter,
    addonBefore,
  } = props;

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.value);
  };

  const onPasteHandler = (event: React.ClipboardEvent<HTMLInputElement>) => {
    if(!onPaste) {
      return;
    }

    onPaste(event.clipboardData?.getData('text'));
  };

  const getContent = () => {
    if (mask) {
      return (
        <Input.Password
          disabled={disabled}
          type={type}
          size='large'
          value={value}
          autoComplete={autoComplete || 'new-password'}
          placeholder={placeholder}
          onChange={onChangeHandler}
          style={{
            ...input,
            ...style,
          }}
          suffix={icon || suffix}
          status={error ? 'error' : status}
          prefix={prefix}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
        />
      );
    }

    return (
      <Input
        disabled={disabled}
        type={type}
        size='large'
        value={value}
        autoComplete={autoComplete || 'new-password'}
        placeholder={placeholder}
        onChange={onChangeHandler}
        style={{
          ...input,
          ...style,
        }}
        suffix={icon || suffix}
        status={error ? 'error' : status}
        prefix={prefix}
        onBlur={() => onBlur && onBlur(value)}
        onPaste={onPasteHandler}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
      />
    );
  };

  return (
    <Stack
      gap='8px'
      flex={1}
      position='relative'
    >
      {label && (
        <Text
          type='secondary'
          styles={labelText}
        >
          {label}
        </Text>
      )}

      <Stack sx={WRAPPER}>
        {getContent()}
      </Stack>
      
      {error && (
        <Text
          type='danger'
          styles={ERROR}
        >
          {error}
        </Text>
      )}
    </Stack>
  );
};
