import { Box, Stack } from '@mui/material';
import { Segmented } from 'antd';
import { useEffect, useState } from 'react';
import { Label } from 'widgets/label';
import { ITabs } from '../interfaces';
import { wrapper } from '../styles';

export const Tabs = (props: ITabs) => {
  const {
    tabs,
    content,
    maxWidth,
    styles,
    defaultValue,
    segmentStyles,
    getTab,
    label,
    segmentPostFixContent,
  } = props;

  const [selected, setSelected] = useState<number>(defaultValue || 0);

  const changeTab = (value: number) => {
    setSelected(value);
    getTab?.(value);
  };

  useEffect(() => {
    if (!defaultValue) {
      return;
    }
    
    setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <Box sx={wrapper(styles)}>
      <Stack
        gap='104px'
        width='100%'
        alignItems='flex-end'
        direction='row'
        sx={{
          ...styles,
        }}
      >
        <Stack
          gap='8px'
          flex={1}
          maxWidth={425}
        >
          {label && (
            <Label
              title={label.value}
              tooltip={label.tooltiop}
            />
          )}

          <Segmented
            value={selected}
            block={true}
            style={{
              width: '100%',
              maxWidth: maxWidth || 563,
              alignSelf: 'center',
              textAlign: 'center',
              ...segmentStyles,
            }}
            size='large'
            options={tabs}
            onChange={changeTab}
          />
        </Stack>

        {segmentPostFixContent}
      </Stack>
      
      <Box
        sx={{
          width: '100%',
        }}
      >
        {content[selected]}
      </Box>
    </Box>
  );
};
