import { TLanguage, TTranslation } from 'shared/types';

export const validationChangePassword = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      changePassword: {
        match: 'Passwords don’t match!',
      },
    },
    ru: {
      changePassword: {
        match: 'Пароли не совпадают!',
      },
    },
  };

  return transaltion[type].changePassword;
};
