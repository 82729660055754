import { TradingTerminal } from 'pages/manual-trading';
import Introduction from 'pages/manual-trading/introduction/ui/introduction';
import ManualTradingHistory from 'pages/manual-trading/trading-history/ui/trading-history';
import { appPath } from '../app-routes/consts';
import { IPage } from '../interfaces';

export const terminalRoutes = () => {
  const routes: IPage[] = [{
    title: 'Trading terminal',
    path: appPath.manual_trading_trading_terminal.path,
    element: (
      <TradingTerminal />
    ),
  }, {
    title: 'Trading diary',
    path: appPath.manual_trading_trading_history.path,
    element: (
      <ManualTradingHistory />
    ),
  }, {
    title: 'Manual trading introduction',
    path: appPath.manual_trading_introduction.path,
    element: (
      <Introduction />
    ),
  }];

  return routes;
};
