import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  borderRadius: 3,
  border: '1px solid rgba(235, 255, 247, 0.90)',
  background: 'linear-gradient(75deg, rgba(38, 63, 57, 0.11) 4.09%, rgba(196, 196, 196, 0.11) 97.37%)',
  boxShadow: '-2px 0px 6.8px 0px rgba(255, 255, 255, 0.12) inset, 0px 4px 4px 0px rgba(255, 255, 255, 0.12) inset, 0px 4px 22.9px 0px rgba(0, 0, 0, 0.05)',
  backdropFilter: 'blur(7.25px)',
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  padding: 3,
  margin: '0 5px',
};
