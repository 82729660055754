import { Colors } from 'shared/consts/colors';
import { formatter } from 'shared/helpers';

export const getTooltip = (params: Props) => {
  const {
    quoteAsset, 
    isCopyBot,
  } = params;
  
  const tooltip: echarts.TooltipComponentOption = {
    trigger: 'axis',
    padding: 0,
    formatter: (params: any) => {      
      const value = String(formatter(params[0].value));
      const color = +value >= 0 ? Colors.GREEN : Colors.RED;

      let result = `${value} ${quoteAsset.toUpperCase()}`;
      if (isCopyBot) {
        result = `${value}%`;
      }

      return `
        <div style='display: flex; flex-direction: column; align-items: center; gap: 4px; background-color: #fff; padding: 14px 25px; font-family: Poppins;'>
          <h3 style='fontSize: 12px; font-weight: 400; color: #3A3A3C; margin: 0;'>
            ${params[0].name}
          </h3>

          <strong style='color: ${color}; font-size: 14px; font-weight: 600;'>
            ${result}
          </strong>
        </div>
      `;
    },
  };
  
  return tooltip;
};

type Props = {
  quoteAsset: string;
  isCopyBot?: boolean;
};
