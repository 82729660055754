import { Stack } from '@mui/material';
import { BlockTitle } from 'pages/trading-bots/configurator-v2/components/block-title';
import { IStepProps } from 'pages/trading-bots/configurator-v2/interfaces';
import { CloseOrders } from 'pages/trading-bots/configurator-v2/views/close-orders';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { options, tooltip } from '../consts';

type ICloseProps = IStepProps;
export const Close = (props: ICloseProps) => {
  const {
    watch,
    setValue,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();

  const close = watch('close');
  const isOpened = close.isOpened;
  const segment = close.segment.value;

  const toggleHandler = (value: boolean) => {
    close.isOpened = value;
    setValue('close', close);
  };

  const changeCustomSegmentHandler = (value: string) => {
    close.segment.value = value;
    setValue('close', close);
  };


  const getSegment = () => {
    if (!isOpened) {
      return undefined;
    }

    return {
      selected: segment,
      options: options(t),
      maxWidth: 258,
      change: changeCustomSegmentHandler,
      tooltip: tooltip(t),
    };
  };
  
  return (
    <Stack gap='20px'>
      <BlockTitle
        title={t('configurator.managment.custom.close.title')}
        toggle={{
          toggled: isOpened,
          change: toggleHandler,
        }}
        segment={getSegment()}
      />

      <CloseOrders
        handleBack={props.handleBack}
        createBot={props.createBot}
        changeCurrentStep={props.changeCurrentStep}
        currentStep={props.currentStep}
      />
    </Stack>
  );
};
