import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { Lock } from 'shared/icons';
import { ChangePasswordModal, Icon } from 'shared/ui';
import { ListItem } from '../../../../list-item';

export const ChangePassword = () => {
  const {
    t, 
  } = useTranslation();
  
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false);
  
  const modalHandler = () => {
    setChangePasswordModalOpen((prev) => !prev);
  };

  return (
    <Fragment>
      <ListItem
        title={t('header.popup.changePassword')}
        icon={(
          <Icon width={16}>
            {Lock}
          </Icon>
        )}
        handler={modalHandler}
      />

      <ChangePasswordModal
        isOpen={changePasswordModalOpen}
        handleClose={modalHandler}
      />
            
      {/* <EmptySubscription
              isOpen={successModalOpen}
              modalTitle={t('success.changePassword.title')}
              modalDescription=''
              handleClose={modalStateHabdler(setSuccessModalOpen)}
              button={{
                title: t('success.changePassword.button'),
                action: modalStateHabdler(setSuccessModalOpen),
              }}
            />
            
            <EmptySubscription
              isOpen={failureModalOpen}
              modalTitle={t('error.changePassword.title')}
              modalDescription=''
              handleClose={modalStateHabdler(setFailureModalOpen)}
              button={{
                title: t('error.changePassword.button'),
                action: modalStateHabdler(setFailureModalOpen),
              }}
              isError={true}
            /> */}
    </Fragment>
  );
};
