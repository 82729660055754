import { IRiskManagmentAdditional } from 'entities/risk-managment-additional/interfaces';

export const defaultAdditionalStepValues = () => {
  const defaultValues: IRiskManagmentAdditional = {
    isOpened: true,
    segment: {
      value: 'preset',
      preset: {
        orders: 1,
        priceChange: -3,
        ordersSizeScale: 1,
        ordersPriceChangeScale: 1,
        orderType: 'market',
      },
      alert: {
        orders: 1,
        ordersSizeScale: 1,
        orderType: 'market',
      },
    },
  };

  return defaultValues;
};
