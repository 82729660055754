import { TLanguage, TTranslation } from 'shared/types';

export const managment = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      managment: {
        tabs: {
          auto: 'Auto',
          custom: 'Custom',
          label: {
            title: 'Choose risk management type',
            tooltip: '',
          },
        },
        auto: {
          presets: [{
            title: 'Conservative',
            settings: {
              display: [{
                title: 'Overlap',
                value: '-30%',
              }, {
                title: 'Take profit',
                value: '4%',
              }, {
                title: 'Stop loss',
                value: 'Disabled',
              }],
            }, 
            caption: 'Dollar-Cost Averaging with widening entries',
            description: 'Larger buys at lower prices, perfect for low-risk position building in volatile markets',
          }, {
            title: 'Aggressive',
            settings: {
              display: [{
                title: 'Overlap',
                value: 'Disabled',
              }, {
                title: 'Take profit',
                value: '5%',
              }, {
                title: 'Stop loss',
                value: '-2%',
              }],
            },
            caption: 'Momentum trading with high reward-risk ratio',
            description: 'Target strong uptrends with wide stop loss and take profit for maximum gains',
          }, {
            title: 'Moderate',
            settings: {
              display: [{
                title: 'Overlap',
                value: '-10%',
              }, {
                title: 'Take profit',
                value: '6%',
              }, {
                title: 'Stop loss',
                value: '-4%',
              }],
            },
            caption: 'Swing trading with distributed entries',
            description: 'Capture market swings in sideways or moderate uptrends with natural corrections',
          }],
        },
        custom: {
          additional: {
            title: 'Additional entries',
            tabs: {
              preset: 'Preset',
              alert: 'Alerts',
              tooltip: {
                preset: 'Preset: Enables you to configure additional entries that activate when specific price levels are reached.',
                alert: 'Alert: Allows you to dynamically open additional entries based on alerts from your indicators and strategies.',
              },
            },
          },
          close: {
            title: 'Close orders',
            tabs: {
              preset: 'Preset',
              alert: 'Alerts',
              tooltip: {
                preset: 'Preset: Enables you to configure close orders that activate when specific price levels are reached.',
                alert: 'Alert: Allows you to dynamically close positions based on alerts from your and strategies.',
              },
            },
          },
          take: {
            title: 'Take profit',
          },
          stop: {
            title: 'Close orders',
          },
        },
        panel: {
          common: {
            entry: {
              title: 'Entry order',
              item: {
                title: 'Open order size',
              },
            },
            additional: {
              title: 'Additional entry orders',
              headers: {
                order: 'Order size',
                price: 'Price change',
              },
            },
            full: 'Full position per coin',
            close: {
              title: 'Close orders',
              take: {
                title: 'Take profit',
              },
              stop: {
                title: 'Stop loss',
              },
            },
          },
          additional: {
            entry: {
              title: 'Entry order',
              item: {
                title: 'Open order size',
              },
            },
            additional: {
              title: 'Additional entry orders',
              headers: {
                order: 'Order size',
                price: 'Price change',
                alert: 'Alert',
                script: 'PineScript',
                json: 'JSON body',
              },
              button: 'Copy',
              full: 'Full position per coin',
            },
          },
          close: {
            title: 'Close orders',
            table: {
              title: 'Take profits',
              headers: {
                volume: 'Volume',
                price: 'Price change',
              },
            },
            stop: 'Stop loss',
          },
        },
      },
    },
    ru: {
      managment: {
        tabs: {
          auto: 'Авто',
          custom: 'Свое',
          label: {
            title: 'Выберите тип риск-менеджмента',
            tooltip: '',
          },
        },
        auto: {
          presets: [
            {
              title: 'Консервативный',
              settings: {
                display: [
                  {
                    title: 'Overlap', value: '-30%', 
                  },
                  {
                    title: 'Take profit', value: '4%', 
                  },
                  {
                    title: 'Stop loss', value: 'Disabled', 
                  },
                ],
              },
              caption: 'Усреднение (DCA) с широкими точками входа',
              description:
                'Крупные покупки при снижении цены, снижая риск в волатильных рынках',
            },
            {
              title: 'Агрессивный',
              settings: {
                display: [
                  {
                    title: 'Overlap', value: 'Disabled', 
                  },
                  {
                    title: 'Take profit', value: '5%', 
                  },
                  {
                    title: 'Stop loss', value: '-2%', 
                  },
                ],
              },
              caption: 'Торговля на импульсе с высоким отношением риск/прибыль',
              description:
                'Работает на сильных восходящих трендах, широкий SL и TP для макс. прибыли',
            },
            {
              title: 'Умеренный',
              settings: {
                display: [
                  {
                    title: 'Overlap', value: '-10%', 
                  },
                  {
                    title: 'Take profit', value: '6%', 
                  },
                  {
                    title: 'Stop loss', value: '-4%', 
                  },
                ],
              },
              caption: 'Свинг-трейдинг с распределёнными входами',
              description:
                'Улавливает колебания в боковом рынке или плавном ап-тренде',
            },
          ],
        },
        custom: {
          additional: {
            title: 'Доп. входы',
            tabs: {
              preset: 'Пресет',
              alert: 'Алерт',
              tooltip: {
                preset:
                  'Пресет: настройка дополнительных входов при достижении цены',
                alert:
                  'Алерт: динамический вход с алертами индикаторов/стратегий',
              },
            },
          },
          close: {
            title: 'Закрывающие ордеры',
            tabs: {
              preset: 'Пресет',
              alert: 'Алерт',
              tooltip: {
                preset:
                  'Пресет: настройка закрытий при достижении цены',
                alert:
                  'Алерт: динамическое закрытие через алерты индикаторов/стратегий',
              },
            },
          },
          take: {
            title: 'Тейк профит',
          },
          stop: {
            title: 'Закрывающие ордеры',
          },
        },
        panel: {
          common: {
            entry: {
              title: 'Открывающий ордер',
              item: {
                title: 'Размер ордера входа',
              },
            },
            additional: {
              title: 'Доп.ордера входа',
              headers: {
                order: 'Размер ордера',
                price: 'Изменение цены',
              },
            },
            full: 'Полная позиция на монету',
            close: {
              title: 'Ордера закрытия',
              take: {
                title: 'Тейк профит',
              },
              stop: {
                title: 'Стоп лосс',
              },
            },
          },
          additional: {
            entry: {
              title: 'Ордер входа',
              item: {
                title: 'Размер ордера входа',
              },
            },
            additional: {
              title: 'Доп.ордера входа',
              headers: {
                order: 'Размер ордера',
                price: 'Изменение цены',
                alert: 'Алерт',
                script: 'PineScript',
                json: 'JSON',
              },
              button: 'Копировать',
              full: 'Полная позиция на монету',
            },
          },
          close: {
            title: 'Закрывающие ордеры',
            table: {
              title: 'Тейк профиты',
              headers: {
                volume: 'Объем',
                price: 'Изменение цены',
              },
            },
            stop: 'Стоп лосс',
          },
        },
      },
    },
  };

  return transaltion[type].managment;
};
