import { TLanguage, TTranslation } from 'shared/types';

export const modalWithdraw = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      withdraw: {
        title: 'Withdrawal request',
        description: 'Provide TRC-20 wallet address only',
        amount: {
          title: 'Amount to withdraw (available: {{available}} USDT)',
          placeholder: 'Enter amount (min. 10)',
        },
        wallet: {
          title: 'TRC-20 wallet',
          placeholder: 'Your TRC-20 wallet address',
        },
        button: 'Submit',
      },
    },
    ru: {
      withdraw: {
        title: 'Запрос на вывод',
        description: 'Используйте только TRC-20 адрес',
        amount: {
          title: 'Сумма вывода (доступно: {{available}} USDT)',
          placeholder: 'Введите сумму (мин. 10)',
        },
        wallet: {
          title: 'TRC-20 кошелёк',
          placeholder: 'Ваш TRC-20 кошелек',
        },
        button: 'Отправить',
      },
    },
  };

  return transaltion[type].withdraw;
};
