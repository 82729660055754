import { TLanguage, TTranslation } from 'shared/types';

export const successRecover = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      recover: {
        title: 'We`ve sent you an email!',
        message: 'If there\'s an account associated with this email, you\'ll receive a recovery link shortly. Please check your inbox and follow the instructions to reset your password.',
      },
    },
    ru: {
      recover: {
        title: 'Мы отправили вам письмо!',
        message:
          'Если аккаунт связан с этим email, вы получите ссылку для сброса. Проверьте почту и следуйте инструкциям',
      },
    },
  };

  return transaltion[type].recover;
};
