import { TLanguage, TTranslation } from 'shared/types';
import { basic } from './basic';
import { managment } from './managment';
import { source } from './source';

export const configurator = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      configurator: {
        steps: {
          1: 'Step 1 - Set account, market and amount',
          2: 'Step 2 - Configure entry order alerts',
          3: 'Step 3 - Set overlap and close orders',
          4: 'Step 4 - Configure close orders',
          5: 'Step 5 - Create alert message',
        },
        button: {
          next: 'Next step',
          back: 'Back',
          create: 'Create bot',
          update: 'Update bot',
          builder: 'Open signal builder',
        },
        basic: basic('en'),
        source: source('en'),
        managment: managment('en'),
      },
    },
    ru: {
      configurator: {
        steps: {
          1: 'Шаг 1 - Выберите аккаунт, рынок и сумму',
          2: 'Шаг 2 - Настройте алерты для ордеров входа',
          3: 'Шаг 3 - Укажите усреднение и закрытия',
          4: 'Шаг 4 - Настройте ордера закрытия',
          5: 'Шаг 5 - Создайте текст алерта',
        },
        button: {
          next: 'Следующий шаг',
          back: 'Назад',
          create: 'Создать бота',
          update: 'Обновить бота',
          builder: 'Открыть конфигуратор сигналов',
        },
        basic: basic('ru'),
        source: source('ru'),
        managment: managment('ru'),
      },
    },
  };

  return transaltion[type].configurator;
};
