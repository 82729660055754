import { Switch } from 'antd';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useAuth } from 'shared/hooks';

export const DemoMode = () => {
  const {
    t, 
  } = useTranslation();
  const {
    isLoading,
    handlers,
  } = useAuth();
  
  const isDemoMode = useSelector(getIsDemoMode);
  
  const onChangeHandler = async (value: boolean) => {
    if (!value) {
      const error = await handlers.disableDemoAccountHandle();
      if (error) {
        toast.error(error);
      }

      return;
    }

    const error = await handlers.enableDemoAccountHandler();
    if (error) {
      toast.error(error);
    }
  };
  
  return (
    <Switch
      value={isDemoMode}
      checkedChildren={t('header.switch')}
      unCheckedChildren={t('header.switch')}
      onChange={onChangeHandler}
      disabled={isLoading}
    />
  );
};
