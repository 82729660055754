import { TLanguage, TTranslation } from 'shared/types';

export const errorOtp = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      otp: {
        incorrect: {
          title: 'OTP is incorrect',
          message: 'Please check the code and try again',
        },
        ownership: {
          title: 'Verify account ownership',
          button: 'Okay',
          message: {
            preffix: 'Contact support at',
            suffix: 'to proceed with 2FA reset.',
          },
        },
      },
    },
    ru: {
      otp: {
        incorrect: {
          title: 'Неверный OTP',
          message: 'Проверьте код и повторите',
        },
        ownership: {
          title: 'Подтвердите владение аккаунтом',
          button: 'Ок',
          message: {
            preffix: 'Обратитесь в поддержку по адресу',
            suffix: 'для сброса 2FA.',
          },
        },
      },
    },
  };

  return transaltion[type].otp;
};
