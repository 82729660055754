export const ByBitIcon = (
  <svg width='76' height='76' viewBox='0 0 76 76' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='38' cy='38' r='38' fill='url(#paint0_linear_8654_198)'/>
    <path d='M50.1261 43.1478V28.5H53.0704V43.1478H50.1261Z' fill='#F7A600'/>
    <path d='M15.812 47.4989H9.5V32.8511H15.5582C18.5025 32.8511 20.218 34.4557 20.218 36.9657C20.218 38.5905 19.1162 39.6405 18.3536 39.9901C19.2639 40.4013 20.4291 41.3269 20.4291 43.2823C20.4291 46.0175 18.5025 47.4989 15.812 47.4989ZM15.3251 35.4026H12.4443V38.7765H15.3251C16.5746 38.7765 17.2738 38.0975 17.2738 37.089C17.2738 36.0816 16.5746 35.4026 15.3251 35.4026ZM15.5155 41.3482H12.4443V44.9485H15.5155C16.8503 44.9485 17.4848 44.1261 17.4848 43.1377C17.4848 42.1505 16.8491 41.3482 15.5155 41.3482Z' fill='white'/>
    <path d='M29.4095 41.4916V47.4989H26.486V41.4916L21.9531 32.8511H25.1512L27.9686 38.7552L30.7433 32.8511H33.9414L29.4095 41.4916Z' fill='white'/>
    <path d='M42.2884 47.4989H35.9764V32.8511H42.0346C44.9788 32.8511 46.6944 34.4557 46.6944 36.9657C46.6944 38.5905 45.5926 39.6405 44.83 39.9901C45.7403 40.4013 46.9056 41.3269 46.9056 43.2823C46.9056 46.0175 44.9788 47.4989 42.2884 47.4989ZM41.8015 35.4026H38.9207V38.7765H41.8015C43.051 38.7765 43.7501 38.0975 43.7501 37.089C43.7501 36.0816 43.051 35.4026 41.8015 35.4026ZM41.9918 41.3482H38.9207V44.9485H41.9918C43.3267 44.9485 43.9612 44.1261 43.9612 43.1377C43.9612 42.1505 43.3267 41.3482 41.9918 41.3482Z' fill='white'/>
    <path d='M62.5599 35.4026V47.5H59.6156V35.4026H55.6757V32.8511H66.4998V35.4026H62.5599Z' fill='white'/>
    <defs>
      <linearGradient id='paint0_linear_8654_198' x1='63.3333' y1='7.6' x2='12.6667' y2='76' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#2B2D3D'/>
        <stop offset='1' stopColor='#1B1F30'/>
      </linearGradient>
    </defs>
  </svg>
);
