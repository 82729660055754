import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { authPath } from 'shared/config/route-config/auth-routes/consts';
import { getRefreshToken } from 'shared/helpers/storage-helper';

export const ProtectedRoute = (props: Record<'children', ReactNode>) => {
  const isAuthenticated = getRefreshToken();

  if (!isAuthenticated) {
    return (
      <Navigate
        to={authPath.login.path}
        replace={true}
      />
    );
  }

  return props.children as JSX.Element;
};
