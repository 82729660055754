import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { LabelInput } from 'shared/ui/inputs';

export const Description = () => {
  const {
    t, 
  } = useTranslation();

  const {
    control,
    formState: {
      errors,
    },
  } = useFormContext();

  const renderFiled: ControllerProps['render'] = (params) => {
    const {
      field,
    } = params;

    return (
      <LabelInput
        label={(
          <Label
            title={t('modal.bots.parameters.description.title')}
          />
        )}
        value={field.value}
        placeholder={t('modal.bots.parameters.description.placeholder')}
        onChange={field.onChange}
        error={errors?.description?.message?.toString()}
      />
    );
  };

  return (
    <Controller
      name='description'
      control={control}
      render={renderFiled as any}
    />
  );
};
