import { newTerminalSlice } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { getLimitLastPrice } from 'entities/terminal/model/selectors/get-limit-last-price/get-limit-last-price';
import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

interface OrderPriceHookProps {
  currentSymbol: any;
  onOrderPriceChange: (value: string) => void;
  forceUpdating?: boolean;
  preventPriceUpdate?: boolean;
}

export const useOrderPrice = ({ 
  currentSymbol,
  onOrderPriceChange, 
  forceUpdating,
  preventPriceUpdate,
}: OrderPriceHookProps) => {
  const dispatch = useDispatch();
  const limitLastPrice = useSelector(getLimitLastPrice);
  const {
    watch, setValue, 
  } = useFormContext();
  const orderType = watch('orderType');

  useEffect(() => {
    if (!preventPriceUpdate && (orderType === 'market' || forceUpdating) && limitLastPrice) {
      const formattedValue = formatByPrecisionAndTrim(
        limitLastPrice.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.priceMin),
        Number(currentSymbol.priceMax),
      );
      dispatch(newTerminalSlice.actions.setBasePrice(Number(formattedValue)));
      setValue('orderPrice', formattedValue);
      onOrderPriceChange(formattedValue);
    }
  }, [limitLastPrice, orderType, forceUpdating, preventPriceUpdate]);
  
  return {
    orderType,
  };
};
