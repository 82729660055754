import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/ui';
import {
  background,
  inner,
  left,
  right,
  title,
} from '../styles';

export const Banner = () => {
  // const linkHandler = (link: string) => {
  //   return () => {
  //     window.open(link, '_blank');
  //   };
  // };

  const {
    t, 
  } = useTranslation();

  return (
    <Box sx={background}>
      <Box sx={left}>
        <Box sx={title}>
          <Text>
            {t('banner.title', {
              price: '$4,000',
            })}
          </Text>
        </Box>

        <Stack sx={inner}>
          <Text type='secondary'>
            {t('banner.text', {
              platform: 'Gate.io',
            })}
          </Text>
        </Stack>

        {/* <Stack
          direction='row'
          gap={1}
          alignItems='center'
        >
          <MainButton
            type='primary'
            size='small'
            styles={button}
            onClick={linkHandler('https://www.bybit.com/bybitinstitutional/broker-service/brokerRegister?brokerId=BRK000388')}
          >
            Join in a click
          </MainButton>

          <MainButton
            type='primary'
            size='small'
            styles={button}
            onClick={linkHandler('https://www.bybit.com/en/trading-contest/game/?name=skyrexq4&period_id=2377')}
            ghost={true}
          >
            Leaderboard
          </MainButton>
        </Stack> */}
      </Box>

      <Box sx={right}>
        <img src='/images/banner-bg.jpg' alt='banner-bg' />
      </Box>
    </Box> 
  );
};
