import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: getResponsiveValue({
    min: 40,
    max: 80,
  }),
};
