import { capitalize } from '@mui/material';
import { formatter } from 'shared/helpers';

export const getTooltip = (selectedCurrency: string) => {
  const tooltip: echarts.TooltipComponentOption = {
    trigger: 'item',
    padding: 0,
    formatter: (params: any) => {
      return `
        <div style='display: flex; flex-direction: column; gap: 9px; background-color: #fff; padding: 14px 12px;'>
          <h3 style='fontSize: 10px; font-weight: 600; color: #3A3A3C;'>
            ${params.name} ${formatter(params.data[`overall${capitalize(selectedCurrency.toLowerCase())}Share`])}%
          </h3>
  
          <div style='display: flex; flex-direction: column; gap: 2px;'>
            <span style='fontSize: 10px; font-weight: 500; color: #9E9E9E;'>
              Amount
            </span>
  
            <span style='fontSize: 10px; font-weight: 500; color: #9E9E9E;'>
              ${formatter(+params?.data?.amountForTooltip)}
            </span>
  
            <span style='fontSize: 10px; font-weight: 500; color: #9E9E9E;'>
              ₿ ${formatter(+params.data.totalBtc)}
            </span>
  
            <span style='fontSize: 10px; font-weight: 500; color: #9E9E9E;'>
              $ ${formatter(+params.data.totalUsdt)}
            </span>
          </div>
        </div>
      `;
    },
  };

  return tooltip;
};
