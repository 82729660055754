import { Stack } from '@mui/material';
import { Segmented } from 'antd';
import { presets } from 'pages/trading-bots/configurator-v2/consts';
import { Preset } from 'pages/trading-bots/configurator-v2/views/configure-risk-managment/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { MainButton } from 'shared/ui';
import { ActiveTradingPairs, Exchange, Indicator, Market, OrderType, Panel, TradingAmount } from '../components';

export const ConfiguratorPreview = () => {
  const [selectedPreset, setSelectedPreset] = useState<'conservative' | 'moderate' | 'aggresive'>('moderate');
  const [tradingAmount, setTradingAmount] = useState<string>('1000');
  const [activePairs, setActivePairs] = useState<number>(10);

  const selectPresetHandler = (preset: string) => {
    return () =>  {
      setSelectedPreset(preset as 'conservative' | 'moderate' | 'aggresive');
    };
  };

  const {
    t, 
  } = useTranslation();

  return (
    <Stack
      direction={{
        xs: 'column',
        lg: 'row',
      }}
      gap='40px'
      justifyContent='space-between'
    >
      <Stack
        flex={1}
        maxWidth={{
          xs: '100%',
          lg: 400,
        }}
      >
        <Stack gap='24px'>
          <Exchange />

          <Market />

          <TradingAmount setTradingAmount={setTradingAmount} />

          <ActiveTradingPairs setActivePairs={setActivePairs} />
        </Stack>

        <Stack gap='24px'>
          <Stack gap='8px'>
            <Label
              title={t('configurator.source.tabs.label.title')}
              tooltip={t('configurator.source.tabs.label.tooltip')}
            />

            <Segmented
              value={0}
              options={[{
                label: t('configurator.source.tabs.indicator'),
                value: 0,
              }, {
                label: t('configurator.source.tabs.strategy'),
                value: 1,
              }, {
                label: t('configurator.source.tabs.custom'),
                value: 2,
              }]}
              className='custom'
              size='large'
              block={true}
            />
          </Stack>

          <Indicator />

          <OrderType />
        </Stack>
      </Stack>

      <Stack
        gap='14px'
        flex={1}
        justifyContent='space-between'
      >
        <Stack gap='8px'>
          <Label
            title={t('configurator.managment.tabs.label.title')}
            tooltip={t('configurator.managment.tabs.label.tooltip')}
          />

          <Segmented
            value={0}
            options={[{
              label: t('configurator.managment.tabs.auto'),
              value: 0,
              disabled: true,
            }, {
              label: t('configurator.managment.tabs.custom'),
              value: 1,
            }]}
            className='custom'
            size='large'
            block={true}
          />
        </Stack>

        <Stack gap='15px'>
          {presets.map((preset, index: number) => {
            return (
              <Preset
                key={preset.title}
                title={t(`configurator.managment.auto.presets.${index}.title`)}
                settings={t(`configurator.managment.auto.presets.${index}.settings`, {
                  returnObjects: true,
                })}
                caption={t(`configurator.managment.auto.presets.${index}.caption`)}
                description={t(`configurator.managment.auto.presets.${index}.description`)}
                isSelected={selectedPreset === preset.title.toLowerCase()}
                onClick={selectPresetHandler(preset.title.toLowerCase())} />
            );
          })}
        </Stack>
      </Stack>

      <Stack
        gap='40px'
        flex={1}
        justifyContent='space-between'
      >
        <Panel
          preset={presets.find((preset) => preset.title.toLowerCase() === selectedPreset)}
          tradingAmount={+tradingAmount}
          activeTradingPairs={activePairs}
        />

        <MainButton
          type='primary'
          size='large'
        >
          {t('bots.introduction.button.open')}
        </MainButton>
      </Stack>
    </Stack>
  );
};
