import { enrichBotsWithStatistic } from '../api/enrich-bots-with-statistics';
import { getAllPublicBots } from '../api/get-all-public-bots';

export const getPublicBots = async () => {
  const allPublicBots = await getAllPublicBots();

  const botsEnrichedWithStatistics = await enrichBotsWithStatistic(allPublicBots);
  
  const sortedBots = botsEnrichedWithStatistics.sort((a, b) => {
    return b.activeCopyBots - a.activeCopyBots;
  });
  
  return sortedBots;
};
