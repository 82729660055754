import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { ActiveDeal, BotDeal } from '../model/types';
import { getBotDealsFromTerminal } from './get-bot-deals-from-terminal';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getBotActiveTrades = async (accounts: string[]) => {
  const response = await axios.request({
    url: '/bot/getByFilterDeal',
    data: {
      exchangeAccountUuids: accounts,
      skyrexUserUuids: [getSkyrexUuid()!],
      statuses: [
        'new',
        'request_completed',
        'requested_cancelled',
        'active',
        'in_progress_update',
        'failed',
      ],
    },
  });

  if (!response.data.data.deals) {
    return [];
  }

  const botDeals = response.data.data.deals as BotDeal[];
  const smartTradeUuids = botDeals.map((deal: any) => deal.smartTradeUuid);
  
  const smartTrades = await getBotDealsFromTerminal({
    smartTradeUuids,
  });

  const result = botDeals.map((botDeal) => {
    const trade = smartTrades.find((smartTrade) => smartTrade.smartTradeUuid === botDeal.smartTradeUuid);

    return {
      ...trade,
      position: botDeal.position,
      maxSafetyOrders: botDeal.maxSafetyOrders,
      botUuid: botDeal.botUuid,
    };
  });

  return result as unknown as ActiveDeal[];
};
