import { Box, Stack } from '@mui/material';
import { Button } from 'antd';
import { newTerminalActions } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { getChartLastPrice } from 'entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price';
import { getLimitLastPrice } from 'entities/terminal/model/selectors/get-limit-last-price/get-limit-last-price';
import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useFormSubmission } from 'pages/manual-trading/trading-terminal/hooks/use-form-submission';
import { SideProps } from 'pages/manual-trading/trading-terminal/types/order-panel.types';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmTransaction, Text, Title, Tooltip } from 'shared/ui';
import { useEntryEffects } from '../../buy/hooks/use-entry-effects';
import { useErrorEffects } from '../../buy/hooks/use-error-effects';
import { useFormUpdates } from '../../buy/hooks/use-form-updates';
import { useSectionTogglers } from '../../buy/hooks/use-section-togglers';
import { useSymbolEffects } from '../../buy/hooks/use-symbol-effects';
import { AdditionalEntrySell } from '../components/additional-entry-sell';
import BaseOrderSell from '../components/base-order-sell';
import { StopLossSell } from '../components/stop-loss-sell';
import { TakeProfitSell } from '../components/take-profit-sell';
import { useAdditionalEntrySellInputsLogic } from '../hooks/use-additional-entry-sell-inputs';
import { useBaseOrderSellInputsLogic } from '../hooks/use-base-order-sell-inputs-logic';
import { usePriceEffects } from '../hooks/use-price-effects-sell';
import { useStopLossSellInputsLogic } from '../hooks/use-stop-loss-sell-inputs';
import { useTakeProfitSellInputsLogic } from '../hooks/use-take-profit-sell-inputs';
import { InfoIcon, Wallet } from '../icons';
import { inner, price, priceWrapper, title, tooltipText, wrapper } from '../styles';

export const Sell = (props: SideProps) => {
  const dispatch = useDispatch();
  const {
    currentSymbol,
    userWalletQuoteAssetBalance = {
      free: '0',
    },
    userWalletBaseAssetBalance = {
      free: '0',
    },
    exchangeAccountUuid,
    editingTrade,
    orderPanelDisabled,
  } = props;

  const chartLastPrice = useSelector(getChartLastPrice);
  const limitLastPrice = useSelector(getLimitLastPrice);

  const {
    setValue,
    watch,
    getValues,
    setError,
    trigger,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();

  const isSkipBaseOrder = watch('skipBaseOrder');
  const orderType = watch('orderType');
  const selectedSide = watch('selectedSide');

  const {
    formState: {
      isModalVisible, confirmationData, collectedData,
    },
    handlers: {
      handleFormSubmit,
      setIsModalVisible,
      isFormValidExceptAdditionalEntries,
    },
  } = useFormSubmission({
    isSkipBaseOrder,
    exchangeAccountUuid,
    editingTrade,
  });

  const {
    setIsSkipAdditionalEntry,
    setIsSkipTakeProfit,
    setIsSkipStopLoss,
  } = useSectionTogglers();

  useSymbolEffects({
    currentSymbol,
  });

  useEntryEffects();

  useErrorEffects();
  
  const baseOrderSellInputsLogic = useBaseOrderSellInputsLogic(
    currentSymbol,
    userWalletQuoteAssetBalance,
    userWalletBaseAssetBalance,
    watch,
    setValue,
    isSkipBaseOrder,
    chartLastPrice,
    limitLastPrice,
  );

  const {
    handleTriggerPriceChange,
    handleOrderPriceChange,
    handleBoughtPriceChange,
    handleTotalChange,
    handleUnitsChange,
    handleSliderChange,
    handleSkipBaseTotalChange,
    handleSkipBaseUnitsChange,
    handleSkipBaseSliderChange,
    setInitialValues,
  } = baseOrderSellInputsLogic;

  const additionalEntrySellInputsLogic = useAdditionalEntrySellInputsLogic(
    currentSymbol,
    userWalletQuoteAssetBalance,
    userWalletBaseAssetBalance,
    watch,
    setValue,
    getValues,
    setError,
    isSkipBaseOrder,
  );

  const {
    handleAdditionalTriggerPriceChange,
    handleAdditionalOrderPriceChange,
    handleAdditionalOrderPricePercentChange,
    handleAdditionalUnitsChange,
    handleAdditionalTotalChange,
    handleAdditionalSliderChange,
    handleAddEntry,
    handleRemoveEntry,
    updateAdditionalEntryValues,
  } = additionalEntrySellInputsLogic;

  const takeProfitInputsLogic = useTakeProfitSellInputsLogic(currentSymbol, watch, setValue, getValues, setError);

  const {
    handleTakeProfitOrderPricePercentChange,
    handleTakeProfitTriggerPriceChange,
    handleTakeProfitOrderPriceChange,
    handleAddTakeProfitEntry,
    handleRemoveTakeProfitEntry,
    updateTakeProfitValues,
    clearAllTakeProfitEntries,
  } = takeProfitInputsLogic;

  const stopLossInputsLogic = useStopLossSellInputsLogic(currentSymbol, watch, setValue);

  const {
    handleStopLossOrderPricePercentChange,
    handleStopLossTriggerPriceChange,
    handleStopLossOrderPriceChange,
    updateStopLossValues,
  } = stopLossInputsLogic;

  useFormUpdates({
    updateAdditionalEntryValues,
    updateTakeProfitValues,
    updateStopLossValues,
  });

  useEffect(() => {
    setInitialValues();
    trigger();
  }, [orderType, chartLastPrice, isSkipBaseOrder, setInitialValues]);

  usePriceEffects({
    chartLastPrice,
    limitLastPrice,
    currentSymbol,
    userWalletBaseAssetBalance: userWalletBaseAssetBalance as WalletData,
  });
  
  const renderButtons = () => {
    if (!!editingTrade) {
      return (
        <>
          <Button
            htmlType='submit'
            type='primary'
            disabled={!isFormValidExceptAdditionalEntries()}
            size='large'
          >
            {t('terminal.panel.button.update')}
          </Button>

          <Button
            htmlType='button'
            type='default'
            onClick={() => {
              dispatch(newTerminalActions.resetEditingTrade());
            }}
            size='large'
          >
            {t('terminal.panel.button.cancel')}
          </Button>
        </>
      );
    }

    return (
      <Button
        htmlType='submit'
        type='primary'
        size='large'
        disabled={!isFormValidExceptAdditionalEntries() || orderPanelDisabled}
      >
        {t('terminal.panel.button.create')}
      </Button>
    );
  };

  const getValue = () => {
    let value = '';

    if (!isSkipBaseOrder) {
      value = formatByPrecisionAndTrim(
        userWalletBaseAssetBalance.free.toString(),
        currentSymbol?.baseAssetPrecision,
        Number(currentSymbol.lotMin),
        Number(currentSymbol.lotMax),
      );

      return`${value} ${currentSymbol?.baseAsset}`;
    }

    value = formatByPrecisionAndTrim(
      userWalletQuoteAssetBalance.free.toString(),
      currentSymbol?.quoteAssetPrecision,
      Number(currentSymbol.minNotional),
      Number(currentSymbol.maxNotional),
    );

    return `${value} ${currentSymbol?.quoteAsset}`;
  };
  
  return (
    <>
      <Box
        sx={wrapper}
        component='form'
        onSubmit={handleFormSubmit}
      >
        <Stack>
          <Box sx={inner}>
            <Title styles={title}>
              {t(`terminal.panel.title.${selectedSide}.${isSkipBaseOrder ? 'skip' : 'origin'}`, {
                currency: currentSymbol?.baseAsset, 
              })}
            </Title>

            <Box sx={priceWrapper}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&>svg': {
                    width: '100%',
                    height: '100%',
                  },
                }}
              >
                {Wallet}
              </Box>

              <Text
                type='success'
                styles={price}
              >
                {getValue()}
              </Text>

              <Tooltip
                title={(
                  <Box maxWidth={188}>
                    <Title
                      level={5}
                      styles={tooltipText}
                    >
                      {t('terminal.panel.title.sell.tooltip', {
                        currency: currentSymbol?.baseAsset, 
                      })}
                    </Title>
                  </Box>
                )}
              >
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&>svg': {
                      width: '100%',
                      height: '100%',
                    },
                  }}
                >
                  {InfoIcon}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        
          <Stack alignItems='flex-end'>
            <Text
              type='secondary'
              styles={{
                fontSize: 8,
              }}
            >
              Leveraged: 365000.32 USDT
            </Text>
          </Stack>
        </Stack>

        <BaseOrderSell
          isSkipBaseOrder={isSkipBaseOrder}
          onTriggerPriceChange={handleTriggerPriceChange}
          onOrderPriceChange={handleOrderPriceChange}
          onBoughtPriceChange={handleBoughtPriceChange}
          onTotalChange={handleTotalChange}
          onUnitsChange={handleUnitsChange}
          onSliderChange={handleSliderChange}
          onSkipBaseTotalChange={handleSkipBaseTotalChange}
          onSkipBaseUnitsChange={handleSkipBaseUnitsChange}
          onSkipBaseSliderChange={handleSkipBaseSliderChange}
          orderPanelDisabled={orderPanelDisabled}
        />

        <Stack gap='14px'>
          <AdditionalEntrySell
            setIsSkipAdditionalEntry={setIsSkipAdditionalEntry}
            onAdditionalOrderPriceChange={handleAdditionalOrderPriceChange}
            onAdditionalTriggerPriceChange={handleAdditionalTriggerPriceChange}
            onAdditionalOrderPricePercentChange={handleAdditionalOrderPricePercentChange}
            onAdditionalUnitsChange={handleAdditionalUnitsChange}
            onAdditionalTotalChange={handleAdditionalTotalChange}
            onAdditionalSliderChange={handleAdditionalSliderChange}
            onAddEntry={handleAddEntry}
            onRemoveEntry={handleRemoveEntry}
            orderPanelDisabled={orderPanelDisabled}
          />

          <TakeProfitSell
            setIsSkipTakeProfit={setIsSkipTakeProfit}
            onTakeProfitOrderPriceChange={handleTakeProfitOrderPriceChange}
            onTakeProfitTriggerPriceChange={handleTakeProfitTriggerPriceChange}
            onTakeProfitOrderPricePercentChange={handleTakeProfitOrderPricePercentChange}
            onAddEntry={handleAddTakeProfitEntry}
            onRemoveEntry={handleRemoveTakeProfitEntry}
            clearAllTakeProfitEntries={clearAllTakeProfitEntries}
            orderPanelDisabled={orderPanelDisabled}
          />

          <StopLossSell
            setIsSkipStopLoss={setIsSkipStopLoss}
            onStopLossTriggerPriceChange={handleStopLossTriggerPriceChange}
            onStopLossOrderPriceChange={handleStopLossOrderPriceChange}
            onStopLossOrderPricePercentChange={handleStopLossOrderPricePercentChange}
            orderPanelDisabled={orderPanelDisabled}
          />

          {renderButtons()}
        </Stack>
      </Box>

      <ConfirmTransaction
        isEditing={!!editingTrade}
        isOpened={isModalVisible}
        closeHandler={() => setIsModalVisible(false)}
        confirmationTableData={confirmationData}
        collectedDataForSubmit={collectedData}
      />
    </>
  );
};

export default Sell;
