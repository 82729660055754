import dayjs from 'dayjs';
import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface IGetTradingStatisticsParams {
  dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;
  exchangeAccountUuids: string[];
  sideFirstStep: string;
}
export const getTradingStatistics = async (params: IGetTradingStatisticsParams) => {
  const {
    exchangeAccountUuids,
    sideFirstStep,
    dates,
  } = params;

  const dateCloseFrom = dates && dates[0] ? dayjs(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss') : null;
  const dateCloseTo = dates && dates[1] ? dayjs(dates[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss') : null;

  try {
    const response = await axios.request({
      url: 'exchangeAccount/accountsTradingStatistic',
      data: {
        exchangeAccountUuids,
        sideFirstStep,
        dateCloseFrom,
        dateCloseTo,
      },
    });

    return response.data.data.calculations;
  } catch (error) {
    console.error('Error fetching trading statistics:', error);
    return [];
  }
};
