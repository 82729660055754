import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const INNER: SxProps<Theme> = {
  gap: getResponsiveValue({
    min: 24,
    max: 56,
  }),
  flex: 1,
  background: 'url("/images/auth-bg.jpg"), linear-gradient(180deg, #2ECD99 0%, #007E82 100%)',
  padding: getResponsiveValue({
    min: 16,
    max: 24,
  }),
  backgroundPosition: 'left',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};
