import { TLanguage, TTranslation } from 'shared/types';

export const modalPlans = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      plans: {
        title: 'Choose your plan',
        segments: {
          monthly: 'Monthly',
          annually: 'Annually {{percent}}%',
        },
        items: {
          subTitle: 'What’s included',
          basic: {
            title: 'basic',
            description: 'Enjoy essential features with no subcription fee',
            price: 'Free',
            included: {
              title: 'What’s included',
              items: [
                '{{smartBotCommissionPercent}}% sharing fee on Skyrexio bots',
                '{{exchangeAccountsConnectedLimit}} exchange account connected',
                '{{activeSmartTradesLimit}} active Smart Terminal position',
                '{{activeBotsLimit}} active copy / alert bot',
                'Bot configurator',
                'Demo account',
                'Real-time portfolio dashboard',
                '24 hours response support',
              ],
            },
          },
          advanced: {
            title: 'Advanced',
            description: 'Extend usage limits and share your bots at Marketplace',
            included: {
              title: 'What’s included',
              items: [
                'Everything from basic',
                null,
                '{{exchangeAccountsConnectedLimit}} exchange accounts connected',
                '{{activeSmartTradesLimit}} active Smart Terminal positions',
                '{{activeBotsLimit}} active copy / alert bots',
                'Access to sharing bots at Marketplace ',
                'Real-time support',
              ],
            },
          },
          pro: {
            title: 'Pro',
            label: 'Best value',
            description: 'Ultimate tools, reduced Skyrexio bot fee, private PineScript strategies',
            included: {
              items: [
                'Everything from Basic and Advanced',
                null,
                '{{smartBotCommissionPercent}}% sharing fee on Skyrexio bots',
                '{{exchangeAccountsConnectedLimit}} exchange accounts connected',
                '{{activeSmartTradesLimit}} active Smart Terminal positions',
                '{{activeBotsLimit}} active copy / alert bots',
                'Access to Private TradingView strategies',
                'Premium dedicated support',
              ],
            },
          },
        },
        button: {
          trial: 'Starts after Trial',
          current: 'Current Plan',
          upgrade: 'Upgrade Plan',
          subscribe: 'Subscribe',
        },
      },
    },
    ru: {
      plans: {
        title: 'Выберите план',
        segments: {
          monthly: 'Ежемесячно',
          annually: 'Ежегодно {{percent}}%',
        },
        items: {
          subTitle: 'Что включено',
          basic: {
            title: 'базовый',
            description: 'Пользуйтесь основными функциями без подписки',
            price: 'Бесплатно',
            included: {
              title: 'Что включено',
              items: [
                '{{smartBotCommissionPercent}}% комиссия на боты Skyrexio',
                '{{exchangeAccountsConnectedLimit}} подключённый аккаунт',
                '{{activeSmartTradesLimit}} активная позиция Smart Terminal',
                '{{activeBotsLimit}} активный копи/алерт-бот',
                'Бот-конфигуратор',
                'Демо-аккаунт',
                'Панель портфеля в реальном времени',
                'Поддержка в течение 24 часов',
              ],
            },
          },
          advanced: {
            title: 'продвинутый',
            description: 'Расширьте лимиты и публикуйте ботов на Маркетплейсе',
            included: {
              title: 'Что включено',
              items: [
                'Всё из basic',
                null,
                '{{exchangeAccountsConnectedLimit}} аккаунтов',
                '{{activeSmartTradesLimit}} активных позиций',
                '{{activeBotsLimit}} активных ботов',
                'Доступ к публикации на Маркетплейсе',
                'Поддержка в реальном времени',
              ],
            },
          },
          pro: {
            title: 'про',
            label: 'Лучший выбор',
            description:
              'Максимум инструментов, сниженная комиссия Skyrexio, приватные PineScript-стратегии',
            included: {
              items: [
                'Всё из Basic и Advanced',
                null,
                '{{smartBotCommissionPercent}}% комиссия на боты Skyrexio',
                '{{exchangeAccountsConnectedLimit}} аккаунтов',
                '{{activeSmartTradesLimit}} активных позиций',
                '{{activeBotsLimit}} активных ботов',
                'Доступ к приватным TradingView-скриптам',
                'Премиум-поддержка',
              ],
            },
          },
        },
        button: {
          trial: 'Начнётся после пробного периода',
          current: 'Текущий план',
          upgrade: 'Улучшить план',
          subscribe: 'Подписаться',
        },
      },
    },
  };

  return transaltion[type].plans;
};
