import { TLanguage, TTranslation } from 'shared/types';

export const modalBot = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      bot: {
        subscriptions: {
          title: 'Subscriptions',
          columns: {
            name: 'Name',
            type: {
              title: 'Type',
              items: {
                copy: 'Copy bot',
              },
            },
            date: {
              start: 'Date start',
              end: 'Date end',
            },
            fee: 'Fee',
            status: {
              title: 'Status',
              items: {
                active: 'active',
                pending: 'pending',
                finished: 'finished',
              },
            },
            mark: 'High-water mark',
            result: 'Result',
            net: 'Net result',
            commission: 'Commission',
            actions: {
              title: 'Actions',
              items: {
                pay: 'Pay',
              },
            },
          },
        },
        update: {
          title: 'Update copy bot',
          description: 'Trading amount change will affect only new positions',
        },
      },
    },
    ru: {
      'bot': {
        'subscriptions': {
          'title': 'Подписки',
          'columns': {
            'name': 'Название',
            'type': {
              'title': 'Тип',
              'items': {
                'copy': 'Копи-бот',
              },
            },
            'date': {
              'start': 'Дата начала',
              'end': 'Дата окончания',
            },
            'fee': 'Комиссия',
            'status': {
              'title': 'Статус',
              'items': {
                'active': 'активна',
                'pending': 'ожидает',
                finished: 'завершен',
              },
            },
            'mark': 'High-water mark',
            'result': 'Результат',
            'net': 'Чистый результат',
            'commission': 'Комиссия',
            'actions': {
              'title': 'Действия',
              'items': {
                'pay': 'Оплатить',
              },
            },
          },
        },
        'update': {
          'title': 'Обновить копи бот',
          'description': 'Изменение торговой суммы повлияет только на новые позиции',
        },
      },
    },
  };

  return transaltion[type].bot;
};
