import { CSSProperties } from 'react';
import { getResponsiveValue } from 'shared/helpers';

export const ERROR: CSSProperties = {
  display: 'block',
  height: 15,
  position: 'absolute',
  right: 0,
  top: 0,
  fontSize: getResponsiveValue({
    min: 8,
    max: 10,
  }),
};
