import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HOME } from 'shared/consts';
import { MainButton, Text, Title } from 'shared/ui';
import { button, imgWrapper, inner, text, title, wrapper } from '../styles';

const NotFound = () => {
  const navigate = useNavigate();
  const {
    t, 
  } = useTranslation();
  
  const goHome = () => {
    navigate(HOME);
  };

  return (
    <Box sx={wrapper}>
      <Box sx={inner}>
        <Title styles={title}>
          {t('unknown.title')}
        </Title>

        <Text styles={text}>
          {t('unknown.description')}
        </Text>

        <Text styles={text}>
          {t('unknown.back')}
        </Text>

        <MainButton
          type='primary'
          styles={button}
          onClick={goHome}
        >
          {t('unknown.button')}
        </MainButton>
      </Box>

      <Box sx={imgWrapper}>
        <img
          src='/images/not-found/bg.jpg'
          alt='bg'
        />
      </Box>
    </Box>
  );
};

export default NotFound;
