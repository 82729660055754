import { SxProps, Theme } from '@mui/material';

export const inner: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: {
    xs: 'center',
    md: 'initial',
  },
  gap: {
    xs: '14px',
    md: '31px',
  },
  textAlign: {
    xs: 'center',
    md: 'left',
  },
};
