import { Box } from '@mui/material';
import { getEditingTrade } from 'entities/new-terminal/model/selectors/get-editing-trade';
import { getSharedTrade } from 'entities/new-terminal/model/selectors/get-shared-trade';
import { getChartLastPrice } from 'entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price';
import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTradingForm } from '../../hooks/use-trading-form';
import { TopNavigation } from '../top-navigation';
import { TradingContent } from '../trading-content/trading-content';
import { wrapper } from './styles';

export interface TradingPanelProps {
  userWalletBaseAssetBalance: WalletData;
  userWalletQuoteAssetBalance: WalletData;
  exchangeAccountUuid: string;
  currentSymbol?: any;
  selectedExchange?: SelectedExchange;
  editingTrade?: any;
  isFuturesAccount?: boolean;
  orderPanelDisabled?: boolean;
}

export interface SelectedExchange {
  balances: WalletData[];
  exchangeCode: string;
  label: string;
  value: string;
  leverageType?: string;
}

export const TradingPanel = (props: TradingPanelProps) => {
  const {
    currentSymbol, 
    selectedExchange,
    userWalletBaseAssetBalance, 
    userWalletQuoteAssetBalance,
    exchangeAccountUuid,
  } = props;

  const chartLastPrice = useSelector(getChartLastPrice);
  const editingTrade = useSelector(getEditingTrade);
  const sharedTrade = useSelector(getSharedTrade);
  const isFuturesAccount = selectedExchange?.exchangeCode?.endsWith('-futures') || false;
  
  const orderPanelDisabled = selectedExchange?.value === 'no_exchange';

  const methods = useTradingForm({
    chartLastPrice, 
    currentSymbol, 
    selectedExchange,
    userWalletBaseAssetBalance,
    userWalletQuoteAssetBalance,
    editingTrade,
    sharedTrade,
    isFuturesAccount,
  });

  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editingTrade && panelRef.current) {
      panelRef.current.scrollIntoView({
        behavior: 'smooth', block: 'center',
      });
    }
  }, [editingTrade]);
  
  return (
    <FormProvider {...methods}>
      <Box
        sx={wrapper}
        ref={panelRef}
      >
        <TopNavigation type={isFuturesAccount ? 'futures' : 'spot'} />
        
        <TradingContent
          currentSymbol={currentSymbol}
          userWalletBaseAssetBalance={userWalletBaseAssetBalance}
          userWalletQuoteAssetBalance={userWalletQuoteAssetBalance}
          exchangeAccountUuid={exchangeAccountUuid}
          editingTrade={editingTrade}
          isFuturesAccount={isFuturesAccount}
          orderPanelDisabled={orderPanelDisabled}
        />
      </Box>
    </FormProvider>
  );
};
