import { CustomSlider } from 'pages/trading-bots/configurator-v2/components';
import { getActiveTradingPairsError } from 'pages/trading-bots/configurator-v2/helpers';
import { useEffect } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';

export const ActiveTradingPairs = () => {
  const {
    t, 
  } = useTranslation();

  const {
    control,
    setValue,
    watch,
    formState: {
      errors,
    },
  } = useFormContext();

  const tradingAmount = watch('tradingAmount');
  const activeTradingPairs = watch('activeTradingPairs');
  const market = watch('market');
  
  const setActiveTradingPairs = (value: number) => {
    setValue('activeTradingPairs', value);
  };

  const error = getActiveTradingPairsError({
    tradingAmount: +tradingAmount,
    activeTradingPairs,
    minNotional: market?.minNotional,
    t,
  });

  useEffect(() => {
    setValue('errors', error ? [error] : []);
  }, [error]);

  const renderFiled: ControllerProps['render'] = (params) => {
    const {
      field,
    } = params;
      
    return (
      <CustomSlider
        title={(
          <Label
            title={t('form.configurator.pairs.title')}
          />
        )}
        slider={{
          value: field.value,
          onChange: setActiveTradingPairs,
          max: 20,
          marks: {
            1: 1,
            5: 5,
            20: 20,
          },
        }}
        input={{
          value: field.value,
          placeholder: '100%',
          onChange: setActiveTradingPairs,
        }}
        error={errors?.activeTradingPairs?.message?.toString() || error}
      />
    );
  };
  
  return (
    <Controller
      name='activeTradingPairs'
      control={control}
      render={renderFiled as any}
    />
  );
};
