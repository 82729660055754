import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MainButton } from 'shared/ui';
import { BasicInfo } from '../../../components';
import { ActiveTradingPairs, Exchange, Futures, Market, TradingAmount } from '../components';
import { FIELDS, INNER, WRAPPER } from '../styles';

export const ChooseAccount = () => {
  const {
    watch,
    setValue,
    getValues,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();
  
  const tradingAmount = watch('tradingAmount');
  const exchange = watch('exchange').value;
  const errors = watch('errors');

  const changeCurrentStep = () => {
    setValue('currentStep', getValues('currentStep') + 1);
  };

  return (
    <Stack sx={WRAPPER}>
      <Stack sx={INNER}>
        <Stack sx={FIELDS}>
          <Stack gap='14px'>
            <Exchange />

            <Market />
          </Stack>

          {exchange.code.includes('future') && (
            <Futures />
          )}

          <Stack gap='14px'>
            <TradingAmount />

            <ActiveTradingPairs />
          </Stack>
        </Stack>

        <Box
          sx={{
            width: '100%',
            maxWidth: 240,
            alignSelf: 'center',
            '&>button': {
              width: '100%',
            },
          }}
        >
          <MainButton
            type='primary'
            onClick={changeCurrentStep}
            loading={!tradingAmount}
            disabled={errors.length}
          >
            {t('configurator.button.next')}
          </MainButton>
        </Box>
      </Stack>

      <BasicInfo
        title={t('configurator.basic.title')}
        links={[{
          title: t('configurator.basic.links.read'),
          type: 'router-link',
          url: '/trading-bots/introduction',
        }, {
          title: t('configurator.basic.links.use'),
          type: 'link',
          url: 'https://www.tradingview.com/u/Skyrexio/#published-scripts',
        }]}
      />
    </Stack>
  );
};
