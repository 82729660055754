import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { appPath } from 'shared/config/route-config/app-routes/consts';
import { getRefreshToken } from 'shared/helpers/storage-helper';

export const GuestRoute = (props: Record<'children', ReactNode>) => {
  const isAuthenticated = getRefreshToken();

  if (!isAuthenticated) {
    return props.children as JSX.Element;
  }

  return (
    <Navigate
      to={appPath.home.path}
      replace={true}
    />
  );
};
