import createAxiosInstance from '../axios';
import { HttpMethod } from '../types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface IFetchPaginatedExchangesParmas {
  endpoint: string;
  requestData: any;
  field: string;
}
export const fetchPaginated = async (params: IFetchPaginatedExchangesParmas): Promise<any> => {
  const {
    endpoint,
    requestData,
    field,
  } = params;

  try {
    const initialResponse = await axios.request({
      url: endpoint,
      method: 'post',
      data: {
        ...requestData,
        page: 1,
      },
    });

    const initialData = initialResponse.data.data[field];
    const pagination = initialResponse.data.data.pagination;
    const totalPages = pagination ? pagination.totalPages : 1;
    let allData = initialData;

    if (totalPages > 1) {
      const requests = [];
      for (let page = 2; page <= totalPages; page++) {
        requests.push(
          axios.request({
            url: endpoint,
            method: 'post',
            data: {
              ...requestData,
              page: page,
            },
          }),
        );
      }

      try {
        const responses = await Promise.all(requests);

        for (const response of responses) {
          if (response && response.data && response.data.data && response.data.data[field]) {
            allData = allData?.concat(response.data.data[field]);
          } else {
            console.warn('One of the responses is undefined or does not contain the expected data structure.', response);
          }
        }
      } catch (err) {
        console.error('======Error in Promise.all:', err);
      }
    }

    return {
      [field]: allData,
      success: true,
      status: 200,
    };
    
  } catch (error) {
    console.error('======Error in fetchPaginatedExchanges:', error);
    throw error;
  }
};
