import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { Label } from 'widgets';
import { LabelInput } from 'shared/ui';
import { TRADING_AMOUNT_INPUT } from '../styles';

export const TradingAmount = (props: any) => {
  const [amount, setAmount] = useState<string>('1000');

  const {
    t, 
  } = useTranslation();

  const debounceAmount = useDebouncedCallback((value: string) => {
    props.setTradingAmount(value);
  }, 300);

  const setTradingAmountHandler = (value: string) => {
    const numericValue = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
    setAmount(numericValue);
    debounceAmount(numericValue);
  };

  return (
    <LabelInput
      label={(
        <Label
          title={t('form.configurator.amount.title')}
        />
      )}
      value={amount}
      placeholder={t('form.configurator.amount.placeholder')}
      onChange={setTradingAmountHandler}
      style={TRADING_AMOUNT_INPUT}
    />
  );
};
