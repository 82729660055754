import { EnrichedTrade } from 'entities/terminal/model/types/terminal-schema';

export const getFundsInTrades = (filteredTrades: EnrichedTrade[], selectedSide: string): number => {
  const validStatuses = ['new', 'request_completed', 'requested_cancelled', 'active', 'in_progress_update', 'failed'];
  const relevantTrades = filteredTrades.filter(trade =>
    validStatuses.includes(trade.status),
  );
  let totalFundsInTrades = 0;
  
  relevantTrades.forEach(trade => {
    const currentExchangePrice = trade.currentExchangePrice;
  
    if (currentExchangePrice === undefined || currentExchangePrice === 0) {
      return;
    }
    if (selectedSide === 'sell') {
      const executedQuoteFirst = parseFloat(trade.executedQuoteFirst);
      const executedQuoteSecond = parseFloat(trade.executedQuoteSecond);
      const funds = (executedQuoteFirst - executedQuoteSecond) / currentExchangePrice;
      totalFundsInTrades += funds;
    } else if (selectedSide === 'buy') {
      const executedBaseFirst = parseFloat(trade.executedBaseFirst);
      const executedBaseSecond = parseFloat(trade.executedBaseSecond);
      const funds = (executedBaseFirst - executedBaseSecond) * currentExchangePrice;
      totalFundsInTrades += funds;
    }
  });

  return totalFundsInTrades;
};
