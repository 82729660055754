import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { getExchangeAccountsUuids } from 'shared/helpers';
import { useExcahnges } from 'shared/hooks';
import { getBotInfo } from '../api';
import { convertExchange } from '../helpers';

export const useGetExchanges = (params: Record<'botUuid', string>) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    values: {
      exchanges,
      isLoading: exchangesLoading,
    },
    handlers: {
      fetchExchangesBalances,
    },
  } = useExcahnges();

  const {
    setValue,
    getValues,
  } = useFormContext();

  const getAccountsHandler = async () => {
    try {
      setIsLoading(true);

      const exchangesBalances: ExchangeAccount[] = await fetchExchangesBalances(getExchangeAccountsUuids(exchanges!)) ;

      const sortedAccounts = exchangesBalances.sort((a, b) => {
        return +b.totalUsdt - +a.totalUsdt;
      });

      if (params.botUuid) {
        const botInfoResponse = await getBotInfo({
          botUuid: params.botUuid,
        });
        if (!botInfoResponse?.length) {
          return;
        }
  
        const [bot] = botInfoResponse;
        const exchangeAccount = sortedAccounts.find((account: ExchangeAccount) => {
          return account.exchangeAccountUuid === bot.exchangeAccountUuid;
        });
        if (!exchangeAccount) {
          return;
        }

        const convertedExchange = convertExchange(exchangeAccount);
        
        setValue('bot', bot);
        setValue('exchange', {
          value: convertedExchange,
          items: [exchangeAccount],
        });
        setValue('name', bot.name);
        setValue('activeTradingPairs', bot.maxActiveDeals || 1);
        return;
      }

      const account = sortedAccounts?.[0] || exchanges![0];
      setValue('exchange', {
        value: convertExchange(account),
        items: sortedAccounts || [],
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!exchanges) {
      return;
    }

    if (!exchanges.length || getValues('exchange.value.id')) {
      return;
    }
    
    getAccountsHandler();
  }, [params.botUuid, exchanges]);

  return {
    values: {
      isLoading: exchangesLoading || isLoading,
    },
  };
};
