import { BaseOptionType } from 'antd/lib/select';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { convertExchange, getExchangeType } from 'pages/trading-bots/configurator-v2/helpers';
import { useCallback } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Label } from 'widgets';
import { SingleSelect } from 'shared/ui/selects';
import { Exchange as ExchangeView } from 'shared/ui/selects/single-select/views';
import { IExchangeProps } from '../interfaces';

export const Exchange = (props: IExchangeProps) => {
  const {
    isLoading,
    exchanges,
    exchange,
    options,
    changeExcahnge,
    openModal,
  } = props;

  const {
    t, 
  } = useTranslation();

  const {
    control,
    formState: {
      errors,
    },
  } = useFormContext();

  const isDemoMode = useSelector(getIsDemoMode);

  const exchangeAccountClickHandler = () => {
    if (!exchanges?.length && !isLoading) {
      openModal();
      return;
    }

    if (isDemoMode) {
      toast.error(t('error.copyBot.demo') as string);
    }
  };

  const exchangeView = useCallback(() => {
    if (!exchange) {
      return null;
    }
  
    const currentExchange = exchanges.find((currentExchange) => {
      return currentExchange.exchangeAccountUuid === exchange;
    });

    if (!currentExchange) {
      return null;
    }

    const convertedExchange = convertExchange(currentExchange);
      
    if (!exchange) {
      return null;
    }
  
    return (
      <ExchangeView
        title={convertedExchange.name}
        code={convertedExchange.code}
        label={convertedExchange.type}
        balance={convertedExchange.totalUsdt}
      />
    );
  }, [exchange, exchanges]);
  
  const exchangeOptionView = useCallback((option: BaseOptionType) => {
    const [name, code, totalUsdt] = (option.label as string || '').split('_');
    
    return (
      <ExchangeView
        title={name}
        code={code}
        label={getExchangeType(code || '')}
        balance={+totalUsdt || 0}
      />
    );
  }, []);

  const renderFiled: ControllerProps['render'] = (params) => {
    const {
      field,
    } = params;

    return (
      <SingleSelect
        label={(
          <Label title={t('modal.copyBot.exchange.title')} />
        )}
        options={options}
        onClick={exchangeAccountClickHandler}
        select={{
          value: field.value || undefined,
          placeholder: t('modal.copyBot.exchange.placeholder'),
          onChange: (value, option) => {
            field.onChange(value);
            changeExcahnge(value, option);
          },
        }}
        labelRender={exchangeView}
        optionRender={exchangeOptionView}
        loading={!!isLoading}
        error={errors?.exchange?.message?.toString()}
      />
    );
  };

  return (
    <Controller
      name='exchange'
      control={control}
      render={renderFiled as any}
    />
  );
};
