import { TLanguage, TTranslation } from 'shared/types';

export const modalChangePassword = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      changePassword: {
        title: 'Change password',
        description: 'Use strong passwords to protect yourself',
        current: {
          title: 'Current password',
          placeholder: 'Enter current password',
        },
        new: {
          title: 'New password',
          placeholder: 'Enter new password',
        },
        confirm: {
          title: 'Password confirmation',
          placeholder: 'Enter password confirmation',
        },
        button: {
          change: 'Change password',
          cancel: 'Cancel',
        },
      },
    },
    ru: {
      changePassword: {
        title: 'Изменить пароль',
        description: 'Используйте сложные пароли ради безопасности',
        current: {
          title: 'Текущий пароль',
          placeholder: 'Введите текущий пароль',
        },
        new: {
          title: 'Новый пароль',
          placeholder: 'Введите новый пароль',
        },
        confirm: {
          title: 'Подтверждение пароля',
          placeholder: 'Повторите новый пароль',
        },
        button: {
          change: 'Изменить пароль',
          cancel: 'Отмена',
        },
      },
    },
  };

  return transaltion[type].changePassword;
};
