export function calculateDailyChange(accumulated: number, dailyChange: number){
  const changeValue = accumulated - dailyChange;
  
  const changePercent = dailyChange !== 0 ? ((changeValue / dailyChange) * 100).toFixed(2) : '0.00';
    
  return {
    value: changeValue,
    percent: changePercent,
  };
}
