import { Box, Stack } from '@mui/material';
import { Actions } from 'pages/trading-bots/configurator-v2/components';
import { getAdditionalEntryOrdersValue, getPanelContent } from 'pages/trading-bots/configurator-v2/helpers';
import { ReactNode, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Panel } from '../../configure-risk-managment/components';
import { WRAPPER } from '../styles';
import { Alerts, Preset } from '../tabs';

export const AdditionalEntry = (props: any) => {
  const {
    watch,
    setValue,
  } = useFormContext();

  const bot = watch('bot');
  const additional = watch('additional');
  
  const isOpened = additional.isOpened;
  const segment = additional.segment.value;
  const alertSource = watch('alertSource');
  
  const errors = watch('errors');

  const {
    t, 
  } = useTranslation();

  const getContent = (): ReactNode => {
    if (!isOpened) {
      return null;
    }

    switch (segment) {
    case 'preset':
      return (
        <Preset />
      );
    case 'alert':
      return (
        <Alerts />
      );
    default:
      return null;
    }
  };

  useEffect(() => {
    if (!bot?.botUuid) {
      return;
    }

    setValue('additional', getAdditionalEntryOrdersValue(bot));
  }, [bot]);

  return (
    <Stack
      sx={{
        ...WRAPPER,
        flexDirection: {
          xs: 'column',
          md: segment === 'preset' ? 'row' : 'column',
        },
        alignItems: {
          xs: 'initial',
          md: segment === 'preset' ? 'flex-start' : 'initial',
        },
      }}
    >
      {getContent()}

      <Box
        sx={{
          display: 'flex',
          gap: '40px',
          width: '100%',
          maxWidth: {
            xs: '100%',
            md: segment === 'alert' ? '100%' : 386,
          },
          justifyContent: 'space-between',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        {isOpened && getPanelContent({
          isAlert: segment === 'alert',
          source: alertSource,
          url: `/videos/additional-${alertSource}.mp4`,
        })}

        <Stack
          sx={{
            gap: '24px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            maxWidth: {
              xs: '100%',
              md: segment === 'alert' ? 386 : '100%',
            },
          }}
        >
          <Panel type='additional' />

          <Actions
            handleBack={props.handleBack}
            button={{
              title: t('configurator.button.next'),
              action: props.changeCurrentStep,
              disabled: errors?.length,
            }}
          />
        </Stack>
      </Box>
    </Stack>
  );
};
