import { TLanguage, TTranslation } from 'shared/types';

export const validationOtp = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      otp: {
        required: 'Please enter your OTP',
        format: 'OTP must be a 6-digit number',
      },
    },
    ru: {
      otp: {
        required: 'Введите OTP',
        format: 'OTP должен состоять из 6 цифр',
      },
    },
  };

  return transaltion[type].otp;
};
