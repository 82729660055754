import { Stack } from '@mui/material';
import { Actions } from 'pages/trading-bots/configurator-v2/components';
import { getCloseOrdersValue, getPanelContent } from 'pages/trading-bots/configurator-v2/helpers';
import { ReactNode, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Panel } from '../../configure-risk-managment/components';
import { ICloseOrdersProps } from '../interfaces';
import { WRAPPER } from '../styles';
import { Alerts, Preset } from '../tabs';

export const CloseOrders = (props: ICloseOrdersProps) => {
  // const closeOrdersError = useSelector(getError);
  const {
    watch,
    setValue,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();

  const close = watch('close');
  const bot = watch('bot');
  const alertSource = watch('alertSource');
  const exchange = watch('exchange');
  
  const isOpened = close.isOpened;
  const segment = close.segment.value;
  
  const errors = watch('errors');

  const getContent = useCallback((): ReactNode => {
    switch (segment) {
    case 'preset':
      return (
        <Preset />
      );
    case 'alert':
      return (
        <Alerts
          alertSource={alertSource}
          exchangeAccountUuid={exchange.value.id}
          handleBack={props.handleBack}
          createBot={props.createBot}
          changeCurrentStep={props.changeCurrentStep}
          botUuid={bot?.botUuid}
        />
      );
    default:
      return null;
    }
  }, [segment, alertSource, exchange]);

  const getBlock = useCallback(() => {
    if (segment === 'preset') {
      const isStrategy = alertSource === 'strategy';

      return (
        <Stack
          sx={{
            gap: '24px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            maxWidth: {
              xs: '100%',
              md: 386,
            },
          }}
        >
          <Panel type='close' />

          <Actions
            handleBack={props.handleBack}
            button={{
              title: isStrategy ? t('configurator.button.next') : bot ? t('configurator.button.update') : t('configurator.button.create'),
              action: isStrategy ? props.changeCurrentStep! : props.createBot!,
              disabled: errors?.length,
            }}
          />
        </Stack>
      );
    }

    return getPanelContent({
      isAlert: segment === 'alert',
      source: alertSource,
      url: `/videos/${alertSource}.mp4`,
    });
  // }, [segment, closeOrdersError]);
  }, [segment, errors?.length]);

  useEffect(() => {
    if (!bot?.botUuid) {
      return;
    }

    setValue('close', getCloseOrdersValue(bot));
  }, [bot]);

  return (
    <Stack sx={WRAPPER}>
      {isOpened && getContent()}

      {isOpened && getBlock()}
    </Stack>
  );
};

export default CloseOrders;
