import { TLanguage, TTranslation } from 'shared/types';

export const validationTerminal = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      terminal: {
        required: 'This field is required',
        number: 'Value should be a number',
        min: 'Min {{min}}',
        max: 'Max {{max}}',
        balance: 'Insufficient balance',
        less: 'Less than minimum',
        slider: {
          min: 'Should be more than 0%',
          max: 'Cannot be more than 100%',
        },
      },
    },
    ru: {
      terminal: {
        required: 'Обязательное поле',
        number: 'Значение должно быть числом',
        min: 'Мин {{min}}',
        max: 'Макс {{max}}',
        balance: 'Недостаточный баланс',
        less: 'Меньше минимального',
        slider: {
          min: 'Должно быть больше 0%',
          max: 'Не может быть больше 100%',
        },
      },
    },
  };

  return transaltion[type].terminal;
};
