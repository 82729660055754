import { Box } from '@mui/material';
import { Segmented } from 'antd';
import { newTerminalActions } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'widgets';
import { SecondaryInfo } from 'shared/icons';
import { Input, LabelInput, SingleSelect, Switch, Title, Tooltip } from 'shared/ui';
import { segments } from '../consts';
import { IStopLoss } from '../interfaces';
import { header, headerTitle, priceInner, wrapper } from '../styles';

export const StopLoss = (props: IStopLoss) => {
  const {
    setIsSkipStopLoss,
    onStopLossOrderPriceChange,
    onStopLossTriggerPriceChange,
    onStopLossOrderPricePercentChange,
    orderPanelDisabled,
  } = props;

  const {
    control,
    formState: {
      errors, 
    },
    setValue,
    trigger,
    watch,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();
  
  const dispatch = useDispatch();
  
  const currentSymbol = watch('currentSymbol');
  const isStopLossEnabled = watch('stopLossEnabled');
  const stopLossTriggerPricePercent = watch('stopLossTriggerPricePercent');
  const quoteAsset = watch('quoteAsset');
  const units = watch('units');
  const stopLossTriggerPrice = watch('stopLossTriggerPrice');
  const stopLossOrderPrice = watch('stopLossOrderPrice');
  const stopLossOrderType = watch('stopLossOrderType');
  
  
  const stopLossPriceFromStore = useSelector((state: any) => state.newTerminal.stopLossPrice);
  
  const lastDispatchedPriceRef = useRef<number | null>(null);
  const prevStopLossPriceRef = useRef<number | null>(null);
  
  useEffect(() => {
    // Проверяем, изменилась ли цена в Redux
    const priceChanged = stopLossPriceFromStore !== prevStopLossPriceRef.current;
    
    // Обновляем предыдущее значение
    prevStopLossPriceRef.current = stopLossPriceFromStore;
    
    // Обновляем форму если:
    // 1. Цена изменилась И
    // 2. Либо это первое обновление, либо цена соответствует последней отправленной,
    //    либо предыдущая цена не соответствовала (т.е. обновление из графика)
    if (priceChanged && stopLossPriceFromStore) {
      const shouldUpdate = 
        lastDispatchedPriceRef.current === null || 
        stopLossPriceFromStore === lastDispatchedPriceRef.current ||
        prevStopLossPriceRef.current !== lastDispatchedPriceRef.current;
      
      if (shouldUpdate) {
        if (stopLossOrderType === 'cond.limit') {
          setValue('stopLossOrderPrice', stopLossPriceFromStore.toString());
          onStopLossOrderPriceChange(stopLossPriceFromStore.toString());
        } else {
          setValue('stopLossTriggerPrice', stopLossPriceFromStore.toString());
          onStopLossTriggerPriceChange(stopLossPriceFromStore.toString());
        }
      }
    }
  }, [
    stopLossPriceFromStore, 
    stopLossOrderType, 
    setValue, 
    onStopLossOrderPriceChange, 
    onStopLossTriggerPriceChange,
  ]);
  
  // Эффект для обновления цены в Redux из формы
  useEffect(() => {
    const priceToSet = stopLossOrderType === 'cond.limit' 
      ? stopLossOrderPrice 
      : stopLossTriggerPrice;
      
    if (isStopLossEnabled) {
      if (priceToSet) {
        const numericPrice = Number(priceToSet);
        dispatch(newTerminalActions.setStopLossPrice(numericPrice));
        // Запоминаем последнюю отправленную цену
        lastDispatchedPriceRef.current = numericPrice;
      }
    } else {
      dispatch(newTerminalActions.setStopLossPrice(0));
      // Сбрасываем последнюю отправленную цену
      lastDispatchedPriceRef.current = 0;
    }
  }, [stopLossTriggerPrice, stopLossOrderPrice, stopLossOrderType, isStopLossEnabled, dispatch]);
  
  const changeSegmentHandler = (value: string) => {
    setValue('stopLossOrderType', value as 'cond.limit' | 'cond.market');
  };
  
  const stopLossPriceRecalculation = watch('stopLossPriceRecalculation');
  const changePriceRecalculationHandler = (value: boolean) => {
    setValue('stopLossPriceRecalculation', value ? 'average' : 'fixed');
  };

  const renderOrderPriceField = () => {
    if (stopLossOrderType === 'cond.market') {
      return null;
    }

    return (
      <Box sx={priceInner}>
        <Controller
          name='stopLossOrderPrice'
          control={control}
          rules={{
            required: t('validation.terminal.required'),
            validate: (value) => {
              const numValue = Number(value);
              if (isNaN(numValue)) {
                return t('validation.terminal.number');
              }
        
              if (numValue < Number(currentSymbol.priceMin)) {
                return t('validation.terminal.min', {
                  min: getTrailedZeroCutted(currentSymbol.priceMin),
                });
              }
              if (numValue > Number(currentSymbol.priceMax)) {
                return t('validation.terminal.max', {
                  max: getTrailedZeroCutted(currentSymbol.priceMax),
                });
              }
              if (stopLossOrderType === 'cond.limit' && numValue * units <= Number(currentSymbol.minNotional)) {
                return t('validation.terminal.less');
              }
              return true;
            },
          }}
          render={({
            field, 
          }) => (
            <LabelInput
              label={(
                <Label title={t('terminal.panel.fields.price.origin')} />
              )}
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onStopLossOrderPriceChange(value);
                }
                trigger('stopLossOrderPrice');
              }}
              onBlur={(value) => {
                const formattedValue = formatByPrecisionAndTrim(value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                field.onChange(formattedValue);
                onStopLossOrderPriceChange(formattedValue);
                trigger('stopLossOrderPrice');
              }}
              icon={quoteAsset}
              status={errors.stopLossOrderPrice ? 'error' : undefined}
              error={errors?.stopLossOrderPrice?.message?.toString()}
            />
          )}
        />
          
        <Controller
          name='stopLossOrderPricePercent'
          control={control}
          render={({
            field, 
          }) => (
            <Input
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                onStopLossOrderPricePercentChange(value);
              }}
              maxWidth={58}
              icon='%'
            />
          )}
        />
      </Box>
    );
  };

  const renderTriggerPriceField = () => {
    return (
      <Box sx={priceInner}>
        <Controller
          name='stopLossTriggerPrice'
          control={control}
          rules={{
            required: t('validation.terminal.required'),
            validate: (value) => {
              const numValue = Number(value);
              if (isNaN(numValue)) {
                return t('validation.terminal.number');
              }
  
              if (numValue < Number(currentSymbol.priceMin)) {
                return t('validation.terminal.min', {
                  min: getTrailedZeroCutted(currentSymbol.priceMin),
                });
              }
              if (numValue > Number(currentSymbol.priceMax)) {
                return t('validation.terminal.max', {
                  max: getTrailedZeroCutted(currentSymbol.priceMax),
                });
              }
              if (stopLossOrderType === 'cond.market' && numValue * units <= Number(currentSymbol.minNotional)) {
                return t('validation.terminal.less');
              }
              return true;
            },
          }}
          render={({
            field,
          }) => (
            <LabelInput
              label={(
                <Label title={t('terminal.panel.fields.triggerPrice.title')} />
              )}
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onStopLossTriggerPriceChange(value);
                }
                trigger('stopLossTriggerPrice');
              }}
              onBlur={(value) => {
                const formattedValue = formatByPrecisionAndTrim(value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                field.onChange(formattedValue);
                onStopLossTriggerPriceChange(formattedValue);
                trigger('stopLossTriggerPrice');
              }}
              icon={quoteAsset}
              addonBefore={stopLossTriggerPricePercent > 100 ? '>100%' : `${stopLossTriggerPricePercent}%`}
              status={errors.stopLossTriggerPrice ? 'error' : undefined}
              error={errors?.stopLossTriggerPrice?.message?.toString()}
            />
          )}
        />
    
        <Controller
          name='stopLossTriggerPriceType'
          control={control}
          render={({
            field, 
          }) => (
            <SingleSelect
              maxWidth='max-content'
              select={{
                value: field.value,
                placeholder: 'Last',
                onChange: (value) => {
                  field.onChange(value);
                  trigger('stopLossTriggerPriceType');
                },
              }}
              options={[{
                label: t('terminal.panel.fields.types.last'),
                value: 'last', 
              }, {
                label: t('terminal.panel.fields.types.bid'),
                value: 'bid', 
              }, {
                label: t('terminal.panel.fields.types.ask'),
                value: 'ask', 
              }]}
            />
          )}
        />
      </Box>
    );
  };
  
  return (
    <Box sx={wrapper}>
      <Box sx={header}>
        <Switch
          value={isStopLossEnabled}
          onChange={setIsSkipStopLoss}
          suffixText={(
            <Title
              level={5}
              styles={headerTitle}
            >
              {t('terminal.panel.stopLoss.title')}
            </Title>
          )}
          size='small'
          disabled={orderPanelDisabled}
        />

        {isStopLossEnabled && (
          <Switch
            value={stopLossPriceRecalculation === 'average'}
            checkedChildren={t('terminal.panel.stopLoss.slider.title')}
            unCheckedChildren={t('terminal.panel.stopLoss.slider.title')}
            onChange={changePriceRecalculationHandler}
            suffixText={(
              <Tooltip
                title={(
                  <Box maxWidth={250}>
                    {t('terminal.panel.stopLoss.slider.tooltip')}
                  </Box>
                )}
              >
                {SecondaryInfo}
              </Tooltip>
            )}
          />
        )}
      </Box>

      {isStopLossEnabled && (
        <>
          <Segmented
            value={stopLossOrderType}
            onChange={changeSegmentHandler}
            options={segments(t)}
            block={true}
            size='large'
          />

          {renderTriggerPriceField()}
          {renderOrderPriceField()}
        </>
      )}
    </Box>
  );
};
