import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Copy } from 'widgets';
import { Text } from 'shared/ui';
import { copyContent, copyLabelText, copyLabelWrapper } from 'shared/ui/modals/add-new-bot/styles';
import { otherSendRequest } from '../../../consts';
import { ITab } from '../../../interfaces';
import { Table } from '../components';

export const Other = (props: ITab) => {
  const {
    t, 
  } = useTranslation();

  return (
    <Box sx={copyContent}>
      <Table
        headers={[
          t('modal.alertSource.other.table.headers.field'),
          t('modal.alertSource.other.table.headers.type'),
          t('modal.alertSource.other.table.headers.description'),
        ]}
        data={[[
          'base',
          'String',
          t('modal.alertSource.other.table.columns.base'),
        ], [
          'quote',
          'String',
          t('modal.alertSource.other.table.columns.quote'),
        ], [
          'position',
          'String',
          t('modal.alertSource.other.table.columns.position'),
        ], [
          'price',
          'String',
          t('modal.alertSource.other.table.columns.price'),
        ], [
          'sourceUuid',
          'String',
          t('modal.alertSource.other.table.columns.sourceUuid'),
        ], [
          'secretToken',
          'String',
          t('modal.alertSource.other.table.columns.secretToken'),
        ], [
          'timestamp',
          'String',
          t('modal.alertSource.other.table.columns.time'),
        ]]}
      />

      <Copy
        label={t('modal.alertSource.other.copy.items.source')}
        value={props.sourceUuid}
      />

      <Copy
        label={t('modal.alertSource.other.copy.items.secret')}
        value={props.secretToken}
      />

      <Copy
        label={(
          <Box sx={copyLabelWrapper}>
            <Text
              type='secondary'
              styles={copyLabelText}
            >
              {t('modal.alertSource.other.copy.items.json.title')}
            </Text>

            <Text
              type='secondary'
              styles={{
                ...copyLabelText,
                fontSize: 10,
              }}
            >
              {t('modal.alertSource.other.copy.items.json.description')}
            </Text>
          </Box>
        )}
        value={otherSendRequest()}
      />

      <Text>
        {t('modal.alertSource.other.note')}
      </Text>
    </Box>
  );
};
