import { SxProps, Theme } from '@mui/material';

export const result: SxProps<Theme> = {
  '&>span': {
    fontWeight: 600,
    lineHeight: 'normal',
    '&[class*="ant-typography-secondary"]': {
      color: '#9E9E9E',
    },
  },
};
