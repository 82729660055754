import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const login = async (email: string, password: string) => {
  try {
    const response = await axios.request({
      url: 'user/login',
      data: {
        email,
        password,
      },
    });

    return response.data;
  } catch (error: any) {
    if (error.response) {
      return {
        success: false,
        data: {
          message: error.response.data.message || '',
        },
      };
    }
    
    // Если ошибка сети или другая
    return {
      success: false,
      data: {
        message: 'Oops, try again',
      },
    };
  }
};

interface IRegisterParams {
  email: string;
  password: string;
  language: string;
  referralCode?: string;
  utm?: string;
}
export const register = async (params: IRegisterParams) => {
  const {
    email,
    password,
    language,
    referralCode,
    utm,
  } = params;

  const data: any = {
    email,
    password,
    language,
  };

  if (referralCode) {
    data.referralCode = referralCode;
  }

  if (utm) {
    data.utm = utm;
  }
  
  try {
    const response = await axios.request({
      url: 'user/create',
      method: 'POST',
      data,
    });
    
    return response.data;
  } catch (error) {
    console.error('Error during user registration:', error);
    return {
      success: false,
      data: {
        message: error.response?.data?.message || 'Oops, try again',
      },
    };
  }
};

export const recover = async (email: string) => {
  const response = await axios.request({
    url: 'user/passwordResetRequest',
    data: {
      email,
    },
  });
  
  return response.data;
};

export const verifyReferalCode = async (referralCode: string) => {
  const response = await axios.request({
    url: '/referral/addClick',
    data: {
      referralCode,
    },
  });
  
  return response.data;
};

export const changePassword = async (props: Record<'passwordResetToken' | 'newPassword', string>) => {
  const {
    passwordResetToken,
    newPassword,
  } = props;
  
  const response = await axios.request({
    url: '/user/passwordReset',
    data: {
      passwordResetToken,
      newPassword,
    },
  });

  return response.data;
};

export const loginWithOtp = async (params: Record<'email' | 'password' | 'otp', string>) => {
  const response = await axios.request({
    url: 'user/login',
    data: {
      email: params.email,
      password: params.password,
      OTP: +params.otp,
    },
  });
    
  return response.data;
};
