import { Hidden, Stack } from '@mui/material';
import { Layout } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Burger,
  Logo,
} from 'shared/icons';
import { Icon } from 'shared/ui';
import {
  DemoMode,
  Menu,
  OnboardingButton,
  UserMenu,
} from '../components';
import {
  ACTIONS,
  ACTIONS_WRAPPER,
  HEADER,
  LOGO_WRAPPER,
  WRAPPER,
} from '../styles';

export const Header = () => {
  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);
  
  const popuHandler = () => {
    setIsPopupOpened((prev) => !prev);
  };

  return (
    <Layout.Header style={HEADER}>
      <Stack sx={WRAPPER}>
        <Stack sx={LOGO_WRAPPER}>
          <Hidden lgUp={true}>
            <Icon
              width={36}
              action={popuHandler}
            >
              {Burger}
            </Icon>
          </Hidden>

          <Link to='/'>
            <Icon width={122}>
              {Logo}
            </Icon>
          </Link>
        </Stack>

        <Menu
          isPopupOpened={isPopupOpened}
          closePopupHandler={popuHandler}
        />

        <Stack sx={ACTIONS_WRAPPER}>
          <OnboardingButton />

          <Stack sx={ACTIONS}>
            <DemoMode />

            <UserMenu />
          </Stack>
        </Stack>
      </Stack>
    </Layout.Header>
  );
};
