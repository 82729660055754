import createAxiosInstance from 'shared/api/axios/axios';
import { fetchPaginatedExchanges } from 'shared/api/axios/helpers/fetch-paginated-exchanges';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getTerminalExchanges = async (exchangeAccountUuids: string[]) => {
  try {
    const response = await fetchPaginatedExchanges({
      axios,
      endpoint: '/exchangeAccount/terminalGetByFilter',
      requestData: {
        exchangeAccountUuids,
      },
    });

    return response?.data;
  } catch (error) {
    console.error('Error fetching exchanges:', error);
    return {
      success: false,
      accounts: [], 
    };
  }
};
