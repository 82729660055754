import dayjs from 'dayjs';
import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface GetBotStatisticParams {
  botUuid: string;
}
export const getBotStatistic = async (params: GetBotStatisticParams) => {
  const {
    botUuid,
  } = params;

  const closedDateFrom = dayjs().subtract(30, 'day').format('YYYY-MM-DD HH:mm:ss');
  const closedDateTo = dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss');

  const response = await axios.request({
    url: '/bot/calculateStatistic',
    data: {
      botUuid,
      closedDateFrom,
      closedDateTo,
    },
  });

  return response.data.data;
};
