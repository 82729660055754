import { TLanguage, TTranslation } from 'shared/types';

export const successPayment = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      payment: {
        title: 'A bit patience',
        message: 'Your payment will be verified in few minutes after CoinPayments confirmation',
        button: 'Okay',
      },
    },
    ru: {
      payment: {
        title: 'Немного терпения',
        message: 'Ваш платёж подтвердится через несколько минут на CoinPayments',
        button: 'Ок',
      },
    },
  };

  return transaltion[type].payment;
};
