import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';

export const getBotNames = (botsData: Bot[]): { label: string; value: string; market: string; }[] => {

  const botNames = botsData.map(bot => ({
    label: bot.name,
    value: bot.botUuid,
    market: bot.quote,
  }));

  return botNames;
};
