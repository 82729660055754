import { TLanguage, TTranslation } from 'shared/types';

export const tradingStatistics = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      tradingStatistics: {
        title: 'Trading statistic',
        segments: {
          sum: 'Sum',
          day: 'Day',
          pair: 'Pair',
        },
      },
    },
    ru: {
      tradingStatistics: {
        title: 'Статистика торговли',
        segments: {
          sum: 'Сумма',
          day: 'День',
          pair: 'Пара',
        },
      },
    },
  };

  return transaltion[type].tradingStatistics;
};
