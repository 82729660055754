import { Box, Stack } from '@mui/material';
import { Actions } from 'pages/trading-bots/configurator-v2/components';
import { presets } from 'pages/trading-bots/configurator-v2/consts';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Panel, Preset } from '../../../components';
import { IAutoProps } from '../interfaces';
import { PRESETS, WRAPPER } from '../styles';

export const Auto = (props: IAutoProps) => {
  const {
    handleBack,
    createBot,
  } = props;

  const {
    t, 
  } = useTranslation();

  const {
    watch,
    setValue,
  } = useFormContext();

  const bot = watch('bot');
  const watcher = watch('auto');
  const selectedPreset = watcher.preset;
  
  const errors = watch('errors');

  const selectPresetHandler = (value: string) => {
    return () => {
      watcher.preset = value;
      setValue('auto', watcher);
    };
  };

  useEffect(() => {
    if (!bot?.botType) {
      return;
    }

    watcher.segment = bot.settingsType ? 'auto' : 'custom';
    watcher.preset = bot.settingsType || 'conservative';
    setValue('auto', watcher);
  }, [bot]);

  return (
    <Stack sx={WRAPPER}>
      <Box sx={PRESETS}>
        {presets.map((preset, index: number) => (
          <Preset
            key={preset.title}
            title={t(`configurator.managment.auto.presets.${index}.title`)}
            settings={t(`configurator.managment.auto.presets.${index}.settings`, {
              returnObjects: true, 
            })}
            caption={t(`configurator.managment.auto.presets.${index}.caption`)}
            description={t(`configurator.managment.auto.presets.${index}.description`)}
            isSelected={selectedPreset === preset.title.toLowerCase()}
            onClick={selectPresetHandler(preset.title.toLowerCase())}
          />
        ))}
      </Box>

      <Stack
        sx={{
          gap: '24px',
          width: '100%',
          maxWidth: {
            xs: '100%',
            md: 386,
          },
        }}
      >
        <Panel type='common' />

        <Actions
          handleBack={handleBack}
          button={{
            title: bot ? t('configurator.button.update') : t('configurator.button.create'),
            action: createBot,
            disabled: errors?.length,
          }}
        />
      </Stack>
    </Stack>
  );
};
