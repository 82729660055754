import { yupResolver } from '@hookform/resolvers/yup';
import { Hidden, Stack } from '@mui/material';
import { getCurrentLanguage } from 'entities/user/model/selectors/get-language/get-language';
import { FormEvent, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { CopyRight, PageWrapper } from 'widgets';
import { LogoAuth } from 'shared/icons';
import { IAuthProps } from '../interfaces';
import { CONTENT, FORM_WRAPPER, INNER, WRAPPER } from '../styles';

export const Auth = (props: IAuthProps) => {
  const {
    title,
    form,
    suffixView,
    submit,
    scheme,
  } = props;

  const language = useSelector(getCurrentLanguage);

  const methods = useForm({
    mode: 'onChange',
    resolver: scheme && yupResolver(scheme),
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    methods.handleSubmit(submit)(event);
  };

  useEffect(() => {
    if (!Object.keys(methods.formState.errors).length) {
      return;
    }

    methods.trigger();
  }, [language, methods]);
  
  return (
    <PageWrapper title={title}>
      <Stack sx={WRAPPER}>
        <Stack sx={FORM_WRAPPER}>
          <FormProvider {...methods}>
            <Stack
              component='form'
              onSubmit={onSubmit}
            >
              {form}
            </Stack>
          </FormProvider>
        </Stack>

        <Hidden mdDown={true}>
          <Stack sx={INNER}>
            <Stack sx={CONTENT}>
              {LogoAuth}
              
              {suffixView}
            </Stack>

            <CopyRight
              styles={{
                color: '#fff',
              }}
            />
          </Stack>
        </Hidden>  
      </Stack>
    </PageWrapper>
  );
};
