import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { authPath } from 'shared/config/route-config/auth-routes/consts';
import { privacyPolicy, termService } from 'shared/consts';
import { Link, Text } from 'shared/ui';
import { ACCOUNT, ACCOUNT_TEXT, POLICY, WRAPPER } from '../styles';

export const Extra = () => {
  const {
    t, 
  } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(authPath.login.navigate());
  };

  return (
    <Stack sx={WRAPPER}>
      <Text styles={POLICY}>
        {t('form.register.links.prefix')}

        {' '}

        <Link
          type='success'
          styles={POLICY}
          href={termService}
          target='_blank'
        >
          {t('form.register.links.terms')}
        </Link>

        {' '}

        {t('form.register.links.and')}
        
        {' '}
    
        <Link
          type='success'
          styles={POLICY}
          href={privacyPolicy}
          target='_blank'
        >
          {t('form.register.links.policy')}
        </Link>
      </Text>

      <Stack sx={ACCOUNT}>
        <Text styles={ACCOUNT_TEXT}>
          {t('form.register.links.login.label')}
        </Text>

        <Text
          type='success'
          styles={ACCOUNT_TEXT}
          onClick={handleNavigate}
        >
          <strong>
            {t('form.register.links.login.button')}
          </strong>
        </Text>
      </Stack>
    </Stack>
  );
};
