import { Box, Stack } from '@mui/material';
import { Divider } from 'antd';
import { BlockTitle } from 'pages/trading-bots/configurator-v2/components/block-title';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { Block, CustomSlider } from '../../../../../components';
import { CONTENT, WRAPPER } from '../styles';

export const Preset = () => {
  const {
    watch,
    setValue,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();

  const close = watch('close');

  const isTakeProfitOpened = close.segment.preset.takeProfit.isOpened;
  const takeProfitOrders = close.segment.preset.takeProfit.orders;
  const takeProfitOrdersSizeScale = close.segment.preset.takeProfit.orderSizeScale;
  const takePriceChange = close.segment.preset.takeProfit.priceChange;
  const takeOrderPriceChangeScale = close.segment.preset.takeProfit.orderPriceChangeScale;
  const takeOrderType = close.segment.preset.takeProfit.orderType;

  const isStopLossOpened = close.segment.preset.stopLoss.isOpened;
  const stopLossPriceChange = close.segment.preset.stopLoss.priceChange;
  const stopLossOrderType = close.segment.preset.stopLoss.orderType;

  const isOneOrder = takeProfitOrders === 1;

  const takeProfitToggleHandler = (value: boolean) => {
    setValue('close.segment.preset.takeProfit.isOpened', value);
  };

  const stopLossToggleHandler = (value: boolean) => {
    setValue('close.segment.preset.stopLoss.isOpened', value);
  };

  const changeTakeProfitOrdersHandler = (value: number) => {
    if (value > 5) {
      return;
    }

    if (value === 1) {
      changeTakeProfitOrderSizeScaleHandler(1);
      changeTakeProfitOrdersPriceChangeScaleHandler(1);
    }

    setValue('close.segment.preset.takeProfit.orders', value);
  };

  const changeTakeProfitOrderSizeScaleHandler = (value: number) => {
    setValue('close.segment.preset.takeProfit.orderSizeScale', value);
  };

  const changeTakeProfitPriceChangeHandler = (value: number) => {
    setValue('close.segment.preset.takeProfit.priceChange', value);
  };

  const changeTakeProfitOrdersPriceChangeScaleHandler = (value: number) => {
    setValue('close.segment.preset.takeProfit.orderPriceChangeScale', value);
  };

  const changeTakeProfitOrderTypeHandler = (value: string) => {
    setValue('close.segment.preset.takeProfit.orderType', value);
  };

  const changeStopLossPriceChangeHandler = (value: number) => {
    setValue('close.segment.preset.stopLoss.priceChange', value);
  };

  const changeStopLossOrderTypeHandler = (value: string) => {
    setValue('close.segment.preset.stopLoss.orderType', value);
  };

  return (
    <Stack
      sx={{
        ...CONTENT,
        gap: '24px',
      }}
    >
      <Block
        title={(
          <BlockTitle
            title={t('configurator.managment.custom.take.title')}
            toggle={{
              toggled: isTakeProfitOpened,
              gap: '36px',
              change: takeProfitToggleHandler,
            }}
            segment={isTakeProfitOpened ? {
              selected: takeOrderType,
              options: [{
                label: t('form.configurator.orderType.items.market'),
                value: 'market',
              }, {
                label: t('form.configurator.orderType.items.limit'),
                value: 'limit',
              }],
              change: changeTakeProfitOrderTypeHandler,
            } : undefined}
          />
        )}
      >
        {isTakeProfitOpened && (
          <Stack
            sx={{
              gap: '25px',
            }}
          >
            <Box sx={WRAPPER}>
              <CustomSlider
                title={(
                  <Label
                    title={t('form.configurator.take.orders')}
                  />
                )}
                slider={{
                  value: takeProfitOrders,
                  max: 5,
                  marks: {
                    1: 1,
                    2: 2,
                    5: 5,
                  },
                  onChange: changeTakeProfitOrdersHandler,
                }}
                input={{
                  value: takeProfitOrders,
                  placeholder: '100%',
                  onChange: changeTakeProfitOrdersHandler,
                }}
              />

              <CustomSlider
                title={(
                  <Label
                    title={t('form.configurator.take.ordersSize')}
                  />
                )}
                slider={{
                  value: takeProfitOrdersSizeScale,
                  min: 0.1,
                  max: 2.5,
                  marks: {
                    0.1: 0.1,
                    1: 1,
                    2.5: 2.5,
                  },
                  onChange: changeTakeProfitOrderSizeScaleHandler,
                }}
                input={{
                  value: takeProfitOrdersSizeScale,
                  placeholder: '100%',
                  onChange: changeTakeProfitOrderSizeScaleHandler,
                }}
                step={0.1}
                disabled={isOneOrder}
              />
            </Box>

            <Divider
              style={{
                margin: 0, 
              }}
            />

            <Box sx={WRAPPER}>
              <CustomSlider
                title={(
                  <Label
                    title={t('form.configurator.take.priceChange')}
                  />
                )}
                slider={{
                  value: takePriceChange,
                  max: 15,
                  marks: {
                    1: 1,
                    5: 5,
                    15: 15,
                  },
                  onChange: changeTakeProfitPriceChangeHandler,
                }}
                input={{
                  value: takePriceChange,
                  placeholder: '100%',
                  onChange: changeTakeProfitPriceChangeHandler,
                }}
                step={0.1}
              />

              <CustomSlider
                title={(
                  <Label
                    title={t('form.configurator.take.priceChangeScale')}
                  />
                )}
                slider={{
                  value: takeOrderPriceChangeScale,
                  min: 0.1,
                  max: 2.5,
                  marks: {
                    0.1: 0.1,
                    1: 1,
                    2.5: 2.5,
                  },
                  onChange: changeTakeProfitOrdersPriceChangeScaleHandler,
                }}
                input={{
                  value: takeOrderPriceChangeScale,
                  placeholder: '100%',
                  onChange: changeTakeProfitOrdersPriceChangeScaleHandler,
                }}
                step={0.1}
                disabled={isOneOrder}
              />
            </Box>
          </Stack>
        )}
      </Block>

      <Block
        title={(
          <BlockTitle
            title={t('configurator.managment.custom.stop.title')}
            toggle={{
              toggled: isStopLossOpened,
              gap: '40px',
              change: stopLossToggleHandler,
            }}
            segment={isStopLossOpened ? {
              selected: stopLossOrderType,
              options: [{
                label: t('form.configurator.orderType.items.market'),
                value: 'market',
              }, {
                label: t('form.configurator.orderType.items.limit'),
                value: 'limit',
              }],
              change: changeStopLossOrderTypeHandler,
            } : undefined}
          />
        )}
      >
        {isStopLossOpened && (
          <Box sx={WRAPPER}>
            <CustomSlider
              title={(
                <Label
                  title={t('form.configurator.stop.priceChange')}
                />
              )}
              slider={{
                value: stopLossPriceChange,
                max: 10,
                marks: {
                  1: -1,
                  3: -3,
                  10: -10,
                },
                onChange: changeStopLossPriceChangeHandler,
                reversed: true,
              }}
              input={{
                value: stopLossPriceChange,
                placeholder: '100%',
                onChange: changeStopLossPriceChangeHandler,
              }}
              step={0.1}
            />
          </Box>
        )}
      </Block>
    </Stack>
  );
};
