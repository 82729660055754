import { BaseOptionType } from 'antd/es/select';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { SingleSelect } from 'shared/ui';
import { Market as MarketView } from 'shared/ui/selects/single-select/views';

export const Market = () => {
  const {
    t, 
  } = useTranslation();
  
  const selectMarketHandler = useCallback((value: string) => {
    console.log('value', value);
  }, []);

  const marketView = useCallback(() => {
    const market = {
      name: '10000_USDT',
      code: 'USDT',
    };

    const [name, value] = market.name.split('_');

    return (
      <MarketView
        title={name}
        code={market.code}
        value={`${name}_${value}`}
      />
    );
  }, []);

  const marketOptionView = (option: BaseOptionType) => {
    const [value, pair] = (option.label as string || '').split('_');
    return (
      <MarketView
        title={value}
        code={pair}
        value={`${value}_${pair}`}
      />
    );
  };
  
  return (
    <SingleSelect
      label={(
        <Label
          title={t('form.configurator.market.title')}
        />
      )}
      options={[{
        label: '10000_USDT',
        value: 1,
      }]}
      select={{
        value: 1,
        placeholder: '',
        onChange: selectMarketHandler,
      }}
      labelRender={marketView}
      optionRender={marketOptionView}
      size='large'
    />
  );
};
