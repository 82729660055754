import dayjs from 'dayjs';
import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { TDates } from 'shared/types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface GetBotStatisticParams {
  botUuid: string;
  dates?: TDates;
}
export const getBotStatisticByDates = async (params: GetBotStatisticParams) => {
  const {
    botUuid,
    dates,
  } = params;

  const closedDateFrom = dates?.[0] ? dayjs(dates?.[0]).subtract(30, 'day').format('YYYY-MM-DD HH:mm:ss') : null;
  const closedDateTo = dates?.[1] ? dates?.[1].endOf('day').format('YYYY-MM-DD HH:mm:ss') : null;

  const response = await axios.request({
    url: '/bot/calculateStatistic',
    data: {
      botUuid,
      closedDateFrom,
      closedDateTo,
    },
  });

  return response.data.data;
};
