import { EnrichedTrade } from 'entities/terminal/model/types/terminal-schema';

export const getMarkets = (trades: EnrichedTrade[]) => {
  const uniqueMarkets = Array.from(new Set(trades.map((trade) => {
    return trade.quoteSymbol.toLowerCase();
  })));

  const options = uniqueMarkets.map((symbol) => ({
    label: symbol.toUpperCase(),
    value: symbol,
  }));

  return options;
};
