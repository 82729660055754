import { TradingStatisticCalculations } from 'pages/trading-bots/my-bots/types/bots-calculations';

export const getMarketOptions = (statistics: TradingStatisticCalculations[]) => {
  const markets = (statistics || []).map((statistic) => {
    return {
      label: statistic.market,
      value: statistic.market,
    };
  });

  return markets;
};
