import { Dropdown } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Down } from 'shared/icons';
import { Icon, TextButton } from 'shared/ui';
import { AddBot } from '../../../components';
import { menus } from '../../../consts';
import { MenuItem } from '../components';
import { BUTTON } from '../components/menu-item/styles';
import { getMenuItems } from '../helpers';

export const useGetMenu = () => {
  const {
    t, 
  } = useTranslation();

  const preventDefault = (event:  React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
  };

  const getMenu = () => {
    const items = menus(t).map((menuItem): ReactNode => {
      if (!menuItem.path) {
        const button = menuItem.key === 'trading_bots_my_bots' && (
          <AddBot type='button' />
        );

        return (
          <Dropdown
            key={menuItem.key}
            menu={{
              items: getMenuItems({
                menuItems: menuItem.children!,
                button,
              }),
            }}
          >
            <NavLink
              to=''
              onClick={preventDefault}
            >
              <TextButton
                icon={(
                  <Icon width={16}>
                    {Down}
                  </Icon>
                )}
                styles={BUTTON()}
              >
                {menuItem.label}
              </TextButton>
            </NavLink>
          </Dropdown>
        );
      }
      
      return (
        <MenuItem
          key={menuItem.key}
          item={menuItem}
        />
      );
    });

    return items;
  };

  return getMenu;
};
