import { Stack } from '@mui/material';
import { getSubscription } from 'pages/trading-bots/marketplace/api/get-subscription';
import { Card } from 'pages/trading-bots/marketplace/components';
import { combineBots } from 'pages/trading-bots/marketplace/helpers/combine-bots';
import { getPublicBots } from 'pages/trading-bots/marketplace/helpers/get-public-bots';
import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Ending, Info, Perfomance, Steps, Welcome } from 'widgets';
import { appPath } from 'shared/config/route-config/app-routes/consts';
import { Loader } from 'shared/ui';
import { CARDS } from '../styles';

export const Custom = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredBots, setFilteredBots] = useState<{[key: string]: Bot[];} | null>(null);
  const [subscription, setSubscription] = useState<any>(null);

  const navigate = useNavigate();
  const {
    t, 
  } = useTranslation();
  
  const navigatePage = (path: string) => {
    return () => {
      navigate(path);
    };
  };

  const fetchBots = async () => {
    try {
      const responseFromAllBots = await getPublicBots();
      const combinedBots = combineBots(responseFromAllBots);
      setFilteredBots(combinedBots);

      const subscriptions = await getSubscription();
      if (!subscriptions?.length) {
        return;
      }

      const [subscription] = subscriptions;
      setSubscription(subscription);
    } catch (error) {
      console.error('Error fetching bots:', error);
    } finally {
      setLoading(false);
    }
  };

  const getMarketplaceCardsContent = (cards: ReactNode) => {
    if (loading) {
      return (
        <Stack
          position='relative'
          minHeight={(420 * 2) + 30}
        >
          <Loader isContentOverflow={true} />
        </Stack>
      );
    }

    return (
      <Stack sx={CARDS}>
        {cards}
      </Stack>
    );
  };

  useEffect(() => {
    fetchBots();
  }, []);

  return (
    <Stack gap='100px'>
      <Welcome
        title={t('bots.introduction.copy.welcome.title')}
        image='/images/trading-bots/copy-bots/1.jpg'
        blocks={[{
          title: t('bots.introduction.copy.welcome.start.title'),
          text: t('bots.introduction.copy.welcome.start.text'),
        }, {
          title: t('bots.introduction.copy.welcome.copy.title'),
          text: t('bots.introduction.copy.welcome.copy.text'),
        }, {
          title: t('bots.introduction.copy.welcome.monthly.title'),
          text: t('bots.introduction.copy.welcome.monthly.text'),
        }, {
          title: t('bots.introduction.copy.welcome.high.title'),
          text: t('bots.introduction.copy.welcome.high.text'),
        }]}
      />

      <Steps
        items={[
          t('bots.introduction.copy.steps.1'),
          t('bots.introduction.copy.steps.2'),
          t('bots.introduction.copy.steps.3'),
          t('bots.introduction.copy.steps.4'),
        ]}
      />

      <Info
        title={t('bots.introduction.copy.positions.title')}
        text={t('bots.introduction.copy.positions.text')}
        image='/images/trading-bots/copy-bots/2.png'
        button={{
          title: t('bots.introduction.button.choose'),
          action: navigatePage(appPath.trading_bots_marketplace.navigate()),
        }}
        isReverse={true}
        isColored={true}
      />

      <Perfomance
        items={[{
          title: '500K+',
          text: t('bots.introduction.copy.perfomance.items.trades'),
        }, {
          title: '2K+',
          text: t('bots.introduction.copy.perfomance.items.bots'),
        }, {
          title: '1M+',
          text: t('bots.introduction.copy.perfomance.items.profit'),
        }, {
          title: '$10M+',
          text: t('bots.introduction.copy.perfomance.items.volume'),
        }]}
      />

      {getMarketplaceCardsContent(
        filteredBots && Object.entries<Bot[]>(filteredBots).map(([key, bots]) => (
          <Card
            key={key}
            bots={bots}
            subscription={subscription}
          />
        )),
      )}

      <Info
        title={t('bots.introduction.copy.fair.title')}
        text={t('bots.introduction.copy.fair.text')}
        image='/images/trading-bots/copy-bots/3.jpg'
        button={{
          title: t('bots.introduction.button.choose'),
          action: navigatePage(appPath.trading_bots_marketplace.navigate()),
        }}
      />

      <Info
        title={t('bots.introduction.copy.control.title')}
        text={t('bots.introduction.copy.control.text')}
        image='/images/trading-bots/copy-bots/4.png'
        isReverse={true}
        isColored={true}
        button={{
          title: t('bots.introduction.button.choose'),
          action: navigatePage(appPath.trading_bots_marketplace.navigate()),
        }}
      />

      <Ending
        title={t('bots.introduction.copy.ending.title')}
        text={t('bots.introduction.copy.ending.text')}
        button={{
          title: t('bots.introduction.button.choose'),
          action: navigatePage(appPath.trading_bots_marketplace.navigate()),
        }}
      />
    </Stack>
  );
};
