import { SxProps, Theme } from '@mui/material';

export const textWrapper: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  '&>span': {
    '&:last-of-type': {
      fontSize: '12px !important',
      display: 'block',
      maxWidth: 220,
    },
  },
};
