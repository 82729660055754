import { Stack } from '@mui/material';
import { Dropdown } from 'antd';
import { SettingsSolid } from 'shared/icons';
import { Icon, Text } from 'shared/ui';
import { Dates, Market, Side, Source, Type } from '../components';
import { IActionsProps } from '../interfaces';
import { FILTERS, WRAPPER } from '../styles';

export const Actions = (props: IActionsProps) => {
  const {
    source,
    market,
    type,
    side,
    dates,
  } = props;

  const getItems = () => {
    const typeSelect = (type && side) && {
      key: 1,
      label: (
        <Type
          value={type.value}
          onChange={type.onChange}
          disabled={type.disabled}
          onChangeSide={side.onChange}
        />
      ),
    };

    const sideSelect = (side && type) && {
      key: 3,
      label: (
        <Side
          value={side.value}
          type={type.value}
          onChange={side.onChange}
        />
      ),
    };

    const sourceSelect =  source &&{
      key: 4,
      label: (
        <Source
          value={source.value}
          onChange={source.onChange}
        />
      ),
    };

    const marketSelect = market && {
      key: 5,
      label: (
        <Market
          value={market.value}
          options={market.options}
          onChange={market.onChange}
        />
      ),
    };
    
    return [{
      key: 1,
      label: 'Strategy',
      children: [
        typeSelect,
        sideSelect,
      ],
    }, {
      key: 2,
      label: 'Trades',
      children: [
        sourceSelect,
        marketSelect,
      ],
    }];
  };

  return (
    <Stack sx={WRAPPER}>
      {(type || side || source || market) && (
        <Dropdown
          trigger={['click']}
          menu={{
            items: getItems(),
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Stack sx={FILTERS}>
              <Icon width={16}>
                {SettingsSolid}
              </Icon>
            
              <Text>
                Filters
              </Text>
            </Stack>
          </a>
        </Dropdown>
      )}

      <Dates
        value={dates.value}
        onChange={dates.onChange}
      />
    </Stack>              
  );
};
