/**
 * Хелпер: пытается получить строку в фиксированном формате (не экспоненциальном)
 * через toFixed(20). Если JS вернул e/Infinity (для очень больших/маленьких чисел),
 * делаем fallback – самостоятельно "разворачиваем" исход в обычную форму.
 */
function toNonScientificStr(input: number, fractionDigits = 20): string {
  // Попробуем через toFixed
  const fixed = input.toFixed(fractionDigits);

  // Если это не содержит 'e', 'E' и не Infinity — возвращаем как есть
  if (!/e|E|Infinity/.test(fixed)) {
    return fixed;
  }

  // Иначе делаем fallback – вручную разбираем исходный input,
  // переводим его в строку, может быть "1.23e+30", "1.23e-5" и т.п.
  const rawStr = input.toString(); // может дать научный формат

  if (!/e|E/.test(rawStr)) {
    // Если по каким-то причинам toString() не дал e/E, но toFixed дал Infinity,
    // значит число слишком большое ( >1e+100... ). Сделаем заглушку — 
    // пусть будет строка с integer-частью (или 'Infinity' проверим).
    if (!Number.isFinite(input)) {
      // Слишком большое или слишком маленькое => вернём "0" или какую-то
      // заглушку. Обычно Infinity => можно вернуть просто intPart.
      return input < 0 ? '-' : '' + '99999999999999999999.0000000000';
    }
    // Иначе всё же вернём то, что есть в fixed (даже если в fixed написано 'Infinity'),
    // — или можно отдать большую строку:
    return fixed;
  }

  // Парсим формат вида "[-]X.Ye±Z" вручную
  return expandExponential(rawStr, fractionDigits);
}

/**
 * Fallback-хелпер: превращает строку "1.23e+5" / "1.23e-5" / "123e10" и т.п.
 * в "обычный" (fixed) формат, без экспоненты, с учётом знака. 
 * При этом дробную часть мы делаем с запасом (fractionDigits).
 *
 * Алгоритм упрощён: мы не выполняем "округление", а делаем скорее 
 * "обрезание/добавление" нулей, т.к. хотим гарантированно убрать экспоненту.
 */
function expandExponential(str: string, fractionDigits: number): string {
  // 1) Выделим знак
  let sign = '';
  if (str.startsWith('-')) {
    sign = '-';
    str = str.slice(1);
  } else if (str.startsWith('+')) {
    str = str.slice(1);
  }

  // 2) Ищем 'e' или 'E'
  const [mantissa, expPart] = str.split(/[eE]/);
  if (!mantissa || !expPart) {
    // Нечто странное, вернём как есть
    return sign + str;
  }

  // 3) Разбираем мантиссу: может быть "123.456"
  //    Уберём точку, но запомним позицию
  const dotIndex = mantissa.indexOf('.');
  let intDigits = '';
  let fracDigits = '';

  if (dotIndex >= 0) {
    intDigits = mantissa.slice(0, dotIndex);
    fracDigits = mantissa.slice(dotIndex + 1);
  } else {
    intDigits = mantissa;
  }

  // 4) Парсим экспоненту (целое)
  let exp = parseInt(expPart, 10);
  if (isNaN(exp)) {
    // fallback
    return sign + str;
  }

  // 5) Если exp > 0, сдвигаем точку вправо exp раз,
  //    если exp < 0 – влево |exp| раз.
  if (exp >= 0) {
    // Переносим fracDigits в intDigits, пока exp > 0 и есть цифры в frac
    while (exp > 0 && fracDigits.length > 0) {
      intDigits += fracDigits.charAt(0);
      fracDigits = fracDigits.slice(1);
      exp--;
    }
    // Если fracDigits уже пуст, но exp всё ещё > 0, значит нужно 
    // добавить нулей в intDigits
    if (exp > 0) {
      intDigits += '0'.repeat(exp);
      exp = 0;
    }
  } else {
    // exp < 0, переносим intDigits в fracDigits
    let absExp = Math.abs(exp);
    while (absExp > 0 && intDigits.length > 0) {
      fracDigits = intDigits.slice(-1) + fracDigits;
      intDigits = intDigits.slice(0, -1);
      absExp--;
    }
    // Если intDigits опустел, но ещё остался сдвиг, => нужно добавить ведущих нулей 
    // в fracDigits
    if (absExp > 0) {
      fracDigits = '0'.repeat(absExp) + fracDigits;
    }
    exp = 0; // мы обработали
  }

  // Теперь соберём "intDigits.fracDigits"
  if (!intDigits) {
    intDigits = '0';
  }

  // В зависимости от fractionDigits (для "запаса") можно укоротить fracDigits
  // (но мы не выполняем классического "математического" округления, а просто 
  // обрезаем «хвост» по желанию).
  if (fracDigits.length > fractionDigits) {
    fracDigits = fracDigits.slice(0, fractionDigits);
  }

  // Удалим лишние нули в конце fracDigits, т.к. .toFixed(20) делала бы то же самое
  // — хотя на самом деле .toFixed(20) не удаляет, но у нас "запас" логики
  // пусть будет "красивее".
  // !!! Впрочем, не все хотят "срезать" нули. Можно оставить как есть, 
  //     но для краткости уберём:
  // while (fracDigits.endsWith("0")) {
  //   fracDigits = fracDigits.slice(0, -1);
  // }

  // Собираем
  let out = intDigits;
  if (fracDigits) {
    out += '.' + fracDigits;
  }
  return sign + out;
}

/**
 * Главная функция: формирует человекочитаемый (без e/E) формат числа 
 * и применяет логику "не более 8 знаков дроби", "до 2 значимых после 
 * первой ненулевой" и т.д.
 */
export function formatter(value: number | string): string {
  // 1. Пробуем привести к числу (для проверки валидности)
  const num = typeof value === 'number' ? value : parseFloat(value);
  if (isNaN(num)) {
    return '0'; // не число -> "0"
  }

  if (num === 0) {
    return '0';
  }

  // 2. Получаем не-экспоненциальную строку с запасом из 20 знаков после запятой
  //    (или fallback, если toFixed вернул научный формат)
  let nonExpStr = toNonScientificStr(num, 20);

  // Теперь nonExpStr гарантированно не должен содержать 'e'/'E' 
  // (если fallback отработал), но на всякий случай можно повторно проверить.
  // Если всё же осталось (может быть экстремальный случай), 
  // попробуем ещё раз "развернуть":
  if (/e|E/.test(nonExpStr)) {
    nonExpStr = expandExponential(nonExpStr, 20);
  }

  // 3. Убираем лишние пробелы, плюсики
  nonExpStr = nonExpStr.trim().replace(/^\+/, '');

  // 4. Выделяем знак
  let sign = '';
  if (nonExpStr.startsWith('-')) {
    sign = '-';
    nonExpStr = nonExpStr.slice(1);
  }

  // 5. Отделяем целую и дробную части
  let [intPartStr, fracPartRaw = ''] = nonExpStr.split('.');

  // Без лишних ведущих нулей в целой части
  // (но если всё "0000", оставим один "0")
  intPartStr = intPartStr.replace(/^0+/, '');
  if (!intPartStr) {
    intPartStr = '0';
  }

  // Проверка: если fracPartRaw нет или она вся из нулей
  if (!fracPartRaw || /^0+$/.test(fracPartRaw)) {
    // Возвращаем целую часть
    if (intPartStr === '') {
      return sign + '0';
    }
    return sign + intPartStr;
  }

  // 6. Если дробная часть длиннее 8 символов, обрезаем до первых 8
  if (fracPartRaw.length > 8) {
    fracPartRaw = fracPartRaw.slice(0, 8);
  }

  // Превращаем дробь в массив
  const fracDigits = fracPartRaw.split('');

  // 7. Ищем первую ненулевую цифру
  let firstNonZeroIndex = -1;
  for (let i = 0; i < fracDigits.length; i++) {
    if (fracDigits[i] !== '0') {
      firstNonZeroIndex = i;
      break;
    }
  }

  // Если не нашли ненулевую, значит вся дробь (до 8 цифр) — нули
  if (firstNonZeroIndex === -1) {
    // Если и целая часть == 0, вернём "0", иначе просто целую часть
    if (intPartStr === '0') {
      return '0';
    }
    return sign + intPartStr;
  }

  // 8. Берём до 2 значимых цифр, начиная с этой первой ненулевой
  const rawSignificant = fracDigits.slice(
    firstNonZeroIndex,
    firstNonZeroIndex + 2,
  );

  // Удаляем концевые нули (trailing zeros) из этого фрагмента
  let endIndex = rawSignificant.length;
  while (endIndex > 0 && rawSignificant[endIndex - 1] === '0') {
    endIndex--;
  }
  const trimmedSignificant = rawSignificant.slice(0, endIndex);

  // Если всё «стерлось» (например, взяли "00" => убрали всё)
  if (trimmedSignificant.length === 0) {
    // Если целая часть 0 => "0", иначе целая
    if (intPartStr === '0') {
      return '0';
    }
    return sign + intPartStr;
  }

  // 9. Добавляем ведущие нули (их число = firstNonZeroIndex)
  //    Например, если firstNonZeroIndex=3, значит "0.000XYZ"
  const leadingZeros = new Array(firstNonZeroIndex).fill('0');
  const finalFractionArray = leadingZeros.concat(trimmedSignificant);

  // 10. Склеиваем результат
  const finalFraction = finalFractionArray.join('');
  if (!finalFraction || /^0+$/.test(finalFraction)) {
    // Если вдруг остались одни нули в дробной части
    // -> вернём только целую
    return sign + intPartStr;
  }

  return sign + intPartStr + '.' + finalFraction;
}
