import { array, object, string } from 'yup';

export const scheme = (t: any) => {
  const validation = object().shape({
    bots: array(string()).required(t()),
    amount: string().required('Required').test({
      name: 'check-amount',
      message: 'qwdqwdqwd',
      test: (value) => {
        return !(value && (/[^\d.]/g).test(value));
      },
    }),
    name: string().required('Required').max(200, 'Max symbols'),
    description: string().required('Required').max(200, 'Max symbols'),
    author: string().required('Required').max(200, 'Max symbols'),
    profit: string().test({
      name: 'test-profit',
      message: 'ddddd',
      test: (value) => {
        return !(/[^\d]/).test(value || '');
      },
    }),
  });

  return validation;
};
