import { BaseOptionType } from 'antd/es/select';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { convertExchange, getExchangeType } from 'pages/trading-bots/configurator-v2/helpers';
import { useGetExchanges } from 'pages/trading-bots/configurator-v2/hooks';
import { useCallback, useMemo } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Label } from 'widgets';
import { SingleSelect } from 'shared/ui';
import { Exchange as ExchangeView } from 'shared/ui/selects/single-select/views';

export const Exchange = () => {
  const params = useParams();
  
  const {
    values,
  } = useGetExchanges({
    botUuid: params.id!,
  });

  const {
    t, 
  } = useTranslation();
  const {
    control,
    watch,
    setValue,
    formState: {
      errors,
    },
  } = useFormContext();

  const watcher = watch('exchange');
  const exchange = watcher.value;
  const exchanges = watcher.items as ExchangeAccount[];

  const selectExchangeHandler = (value: string) => {
    const exchange = exchanges.find((exchange) => {
      return exchange.exchangeAccountUuid === value;
    });
    if (!exchange) {
      return;
    }

    watcher.value = convertExchange(exchange);
    setValue('exchange', watcher);
  };

  const exchangeView = useCallback(() => {
    if (!exchange.id) {
      return null;
    }

    return (
      <ExchangeView
        title={exchange.name}
        code={exchange.code}
        label={exchange.type}
        balance={exchange.totalUsdt}
      />
    );
  }, [exchange]);

  const exchangeOptionView = useCallback((option: BaseOptionType) => {
    if (!exchange.id) {
      return null;
    }

    const [name, code, totalUsdt] = (option.label as string || '').split('_');
    return (
      <ExchangeView
        title={name}
        code={code}
        label={getExchangeType(code || '')}
        balance={+totalUsdt || 0}
      />
    );
  }, [exchange]);

  const exchangeOptions = useMemo(() => {
    if (!exchanges.length) {
      return [];
    }
    
    return exchanges?.map((account: ExchangeAccount) => {
      return {
        label: `${account.accountName}_${account.exchangeCode}_${account.totalUsdt}`,
        value: account.exchangeAccountUuid,
      };
    });
  }, [exchanges]);

  const renderFiled: ControllerProps['render'] = (params) => {
    const {
      field,
    } = params;

    return (
      <SingleSelect
        label={(
          <Label
            title={t('form.configurator.exchange.title')}
          />
        )}
        select={{
          value: field.value.value.id,
          placeholder: '',
          onChange: selectExchangeHandler,
        }}
        options={exchangeOptions}
        labelRender={exchangeView}
        optionRender={exchangeOptionView}
        size='large'
        loading={values.isLoading}
        error={errors?.exchange?.message?.toString()}
      />
    );
  };

  return (
    <Controller
      name='exchange'
      control={control}
      render={renderFiled as any}
    />
  );
};
