import { TLanguage, TTranslation } from 'shared/types';

export const formChange = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      change: {
        title: 'Recover password',
        description: 'Enter new password',
        password: {
          label: 'Password (6 or more characters)',
          placeholder: '',
        },
        button: 'Change password',
      },
    },
    ru: {
      change: {
        title: 'Восстановление пароля',
        description: 'Введите новый пароль',
        password: {
          label: 'Пароль (6+ символов)',
          placeholder: '',
        },
        button: 'Изменить пароль',
      },
    },
  };

  return transaltion[type].change;
};
