import { TLanguage, TTranslation } from 'shared/types';

export const errorPayment = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      payment: {
        promo: {
          message: 'Wrong code',
        },
      },
    },
    ru: {
      payment: {
        promo: {
          message: 'Неверный код',
        },
      },
    },
  };

  return transaltion[type].payment;
};
