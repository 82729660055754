import { CSSProperties } from 'react';
import { getResponsiveValue } from 'shared/helpers';

export const BUTTON = (active?: boolean) => {
  const styles: CSSProperties = {
    padding: getResponsiveValue({
      min: 8,
      max: 14,
    }),
    height: 'auto',
    display: 'flex',
    placeItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  };

  if (active) {
    styles.backgroundColor = '#F6F6F6';
    styles.color = '#3A3A3C';
  }

  return styles;
};
