import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { formatter } from 'shared/helpers';
import { SecondaryInfo } from 'shared/icons';
import { Icon, Tooltip } from 'shared/ui';
import { LabelInput } from 'shared/ui/inputs';
import { IBalanceProps } from '../interfaces';

export const Balance = (props: IBalanceProps) => {
  const {
    t, 
  } = useTranslation();
  
  const {
    control,
    formState: {
      errors,
    },
  } = useFormContext();
    
  const renderFiled: ControllerProps['render'] = (params) => {
    const {
      field,
    } = params;

    return (
      <LabelInput
        label={(
          <Label title={t('modal.copyBot.amount.title', {
            balance: formatter(props.avialable),
            quote: props.bot.quote, 
          })} />
        )}
        value={field.value}
        placeholder={t('modal.copyBot.amount.placeholder', {
          minInvestment: props.bot.minInvestment ?? 0,
          quote: props.bot.quote,
        })}
        onChange={(value) => {
          field.onChange(value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1'));
        }}
        suffix={(
          <Tooltip title={t('modal.copyBot.amount.tooltip')}>
            <Icon width={16}>
              {SecondaryInfo}
            </Icon>
          </Tooltip>
        )}
        error={errors?.balance?.message?.toString()}
      />
    );
  };

  return (
    <Controller
      name='balance'
      control={control}
      render={renderFiled as any}
    />
  );
};
