import { getExchanges, getTerminalExchanges } from 'entities/exchange/api';
import { getExchangeData } from 'entities/exchange/model/selectors/get-exchange-data/get-exchange-data';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDemoAccount } from 'shared/helpers';

export const useExcahnges = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const exchangeData = useSelector(getExchangeData);
  const [exchanges, setExchanges] = useState<ExchangeAccount[] | null>(null);

  const isDemoMode = useSelector(getIsDemoMode);

  const fetchExchanges = async () => {
    try {
      setIsLoading(true);
      setExchanges(null);
      const response = await getExchanges();
      const accounts: ExchangeAccount[] = response?.accounts;
      const filteredAccounts = getDemoAccount(accounts, isDemoMode);
      setExchanges(filteredAccounts);
      // dispatch(exchangeActions.setAllExchanges(filteredAccounts));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchExchangesBalances = async (uuids: string[]) => {
    try {
      setIsLoading(true);

      const exchangesData = await getTerminalExchanges(uuids);
      // dispatch(exchangeActions.setAllExchanges(exchangesData?.accounts));
      return exchangesData?.accounts;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchExchanges();
  }, [isDemoMode, exchangeData]);

  return {
    values: {
      isLoading,
      exchanges,
    },
    handlers: {
      setIsLoading,
      fetchExchangesBalances,
      setExchanges,
    },
  };
};
