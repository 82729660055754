import { TLanguage, TTranslation } from 'shared/types';

export const errorBots = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      bots: {
        update: {
          title: 'Failed to update bot',
          description: 'We will fix it soon, try again later or contact support',
          button: 'Ok',
        },
        delete: {
          title: 'Failed to delete bot',
          description: 'We will fix it soon, try again later or contact support',
          button: 'Ok',
        },
        close: {
          deals: {
            title: 'Failed to close deals',
            description: 'We will fix it soon, try again later or contact support',
            button: 'Ok',
          },
          trades: {
            title: 'Failed to close trades',
            description: 'We will fix it soon, try again later or contact support',
            button: 'Ok',
          },
        },
        cancel: {
          trades: {
            title: 'Failed to cancel trades',
            description: 'We will fix it soon, try again later or contact support',
            button: 'Ok',
          },
        },
        start: {
          title: 'Failed to start the bot',
          description: 'We will fix it soon, try again later or contact support',
          button: 'Ok',
        },
        stop: {
          title: 'Failed to stop the bot',
          description: 'We will fix it soon, try again later or contact support',
          button: 'Ok',
        },
        status: {
          'There are pending success fee payments. Please pay the fees to turn on the bot': {
            title: 'Some of your bots await profit sharing',
            description: 'Please complete all pending payments to turn on the bot',
            button: 'Subscriptions',
          },
          'Sharing option is limited. Please upgrade subscription': {
            title: 'Sharing is not available',
            description: 'Edit bot settings or upgrade Skyrexio plan',
            button: 'Upgrade',
          },
          'Maximum number of active bots reached. Please upgrade subscription': {
            title: 'Maximum number of bots reached',
            description: 'Please upgrade your Skyrexio subscription',
            button: 'Upgrade',
          },
          'There is no active success subscription': {
            title: 'There is no active subscription',
            description: 'Please activate subscription to turn on the bot',
            button: 'Subscribe',
          },
          'No skyrex subscription found': {
            title: 'No Skyrexio subscription found',
            description: '',
            button: 'Subscribe',
          },
        },
        alert: {
          title: 'Oops! You have no alert bots',
          description: 'Create an alert bot, to publish it in the marketplace',
          button: 'Okay',
        },
      },
    },
    ru: {
      bots: {
        update: {
          title: 'Не удалось обновить бота',
          description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
          button: 'Ок',
        },
        delete: {
          title: 'Не удалось удалить бота',
          description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
          button: 'Ок',
        },
        close: {
          deals: {
            title: 'Не удалось закрыть сделки',
            description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
            button: 'Ок',
          },
          trades: {
            title: 'Не удалось закрыть трейды',
            description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
            button: 'Ок',
          },
        },
        cancel: {
          trades: {
            title: 'Не удалось отменить трейды',
            description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
            button: 'Ок',
          },
        },
        start: {
          title: 'Не удалось запустить бота',
          description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
          button: 'Ок',
        },
        stop: {
          title: 'Не удалось остановить бота',
          description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
          button: 'Ок',
        },
        status: {
          'There are pending success fee payments. Please pay the fees to turn on the bot': {
            title: 'У ботов есть неоплаченные комиссии',
            description: 'Оплатите все подписки, чтобы включить бота',
            button: 'Подписки',
          },
          'haring option is limited. Please upgrade subscription': {
            title: 'Шеринг недоступен',
            description: 'Измените настройки бота или обновите план Skyrexio',
            button: 'Обновить',
          },
          'Maximum number of active bots reached. Please upgrade subscription': {
            title: 'Достигнут лимит ботов',
            description: 'Обновите подписку Skyrexio',
            button: 'Обновить',
          },
          'There is no active success subscription': {
            title: 'Нет активной подписки бота',
            description: 'Активируйте подписку, чтобы включить бота',
            button: 'Подписаться',
          },
          'No skyrex subscription found': {
            title: 'Подписка на Skyrexio не найдена',
            description: '',
            button: 'Подписаться',
          },
        },
        alert: {
          title: 'Упс! Нет алерт-ботов',
          description: 'Создайте алерт-бот и разместите его на маркетплейсе',
          button: 'Ок',
        },
      },
    },
  };

  return transaltion[type].bots;
};
