import { CSSProperties } from 'react';

export const mainStyles: CSSProperties = {
  padding: '2px 12px',
  borderRadius: 4,
  height: 'auto',
  fontSize: 9,
  textTransform: 'uppercase',
  lineHeight: '12px',
  fontWeight: 500,
  boxShadow: 'none',
};
