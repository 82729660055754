import { TLanguage, TTranslation } from 'shared/types';

export const diary = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      diary: {
        title: 'Trading diary',
        button: 'Export',
        select: {
          exchange: {
            title: 'Exchange account',
            placeholder: 'Select exchange account',
            items: {
              all: 'All',
            },
          },
          side: {
            title: 'Side',
            placeholder: 'Select side',
            items: {
              buy: 'Buy',
              sell: 'Sell',
            },
          },
          date: {
            create: 'Create dates',
            close: 'Close dates',
          },
          market: {
            title: 'Market',
            placeholder: 'Select market',
            items: {
              all: 'All',
            },
          },
          currency: {
            title: 'Base currency',
            placeholder: 'Select base currency',
            items: {
              all: 'All',
            },
          },
          status: {
            title: 'Status',
            placeholder: 'Select status',
            items: {
              all: 'All',
              completed: 'Completed',
              active: 'Active',
              cancelled: 'Cancelled',
            },
          },
        },
        winRate: {
          title: 'Win Rate',
          items: {
            total: 'Total P&L',
            upl: 'UP&L',
            trades: 'Total trades',
            inTrades: 'In trades',
          },
        },
        table: {
          search: {
            placeholder: 'Search by pair',
          },
          columns: {
            pair: 'Pair',
            account: 'Account',
            date: {
              create: 'Create date',
              close: 'Close date',
            },
            status: 'Status',
            total: 'Total',
            price: {
              entry: 'Entry price',
              close: 'Close price',
            },
            return: 'Return',
          },
        },
      },
    },
    ru: {
      diary: {
        title: 'Торговый дневник',
        button: 'Экспорт',
        select: {
          exchange: {
            title: 'Биржевой аккаунт',
            placeholder: 'Выберите аккаунт',
            items: {
              all: 'Все',
            },
          },
          side: {
            title: 'Сторона',
            placeholder: 'Выберите сторону',
            items: {
              buy: 'Купить',
              sell: 'Продать',
            },
          },
          date: {
            create: 'Даты создания',
            close: 'Даты закрытия',
          },
          market: {
            title: 'Рынок',
            placeholder: 'Выберите рынок',
            items: {
              all: 'Все',
            },
          },
          currency: {
            title: 'Базовая валюта',
            placeholder: 'Выберите базовую валюту',
            items: {
              all: 'Все',
            },
          },
          status: {
            title: 'Статус',
            placeholder: 'Выберите статус',
            items: {
              all: 'Все',
              completed: 'Завершенные',
              active: 'Активные',
              cancelled: 'Отмененные',
            },
          },
        },
        winRate: {
          title: 'Успешных',
          items: {
            total: 'Всего P&L',
            upl: 'UP&L',
            trades: 'Всего сделок',
            inTrades: 'В сделках',
          },
        },
        table: {
          search: {
            placeholder: 'Поиск по паре',
          },
          columns: {
            pair: 'Пара',
            account: 'Аккаунт',
            date: {
              create: 'Дата создания',
              close: 'Дата закрытия',
            },
            status: 'Статус',
            total: 'Итого',
            price: {
              entry: 'Входа',
              close: 'Закрытия',
            },
            return: 'Доход',
          },
        },
      },
    },
  };

  return transaltion[type].diary;
};
