import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const BLOCK_WRAPPER: SxProps<Theme> = {
  backgroundColor: '#F8FBF9',
  padding: getResponsiveValue({
    min: 16,
    max: 32,
  }),
  borderRadius: '16px',
  gap: getResponsiveValue({
    min: 12,
    max: 24,
  }),
};
