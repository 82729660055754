import { TLanguage, TTranslation } from 'shared/types';

export const remote = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      remote: {
        pl: {
          total: {
            title: 'Total P&L',
            tooltip: 'Total lifetime profit generated from all closed deals. Please note: this statistic is affected by any filters you have active.',
          },
          today: {
            title: 'Today P&L',
            tooltip: 'Profit taken from closed deals (resets at midnight UTC+0 each day). Please note: this statistic is affected by any filters you have active.',
          },
        },
        upl: {
          title: 'UP&L',
          tooltip: 'Unrealized P&L shows the current profit or loss for all open positions',
        },
        trades: {
          title: 'Trades',
          value: '{{activeTrades}} active / {{totalTrades}} total',
          tooltip: 'Current quantity of active trades / Quantity of all trades in selected date range',
        },
        funds: {
          title: 'Funds',
          tooltip: 'Amount of quote currency currently used in active trades / Total amount of quote currency allocated to the bot (percantage of currently used allocated funds)',
        },
        actions: {
          start: 'Start',
          stop: 'Stop',
          edit: 'Edit',
          delete: 'Delete',
        },
        links: {
          sales: 'Sales',
          subscriptions: 'Subscriptions',
          alert: 'Alert messages',
          signal: 'Signal source',
        },
      },
    },
    ru: {
      'remote': {
        'pl': {
          'total': {
            'title': 'Общий P&L',
            'tooltip': 'Общая прибыль за всё время, полученная от всех закрытых сделок. Обратите внимание: данная статистика зависит от активных фильтров.',
          },
          'today': {
            'title': 'Сегодняшний P&L',
            'tooltip': 'Прибыль от закрытых сделок за текущий день (обнуляется в полночь UTC+0). Обратите внимание: данная статистика зависит от активных фильтров.',
          },
        },
        'upl': {
          'title': 'UP&L',
          'tooltip': 'Нереализованный P&L показывает текущую прибыль или убыток по всем открытым позициям',
        },
        'trades': {
          'title': 'Сделки',
          'value': '{{activeTrades}} активных / {{totalTrades}} всего',
          'tooltip': 'Текущее количество активных сделок / общее количество сделок за выбранный период',
        },
        'funds': {
          'title': 'Баланс',
          'tooltip': 'Сумма в котируемой валюте, используемая в активных сделках / общая сумма котируемой валюты, выделенная боту (процент уже задействованных средств)',
        },
        'actions': {
          'start': 'Вкл',
          'stop': 'Выкл',
          'edit': 'Редактировать',
          'delete': 'Удалить',
        },
        'links': {
          'sales': 'Продажи',
          'subscriptions': 'Подписки',
          'alert': 'Сообщения сигналов',
          'signal': 'Источник сигналов',
        },
      },
    },
  };

  return transaltion[type].remote;
};
