import { Stack } from '@mui/material';
import { IIconProps } from '../interfaces';
import { WRAPPER } from '../styles';

export const Icon = (props: IIconProps) => {
  const {
    children,
    width,
    action,
  } = props;

  return (
    <Stack
      sx={WRAPPER(width)}
      onClick={action}
    >
      {children}
    </Stack>
  );
};
