import { SxProps, Theme } from '@mui/material';

export const filtersWrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: {
    xs: 'initial',
    md: 'center',
  },
  justifyContent: 'space-between',
  flexDirection: {
    xs: 'column-reverse',
    md: 'row',
  },
  gap: 3,
};
