import { SxProps, Theme } from '@mui/material';

export const FILTER_WRAPPER: SxProps<Theme> = {
  display: 'grid',
  gridTemplate: `
    'type type exchange exchange side side create_date'
    'market market base_currency base_currency status status close_date'
  `,
  gap: {
    xs: 1,
    lg: 3,
  },
  flex: 1,
  width: {
    xs: '100%',
    sm: 'auto',
  },
  '@media (max-width: 1085px)': {
    gridTemplate: `
    'type exchange exchange side'
    'base_currency base_currency market status'
    'create_date create_date close_date close_date'
  `,
    '&>div>div': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  '@media (max-width: 510px)': {
    gridTemplate: `
    'type exchange exchange exchange'
    'market market market market'
    'base_currency base_currency base_currency base_currency'
    'side side side side'
    'status status status status'
    'create_date create_date create_date create_date'
    'close_date close_date close_date close_date'
  `,
  },
};
