import { ReactNode, Suspense } from 'react';
import { Loader } from 'shared/ui';

const SuspenseWrapper = (props: Record<'children', ReactNode>) => {
  return (
    <Suspense
      fallback={(
        <Loader isContentOverflow={true} />
      )}
    >
      {props.children}
    </Suspense>
  );
};

export default SuspenseWrapper;
