import { getTerminalSnapshots } from '../../../../../entities/terminal/api/get-terminal-snapshots';

export async function getTerminalSnapshotsData(chosenAccount: any, skyrexUserUuid: string, exchangeAccountUuid: string) {

  const terminalSnapshotsResponse = await getTerminalSnapshots(skyrexUserUuid, exchangeAccountUuid);

  if (!terminalSnapshotsResponse?.data?.accounts || terminalSnapshotsResponse.data.accounts.length === 0) {
    return {
      usdtDailyChangePercent: '0',
      usdtDailyChangeValue: 0,
    };
  }

  const terminalSnapshotPreviousDay = terminalSnapshotsResponse.data.accounts[0];

  const usdtDailyChangeValue = parseFloat(chosenAccount.totalUsdt) - parseFloat(terminalSnapshotPreviousDay.totalUsdt);
  const usdtDailyChangePercent = ((usdtDailyChangeValue / parseFloat(terminalSnapshotPreviousDay.totalUsdt)) * 100).toFixed(2);
  
  return {
    usdtDailyChangePercent,
    usdtDailyChangeValue,
  };
}
