import { capitalizeFirstLetter } from 'pages/trading-bots/trading-history/helpers/get-capitalized-first-letter';
import { getOppositeSide, toUpperCase } from './helpers';

export function collectDataForEditTrade(formValues: any) {
  const {
    currentSymbol,
    addEntryEnabled,
    takeProfitEnabled,
    stopLossEnabled,
  } = formValues;
  
  const result: any = {
    baseSymbol: currentSymbol.baseAsset,
    quoteSymbol: currentSymbol.quoteAsset,
  };

  result.baseOrder = collectBaseOrder(formValues);

  if (addEntryEnabled) {
    result.additionalBaseOrders = collectAdditionalBaseOrders(formValues);
  }

  if (takeProfitEnabled) {
    result.takeProfits = collectTakeProfits(formValues);
  }

  if (stopLossEnabled) {
    result.stopLoss = collectStopLoss(formValues);
  }
  
  return result;
}

function collectBaseOrder(formValues: any) {
  const {
    selectedSide,
    orderType, 
    conditionalOrderType, 
    orderPrice, 
    triggerPrice, 
    triggerPriceType, 
    units, 
    total,
  } = formValues;

  const baseOrder: any = {
    side: toUpperCase(selectedSide),
    type: orderType,
  };

  switch (orderType) {
  case 'limit':
    baseOrder.limitPrice = orderPrice;
    baseOrder.unitsBase = units;
    baseOrder.total = total;
    break;
  case 'market':
    baseOrder.unitsBase = units;
    baseOrder.total = total;
    break;
  case 'conditional':
    if (conditionalOrderType === 'limit') {
      baseOrder.type = 'cond.limit';
      baseOrder.limitPrice = orderPrice;
      baseOrder.triggerPrice = triggerPrice;
      baseOrder.typeTriggerPrice = triggerPriceType;
      baseOrder.unitsBase = units;
      baseOrder.total = total;
    } else if (conditionalOrderType === 'market') {
      baseOrder.type = 'cond.market';
      baseOrder.triggerPrice = triggerPrice;
      baseOrder.typeTriggerPrice = triggerPriceType;
      baseOrder.unitsBase = units;
      baseOrder.total = total;
    }
    break;
  }

  return baseOrder;
}

function collectAdditionalBaseOrders(formValues: any) {
  const {
    additionalEntries, 
    additionalOrderType, 
    additionalOrderPrice, 
    additionalTriggerPrice, 
    additionalTriggerPriceType, 
    additionalUnits,
    additionalTotal,
    selectedSide,
  } = formValues;

  if (additionalEntries.length === 0) {
    return [{
      side: toUpperCase(selectedSide),
      type: additionalOrderType,
      ...collectAdditionalOrderDetails(
        {
          orderType: additionalOrderType, 
          orderPrice: additionalOrderPrice, 
          triggerPrice: additionalTriggerPrice, 
          triggerPriceType: additionalTriggerPriceType, 
          units: additionalUnits,
          total: additionalTotal,
        },
      ),
    }];
  } else {
    return additionalEntries.map((entry: any) => ({
      side: toUpperCase(selectedSide),
      type: entry.additionalOrderType,
      ...collectAdditionalOrderDetails(
        {
          orderType: entry.additionalOrderType,
          orderPrice: entry.orderPrice, 
          triggerPrice: entry.triggerPrice, 
          triggerPriceType: entry.typeTriggerPrice, 
          units: entry.units, 
          total: entry.total,
        },
      ),
    }));
  }
}

function collectAdditionalOrderDetails({
  orderType,
  orderPrice,
  triggerPrice,
  triggerPriceType,
  units,
  total,
}: {
  orderType: string;
  orderPrice: number;
  triggerPrice: number;
  triggerPriceType: string;
  units: number;
  total: number;
}) {
  const baseDetails = {
    unitsBase: units,
    total,
  };
  
  switch (orderType) {
  case 'limit':
    return {
      ...baseDetails,
      limitPrice: orderPrice,
    };
  case 'cond.limit':
    return {
      ...baseDetails,
      limitPrice: orderPrice,
      triggerPrice: triggerPrice,
      typeTriggerPrice: triggerPriceType,
    };
  case 'cond.market':
    return {
      ...baseDetails,
      limitPrice: triggerPrice,
      triggerPrice: triggerPrice,
      typeTriggerPrice: triggerPriceType,
    };
  default:
    return baseDetails;
  }
}

function collectTakeProfits(formValues: any) {
  const {
    selectedSide,
    takeProfitEntries, 
    takeProfitOrderType, 
    takeProfitOrderPrice, 
    takeProfitOrderPricePercent, 
    takeProfitTriggerPrice, 
    takeProfitTriggerPriceType, 
    takeProfitPriceRecalculation,
  } = formValues;

  if (takeProfitEntries.length === 0) {
    return [{
      side: getOppositeSide(selectedSide),
      type: takeProfitOrderType,
      ...collectTakeProfitDetails(
        takeProfitOrderType,
        takeProfitOrderPrice,
        takeProfitTriggerPrice,
        takeProfitTriggerPriceType,
        100,
        takeProfitOrderPricePercent, 
        takeProfitPriceRecalculation,
      ),
    }];
  } else {
    return takeProfitEntries.map((entry: any) => ({
      side: getOppositeSide(selectedSide),
      type: entry.takeProfitOrderType,
      ...collectTakeProfitDetails(
        entry.takeProfitOrderType, 
        entry.price,
        entry.price, 
        entry.typeTriggerPrice, 
        entry.volume, 
        entry.orderPricePercent, 
        takeProfitPriceRecalculation,
      ),
    }));
  }
}

function collectTakeProfitDetails(orderType: string, orderPrice: number, triggerPrice: number, triggerPriceType: string, volume: number, orderPricePercent: number, priceRecalculation: string) {
  const baseDetails = {
    priceRecalculation: priceRecalculation,
    percent: volume,
    orderPricePercent: orderPricePercent,
  };

  switch (orderType) {
  case 'limit':
    return {
      ...baseDetails,
      limitPrice: orderPrice,
    };
  case 'cond.market':
    return {
      ...baseDetails,
      triggerPrice,
      typeTriggerPrice: triggerPriceType,
    };
  default:
    return baseDetails;
  }
}

function collectStopLoss(formValues: any) {
  const {
    selectedSide,
    stopLossOrderType, 
    stopLossTriggerPrice,
    stopLossTriggerPricePercent,
    stopLossTriggerPriceType, 
    stopLossOrderPrice,
    stopLossOrderPricePercent,
    stopLossPriceRecalculation,
  } = formValues;

  const stopLoss: any = {
    side: getOppositeSide(selectedSide),
    type: stopLossOrderType,
    typeTriggerPrice: stopLossTriggerPriceType,
    priceRecalculation: stopLossPriceRecalculation,
    triggerPrice: stopLossTriggerPrice,
    triggerPricePercent: stopLossTriggerPricePercent,
  };

  if (stopLossOrderType === 'cond.limit') {
    stopLoss.limitPrice = stopLossOrderPrice;
    stopLoss.orderPricePercent = stopLossOrderPricePercent;
  }

  return stopLoss;
}

export const formatDataForConfirmation = (data: any) => {
  const result = [];

  // Base order
  result.push({
    title: 'Base order',
    columns: ['Side', 'Price', 'Units', 'Total', 'Type'],
    items: [[
      {
        value: capitalizeFirstLetter(data.baseOrder.side), 
      },
      {
        value: data.baseOrder.limitPrice || data.baseOrder.triggerPrice || data.baseOrder.boughtPrice, 
      },
      {
        value: `${data.baseOrder.unitsBase} ${data.baseSymbol}`, 
      },
      {
        value: `${(data.baseOrder.total)} ${data.quoteSymbol}`, 
      },
      {
        value: capitalizeFirstLetter(
          data.baseOrder.type === 'SKIP_FIRST_STEP' 
            ? 'Skip first step'
            : data.baseOrder.type,
        ), 
      },
    ]],
  });

  // Additional base orders
  if (data.additionalBaseOrders && data.additionalBaseOrders.length > 0) {
    result.push({
      title: 'Additional base orders',
      columns: ['Side', 'Price', 'Units', 'Total', 'Type'],
      items: data.additionalBaseOrders.map((order: any) => [
        {
          value: capitalizeFirstLetter(data.baseOrder.side), 
        },
        {
          value: `${order.limitPrice || order.triggerPrice} ${data.quoteSymbol}`, 
        },
        {
          value: `${order.unitsBase} ${data.baseSymbol}`, 
        },
        {
          value: `${order.total} ${data.quoteSymbol}`, 
        },
        {
          value: capitalizeFirstLetter(order.type), 
        },
      ]),
    });
  }

  // Take profit targets
  if (data.takeProfits && data.takeProfits.length > 0) {
    result.push({
      title: 'Take profit targets',
      columns: ['Volume', 'Price', 'Percent', 'Calculation', 'Type'],
      items: data.takeProfits.map((tp: any) => [
        {
          value: `${tp.percent}%`, 
        },
        {
          value: `${tp.limitPrice || tp.triggerPrice} ${data.quoteSymbol}`, 
        },
        {
          value: `${tp.orderPricePercent}%`,
          type: parseFloat(tp.orderPricePercent) > 0 ? 'success' : 'danger',
        },
        {
          value: capitalizeFirstLetter(tp.priceRecalculation), 
        },
        {
          value: capitalizeFirstLetter(tp.type), 
        },
      ]),
    });
  }

  // Stop loss
  if (data.stopLoss) {
    result.push({
      title: 'Stop loss',
      columns: ['Percent', 'Price', 'Calculation', 'Type'],
      items: [[
        {
          value: `${data.stopLoss.type === 'cond.limit' ? data.stopLoss.orderPricePercent : data.stopLoss.triggerPricePercent}%`,
          type: parseFloat(data.stopLoss.type === 'cond.limit' ? data.stopLoss.orderPricePercent : data.stopLoss.triggerPricePercent) > 0 ? 'success' : 'danger',
        },
        {
          value: `${data.stopLoss.type === 'cond.limit' ? data.stopLoss.limitPrice : data.stopLoss.triggerPrice} ${data.quoteSymbol}`,
        },
        {
          value: capitalizeFirstLetter(data.stopLoss.priceRecalculation), 
        },
        {
          value: capitalizeFirstLetter(data.stopLoss.type), 
        },
      ]],
    });
  }

  return result;
};

