export const getWinRatePercent = (trades: { returnPercent: number | null; }[]): number => {
  if (trades.length === 0) {
    return 0;
  }
  
  const winCount = trades.filter(trade => parseFloat(String(trade.returnPercent || '0')) >= 0).length;
  const winRatePercent = (winCount / trades.length) * 100;
  
  return parseFloat(winRatePercent.toFixed(2));
};
