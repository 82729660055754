import { Stack } from '@mui/material';
import { Segmented } from 'antd';
import { CustomSlider } from 'pages/trading-bots/configurator-v2/components';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Label } from 'widgets';

export const Futures = () => {
  const {
    watch,
    setValue,
  } = useFormContext();

  const exchange = watch('exchange').value;
  const bot = watch('bot');
  const futures = watch('futures');

  const changeStrategy = (strategy: string) => {
    futures.strategy = strategy;
    setValue('futures', futures);
  };

  const changeMarginMode = (marginMode: string) => {
    futures.marginMode = marginMode.toLowerCase();
    setValue('futures', futures);
  };

  const changeLeverage = (leverage: number) => {
    if (leverage > 125) {
      return;
    }
    
    futures.leverage = leverage;
    setValue('futures', futures);
  };

  useEffect(() => {
    if (!exchange.id || !bot) {
      return;
    }

    futures.marginMode = String(exchange.marginMode).toLowerCase();

    futures.leverage = bot?.leverage || 5;
    futures.strategy = String(bot.strategy || 'short').toLowerCase();
    setValue('futures', futures);
  }, [exchange, bot]);

  return (
    <Stack gap='24px'>
      <Stack gap='8px'>
        <Label
          title='Strategy'
          tooltip=''
        />

        <Segmented
          value={futures.strategy}
          onChange={changeStrategy}
          options={[{
            label: 'Short',
            value: 'short',
          }, {
            label: 'Long',
            value: 'long',
          }]}
          size='large'
          block={true}
        />
      </Stack>

      <Stack gap='8px'>
        <Label
          title='Leverage settings'
          tooltip=''
        />

        <Segmented
          value={String(futures.marginMode).toLowerCase()}
          onChange={changeMarginMode}
          options={[{
            label: 'Cross',
            value: 'cross',
          }, {
            label: 'Isolated',
            value: 'isolated',
          }]}
          size='large'
          block={true}
          disabled={exchange.marginMode}
        />
      </Stack>

      <CustomSlider
        title=''
        slider={{
          value: futures.leverage,
          max: 25,
          marks: {
            1: '1x',
            5: '5x',
            25: '25x',
          },
          onChange: changeLeverage,
        }}
        input={{
          value: futures.leverage,
          placeholder: '100x',
          onChange: changeLeverage,
        }}
      />
    </Stack>
  );
};
