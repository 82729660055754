import { Box } from '@mui/material';
import { TableColumnsType } from 'antd';
import { SortIcon, Text } from 'shared/ui';
import { IToken } from '../interfaces';

export const columns: TableColumnsType = [
  {
    title: 'Token',
    dataIndex: 'token',
    key: 'token',
    sorter: (a, b) => a - b,
    width: 190,
    render: (value: IToken) => (
      <Box
        display='flex'
        alignItems='center'
        gap={0.5}
      >
        <Text>{value?.platform}</Text>
        <Text type='secondary'>{value?.value}</Text>
      </Box>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  },
  {
    title: 'Share',
    dataIndex: 'share',
    key: 'share',
    sorter: (a, b) => a - b,
    width: 190,
    render: (value: string) => (
      <Text>{value}</Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  },
  {
    title: 'Change 24h',
    dataIndex: 'change',
    key: 'change',
    sorter: (a, b) => a - b,
    width: 190,
    render: (value: string) => (
      <Text type='success'>{value}</Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    width: 190,
    render: (value: string) => (
      <Text>{value}</Text>
    ),
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: 190,
    render: (value: string) => (
      <Text>{value}</Text>
    ),
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total',
    sorter: (a, b) => a - b,
    width: 190,
    render: (value: string) => (
      <Text>{value}</Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  },
];
