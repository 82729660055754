import { SxProps, Theme } from '@mui/material';

export const titleWrapper: SxProps<Theme> = {
  display: 'flex'  ,
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 2,

  '@media (max-width: 398px)': {
    flexDirection: 'column',
    
    '&>button': {
      width: '100%',
    },
  },
};
