import { TSide } from 'shared/types';

interface ITypeOptions {
  label: string;
  value: TSide;
}
export const getTypeOptions = (_?: any) => {
  const options: ITypeOptions[] = [{
    label: 'Long',
    value: 'long',
  }, {
    label: 'Short',
    value: 'short',
  }];

  return options;
};
