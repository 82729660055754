import { TLanguage, TTranslation } from 'shared/types';

export const successChangePassword = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      changePassword: {
        title: 'Password is updated',
        message: 'Now you can login with new password',
        button: 'Okay',
      },
    },
    ru: {
      changePassword: {
        title: 'Пароль обновлён',
        message: 'Теперь можно войти с новым паролем',
        button: 'Ок',
      },
    },
  };

  return transaltion[type].changePassword;
};
