import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const TEXT: CSSProperties = {
  fontSize: getFontSize(12, 14),
  fontWeight: 400,
  lineHeight: '24px',
  color: '#2B1F3B',
  textAlign: 'center',
};
