import { Box, Stack } from '@mui/material';
import { TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { getTextType } from 'pages/my-accounts/connected-exchange-account-single/helpers/get-text-type';
import { statuses } from 'shared/consts';
import { dateSorter, numberSorter, pairSorter, stringSorter } from 'shared/helpers/sorters';
import { History } from 'shared/icons';
import { Icon, SortIcon, Text } from 'shared/ui';

export const columns = (t: any, openModal: (tradeUuid: string) => () => void): TableColumnsType => {
  return [{
    title: t('bots.diary.table.columns.pair'),
    dataIndex: 'pair',
    key: 'pair',
    sorter: (a, b) => pairSorter({
      aFirstPair: a.pair.firstPair,
      aSecondPair: a.pair.secondPair,
      bFirstPair: b.pair.firstPair,
      bSecondPair: b.pair.secondPair,
    }),
    width: 85,
    render: (value: any) => (
      <Box
        display='flex'
        alignItems='center'
        gap={0.5}
      >
        <Text>
          {value?.firstPair}
        </Text>

        <Text type='secondary'>
          {value?.secondPair}
        </Text>
      </Box>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('bots.diary.table.columns.bot'),
    dataIndex: '',
    key: 'bot',
    sorter: (a, b) => stringSorter(a.name, b.name),
    width: 115,
    render: (value: any) => (
      <Text
        styles={{
          cursor: 'pointer', 
          display: 'block',
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {value.name}
      </Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('bots.diary.table.columns.date.create'),
    dataIndex: 'createDate',
    key: 'create-adate',
    sorter: (a, b) => dateSorter(a.createDate, b.createDate),
    width: 150,
    render: (date: string) => (
      <Text type='secondary'>
        {date ? dayjs(date).format('DD.MM.YYYY, HH:mm:ss') : '-'}
      </Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
    defaultSortOrder: 'descend',
  }, {
    title: t('bots.diary.table.columns.date.close'),
    dataIndex: 'closeDate',
    key: 'close-adate',
    sorter: (a, b) => dateSorter(a.closeDate, b.closeDate),
    width: 150,
    render: (date: string) => (
      <Text type='secondary'>
        {date ? dayjs(date).format('DD.MM.YYYY, HH:mm:ss') : '-'}
      </Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('bots.diary.table.columns.status'),
    dataIndex: 'status',
    key: 'status',
    sorter: (a, b) => stringSorter(a.status, b.status),
    width: 100,
    render: (value: string) => {
      const status = statuses[value];
  
      return (
        <Text type={status?.color || undefined}>
          {status?.text || ''}
        </Text>
      );
    },
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('bots.diary.table.columns.total'),
    dataIndex: 'total',
    key: 'total',
    sorter: (a, b) => numberSorter(a.total, b.total),
    width: 80,
    render: (total: string) => (
      <Text>
        {total}
      </Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('bots.diary.table.columns.price.entry'),
    dataIndex: 'entryPrice',
    key: 'entry-price',
    width: 100,
    render: (price: string) => (
      <Text>
        {price}
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: t('bots.diary.table.columns.entries'),
    dataIndex: 'entries',
    key: 'entries',
    width: 60,
    render: (entries: any) => {
      return (
        <Text>
          {entries}
        </Text>
      );
    },
    showSorterTooltip: false,
  }, {
    title: t('bots.diary.table.columns.price.close'),
    dataIndex: 'closePrice',
    key: 'close-price',
    width: 120,
    render: (price: string) => (
      <Text>
        {price}
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: t('bots.diary.table.columns.return'),
    dataIndex: 'return',
    key: 'return',
    sorter: (a, b) => numberSorter(a.return_pr, b.return_pr),
    width: 145,
    render: (value: any, record) => {
      const textType = getTextType(value);
  
      return (
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '4px',
            '&>span': {
              fontSize: 12,
            },
          }}
        >
          <Text type={textType}>
            {value} ({record?.return_pr || 0}%)
          </Text>

          <Icon
            width={16}
            action={openModal(record.smartTradeUuid)}
          >
            {History}
          </Icon>
        </Stack>
      );
    },
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }];
};
