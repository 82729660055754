import { Box } from '@mui/material';
import { Modal } from 'antd';
import { userActions } from 'entities/user/model/slices/user-slice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { LabelInput, MainButton, Title } from 'shared/ui';
import { disableTwoFactorRequest } from '../../two-factor/api/disable-two-factor';
import { button, buttons, modal, modalChildren, title, wrapper } from '../styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleSetTwoFactorDisabled?: () => void;
  handleSetTwoFactorDisableFailedOpen?: () => void;
  handleSetTwoFactorResetModalOpen: () => void;
}

export const TwoFactorDisable = (props: Props) => {
  const {
    isOpen,
    onClose,
    handleSetTwoFactorResetModalOpen,
  } = props;

  const [otp, setOtp] = useState<string>('');

  const {
    t, 
  } = useTranslation();
  const dispatch = useDispatch();
  
  const handleSetOtp = (value: string) => {
    setOtp(value);    
  };
  
  const handleReset = () => {
    handleSetTwoFactorResetModalOpen();
  };

  const handleDisableTwoFactor = async () => {
    try {
      const responseFromDisableTwoFactor = await disableTwoFactorRequest(otp);
      
      if (!responseFromDisableTwoFactor.success) {
        if (responseFromDisableTwoFactor.data.message === '2FA is already disabled') {
          onClose();
          setOtp('');
          return;
        }
        
        onClose();
        setOtp('');
        toast.error(t('error.twoFactor.disable.message') as string);
        return;
      }
      
      onClose();
      toast.success(t('success.twoFactor.disable') as string);
      setOtp('');
      dispatch(userActions.setIs2faEnabled(false));
    } catch (error) {
      setOtp('');
      toast.error(t('error.twoFactor.disable.message') as string);
    }
  };
  
  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={onClose}
      onOk={onClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Title styles={title}>
          {t('modal.twoFactorDisable.title')}
        </Title>

        <LabelInput
          label={t('modal.twoFactorDisable.otp.title')}
          value={otp}
          placeholder={t('modal.twoFactorDisable.otp.placeholder')}
          onChange={handleSetOtp}
        />

        <Box sx={buttons}>
          <MainButton
            type='primary'
            ghost={true}
            styles={button}
            onClick={handleReset}
          >
            {t('modal.twoFactorDisable.button.reset')}
          </MainButton>
          
          <MainButton
            type='primary'
            styles={button}
            onClick={handleDisableTwoFactor}
          >
            {t('modal.twoFactorDisable.button.ok')}
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};
