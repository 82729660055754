import { TLanguage, TTranslation } from 'shared/types';

export const successWithdraw = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      withdraw: {
        success: 'Payout created',
      },
    },
    ru: {
      withdraw: {
        success: 'Вывод создан',
      },
    },
  };

  return transaltion[type].withdraw;
};
