import { Bot } from 'entities/bot/model/types';
import { combineBots } from 'pages/trading-bots/marketplace/helpers/combine-bots';
import { useCallback, useEffect, useState } from 'react';
import { exportToCsv } from 'shared/helpers';
import { getUserEmail } from 'shared/helpers/storage-helper';
import { TDates } from 'shared/types';
import { getSubscriptions, getTotalSales, getUserPublicBots, getWithDrawals } from '../api';
import { IProviderPayout } from '../interfaces';

export const useSales = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSalesLoading, setIsSalesLoadingLoading] = useState<boolean>(true);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState<boolean>(true);
  const [isBotsLoading, setIsBotsLoading] = useState<boolean>(true);

  const [bots, setBots] = useState<Bot[]>([]);
  const [combinedBots, setCombinedBots] = useState<Record<string, Bot[]> | null>(null);
  const [selectedCombinedBots, setSelectedCombinedBots] = useState<Bot[]>([]);

  const [withDrawalDates, setWithDrawalDates] = useState<TDates>([null, null]);
  const [dates, setDates] = useState<TDates>([null, null]);

  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [subscription, setSubscription] = useState<any>(null);

  const [providerPayouts, setProviderPayouts] = useState<IProviderPayout[]>([]);
  const [sales, setSales] = useState<any>({
    total: 0,
    withDrawal: 0,
  });

  const [market, setMarket] = useState<string>('');

  const [botUuids, setBotUuids] = useState<string[]>([]);
  const [botItemName, setBotItemName] = useState<string>('');

  const [isEmptyBotsModalOpened, setIsEmptyBotsModalOpened] = useState<boolean>(false);
  const [chooseMarketModal, setChooseMarketModal] = useState<boolean>(false);

  const changeSelectedCombineBots = (bots: Bot[]) => {
    setSelectedCombinedBots(bots);
    setMarket(bots[0].quote);
  };

  const onSelectChange = async (value: any) => {
    const requireSubscription = subscriptions.find((subscription) => {
      return subscription.bot === value;
    });

    setSubscription(requireSubscription);
  };

  const changeMarket = (value: string) => {
    setMarket(value);
    setSelectedCombinedBots([]);
  };

  const changeBotName = (value: string) => {
    setBotItemName(value);
  };

  const changeBotUuids = (value: string[]) => {
    setBotUuids(value);
  };

  const exportHandler = () => {
    exportToCsv(providerPayouts, `${getUserEmail()}-sales`);
  };
  
  const changeDates = (dates: TDates) => {
    setDates(dates);
  };

  const changeWithDrawDates = (dates: TDates) => {
    setWithDrawalDates(dates);
  };

  const chooseMarketModalHandler = () => {
    if (!bots.length) {
      setIsEmptyBotsModalOpened(true);
      return;
    }

    setChooseMarketModal((prev) => !prev);
  };

  const subscriptionModalHandler = () => {
    setIsEmptyBotsModalOpened((prev) => !prev);
  };

  const subscriptionOptions = useCallback(() => {
    if (!subscriptions.length) {
      return [];
    }

    const options = subscriptions.map((subscription: any) => {
      return {
        label: subscription.bot,
        value: subscription.bot,
      };
    });
  
    return options;
  }, [subscriptions]);

  const resultBots = useCallback(() => {
    if (!bots.length) {
      return [];
    }
    
    const selectedBot = selectedCombinedBots[0];
    const filteredBots = bots.filter((bot) => {
      const marketCondition = bot.quote === (market || selectedBot?.quote);
      const sharingCondition = (bot.allowSharing === 'FALSE' || (bot.allowSharing === 'TRUE' && bot.itemName === (selectedBot?.itemName || selectedBot?.name)));

      return marketCondition && selectedCombinedBots.length ? sharingCondition : bot.allowSharing === 'FALSE';
    });

    return filteredBots;
  }, [market, bots, selectedCombinedBots.length]);

  const getTotalSalesHandler = async () => {
    try {
      setIsSalesLoadingLoading(true);

      const response = await getTotalSales(withDrawalDates);
      if (!response.success) {
        return;
      }
      
      setSales({
        total: response.data.totalSales,
        withDrawal: response.data.availableForWithdrawal,
      });
    } finally {
      setIsSalesLoadingLoading(false);
    }
  };
  
  const getDrawalsHandler = async () => {
    try {
      setIsLoading(true);
      const response = await getWithDrawals(dates);
      if (!response.success)  {
        return;
      }
    
      setProviderPayouts(response.data?.providerPayouts || []);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getSubscriptionsHandler = async () => {
    try {
      setIsSubscriptionLoading(true);

      const response = await getSubscriptions(withDrawalDates);
      const subscriptions = response?.data?.stats;
      if (!subscriptions?.length)  {
        setIsSubscriptionLoading(false);
        return;
      }

      setSubscriptions(subscriptions);
      setSubscription(subscriptions[0]);
    } finally {
      setIsSubscriptionLoading(false);
    }
  };

  const fetchBots = async () => {
    try {
      setIsBotsLoading(true);
  
      const responseFromAllBots = await getUserPublicBots();
      const allowSharingBots = responseFromAllBots.filter((bot) => {
        return bot.allowSharing === 'TRUE';
      });
      const combinedBots = combineBots(allowSharingBots);
      setBots(responseFromAllBots);
      setCombinedBots(combinedBots);
    } catch (error) {
      setIsBotsLoading(false);
    } finally {
      setIsBotsLoading(false);
    }
  };

  const fetchDrawalDatesData = async () => {
    try {
      setIsSalesLoadingLoading(true);
      setIsSubscriptionLoading(true);

      getTotalSalesHandler();
      getSubscriptionsHandler();      
    } catch (error) {
      console.log('error', error);
      setIsSalesLoadingLoading(false);
      setIsSubscriptionLoading(false);
    }
  };

  useEffect(() => {
    fetchBots();
  }, []);

  useEffect(() => {
    fetchDrawalDatesData();
  }, [withDrawalDates]);

  useEffect(() => {
    getDrawalsHandler();
  }, [dates]);

  return {
    values: {
      isLoading,
      isSalesLoading,
      isSubscriptionLoading,
      isBotsLoading,
      bots,
      combinedBots,
      withDrawalDates,
      dates,
      subscription,
      subscriptions,
      subscriptionOptions,
      sales,
      providerPayouts,
      isEmptyBotsModalOpened,
      chooseMarketModal,
      market,
      botItemName,
      botUuids,
      resultBots,
      selectedCombinedBots,
    },
    handlers: {
      setIsLoading,
      onSelectChange,
      changeDates,
      changeWithDrawDates,
      exportHandler,
      chooseMarketModalHandler,
      setChooseMarketModal,
      changeMarket,
      changeBotUuids,
      changeBotName,
      fetchBots,
      subscriptionModalHandler,
      getDrawalsHandler,
      changeSelectedCombineBots,
    },
  };
};

