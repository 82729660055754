import { Stack } from '@mui/material';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import { createReferralLink } from 'entities/user/api';
import html2canvas from 'html2canvas';
import { telegramLink, xcomLink } from 'pages/invite-friends/consts';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReferralLink } from 'widgets';
import { socials } from 'widgets/header/consts';
import { formatter } from 'shared/helpers';
import { getReferralCode } from 'shared/helpers/storage-helper';
import { DOWNLOAD_SECONDARY, Telegram, Twitter } from 'shared/icons';
import { Text, Title } from 'shared/ui';
import { QrCode, Social, Value } from '../components';
import { getEntryPrice, getShareLink } from '../helpers';
import { getTelegramShare } from '../helpers/get-telegram-share';
import { getXShare } from '../helpers/get-x-share';
import { IShareTradeProps } from '../interfaces';
import { BLOCK, MODAL, MODAL_CHILDREN, SOCIALS, TITLE, WRAPPER } from '../styles';
import { Download } from '../views';

export const ShareTrade = (props: IShareTradeProps) => {
  const {
    isOpen,
    trade,
    closeHandler,
  } = props;

  const ref = useRef<HTMLDivElement | null>(null);
  const [referralCode, setReferralCode] = useState<string>(getReferralCode() || '');

  const {
    t, 
  } = useTranslation();

  const downloadHandler = async () => {
    if (!ref.current) {
      return;
    }

    const canvas = await html2canvas(ref.current);
    const image = canvas.toDataURL('image/png');

    const link = document.createElement('a');
    link.href = image;
    link.download = `${pair}_${entry.date}_${current.date}.png`;
    link.click();
  };

  const createReferralLinkHandler = (referralCode: string) => {
    return async () => {
      createReferralLink({
        referralCode,
        onResult: (result) => {
          setReferralCode(result.referralCode);
        },
      });
    }; 
  };

  const pair = `${trade?.baseSymbol}${trade?.quoteSymbol}`;
  const roi = Number(trade?.returnPercent).toFixed(2);

  const entry = useMemo(() => {
    return {
      value: formatter((getEntryPrice(trade) || 0)),
      date: dayjs(trade?.createdDate || '').format('MMMM DD, YYYY'),
    };
  }, [trade]);

  const current = useMemo(() => {
    return {
      value: formatter((trade?.currentExchangePrice || (+entry?.value * (1 + (+roi / 100))))),
      date: dayjs(trade?.finishDate || dayjs()).format('MMMM DD, YYYY'),
    };
  }, [trade, entry, roi]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={MODAL}
      styles={MODAL_CHILDREN}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
      title={(
        <Title styles={TITLE}>
          {t('modal.shareResult.title')}
        </Title>
      )}
    >
      <Stack gap='24px'>
        <Stack sx={WRAPPER}>
          <Stack sx={BLOCK}>
            <Value
              title={t('modal.shareResult.pair')}
              value={pair}
              type='pair'
            />

            <Value
              title={t('modal.shareResult.roi')}
              value={roi}
              type='roi'
            />

            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Value
                title={t('modal.shareResult.entry')}
                value={entry.value}
                type='entry'
                date={entry.date}
              />
                
              <Value
                title={t(`modal.shareResult.${trade?.status === 'completed' ? 'close' : 'current'}`)}
                value={current.value}
                type='entry'
                date={current.date}
              />
            </Stack>
          </Stack>

          <Text>
            {t('modal.shareResult.subText')}
          </Text>

          <QrCode
            link={getShareLink(referralCode)}
            referralCode={referralCode}
          />
        </Stack>

        <Download
          currentRef={ref}
          pair={pair}
          roi={roi}
          entry={entry}
          current={current}
          link={getShareLink(referralCode)}
          referralCode={referralCode}
          isCompleted={trade?.status === 'completed'}
        />

        <Stack
          sx={{
            placeItems: 'center',
            flex: 1,
          }}
        >
          <Stack sx={SOCIALS}>
            <Social
              icon={Telegram}
              text='Telegram'
              action={() => {
                window.open(getTelegramShare({
                  roi,
                  pair,
                  referralCode,
                }), '_blank', 'width=600,height=400');
              }}
            />

            <Social
              icon={Twitter}
              text='X'
              action={() => {
                window.open(getXShare({
                  roi,
                  pair,
                  referralCode,
                }), '_blank', 'width=600,height=400');
              }}
            />

            <Social
              icon={DOWNLOAD_SECONDARY}
              text={t('modal.shareResult.button')}
              action={downloadHandler}
            />
          </Stack>
        </Stack>

        <ReferralLink
          referralCode={referralCode}
          socials={[{
            icon: socials[2].icon,
            link: (shareLink) => xcomLink(shareLink),
          }, {
            icon: socials[3].icon,
            link: (shareLink) => telegramLink(shareLink),
          }]}
          onCreate={createReferralLinkHandler}
        />
      </Stack>
    </Modal>
  );
};
