import { CloseOutlined } from '@ant-design/icons';
import { Hidden, Stack } from '@mui/material';
import { Popup } from 'antd-mobile';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IMenuProps } from 'widgets/header/interfaces';
import { useAuth } from 'shared/hooks';
import { Logo } from 'shared/icons';
import { Icon, Text } from 'shared/ui';
import { useGetMenu } from '../hooks';
import { ACTIONS, BODY, CLOSE_ICON, HEADER_WRAPPER, WRAPPER } from '../styles';

export const Vertical = (props: Pick<IMenuProps, 'isPopupOpened' | 'closePopupHandler'>) => {
  const {
    isPopupOpened,
    closePopupHandler,
  } = props;

  const menu = useGetMenu();
  const location = useLocation();

  const {
    handlers,
  } = useAuth();

  const {
    t, 
  } = useTranslation();

  useEffect(() => {
    if (!isPopupOpened) {
      return;
    }
    
    closePopupHandler();
  }, [location.pathname]);

  return (
    <Hidden lgUp={true}>
      <Popup
        visible={isPopupOpened}
        position='left'
        bodyStyle={BODY}
        onMaskClick={closePopupHandler}
        onClose={closePopupHandler}
      >
        <Stack sx={WRAPPER}>
          <Stack sx={HEADER_WRAPPER}>
            <CloseOutlined
              style={CLOSE_ICON}
              onClick={closePopupHandler}
            />

            <Icon width={100}>
              {Logo}
            </Icon>
          </Stack>

          <Stack gap='4px'>
            {menu()}
          </Stack>

          <Stack sx={ACTIONS}>
            <Text onClick={handlers.logOutHandler}>
              {t('header.popup.logOut')}
            </Text>
          </Stack>
        </Stack>
      </Popup>
    </Hidden>
  );
};
