import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { combineBots } from '../helpers/combine-bots';
import { Bot } from '../types/bots.types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});
  
export const enrichBotsWithStatistic = async (bots: Bot[]): Promise<Bot[]> => {
  const combinedBots = combineBots(bots);

  const activeCopyBotsRequests = Object.entries<Bot[]>(combinedBots).map(([_, bots]) => {
    return bots.map(bot => 
      axios.request({
        url: '/bot/calculateActiveCopyBotsLandingMarketplace',
        data: {
          sourceUuid: bot.sourceUuid, 
        },
      }),
    );
  });

  const activeCopyBotsResponses = await Promise.all(activeCopyBotsRequests.map((request) => Promise.all(request)));

  const calculatedBots: Bot[] = [];
  const botPromises = Object.entries<Bot[]>(combinedBots).map(async ([_, bots], index) => {
    const responses = activeCopyBotsResponses[index];

    const calculatedBotsCopy = responses.map((response) => {
      const sourceUuid = response.data.data.sourceUuid;
      const activeCopyBots = response.data.data.activeCopyBots;

      const bot = bots.find((currentBot) => currentBot.sourceUuid === sourceUuid)!;
      bot.activeCopyBots = activeCopyBots;
      return bot;
    });

    const maxActiveCopyBotsBot = calculatedBotsCopy.reduce((max, current) => 
      (current.activeCopyBots > max.activeCopyBots) ? current : max,
    );

    const response = await axios.request({
      url: '/bot/calculateStatisticLandingMarketplace',
      data: {
        botUuid: maxActiveCopyBotsBot.botUuid,
      },
    });

    maxActiveCopyBotsBot.statistics = response.data.data;
    
    return calculatedBotsCopy;
  });

  const results = await Promise.all(botPromises);
  calculatedBots.push(...results.flat());

  return calculatedBots;
};

