import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';

export const getUniqueMarketOptions = (botsData: Bot[]): { label: string; value: string; }[] => {
  const uniqueMarkets = Array.from(new Set(botsData.map(trade => trade.quote.toLowerCase())));
  const marketOptions = uniqueMarkets.map(symbol => ({
    label: symbol.toUpperCase(),
    value: symbol,
  }));
  
  return marketOptions;
};
