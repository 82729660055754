import * as localization from 'shared/localization';

export const ruLocalization = {
  translation: {
    auth: localization.auth('ru'),
    copyRight: 'All rights reserved',
    banner: localization.banner('ru'),
    pages: localization.pages('ru'),
    error: {
      login: localization.errorLogin('ru'),
      register: localization.errorRegister('ru'),
      twoFactor: localization.errorTwoFactor('ru'),
      wrong: localization.errorWrong('ru'),
      otp: localization.errorOtp('ru'),
      exchange: localization.errorExchange('ru'),
      bots: localization.errorBots('ru'),
      copyBot: localization.errorCopuBot('ru'),
      subscription: localization.errorSubscription('ru'),
      payment: localization.errorPayment('ru'),
      changePassword: localization.errorChangePassword('ru'),
      withdraw: localization.errorWithdraw('ru'),
      funds: localization.errorFunds('ru'),
      bot: localization.errorBot('ru'),
    },
    success: {
      login: localization.successLogin('ru'),
      register: localization.successRegister('ru'),
      recover: localization.successRecover('ru'),
      changePassword: localization.successChangePassword('ru'),
      exchange: localization.successExchange('ru'),
      payment: localization.successPayment('ru'),
      twoFactor: localization.successTwoFactor('ru'),
      withdraw: localization.successWithdraw('ru'),
      funds: localization.successFunds('ru'),
    },
    validation: {
      email: localization.validationEmail('ru'),
      password: localization.validationPassword('ru'),
      otp: localization.validationOtp('ru'),
      referralCode: localization.validationRederralCode('ru'),
      withdraw: localization.validationWithdraw('ru'),
      changePassword: localization.validationChangePassword('ru'),
      funds: localization.validationFunds('ru'),
      bot: localization.validationBot('ru'),
      configurator: localization.validationConfigurator('ru'),
      terminal: localization.validationTerminal('ru'),
    },
    modal: {
      exchange: localization.modalExchange('ru'),
      terminal: localization.modalTerminal('ru'),
      bots: localization.modalBots('ru'),
      copyBot: localization.modalCopyBot('ru'),
      trial: localization.modalTrial('ru'),
      subscription: localization.modalSubscription('ru'),
      payment: localization.modalPayment('ru'),
      plans: localization.modalPlans('ru'),
      withdraw: localization.modalWithdraw('ru'),
      twoFactor: localization.modalTwoFactor('ru'),
      twoFactorDisable: localization.modalTwoFactorDisable('ru'),
      changePassword: localization.modalChangePassword('ru'),
      onboarding: localization.modalOnboarding('ru'),
      addBot: localization.modalAddBot('ru'),
      addFunds: localization.modalAddFunds('ru'),
      shareResult: localization.modalShareResult('ru'),
      bot: localization.modalBot('ru'),
      alertSource: localization.modalAlertSource('ru'),
      confirmTransaction: localization.modalConfirmTransaction('ru'),
      confirmCreateBot: localization.modalConfirmCreateBot('ru'),
    },
    tradingStatistics: localization.tradingStatistics('ru'),
    aggregateBalance: localization.aggregateBalance('ru'),
    withdrawal: localization.withdraw('ru'),
    form: {
      login: localization.formLogin('ru'),
      register: localization.formRegister('ru'),
      recover: localization.formRecover('ru'),
      change: localization.formChange('ru'),
      twoFactor: localization.formTwoFactor('ru'),
      exchange: localization.formExchange('ru'),
      payment: localization.formPayment('ru'),
      configurator: localization.formConfigurator('ru'),
      date: localization.formDate('ru'),
      funds: localization.formFunds('ru'),
      bot: localization.formBot('ru'),
    },
    header: localization.header('ru'),
    home: localization.home('ru'),
    exchange: localization.exchange('ru'),
    terminal: localization.terminal('ru'),
    bots: localization.bots('ru'),
    bot: localization.bot('ru'),
    marketplace: localization.marketplace('ru'),
    subscriptions: localization.subscriptions('ru'),
    invite: localization.inviteFriends('ru'),
    configurator: localization.configurator('ru'),
    unknown: localization.unknown('ru'),
    emptyChart: localization.emptyChartData('ru'),
  },
};
