import { CSSProperties } from 'react';

export const EXPORT_BUTTON: CSSProperties = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
