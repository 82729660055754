import { TLanguage, TTranslation } from 'shared/types';

export const validationConfigurator = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      configurator: {
        orderSize: 'Order size is less than minimal',
        'Base order is less than minimum order amount': 'Base order is less than minimum order amount.',
        'Some additional orders are too small': 'Some additional orders are too small.',
        additional: 'Some of additional orders are less than minimum order amount',
        takeProfit: 'Some of take profit orders are less than minimum order amount',
        stopLoss: 'Stop loss order is less than minimum order amount',
      },
    },
    ru: {
      configurator: {
        'orderSize': 'Размер ордера меньше минимального',
        'Base order is less than minimum order amount': 'Размер базового ордера меньше минимального',
        'Some additional orders are too small': 'Размер некоторых ордеров меньше минимального',
        'additional': 'Размер некоторых ордеров меньше минимального',
        'takeProfit': 'Размер тейк профи ордеров меньше минимального',
        'stopLoss': 'Размер стоп лосс ордера меньше минимального',
      },
    },
  };

  return transaltion[type].configurator;
};
