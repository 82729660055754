import { TLanguage, TTranslation } from 'shared/types';
import { remote } from './remote';

export const bot = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      bot: {
        status: {
          on: 'On',
          off: 'Off',
          deleted: 'Deleted',
        },
        button: 'Add new bot',
        remote: remote('en'),
        chart: {
          segments: {
            sum: 'Sum',
            day: 'Day',
            pair: 'Pair',
          },
        },
        table: {
          title: 'Trading history',
          search: {
            placeholder: 'Search by pair',
          },
          columns: {
            pair: 'Pair',
            date: {
              create: 'Create date',
              close: 'Close date',
            },
            status: {
              title: 'Status',
              items: {
                pending: 'Pending',
                cancelled: 'Cancelled',
                active: 'Active',
                completed: 'Completed',
                failed: 'Failed',
              },
            },
            total: 'Total',
            price: {
              entry: 'Entry price',
              close: 'Close price',
            },
            entries: 'Entries',
            return: 'Return',
          },
        },
      },
    },
    ru: {
      'bot': {
        'status': {
          'on': 'Вкл.',
          'off': 'Выкл.',
          deleted: 'Удален',
        },
        'button': 'Добавить нового бота',
        remote: remote('ru'),
        'chart': {
          'segments': {
            'sum': 'Сумма',
            'day': 'День',
            'pair': 'Пара',
          },
        },
        'table': {
          'title': 'История торговли',
          'search': {
            'placeholder': 'Поиск по паре',
          },
          'columns': {
            'pair': 'Пара',
            'date': {
              'create': 'Дата создания',
              'close': 'Дата закрытия',
            },
            'status': {
              'title': 'Статус',
              'items': {
                'pending': 'В ожидании',
                'cancelled': 'Отменено',
                'active': 'Активна',
                'completed': 'Завершена',
                'failed': 'Неудачна',
              },
            },
            'total': 'Итого',
            'price': {
              'entry': 'Цена входа',
              'close': 'Цена выхода',
            },
            'entries': 'Входы',
            'return': 'Доход',
          },
        },
      },
    },
  };

  return transaltion[type].bot;
};
