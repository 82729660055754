import { LoadingOutlined } from '@ant-design/icons';
import { Box } from '@mui/material';
import { Spin } from 'antd';
import { inner } from '../styles';

export const Loader = (props: Partial<Record<'isContentOverflow', boolean>>) => {
  const {
    isContentOverflow,
  } = props;

  return (
    <Box sx={inner(!!isContentOverflow)}>
      <Spin
        indicator={(
          <LoadingOutlined
            style={{
              fontSize: 48,
            }}
            spin
          />
        )}
      />
    </Box>
  );
};
