import { newTerminalActions } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { memo, useEffect, useRef, useState } from 'react';
import './index.css';
import { useDispatch, useStore } from 'react-redux';
import {
  ChartingLibraryWidgetOptions,
  widget,
} from '../charting_library';
import { ResolutionString, Exchange } from '../charting_library/datafeed-api';
import { chartingLibraryPath } from './constants/chart-constants';
import { createCustomDataFeed } from './datafeed/datafeed-factory';

interface TradingViewChartProps {
  exchangeName: string;
  currentSymbol?: any;
}

const arePropsEqual = (
  prevProps: TradingViewChartProps,
  nextProps: TradingViewChartProps,
) => {
  const prevSymbol = `${prevProps.currentSymbol?.baseAsset}${prevProps.currentSymbol?.quoteAsset}`;
  const nextSymbol = `${nextProps.currentSymbol?.baseAsset}${nextProps.currentSymbol?.quoteAsset}`;
  const isPrecisionEqual =
    prevProps.currentSymbol?.quoteAssetPrecision === nextProps.currentSymbol?.quoteAssetPrecision &&
    prevProps.currentSymbol?.baseAssetPrecision === nextProps.currentSymbol?.baseAssetPrecision;
  return (
    prevProps.exchangeName === nextProps.exchangeName &&
    prevSymbol === nextSymbol &&
    isPrecisionEqual
  );
};

export const TradingViewChart = memo(
  ({
    exchangeName,
    currentSymbol = {
      quoteAsset: 'USDT',
      baseAsset: 'BTC',
      quoteAssetPrecision: 8,
      baseAssetPrecision: 8,
      minNotional: '0',
      maxNotional: '0',
      lotMin: '0',
      lotMax: '0',
      symbol: '',
    },
  }: TradingViewChartProps) => {
    const dispatch = useDispatch();
    const store = useStore();
    const chartContainerRef = useRef<HTMLDivElement | null>(null);
    const tvWidgetRef = useRef<any>(null);
    // Объект для хранения созданных линий
    const linesRef = useRef<{ [key: string]: any; }>({});
    const updateLinesTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    // Локальное состояние готовности графика (используется только при инициализации)
    const [chartReady, setChartReady] = useState(false);

    const createExchange = (value: string): Exchange => ({
      value,
      name: value.charAt(0).toUpperCase() + value.slice(1),
      desc: `${value.charAt(0).toUpperCase() + value.slice(1)} Exchange`,
    });
    const exchangeOptions: Exchange[] = [
      'binance',
      'bybit',
      'okx',
      'gate',
      'crypto-com',
      'htx',
      'kucoin',
    ].map(createExchange);

    const normalizedExchangeName = exchangeName.replace(/-futures$/, '');
    const customDatafeed = createCustomDataFeed({
      exchangeName: normalizedExchangeName,
      currentSymbol,
      dispatch,
      exchangeOptions,
    });

    // Метод updateLines
    const updateLines = () => {
      if (!tvWidgetRef.current || !tvWidgetRef.current.chart) {
        console.warn('Chart reference is not ready yet');
        return;
      }
      
      try {
        const chart = tvWidgetRef.current.chart();
        if (!chart) {
          console.warn('Chart is not available');
          return;
        }
        
        // Читаем текущие значения из Redux
        const state = store.getState();
        const basePrice = state.newTerminal.basePrice;
        const additionalOrderPrice = state.newTerminal.additionalOrderPrice;
        const takeProfitPrice = state.newTerminal.takeProfitPrice;
        const stopLossPrice = state.newTerminal.stopLossPrice;
        const additionalEntryLines = state.newTerminal.additionalEntryLines || [];
        const takeProfitLines = state.newTerminal.takeProfitLines || [];
        
        // Обработка basePrice
        if (basePrice && basePrice !== 0) {
          if (linesRef.current['basePrice']) {
            linesRef.current['basePrice'].setPrice(Number(basePrice));
          } else {
            linesRef.current['basePrice'] = chart
              .createOrderLine({})
              .setText('Entry Order')
              .setPrice(Number(basePrice))
              .setLineColor('#3D8BFF')
              .setQuantity('') // скрываем количество
              .setBodyTextColor('#3D8BFF')
              .setBodyBackgroundColor('#ffffff')
              .setBodyBorderColor('#3D8BFF')
              .onMove(() => {
                const newPrice = linesRef.current['basePrice'].getPrice();
                dispatch(newTerminalActions.setBasePrice(newPrice));
                console.log('Moving basePrice to:', newPrice);
              });
          }
        } else if (linesRef.current['basePrice']) {
          // Удаляем линию при нулевом значении
          linesRef.current['basePrice'].remove();
          linesRef.current['basePrice'] = null;
        }
        
        // Обработка additionalOrderPrice
        if (additionalOrderPrice && additionalOrderPrice !== 0) {
          if (linesRef.current['additionalOrderPrice']) {
            linesRef.current['additionalOrderPrice'].setPrice(Number(additionalOrderPrice));
          } else {
            linesRef.current['additionalOrderPrice'] = chart
              .createOrderLine({})
              .setText('Additional Order Price')
              .setPrice(Number(additionalOrderPrice))
              .setQuantity('') // скрываем количество
              // .setBodyTextColor('#FFA500')
              // .setBodyBackgroundColor('#ffffff')
              // .setBodyBorderColor('#FFA500')
              .onMove(() => {
                const newPrice = linesRef.current['additionalOrderPrice'].getPrice();
                dispatch(newTerminalActions.setAdditionalOrderPrice(newPrice));
                console.log('New price for additionalOrderPrice:', newPrice);
              });
          }
        } else if (linesRef.current['additionalOrderPrice']) {
          // Удаляем линию при нулевом значении
          linesRef.current['additionalOrderPrice'].remove();
          linesRef.current['additionalOrderPrice'] = null;
        }
        
        // Обработка takeProfitPrice
        if (takeProfitPrice && takeProfitPrice !== 0) {
          if (linesRef.current['takeProfitPrice']) {
            linesRef.current['takeProfitPrice'].setPrice(Number(takeProfitPrice));
          } else {
            linesRef.current['takeProfitPrice'] = chart
              .createOrderLine({})
              .setText('Take Profit')
              .setPrice(Number(takeProfitPrice))
              .setLineColor('#1B886A')
              .setQuantity('')
              .setBodyTextColor('#2ECD99')
              .setBodyBackgroundColor('#ffffff')
              .setBodyBorderColor('#2ECD99')
              .setLineStyle(2)  // пунктирная линия
              .setLineLength(25)  // длина линии
              .onMove(() => {
                const newPrice = linesRef.current['takeProfitPrice'].getPrice();
                dispatch(newTerminalActions.setTakeProfitPrice(newPrice));
                console.log('New price for takeProfitPrice:', newPrice);
              });
          }
        } else if (linesRef.current['takeProfitPrice']) {
          // Удаляем линию при нулевом значении
          linesRef.current['takeProfitPrice'].remove();
          linesRef.current['takeProfitPrice'] = null;
        }
        
        // Обработка stopLossPrice
        if (stopLossPrice && stopLossPrice !== 0) {
          if (linesRef.current['stopLossPrice']) {
            linesRef.current['stopLossPrice'].setPrice(Number(stopLossPrice));
          } else {
            linesRef.current['stopLossPrice'] = chart
              .createOrderLine({})
              .setText('Stop Loss')
              .setPrice(Number(stopLossPrice))
              .setLineColor('#FF5E5E')
              .setQuantity('')
              .setBodyTextColor('#FF5E5E')
              .setBodyBackgroundColor('#ffffff')
              .setBodyBorderColor('#FF5E5E')
              .setCancelButtonBorderColor('#FF5E5E')
              .setCancelButtonIconColor('#ffffff')
              .setLineStyle(2)  // пунктирная линия
              .setLineLength(25)  // длина линии
              .onMove(() => {
                const newPrice = linesRef.current['stopLossPrice'].getPrice();
                dispatch(newTerminalActions.setStopLossPrice(newPrice));
                console.log('New price for stopLossPrice:', newPrice);
              });
          }
        } else if (linesRef.current['stopLossPrice']) {
          // Удаляем линию при нулевом значении
          linesRef.current['stopLossPrice'].remove();
          linesRef.current['stopLossPrice'] = null;
        }
        
        // Обработка линий дополнительных ордеров
        // Сначала удаляем все линии, которые больше не нужны
        Object.keys(linesRef.current).forEach(key => {
          if (key.startsWith('additionalEntry_') && !additionalEntryLines.some((_: any, idx: number) => key === `additionalEntry_${idx}`)) {
            try {
              if (linesRef.current[key]) {
                linesRef.current[key].remove();
              }
              delete linesRef.current[key];
            } catch (error) {
              console.error(`Error removing additional entry line ${key}:`, error);
            }
          }
        });

        // Затем создаем или обновляем линии для каждого ордера
        additionalEntryLines.forEach((entry: any, index: number) => {
          const lineKey = `additionalEntry_${index}`;
          const price = entry.price;
          const type = entry.type;
          
          let lineColor = '#FFA500'; // Orange by default
          let lineText = 'Additional Entry';
          
          // Разные цвета для разных типов ордеров
          if (type === 'cond.market') {
            lineColor = '#9370DB'; // Purple for conditional market
            lineText = 'Cond. Market Entry';
          } else if (type === 'cond.limit') {
            lineColor = '#20B2AA'; // Light sea green for conditional limit
            lineText = 'Cond. Limit Entry';
          }
          
          if (price && price !== 0) {
            if (linesRef.current[lineKey]) {
              try {
                linesRef.current[lineKey].setPrice(Number(price));
              } catch (error) {
                console.error(`Error updating price for ${lineKey}:`, error);
                // Если произошла ошибка, попробуем пересоздать линию
                try {
                  if (linesRef.current[lineKey]) {
                    linesRef.current[lineKey].remove();
                  }
                  linesRef.current[lineKey] = null;
                } catch (e) {
                  console.warn(`Failed to clean up line ${lineKey}:`, e);
                }
              }
            }
            
            if (!linesRef.current[lineKey]) {
              try {
                linesRef.current[lineKey] = chart
                  .createOrderLine({})
                  .setText(`${lineText} #${index + 1}`)
                  .setPrice(Number(price))
                  .setLineColor(lineColor)
                  .setQuantity('')
                  .setBodyTextColor(lineColor)
                  .setBodyBackgroundColor('#ffffff')
                  .setBodyBorderColor(lineColor);
              } catch (error) {
                console.error(`Error creating line for ${lineKey}:`, error);
              }
            }
          } else if (linesRef.current[lineKey]) {
            try {
              linesRef.current[lineKey].remove();
              delete linesRef.current[lineKey];
            } catch (error) {
              console.error(`Error removing line ${lineKey}:`, error);
            }
          }
        });
        
        // Обработка линий Take Profit
        // Сначала удаляем все линии, которые больше не нужны
        Object.keys(linesRef.current).forEach(key => {
          if (key.startsWith('takeProfit_') && !takeProfitLines.some((_: any, idx: number) => key === `takeProfit_${idx}`)) {
            try {
              if (linesRef.current[key]) {
                linesRef.current[key].remove();
              }
              delete linesRef.current[key];
            } catch (error) {
              console.error(`Error removing take profit line ${key}:`, error);
            }
          }
        });
        
        // Затем создаем или обновляем линии для каждого Take Profit ордера
        takeProfitLines.forEach((entry: any, index: number) => {
          const lineKey = `takeProfit_${index}`;
          const price = entry.price;
          const type = entry.type;
          const volume = entry.volume || 0;
          
          // Зеленый цвет для TP
          const lineColor = '#1B886A';
          let lineText = 'TP Market';
          
          if (type === 'limit') {
            lineText = 'TP Limit';
          } else if (type === 'cond.limit') {
            lineText = 'TP Cond. Limit';
          }
          
          if (price && price !== 0) {
            if (linesRef.current[lineKey]) {
              try {
                linesRef.current[lineKey].setPrice(Number(price));
                // Обновляем текст на случай, если изменился процент объема
                linesRef.current[lineKey].setText(`${lineText} ${volume}% #${index + 1}`);
              } catch (error) {
                console.error(`Error updating price for ${lineKey}:`, error);
                // Если произошла ошибка, попробуем пересоздать линию
                try {
                  if (linesRef.current[lineKey]) {
                    linesRef.current[lineKey].remove();
                  }
                  linesRef.current[lineKey] = null;
                } catch (e) {
                  console.warn(`Failed to clean up line ${lineKey}:`, e);
                }
              }
            }
            
            if (!linesRef.current[lineKey]) {
              try {
                linesRef.current[lineKey] = chart
                  .createOrderLine({})
                  .setText(`${lineText} ${volume}% #${index + 1}`)
                  .setPrice(Number(price))
                  .setLineColor(lineColor)
                  .setQuantity('')
                  .setBodyTextColor(lineColor)
                  .setBodyBackgroundColor('#ffffff')
                  .setBodyBorderColor(lineColor);
              } catch (error) {
                console.error(`Error creating line for ${lineKey}:`, error);
              }
            }
          } else if (linesRef.current[lineKey]) {
            try {
              linesRef.current[lineKey].remove();
              delete linesRef.current[lineKey];
            } catch (error) {
              console.error(`Error removing line ${lineKey}:`, error);
            }
          }
        });
        
      } catch (error) {
        console.error('Error updating chart lines:', error);
      }
    };

    // Инициализация виджета TradingView
    useEffect(() => {
      if (tvWidgetRef.current) {
        try {
          tvWidgetRef.current.remove();
        } catch (error) {
          console.error('Error removing existing widget:', error);
        }
      }
      
      try {
        const widgetOptions: ChartingLibraryWidgetOptions = {
          symbol: currentSymbol?.tvFormatSymbol ?? 'BTCUSDT',
          datafeed: customDatafeed,
          interval: '60' as ResolutionString,
          container: chartContainerRef.current as HTMLDivElement,
          library_path: chartingLibraryPath,
          locale: 'en',
          disabled_features: ['use_localstorage_for_settings', 'header_symbol_search'],
          enabled_features: ['study_templates'],
          client_id: 'tradingview.com',
          user_id: 'public_user_id',
          fullscreen: false,
          autosize: true,
          studies_overrides: {},
          debug: true,
        };

        const tvWidget = new widget(widgetOptions);
        tvWidgetRef.current = tvWidget;
        
        tvWidget.onChartReady(() => {
          console.log('Chart is ready');
          setChartReady(true);
          
          // Задержка с проверкой доступности графика
          setTimeout(() => {
            if (tvWidgetRef.current && tvWidgetRef.current.chart) {
              try {
                console.log('Initializing chart lines after delay');
                updateLines();
              } catch (error) {
                console.error('Error in initial line update:', error);
                // Если не удалось с первого раза, пробуем еще раз через небольшую задержку
                setTimeout(() => {
                  try {
                    console.log('Retrying chart lines initialization');
                    updateLines();
                  } catch (retryError) {
                    console.error('Error in retry line update:', retryError);
                  }
                }, 1000);
              }
            } else {
              console.warn('Chart not ready after timeout, will retry');
              setTimeout(() => {
                try {
                  if (tvWidgetRef.current && tvWidgetRef.current.chart) {
                    console.log('Final retry for chart lines initialization');
                    updateLines();
                  } else {
                    console.error('Chart still not available after retries');
                  }
                } catch (finalError) {
                  console.error('Error in final retry update:', finalError);
                }
              }, 1000);
            }
          }, 3000);
        });

        return () => {
          if (tvWidgetRef.current) {
            try {
              tvWidgetRef.current.remove();
              tvWidgetRef.current = null;
            } catch (error) {
              console.error('Error during cleanup:', error);
            }
          }
        };
      } catch (error) {
        console.error('Error creating trading view widget:', error);
        return () => {};
      }
    }, [normalizedExchangeName, currentSymbol, customDatafeed]);

    // Подписка на обновления Redux
    useEffect(() => {
      let isSubscribed = true;
      
      const unsubscribe = store.subscribe(() => {
        if (!isSubscribed || !chartReady) return;
        
        // Используем debounce для предотвращения слишком частых обновлений
        if (updateLinesTimeoutRef.current) {
          clearTimeout(updateLinesTimeoutRef.current);
        }
        
        updateLinesTimeoutRef.current = setTimeout(() => {
          if (!isSubscribed) return;
          
          if (tvWidgetRef.current && tvWidgetRef.current.chart) {
            try {
              console.log('Updating lines from Redux');
              updateLines();
            } catch (error) {
              console.error('Error updating lines from Redux:', error);
            }
          } else {
            console.warn('Chart not ready for Redux update');
          }
        }, 100); // Уменьшаем задержку до 100мс
      });
      
      return () => {
        isSubscribed = false;
        if (updateLinesTimeoutRef.current) {
          clearTimeout(updateLinesTimeoutRef.current);
        }
        unsubscribe();
      };
    }, [chartReady, store]);

    return <div ref={chartContainerRef} className='TVChartContainer' />;
  },
  arePropsEqual,
);

TradingViewChart.displayName = 'TradingViewChart';
