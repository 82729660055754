import { appPath } from 'shared/config/route-config/app-routes/consts';

export const items = (t: any) => {
  return [{
    icon: '/images/onboarding/copy-bot.jpg',
    title: t('modal.onboarding.start.copy.title'),
    description: t('modal.onboarding.start.copy.description'),
    link: `${appPath.trading_bots_marketplace.navigate()}?mt=copy-bots`,
  }, {
    icon: '/images/onboarding/alert-bot.jpg',
    title: t('modal.onboarding.start.alert.title'),
    description: t('modal.onboarding.start.alert.description'),
    link: `${appPath.trading_bots_configurator.navigate()}?mt=alert-bots`,
  }, {
    icon: '/images/onboarding/terminal.jpg',
    title: t('modal.onboarding.start.terminal.title'),
    description: t('modal.onboarding.start.terminal.description'),
    link: `${appPath.manual_trading_trading_terminal.navigate()}?mt=smart-terminal`,
  }];
};
