import { TLanguage, TTranslation } from 'shared/types';

export const modalOnboarding = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      onboarding: {
        button: 'Back',
        steps: {
          summary: 'Onboarding summary',
          exchange: 'Connect an exchange',
          trading: 'Start trading',
        },
        welcome: {
          title: 'Welcome',
          description: 'Here is where your trading journey starts. Explore features with our onboarding',
          button: {
            start: 'Start onboarding',
            skip: 'Skip onboarding',
          },
        },
        start: {
          title: 'Choose your trading automation style',
          copy: {
            title: 'Copy bot',
            description: 'Copy top performing bots with protected profit sharing',
          },
          alert: {
            title: 'Alert bot',
            description: 'Turn webhook alerts into automated trades at exchanges',
          },
          terminal: {
            title: 'Smart terminal',
            description: 'Create multiple order trades and track live performance',
          },
        },
      },
    },
    ru: {
      onboarding: {
        button: 'Назад',
        steps: {
          summary: 'Итоги онбординга',
          exchange: 'Подключите биржу',
          trading: 'Начните торговать',
        },
        welcome: {
          title: 'Добро пожаловать',
          description: 'Здесь начинается ваш путь в трейдинге. Исследуйте функции в нашем онбординге',
          button: {
            start: 'Начать онбординг',
            skip: 'Пропустить',
          },
        },
        start: {
          title: 'Выберите стиль торговли',
          copy: {
            title: 'Копи бот',
            description: 'Копируйте лучших ботов с high-water марк защитой',
          },
          alert: {
            title: 'Алерт бот',
            description: 'Торгуйте автоматически с алертами TradingView',
          },
          terminal: {
            title: 'Умный терминал',
            description: 'Создавайте мульти-ордерные позиции и управляйте ими',
          },
        },
      },
    },
  };

  return transaltion[type].onboarding;
};
