import { Box } from '@mui/material';
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { MainButton, Text, WithDrawRequest } from 'shared/ui';
import { IWithDraw } from '../interfaces';
import { button, inner, wrapper } from '../styles';

export const WithDraw = (props: IWithDraw) => {
  const {
    fetchData,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {
    t, 
  } = useTranslation();

  const modalHandler = () => {
    setIsModalOpen((prev) => !prev);
  };
  
  return (
    <Fragment>
      <Box sx={wrapper}>
        <Box sx={inner}>
          <Text>
            {t('withdrawal.title')}
          </Text>

          <Text>
            {t('withdrawal.text')}
          </Text>
        </Box>

        <MainButton
          type='primary'
          ghost={true}
          size='large'
          styles={button}
          onClick={modalHandler}
        >
          {t('withdrawal.button')}
        </MainButton>
      </Box>

      <WithDrawRequest
        isOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen(false);
        }}
        available={props.available}
        fetchData={fetchData}
      />
    </Fragment>
  );
};
