import { CustomSlider } from 'pages/trading-bots/configurator-v2/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { Label } from 'widgets';

export const ActiveTradingPairs = (props: any) => {
  const {
    t, 
  } = useTranslation();

  const [value, setValue] = useState<number>(10);

  const debouncedValue = useDebouncedCallback((value: number) => {
    props.setActivePairs(value);
  }, 300);

  const setActiveTradingPairs = (value: number) => {
    setValue(value);
    debouncedValue(value);
  };
  
  return (
    <CustomSlider
      title={(
        <Label
          title={t('form.configurator.pairs.title')}
        />
      )}
      slider={{
        value: value,
        onChange: setActiveTradingPairs,
        max: 20,
        marks: {
          1: 1,
          5: 5,
          20: 20,
        },
      }}
      input={{
        value: value,
        placeholder: '100%',
        onChange: setActiveTradingPairs,
      }}
    />
  );
};
