import { Stack } from '@mui/material';
import { Modal } from 'antd';
import { previewTags } from 'pages/register/consts';
import { useNavigate } from 'react-router-dom';
import { authPath } from 'shared/config/route-config/auth-routes/consts';
import { useQuery } from 'shared/hooks';
import { modal, modalContent } from '../styles';
import { AlertBots, CopyBots, SmartTerminal } from '../view';

export const Preview = (props: Partial<Record<'onClose', () => void>>) => {
  const query = useQuery();
  const navigate = useNavigate();

  const type = query.get('mt');
  const isOpened = previewTags.includes(type || '');

  const closeHandler = () => {
    if (props.onClose) {
      props.onClose();
      return;
    }
    
    query.delete('mt');
    navigate(`${authPath.register.navigate()}?${query.toString()}`);
  };

  const getContent = () => {
    if (!type) {
      return null;
    }

    if (type === 'smart-terminal') {
      return (
        <SmartTerminal />
      );
    }

    if (type === 'copy-bots') {
      return (
        <CopyBots />
      );
    }

    return (
      <AlertBots />
    );
  };

  return (
    <Modal
      open={isOpened}
      footer={null}
      closeIcon={false}
      style={modal}
      styles={modalContent}
      centered={true}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      onOk={closeHandler}
      width='100%'
    >
      <Stack
        maxHeight='calc(100dvh - 96px)'
        overflow='scroll'
      >
        {getContent()}
      </Stack>
    </Modal>
  );
};
