import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { getExchangeCode } from 'pages/manual-trading/trading-terminal/helpers/active-trades-utils';
import { SmartTrade } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';
import { getExchangeSymbols } from 'pages/trading-terminal-page/trading-chart/helpers/get-symbols';
import { useEffect, useState, useRef, useMemo } from 'react';

interface SymbolCache {
  [key: string]: CurrentSymbol;
}

export const useTradeSymbols = (trade: SmartTrade) => {
  const [tradeSymbol, setTradeSymbol] = useState<CurrentSymbol | null>(null);
  const symbolCache = useRef<SymbolCache>({});
  const [exchangeCode, setExchangeCode] = useState<string | null>(null);
  const isMounted = useRef(true);
  const symbol = trade?.baseSymbol + trade?.quoteSymbol;
  
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!trade) {
      return;
    }
    
    const fetchExchangeCode = async () => {
      try {
        const code = await getExchangeCode(trade);
        
        if (isMounted.current) {
          setExchangeCode(code);
        }
      } catch (error) {
        console.error('Error fetching exchange code:', error);
      }
    };
    
    fetchExchangeCode();
  }, [trade]);

  useEffect(() => {
    const fetchSymbols = async () => {
      if (!exchangeCode) return;

      try {
        const cacheKey = `${exchangeCode}_${symbol}`;
        
        if (symbolCache.current[cacheKey]) {
          setTradeSymbol(symbolCache.current[cacheKey]);
          return;
        }

        const symbols = await getExchangeSymbols(exchangeCode) as CurrentSymbol[];
        
        if (isMounted.current) {
          const foundSymbol = symbols.find(el => el.symbol === symbol);
          
          if (foundSymbol) {
            symbolCache.current[cacheKey] = foundSymbol;
            setTradeSymbol(foundSymbol);
          }
        }
      } catch (error) {
        console.error('Error fetching symbols:', error);
      }
    };

    fetchSymbols();
  }, [exchangeCode, symbol]);

  return useMemo(() => tradeSymbol, [tradeSymbol]);
};
