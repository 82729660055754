import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const botStart = async (botUuid: string) => {
  const response = await axios.request({
    url: '/bot/startBot',
    data: {
      botUuid,
    },
  });
    
  return response?.data;
};
