import { CSSProperties } from 'react';

export const RANGE = (properties?: CSSProperties) => {
  const styles: CSSProperties = {
    padding: '8px 11px',
    ...properties,
  };

  return styles;
};
