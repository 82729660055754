import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});
  
export const botCreateSubscription = async (params: Record<'botUuid' | 'sourceUuid', string>) => {

  const response = await axios.request({
    url: '/subscription/createBotSuccessSubscription',
    data: {
      skyrexUserUuid: getSkyrexUuid()!,
      botUuid: params.botUuid,
      sourceUuid: params.sourceUuid,
    },
  });
    
  return response?.data;
};
