import dayjs from 'dayjs';
import { getSnapshotsByDate } from 'entities/exchange/api/get-snapshots-by-date';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { AggregatedSnapshot, ChartSnapshot } from 'entities/exchange/types/snapshot.types';
import { defaultDates } from 'pages/home/consts';
import { useEffect, useState } from 'react';
import { getExchangeAccountsUuids } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { getBalances, getCalculatedExchangeTotal, getDailyChange } from '../helpers';

export const useAggregate = (exchanges: ExchangeAccount[] | null) => {
  const [selectedCurrency, setSelectedCurrency] = useState<string>('USDT');
  const [snapshots, setSnapshots] = useState<AggregatedSnapshot[]>([]);
  const [modifiedSnapshots, setModifiedSnapshots] = useState<AggregatedSnapshot[]>([]);
  const [dates, setDates] = useState<[dayjs.Dayjs | null, dayjs.Dayjs | null] | null>(defaultDates);
  const [calculatedPairs, setCalculatedPairs] = useState<Record<'usdt' | 'btc', number> | null>(null);
  const [dailyChange, setDailyChange] = useState<any>(null);
  const [balances, setBalances] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const isEmptyEchanges = exchanges?.length === 0;

  const updateAggregate = () => {
    if (!exchanges || isEmptyEchanges) {
      return;
    }
    
    fetchSnapshots();
  };

  const fetchSnapshots = async () => {
    try {
      setIsLoading(true);
      
      const updatedDates: [dayjs.Dayjs | null, dayjs.Dayjs | null] = [
        dates?.[0]!,
        dates?.[1]!,
      ];
  
      const now = dayjs();
      if (dates?.[1]!.isAfter(now) || dates?.[1]!.isSame(now)) {
        updatedDates[1] = dayjs().subtract(1, 'day').endOf('day');
      }
  
      const allAccountsSnapshots = await getSnapshotsByDate({
        skyrexUserUuid: getSkyrexUuid()!,
        dates: updatedDates,
        exchangeAccountUuids: getExchangeAccountsUuids(exchanges!),
      });
  
      const snapshots = allAccountsSnapshots?.data?.accounts || [];
  
      const snapshotsMap: Map<string, AggregatedSnapshot> = new Map();
        
      snapshots.forEach((snapshot: ChartSnapshot) => {
        const createdDate = dayjs(snapshot.createdDate).format('DD-MM-YYYY');
        
        const btcValue = parseFloat(snapshot.totalBtc);
        const usdtValue = parseFloat(snapshot.totalUsdt);
      
        if (!snapshotsMap.has(createdDate)) {
          snapshotsMap.set(createdDate, {
            createdDate,
            totalBtc: 0,
            totalUsdt: 0,
          });
        }
      
        const aggregatedSnapshot = snapshotsMap.get(createdDate);
        if (aggregatedSnapshot) {
          aggregatedSnapshot.totalBtc += btcValue;
          aggregatedSnapshot.totalUsdt += usdtValue;
        }
      });
  
      const values = getCalculatedExchangeTotal(exchanges!);
  
      const sortSnapshots = () => {
        return Array.from(snapshotsMap.values()).sort((a, b) => {
          return dayjs(a.createdDate, 'DD-MM-YYYY').unix() - dayjs(b.createdDate, 'DD-MM-YYYY').unix();
        });
      };
  
      let sortedSnapshots = sortSnapshots();
  
      if (dates?.[1]!.isAfter(now) || dates?.[1]!.isSame(now)) {
        const snapshot = {
          createdDate: dayjs().format('DD-MM-YYYY'),
          totalBtc: values?.btc || 0,
          totalUsdt: values?.usdt || 0,
        };

        snapshotsMap.set(snapshot.createdDate, snapshot);
      }

      setCalculatedPairs(values);

      const snapshot = sortedSnapshots.at(-1);
      setDailyChange(
        getDailyChange({
          exchange: values,
          snapshot: {
            btc: snapshot?.totalBtc || 0,
            usdt: snapshot?.totalUsdt || 0,
          },
        }),
      );
  
      sortedSnapshots = sortSnapshots();
  
      setSnapshots(snapshots);
      setModifiedSnapshots(sortedSnapshots);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!exchanges) {
      setIsLoading(true);
      return;
    }
    
    if (isEmptyEchanges) {
      setSnapshots([]);
      setModifiedSnapshots([]);
      setIsLoading(false);
      return;
    }

    fetchSnapshots();
  }, [exchanges, dates]);

  useEffect(() => {
    if (!exchanges) {
      setIsLoading(true);
      return;
    }

    if (isEmptyEchanges) {
      setBalances([]);
      setDailyChange({});
      setCalculatedPairs({
        btc: 0,
        usdt: 0,
      });
      setIsLoading(false);
      return;
    }

    setBalances(getBalances(exchanges));
  }, [exchanges]);

  return {
    values: {
      selectedCurrency,
      snapshots,
      dates,
      modifiedSnapshots,
      dailyChange,
      calculatedPairs,
      balances,
      isLoading,
    },
    handlers: {
      setSelectedCurrency,
      setDates,
      updateAggregate,
    },
  };
};
