import { CSSProperties } from 'react';

export const BODY: CSSProperties = {
  width: '100%',
  maxWidth: 390,
  padding: '24px 0 24px 16px',
  backgroundColor: '#29A97F',
  overflow: 'scroll',
  display: 'flex',
};
