import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { TType } from 'shared/types';

export const filterExchangesByType = (exchange: ExchangeAccount[]) => {
  return (type: TType) => {
    return exchange.filter((exchange) => {
      if (type === 'spot') {
        return !exchange.exchangeCode.includes('futures');
      }

      return exchange.exchangeCode.includes('futures');
    });
  };
};
