import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  flexDirection:'row',
  justifyContent:'center',
  alignItems:'center',
  gap: '24px',
  bgcolor:'#F2F2F2',
  borderRadius: '4px',
  padding:'20px 18px',
};
