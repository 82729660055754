import { generateOauthParams } from '../../helpers';
import { IRoute } from '../../interfaces';
import { TAppPages } from '../../types/app-pages';

export const appPath: Record<TAppPages, IRoute> = {
  home: {
    path: '/home',
    navigate: () => '/home',
  },
  activate_account: {
    path: '/user/activate',
    navigate: () => '/user/activate',
  },
  my_accounts: {
    path: '/my-accounts',
    navigate: () => '/my-accounts',
  },
  my_account: {
    path: '/my-accounts/:id',
    navigate: (id) => `/my-accounts/${id}`,
  },
  subscriptions: {
    path: '/subscriptions',
    navigate: () => '/subscriptions',
  },
  invite_friends: {
    path: '/invite-friends',
    navigate: () => '/invite-friends',
  },

  gateio_create_oauth: generateOauthParams({
    platform: 'gateio',
  }),
  cryptocom_oauth: {
    path: '/my-accounts/oauthCallback/crypto-com',
    navigate: () => '/my-accounts/oauthCallback/crypto-com',
  },
  bybit_create_oauth: generateOauthParams({
    platform: 'bybit',
  }),
  binance_create_oauth: generateOauthParams({
    platform: 'binance',
  }),
  okx_create_oauth: generateOauthParams({
    platform: 'okx',
  }),
  htx_create_oauth: generateOauthParams({
    platform: 'htx',
  }),
  kucoin_create_oauth: generateOauthParams({
    platform: 'kucoin',
  }),

  crypto_com_update_oauth: {
    path: '/my-accounts/oauthCallback/crypto-com',
    navigate: () => '/my-accounts/oauthCallback/crypto-com',
  },
  bybit_update_oauth: generateOauthParams({
    platform: 'bybit',
    isUpdate: true,
  }),
  binance_update_oauth: generateOauthParams({
    platform: 'binance',
    isUpdate: true,
  }),
  okx_update_oauth: generateOauthParams({
    platform: 'okx',
    isUpdate: true,
  }),
  htx_update_oauth: generateOauthParams({
    platform: 'htx',
    isUpdate: true,
  }),
  kucoin_update_oauth: generateOauthParams({
    platform: 'kucoin',
    isUpdate: true,
  }),
  kucoin_oauth: {
    path: '/oauth/kucoin',
    navigate: () => '/oauth/kucoin',
  },

  manual_trading_trading_terminal: {
    path: '/manual-trading/trading-terminal',
    navigate: () => '/manual-trading/trading-terminal',
  },
  manual_trading_trading_history: {
    path: '/manual-trading/trading-diary',
    navigate: () => '/manual-trading/trading-diary',
  },
  manual_trading_introduction: {
    path: '/manual-trading/introduction',
    navigate: () => '/manual-trading/introduction',
  },

  trading_bots_my_bots: {
    path: '/trading-bots/my-bots',
    navigate: () => '/trading-bots/my-bots',
  },
  trading_bots_my_bot: {
    path: '/trading-bots/my-bots/bot/:id',
    navigate: (id) => `/trading-bots/my-bots/bot/${id}`,
  },
  trading_bots_trading_history: {
    path: '/trading-bots/trading-diary',
    navigate: () => '/trading-bots/trading-diary',
  },
  trading_bots_marketplace: {
    path: '/trading-bots/marketplace',
    navigate: () => '/trading-bots/marketplace',
  },
  trading_bots_sales: {
    path: '/trading-bots/my-public-bots',
    navigate: () => '/trading-bots/my-public-bots',
  },
  trading_bots_introduction: {
    path: '/trading-bots/introduction',
    navigate: () => '/trading-bots/introduction',
  },
  trading_bots_configurator: {
    path: '/trading-bots/create',
    navigate: () => '/trading-bots/create',
  },
  trading_bot_update: {
    path: '/trading-bots/:id/update',
    navigate: (id) => `/trading-bots/${id}/update`,
  },
  not_found: {
    path: '/not-found',
    navigate: () => '/not-found',
  },
};

