import { Configurator } from 'pages/trading-bots/configurator-v2';
import Introduction from 'pages/trading-bots/introduction/ui/introduction';
import { MarketplacePage } from 'pages/trading-bots/marketplace';
import { MyBotPage } from 'pages/trading-bots/my-bot';
import { MyBotsPage } from 'pages/trading-bots/my-bots';
import { PublicBotSales } from 'pages/trading-bots/public-bot-sales';
import BotsTradingHistory from 'pages/trading-bots/trading-history/ui/trading-history';
import { appPath } from '../app-routes/consts';
import { IPage } from '../interfaces';

export const botsRoutes = () => {
  const routes: IPage[] = [{
    title: 'My bots',
    path: appPath.trading_bots_my_bots.path,
    element: (
      <MyBotsPage />
    ),
  }, {
    title: 'My bot',
    path: appPath.trading_bots_my_bot.path,
    element: (
      <MyBotPage />
    ),
  }, {
    title: 'Trading diary',
    path: appPath.trading_bots_trading_history.path,
    element: (
      <BotsTradingHistory />
    ),
  }, {
    title: 'Marketplace',
    path: appPath.trading_bots_marketplace.path,
    element: (
      <MarketplacePage />
    ),
  }, {
    title: 'My public bots',
    path: appPath.trading_bots_sales.path,
    element: (
      <PublicBotSales />
    ),
  }, {
    title: 'Bots introduction',
    path: appPath.trading_bots_introduction.path,
    element: (
      <Introduction />
    ),
  }, {
    title: 'Bots configurator create',
    path: appPath.trading_bots_configurator.path,
    element: (
      <Configurator />
    ),
  }, {
    title: 'Bots configurator update',
    path: appPath.trading_bot_update.path,
    element: (
      <Configurator />
    ),
  }];

  return routes;
};
