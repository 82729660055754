import { TLanguage, TTranslation } from 'shared/types';

export const formRegister = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      register: {
        title: 'Let\'s get started',
        text: 'Start with a FREE 7-day Trial of our PRO plan.',
        banner: ' Get a FREE 7-day Trial on our PRO plan and start automating your investments. ',
        email: {
          label: 'E-mail',
          placeholder: '',
        },
        password: {
          label: 'Password (6 or more characters)',
          placeholder: '',
        },
        referalCode: {
          label: 'Referral code (optional)',
          placeholder: '',
        },
        links: {
          prefix: 'By registering, you agree to Skyrexio',
          terms: 'Terms of Service',
          and: 'and',
          policy: 'Privacy Policy',
          login: {
            label: 'Already have an account?',
            button: 'Login',
          },
        },
        button: 'Start Free Trial',
        comments: {
          title: 'Here\'s what other people say on',
          items: [{
            name: 'Karnel Jelli',
            type: 'Beginner trader',
            text: 'As a new trader, I found Smartbot to be incredibly user-friendly. The interface is intuitive, and the bot handles all the complex routine',
          }, {
            name: 'Thomas Watson',
            type: 'Strategy developer',
            text: 'With Skyrexio alert bots feature I run PineScript strategy on my Binance account and share it with my folks',
          }, {
            name: 'John Anderson',
            type: 'Experienced trader',
            text: 'SmartTrade terminal is a game-changer for me. Its intuitive interface and advanced features provide the control and flexibility for efficient trading',
          }],
        },
      },
    },
    ru: {
      register: {
        title: 'Начнём',
        text: 'Начните с БЕСПЛАТНОГО 7-дневного PRO плана',
        banner:
          'Получите БЕСПЛАТНЫЙ 7-дневный PRO план и торгуйте на максимуме.',
        email: {
          label: 'E-mail',
          placeholder: '',
        },
        password: {
          label: 'Пароль (6+ символов)',
          placeholder: '',
        },
        referalCode: {
          label: 'Рефкод (необязательно)',
          placeholder: '',
        },
        links: {
          prefix: 'Регистрируясь, вы соглашаетесь с',
          terms: 'Условиями Skyrexio',
          and: 'и',
          policy: 'Политикой конфиденциальности',
          login: {
            label: 'Уже есть аккаунт?',
            button: 'Войти',
          },
        },
        button: 'Начать бесплатный пробный период',
        comments: {
          title: 'Вот что говорят другие на',
          items: [
            {
              name: 'Karnel Jelli',
              type: 'Новичок',
              text: 'Как новичку, мне понравилась простота. Интерфейс понятен, а боты берут на себя всю рутину',
            },
            {
              name: 'Thomas Watson',
              type: 'Разработчик стратегий',
              text: 'С алерт-ботами Skyrexio я запустил PineScript-стратегии на Binance и поделился ими с друзьями',
            },
            {
              name: 'John Anderson',
              type: 'Опытный трейдер',
              text: 'Терминал — реальный прорыв. Управлять сделками удобно, функционал продвинутый',
            },
          ],
        },
      },
    },
  };

  return transaltion[type].register;
};
