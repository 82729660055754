interface IDefaultValues {
  alertSource: 'indicator' | 'strategy' | 'custom';
  orderType: 'market' | 'limit';
}
export const defaultSecondStepValues = () => {
  const defaultValues: IDefaultValues = {
    alertSource: 'indicator',
    orderType: 'market',
  };

  return defaultValues;
};
