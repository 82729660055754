import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { SingleSelect, Text } from 'shared/ui';

interface TradingPairSelectProps {
  tradingPairs: any[];
  selectedTradingPair: any;
  isLoading: boolean;
  onSelect: (value: string) => void;
  disabled?: boolean;
}

export const TradingPairSelect = (props: TradingPairSelectProps) => {
  const {
    tradingPairs,
    selectedTradingPair,
    isLoading,
    onSelect,
    disabled,
  } = props;

  const {
    t, 
  } = useTranslation();

  const optionRenderHandler = (option: any) => {
    return (
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <Text type='success'>
          {option.label}
        </Text>

        <Box
          display='flex'
          alignItems='center'
          gap={0.5}
        >
          <Text>
            {option?.value?.split('-')[0]}
          </Text>

          <Text type='secondary'>
            {option.label?.toString().split(' ')[0]}
          </Text>
        </Box>
      </Box>
    );
  };

  const labelRenderHandler = (props: any) => (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      gap={1}
    >
      <Text type='success'>
        {props.label}
      </Text>

      <Box
        display='flex'
        alignItems='center'
        gap={0.5}
      >
        <Text>
          {props.value?.split('-')[0]}
        </Text>

        <Text type='secondary'>
          {props.label?.toString().split(' ')[0]}
        </Text>
      </Box>
    </Box>
  );

  return (
    <SingleSelect
      showSearch={true}
      label={(
        <Label
          title={t('terminal.select.pair.title')}
        />
      )}
      options={tradingPairs}
      size='large'
      select={{
        value: selectedTradingPair,
        placeholder: t('terminal.select.pair.placeholder'),
        onChange: onSelect,
      }}
      optionRender={optionRenderHandler}
      labelRender={labelRenderHandler}
      loading={isLoading}
      disabled={disabled}
    />
  );
};
