import { BaseOptionType } from 'antd/es/select';
import { convertMarket } from 'pages/trading-bots/configurator-v2/helpers';
import { useGetMarkets } from 'pages/trading-bots/configurator-v2/hooks/use-get-markets';
import { useCallback } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { SingleSelect } from 'shared/ui';
import { Market as MarketView } from 'shared/ui/selects/single-select/views';

export const Market = () => {
  const {
    values: {
      isLoading,
    },
  } = useGetMarkets();

  const {
    control,
    watch,
    setValue,
    formState: {
      errors,
    },
  } = useFormContext();

  const watcher = watch('market');
  const markets = watcher.items;
  const market = watcher.value;

  const {
    t, 
  } = useTranslation();
  
  const selectMarketHandler = useCallback((value: string) => {
    const market = markets.find((market: any) => {
      return market.value === value;
    });
    if (!market) {
      return;
    }

    watcher.value = convertMarket(market);
    setValue('market', watcher);
  }, [markets, setValue]);

  const marketView = useCallback(() => {
    if (!market.name) {
      return null;
    }
    
    const [name, value] = market.name.split('_');

    return (
      <MarketView
        title={name}
        code={market.code}
        value={`${value}_${name}`}
      />
    );
  }, [market]);

  const marketOptionView = (option: BaseOptionType) => {
    if (!market.name) {
      return null;
    }
    
    const [name, value] = (option.label as string || '').split('_');
    return (
      <MarketView
        title={name}
        code={name}
        value={`${value}_${name}`}
      />
    );
  };

  const renderFiled: ControllerProps['render'] = (params) => {
    const {
      field,
    } = params;
  
    return (
      <SingleSelect
        label={(
          <Label
            title={t('form.configurator.market.title')}
          />
        )}
        options={markets}
        select={{
          value: field.value.value,
          placeholder: '',
          onChange: selectMarketHandler,
        }}
        labelRender={marketView}
        optionRender={marketOptionView}
        size='large'
        loading={isLoading}
        error={errors?.market?.message?.toString()}
      />
    );
  };
  
  return (
    <Controller
      name='market'
      control={control}
      render={renderFiled as any}
    />
  );
};
