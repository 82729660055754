import { TLanguage, TTranslation } from 'shared/types';

export const withdraw = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      withdrawal: {
        title: 'Create withdrawal request',
        text: 'Your request will be processed within 24 hours',
        button: 'Withdraw',
      },
    },
    ru: {
      withdrawal: {
        title: 'Создать запрос на вывод',
        text: 'Ваша заявка будет обработана в течение 24 часов',
        button: 'Вывести',
      },
    },
  };

  return transaltion[type].withdrawal;
};
