import { useFormContext, useWatch } from 'react-hook-form';
import { Buy } from '../buy/ui/buy';
import { Sell } from '../sell/ui/sell';
import { TradingPanelProps } from '../trading-panel/trading-panel';

export const TradingContent = ({
  currentSymbol,
  userWalletBaseAssetBalance,
  userWalletQuoteAssetBalance,
  exchangeAccountUuid,
  editingTrade,
  isFuturesAccount,
  orderPanelDisabled,
}: TradingPanelProps) => {
  const {
    control,
    watch, 
  } = useFormContext();
  const selectedSide = watch('selectedSide');
  
  const computedWallet = useWatch({
    control, name: 'computedWallet', defaultValue: userWalletQuoteAssetBalance, 
  });
  
  if (selectedSide === 'buy' || selectedSide === 'long' || selectedSide === 'short') {
    return (
      <Buy 
        currentSymbol={currentSymbol} 
        userWalletBaseAssetBalance={userWalletBaseAssetBalance} 
        userWalletQuoteAssetBalance={computedWallet}
        exchangeAccountUuid={exchangeAccountUuid}
        editingTrade={editingTrade}
        isFuturesAccount={isFuturesAccount}
        orderPanelDisabled={orderPanelDisabled}
      />
    );
  }
  
  return (
    <Sell
      currentSymbol={currentSymbol} 
      userWalletBaseAssetBalance={userWalletBaseAssetBalance}
      userWalletQuoteAssetBalance={userWalletQuoteAssetBalance}
      exchangeAccountUuid={exchangeAccountUuid}
      editingTrade={editingTrade}
      orderPanelDisabled={orderPanelDisabled}
    />
  );
};
