import { Stack } from '@mui/material';
import { socials } from 'widgets/header/consts';
import { MainButton } from 'shared/ui';
import { BUTTON, WRAPPER } from '../styles';

export const Socials = () => {
  const onClickHandler = (link: string) => {
    return () => {
      window.open(link, '_blank');
    };
  };
  
  return (
    <Stack sx={WRAPPER}>
      {socials.map((social) => (
        <MainButton
          key={social.link}
          styles={BUTTON}
          type='text'
          onClick={onClickHandler(social.link)}
        >
          {social.icon}
        </MainButton>
      ))}
    </Stack>
  );
};
