import { formatter } from 'shared/helpers';
import { EnrichedBotSmartTrade } from './get-all-bots-trading-diary-data';

export const botsTradesGetUnrealizedPnl = (trades: EnrichedBotSmartTrade[]) => {
  const validStatuses = [
    'new',
    'request_completed',
    'requested_cancelled',
    'active',
    'in_progress_update',
    'failed',
  ];

  const filteredByStatus = trades.filter(trade => validStatuses.includes(trade.status));
    
  const result = filteredByStatus.reduce((total, trade) => {
    return total + (trade.returnQuote !== null ? +trade.returnQuote : 0);
  }, 0);
  
  return formatter(result);
};
