import { TLanguage, TTranslation } from 'shared/types';

export const errorBot = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      bot: {
        close: {
          title: 'Close Failed',
          description: 'Couldn\'t close trades. Try again or contact support',
          button: 'Ok',
        },
        delete: {
          title: 'Deletion Failed',
          description: 'Unable to delete bot. Please try again or contact support',
          button: 'Ok',
        },
        update: {
          title: 'Update Failed',
          description: 'Bot update unsuccessful. Please try again or contact support',
          button: 'Ok',
        },
      },
    },
    ru: {
      'bot': {
        'close': {
          'title': 'Не удалось закрыть',
          'description': 'Не получилось закрыть сделки. Попробуйте снова или обратитесь в службу поддержки',
          'button': 'Ок',
        },
        'delete': {
          'title': 'Не удалось удалить',
          'description': 'Невозможно удалить бота. Попробуйте снова или обратитесь в службу поддержки',
          'button': 'Ок',
        },
        'update': {
          'title': 'Не удалось обновить',
          'description': 'Обновление бота прошло неудачно. Попробуйте снова или обратитесь в службу поддержки',
          'button': 'Ок',
        },
      },
    },
  };

  return transaltion[type].bot;
};
