import { useTranslation } from 'react-i18next';
import { TradingStatistic } from 'widgets';
import { useStatistics } from 'shared/hooks';
import { TPeriod } from 'shared/types';
import { Actions } from '../components';
import { getListItems, getMarketOptions } from '../helpers';
import { IStatisticsProps } from '../interfaces';

export const Statistics = (props: IStatisticsProps) => {
  const {
    values: {
      statistics,
      period,
      dates,
      source,
      market,
      isLoading,
      type,
      side,
      isMyAccounts,
    },
    handlers: {
      getChartData,
      setPeriod,
      setSource,
      setMarket,
      setDates,
      getCurrentStatistics,
      updateStatistics,
      setType,
      setSide,
    },
  } = useStatistics({
    exchanges: props.exhcnages,
  });

  const {
    t, 
  } = useTranslation();

  const changePeriodHandler = (value: string) => {
    setPeriod(value as TPeriod);
  };

  return (
    <TradingStatistic
      dates={dates}
      exchanges={props.exhcnages}
      selectedQuote={market}
      handleUpdateStatistic={updateStatistics}
      listItems={getListItems({
        t,
        statistics: getCurrentStatistics(),
        source,
      })}
      chartData={getChartData()}
      chartAction={(
        <Actions
          type={{
            value: type,
            disabled: isMyAccounts,
            onChange: setType,
          }}
          side={{
            value: side,
            onChange: setSide,
          }}
          source={{
            value: source,
            onChange: setSource,
          }}
          market={{
            value: market,
            options: getMarketOptions(statistics),
            onChange: setMarket,
          }}
          dates={{
            value: dates!,
            onChange: setDates,
          }}
        />
      )}
      selectedSegment={period}
      setSelectedSegment={changePeriodHandler}
      loading={isLoading}
    />
  );
};
