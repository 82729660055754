import { TLanguage, TTranslation } from 'shared/types';

export const validationRederralCode = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      referralCode: {
        max: 'Code does not exist',
      },
    },
    ru: {
      referralCode: {
        max: 'Код не существует',
      },
    },
  };

  return transaltion[type].referralCode;
};
