import { Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { CustomSlider, OrderType } from '../../../../../components';
import { WRAPPER } from '../styles';

export const Alerts = () => {
  const {
    watch,
    setValue,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();

  const additional = watch('additional');
  const orders = additional.segment.alert.orders;
  const ordersSizeScale = additional.segment.alert.ordersSizeScale;
  const orderType = additional.segment.alert.orderType;

  const changeAlertOrdersHandler = (value: number) => {
    additional.segment.alert.orders = value;
    setValue('additional', additional);
  };

  const changeAlertOrdersSizeScaleHandler = (value: number) => {
    additional.segment.alert.ordersSizeScale = value;
    setValue('additional', additional);
  };

  const changeAlertOrderTypeHandler = (value: string) => {
    additional.segment.alert.orderType = value;
    setValue('additional', additional);
  };

  return (
    <Stack sx={WRAPPER}>
      <CustomSlider
        title={(
          <Label
            title={t('form.configurator.additional.orders')}
          />
        )}
        slider={{
          value: orders,
          max: 10,
          marks: {
            1: 1,
            4: 4,
            10: 10,
          },
          onChange: changeAlertOrdersHandler,
        }}
        input={{
          value: orders,
          placeholder: '100%',
          onChange: changeAlertOrdersHandler,
        }}
      />

      <CustomSlider
        title={(
          <Label
            title={t('form.configurator.additional.ordersSize')}
          />
        )}
        slider={{
          value: ordersSizeScale,
          min: 0.1,
          max: 2.5,
          marks: {
            0.1: 0.1,
            1: 1,
            2.5: 2.5,
          },
          onChange: changeAlertOrdersSizeScaleHandler,
        }}
        input={{
          value: ordersSizeScale,
          placeholder: '100%',
          onChange: changeAlertOrdersSizeScaleHandler,
        }}
        step={0.1}
      />
        
      <OrderType
        value={orderType}
        onChange={changeAlertOrderTypeHandler}
      />
    </Stack>
  );
};
