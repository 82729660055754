import { Bot } from 'entities/bot/model/types';
import { IChooseAccount } from 'entities/choose-account/interfaces';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { exchangeInfoRequests } from 'shared/consts';
import { formatter, getUniqueSymbolCodes } from 'shared/helpers';
import { calculateMarketOptions, convertMarket } from '../helpers';

export const useGetMarkets = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const {
    watch,
    setValue,
  } = useFormContext();

  const exchange = watch('exchange').value as IChooseAccount['exchange'];
  const exchanges = watch('exchange').items as ExchangeAccount[];
  const tradingAmount = watch('tradingAmount');

  const marketWatcher = watch('market');
  const selectedMarket = marketWatcher.value  as IChooseAccount['market'];

  const bot = watch('bot') as Bot;

  const [symbols, setSymbols] = useState<any[]>([]);

  const getMarketsHandler = async () => {
    try {
      setIsLoading(true);
      const chosenExchange = exchanges.find((currentExchange) => {
        return currentExchange.exchangeAccountUuid === exchange.id;
      });
      if (!chosenExchange) {
        return;
      }

      const request = exchangeInfoRequests[exchange.code.split('-')[0].toLowerCase()];
      const symbols = await request();
      if (!symbols) {
        return;
      }

      const list = getUniqueSymbolCodes(symbols);
      const markets = calculateMarketOptions(chosenExchange.balances, list);
      if (!markets?.length) {
        return;
      }

      const market = markets.find((market: any) => {
        if (bot?.quote) {
          return market.value === bot.quote;
        }

        return selectedMarket.value ? selectedMarket.value === market.value : market.value === 'USDT';
      });

      const convertedMarket = convertMarket(market || markets[0]);

      setSymbols(symbols);
      setValue('market', {
        value: convertedMarket,
        items: markets,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!symbols.length) {
      return;
    }

    const filteredMarketSymbols = symbols.filter((symbol) => {
      return symbol.quoteAsset === selectedMarket.code;
    });
    const minNotional = filteredMarketSymbols.reduce((prev, current) => {
      if (+prev < +current.minNotional) {
        prev = +current.minNotional;
        return prev;
      }

      return prev;
    }, 0);


    marketWatcher.minNotional = minNotional;
    setValue('market', marketWatcher);

    if (tradingAmount) {
      return;
    }

    const marketValue = +selectedMarket.name.split('_')[1]?.replace(' ', '');
    const value = formatter(bot?.tradingAmount || (marketValue * 0.1));
    setValue('tradingAmount', value);
  }, [selectedMarket.code, bot, symbols]);

  useEffect(() => {
    if (!exchanges.length) {
      return;
    }

    getMarketsHandler();
  }, [exchanges, bot?.botUuid]);

  return {
    values: {
      isLoading: !exchanges.length || isLoading,
    },
  };
};
