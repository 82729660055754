import { formatter } from 'shared/helpers';

export const getSalesStatistics = (params: Record<'sales' | 't', any>) => {
  const {
    t,
    sales,
  } = params;

  const items = [{
    title: t('bots.public.sales.items.total'),
    value: `${formatter(sales?.total || 0)} USDT`,
  }, {
    title: t('bots.public.sales.items.withdrawn'),
    value: `${formatter((sales.total - sales?.withDrawal) || 0)} USDT`,
  }, {
    title: t('bots.public.sales.items.available'),
    value: `${formatter(sales.withDrawal || 0)} USDT`,
  }];

  return items;
};
