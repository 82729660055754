import { Stack } from '@mui/material';
import { Text, TooltipSingleSelect } from 'shared/ui';
import { ISideProps } from '../interfaces';

export const Side = (props: ISideProps) => {
  // const {
  //   t, 
  // } = useTranslation();

  const changeSourceHandler = (side: string) => {
    props.onChange(side as ISideProps['value']);
  };

  const getOptions = () => {
    if (props.type === 'spot') {
      return [{
        label: 'Buy',
        value: 'buy',
      }];
    }

    return [{
      label: 'Long',
      value: 'long',
    }, {
      label: 'Short',
      value: 'short',
    }];
  };

  const getTooltip = () => {
    if (props.type === 'spot') {
      return (
        <Text>
          Buy - Statistic of your Buy side trades
        </Text>
      );
    }

    return (
      <Stack>
        <Text>
          Long - Statistic of your Long trades
        </Text>
        <Text>
          Short - Statistic of your Short trades
        </Text>
      </Stack>
    );
  };
  
  return (
    <TooltipSingleSelect
      tooltip={getTooltip()}
      options={getOptions()}
      select={{
        value: props.value,
        placeholder: 'Select side',
        onChange: changeSourceHandler,
      }}
    />
  );
};
