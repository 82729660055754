import { Box, Stack } from '@mui/material';
import { exchangeIcons, IExchangeIcons } from 'shared/consts/exchange-icons';
import { formatter } from 'shared/helpers';
import { Icon, Text, Title } from 'shared/ui';
import { IExchangeProps } from '../interfaces';
import { BALANCE, INNER, LABEL, TITLE, WRAPPER } from '../styles';

export const Exchange = (props: IExchangeProps) => {
  const getIcon = () => {
    if (props.code.includes('crypto-com')) {
      return exchangeIcons['crypto-com'];
    }

    const [name] = props.code.split('-');
    return exchangeIcons[(name || 'demo') as keyof IExchangeIcons];
  };
  
  return (
    <Stack sx={WRAPPER}>
      <Stack sx={INNER}>
        <Icon width={24}>
          {getIcon()}
        </Icon>

        <Title styles={TITLE}>
          {props.title}
        </Title>

        <Box sx={LABEL}>
          {props.label}
        </Box>
      </Stack>

      <Stack sx={BALANCE}>
        <Text>
          {formatter(props.balance)}
        </Text>

        <Text
          type='secondary'
        >
          USDT
        </Text>
      </Stack>
    </Stack>
  );
};
