import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const BACKGROUND: SxProps<Theme> = {
  width: '100%',
  minWidth: getResponsiveValue({
    min: 240,
    max: 320,
  }),
  border: '1px solid #727373',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, .25)',
  borderRadius: '16px',
  padding: '14px',
  backgroundColor: '#fff',
  gap: '24px',
};
