import { Box } from '@mui/material';
import { Avatar, Rate } from 'antd';
import { Shield } from 'shared/icons';
import { Text, Title } from 'shared/ui';
import { IReview } from '../interfaces';
import { avatar, description, header, rate, roleWrapper, text, title, userInner, userWrapper, wrapper } from '../styles';

export const Review = (props: IReview) => {
  const {
    userName,
    userRole,
    comment,
  } = props;

  return (
    <Box sx={wrapper}>
      <Box sx={header}>
        <Box sx={userWrapper}>
          <Avatar
            size='large'
            style={avatar}
          >
            {userName.charAt(0)}
          </Avatar>

          <Box sx={userInner}>
            <Title styles={title}>
              {userName}
            </Title>

            <Box sx={roleWrapper}>
              {Shield}

              <Text styles={text}>
                {userRole}
              </Text>
            </Box>
          </Box>
        </Box>

        <Rate
          value={5}
          disabled
          style={rate}
        />
      </Box>

      <Text styles={description}>
        {comment}
      </Text>
    </Box>
  );
};
