import { Bot } from 'entities/bot/model/types';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';

interface IGetBotsAccountsParams {
  exchanges: ExchangeAccount[];
  bots: Bot[];
}
export const getBotsAccounts = (params: IGetBotsAccountsParams) => {
  const accounts = params.exchanges.filter((account: ExchangeAccount) => {
    return params.bots.some((bot) => {
      return account.exchangeCode === bot.exchangeCode;
    });
  });
        
  return accounts;
};
