import { object, string } from 'yup';

export const validationScheme = (t: any, minInvestment: number) => {
  const scheme =  object().shape({
    exchange: string().required(t('validation.bot.update.exchange.required')),
    name: string().required(t('validation.bot.update.name.required')).max(20, t('validation.bot.update.name.more')),
    balance: string().required(t('validation.bot.update.balance.required')).test({
      name: 'check-balance',
      message: t('validation.bot.update.balance.less', {
        minInvestment,
      }),
      test: (value) => {
        return !(parseFloat(value) < +minInvestment);
      },
    }),
  });

  return scheme;
};
