import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const button: CSSProperties = {
  height: 'auto',
  padding: '12px 0',
  width: '100%',
  maxWidth: '291px',
  fontSize: getFontSize(14, 18),
  fontWeight: 500,
  lineHeight: '33px',
};
