import { TLanguage, TTranslation } from 'shared/types';

export const formFunds = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      funds: {
        price: {
          origin: {
            title: 'Order price',
            placeholder: '',
          },
          trigger: {
            title: 'Trigger price',
            placeholder: '',
            items: {
              last: 'Last',
              bid: 'Bid',
              ask: 'Ask',
            },
          },
        },
        units: {
          title: 'Units',
          placeholder: '',
        },
        total: {
          title: 'Total',
          placeholder: '',
        },
        button: 'Submit',
      },
    },
    ru: {
      funds: {
        price: {
          origin: {
            title: 'Ордер цена',
            placeholder: '',
          },
          trigger: {
            title: 'Триггер цена',
            placeholder: '',
            items: {
              last: 'Ласт',
              bid: 'Бид',
              ask: 'Аск',
            },
          },
        },
        units: {
          title: 'Количество',
          placeholder: '',
        },
        total: {
          title: 'Всего',
          placeholder: '',
        },
        button: 'Подтвердить',
      },
    },
  };

  return transaltion[type].funds;
};
