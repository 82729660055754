import { EnrichedTrade } from 'entities/terminal/model/types/terminal-schema';

// @TODO rewrite method to get correct value, fix logic
export const getTotalPnl = (filteredTrades: EnrichedTrade[], selectedSide: string): number => {
  let totalPnl = 0;
  const side = selectedSide;

  if (side === 'buy') {
    totalPnl = filteredTrades.reduce((sum, trade) => {
      const returnQuote = trade.returnQuote !== null  ? +trade.returnQuote : 0;
      return sum + returnQuote;
    }, 0);
  } else if (side === 'sell') {
    totalPnl = filteredTrades.reduce((sum, trade) => {
      const returnBase = trade.returnBase !== null ? +trade.returnBase : 0;
      return sum + returnBase;
    }, 0);
  } else {
    return 0;
  }
  
  return totalPnl;
};
  
// @TODO rewrite method to get correct value, fix logic
export const getUnrealizedPnl = (filteredTrades: EnrichedTrade[], selectedSide: string): number => {
  const validStatuses = ['new', 'request_completed', 'requested_cancelled', 'active', 'in_progress_update', 'failed'];
  const filteredByStatus = filteredTrades.filter(trade => validStatuses.includes(trade.status));
  let unrealizedPnl = 0;
  
  if (selectedSide === 'buy') {
    unrealizedPnl = filteredByStatus.reduce((sum, trade) => {
      const returnQuote = trade.returnQuote !== null ? +trade.returnQuote : 0;
      return sum + returnQuote;
    }, 0);
  } else if (selectedSide === 'sell') {
    unrealizedPnl = filteredByStatus.reduce((sum, trade) => {
      const returnBase = trade.returnBase !== null ? +trade.returnBase : 0;
      return sum + returnBase;
    }, 0);
  } else {
    return 0;
  }
  
  // return +currencyFormatter(unrealizedPnl, 2);
  return unrealizedPnl;
};
  
