import { Stack } from '@mui/material';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusGreen } from 'shared/icons';
import { MainButton, Switch } from 'shared/ui';
import { Slider, Wallet } from '../components';

export const Panel = (props: any) => {
  const {
    t, 
  } = useTranslation();

  return (
    <Stack
      gap='24px'
      maxWidth={{
        xs: '100%',
        sm: 300,
      }}
    >
      <Stack gap='18px'>
        <Segmented
          value={0}
          options={[{
            label: t('terminal.panel.switch.buy.spot'),
            value: 0,
          }, {
            label: t('terminal.panel.switch.sell.spot'),
            value: 1,
          }]}
          block={true}
          size='large'
        />

        <Wallet />

        <Segmented
          value={0}
          options={[{
            label: t('terminal.panel.segments.limit'),
            value: 0,
          }, {
            label: t('terminal.panel.segments.market'),
            value: 1,
            disabled: true,
          }]}
          block={true}
          size='middle'
          className='custom'
        />

        <Slider />
      </Stack>

      <Stack gap='16px'>
        <Switch
          suffixText={t('terminal.panel.additional.title')}
          size='small'
        />

        <Segmented
          value={0}
          options={[{
            label: t('terminal.panel.additional.segments.limit'),
            value: 0,
          }, {
            label: t('terminal.panel.additional.segments.conditional'),
            value: 1,
            disabled: true,
          }, {
            label: t('terminal.panel.additional.segments.market'),
            value: 1,
          }]}
          block={true}
          size='middle'
          className='custom'
        />

        <MainButton
          type='primary'
          ghost={true}
          icon={(
            <Stack>
              {PlusGreen}
            </Stack>
          )}
          size='middle'
          styles={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          disabled={true}
        >
          {t('terminal.panel.additional.button')}
        </MainButton>
      </Stack>

      <Stack gap='16px'>
        <Switch
          suffixText={t('terminal.panel.takeProfit.title')}
          size='small'
        />

        <Segmented
          value={0}
          options={[{
            label: t('terminal.panel.takeProfit.segments.limit'),
            value: 0,
            disabled: true,
          }, {
            label: t('terminal.panel.takeProfit.segments.market'),
            value: 1,
          }]}
          block={true}
          size='middle'
          className='custom'
        />

        <MainButton
          type='primary'
          ghost={true}
          icon={(
            <Stack>
              {PlusGreen}
            </Stack>
          )}
          size='middle'
          styles={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          disabled={true}
        >
          {t('terminal.panel.takeProfit.button.add')}
        </MainButton>
      </Stack>

      <Stack gap='16px'>
        <Switch
          suffixText={t('terminal.panel.stopLoss.title')}
          size='small'
        />

        <Segmented
          value={0}
          options={[{
            label: t('terminal.panel.stopLoss.segments.limit'),
            value: 0,
            disabled: true,
          }, {
            label: t('terminal.panel.stopLoss.segments.market'),
            value: 1,
          }]}
          block={true}
          size='middle'
          className='custom'
        />
      </Stack>

      <MainButton
        type='primary'
        size='large'
        onClick={props.openTerminal}
      >
        {t('terminal.introduction.button.open')}
      </MainButton>
    </Stack>
  );
};
