import { Stack } from '@mui/material';
import { Text } from 'shared/ui';

export const tooltip = (t: any) => {
  return (
    <Stack gap='8px'>
      <Text>
        {t('configurator.managment.custom.additional.tabs.tooltip.preset')}
      </Text>

      <Text>
        {t('configurator.managment.custom.additional.tabs.tooltip.alert')}
      </Text>
    </Stack>
  );
};
