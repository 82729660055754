import { Balance } from 'entities/exchange/model/types/currency-balance';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { getAccumulatedValue } from './get-accumulated-value';

export const getBalances = (exchanges: ExchangeAccount[]) => {
  const accumulatedBalances = exchanges.reduce((acc: Balance[], current: ExchangeAccount) => {
    current.balances.forEach(currentBalance => {
      const existingBalance = acc.find(item => item.symbol === currentBalance.symbol);
      
      if (existingBalance) {
        existingBalance.total = getAccumulatedValue(existingBalance.total, currentBalance.total);
        existingBalance.btc.total = getAccumulatedValue(existingBalance.btc.total, currentBalance.btc.total);
        existingBalance.usdt.total = getAccumulatedValue(existingBalance.usdt.total, currentBalance.usdt.total);
      } else {
        acc.push({
          ...currentBalance, 
        });
      }
    });
          
    return acc;
  }, []);

  return accumulatedBalances;
};
