import { TLanguage, TTranslation } from 'shared/types';

export const validationBot = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      bot: {
        update: {
          exchange: {
            required: 'Choose account',
          },
          name: {
            required: 'Please input the bot name',
            more: 'Max is 20 characters',
          },
          balance: {
            required: 'Input trading balance',
            less: 'Min. balance is {{minInvestment}}',
          },
        },
      },
    },
    ru: {
      'bot': {
        'update': {
          'exchange': {
            'required': 'Выберите аккаунт',
          },
          'name': {
            'required': 'Введите название',
            'more': 'Макс. 20 символов',
          },
          'balance': {
            'required': 'Введите сумму',
            'less': 'Мин. {{minInvestment}}',
          },
        },
      },
    },
  };

  return transaltion[type].bot;
};
