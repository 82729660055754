import { Fragment } from 'react/jsx-runtime';
import { Route, useLocation } from 'react-router-dom';
import { PageWrapper } from 'widgets';
import { ProtectedRoute } from 'app/providers/router/ui/protected-route';
import SuspenseWrapper from 'app/providers/router/ui/suspense-wrapper';
import { appRoutes } from '../consts';

export const AppRoutes = () => {
  const location = useLocation();

  return (
    <Fragment>
      {appRoutes(location.pathname).map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={(
              <SuspenseWrapper>
                <ProtectedRoute>
                  <PageWrapper title={`${route.title} - Skyrexio`}>
                    {route.element}
                  </PageWrapper>
                </ProtectedRoute>
              </SuspenseWrapper>
            )}
          />
        );
      })}
    </Fragment>
  );
};
