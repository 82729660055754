import { TLanguage, TTranslation } from 'shared/types';

export const modalShareResult = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      shareResult: {
        title: 'Share your trade',
        pair: 'Trading pair',
        roi: 'ROI',
        entry: 'Entry',
        current: 'Current',
        qrcode: 'Referral code:',
        subText: 'Win with Skyrexio.com',
        button: 'Download',
        close: 'Close',
      },
    },
    ru: {
      shareResult: {
        title: 'Поделиться сделкой',
        pair: 'Торговая пара',
        roi: 'ROI',
        entry: 'Вход',
        current: 'Сейчас',
        qrcode: 'Реф. код:',
        subText: 'Побеждай со Skyrexio.com',
        button: 'Скачать',
        close: 'Выход',
      },
    },
  };

  return transaltion[type].shareResult;
};
