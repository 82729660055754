import { TLanguage, TTranslation } from 'shared/types';

export const header = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      header: {
        menu: {
          home: 'Home',
          accounts: 'Accounts',
          terminal: {
            title: 'Terminal',
            items: {
              terminal: {
                title: 'Smart terminal',
                text: 'Create and manage advanced positions',
              },
              diary: {
                title: 'Trading diary',
                text: 'Track and export your statistic',
              },
              introduction: {
                title: 'Introduction',
                text: 'Explore benefits of Smart terminal',
              },
            },
          },
          bots: {
            title: 'Bots',
            items: {
              all: {
                title: 'My Bots',
                text: 'Aggregate statistic and bots list',
              },
              diary: {
                title: 'Trading diary',
                text: 'Track and export your statistic',
              },
              marketplace: {
                title: 'Marketplace',
                text: 'Copy top performing bots',
              },
              public: {
                title: 'My public bots',
                text: 'Track sales of your shared bots',
              },
              introduction: {
                title: 'Introduction',
                text: 'Explore benefits of trading bots',
              },
            },
            button: 'New bot',
          },
          subscriptions: 'Subcriptions',
          invite: 'Invite friends',
        },
        button: 'Product tour',
        switch: 'Demo',
        popup: {
          message: 'Up to $4,000 Prize! Connect Gate.io NOW!',
          twoFactor: '2FA authentication',
          changePassword: 'Change password',
          logOut: 'Log out',
          links: [
            'Rate us',
            'Chat',
            'Suggest feature',
            'Knowledge base',
          ],
          terms: 'Terms',
          and: 'and',
          policy: 'Privacy Policy',
        },
      },
    },
    ru: {
      header: {
        menu: {
          home: 'Главная',
          accounts: 'Аккаунты',
          terminal: {
            title: 'Терминал',
            items: {
              terminal: {
                title: 'Смарт-терминал',
                text: 'Создавайте и управляйте продвинутыми позициями',
              },
              diary: {
                title: 'Торговый дневник',
                text: 'Отслеживайте и экспортируйте статистику',
              },
              introduction: {
                title: 'Введение',
                text: 'Изучите преимущества Смарт-терминала',
              },
            },
          },
          bots: {
            title: 'Боты',
            items: {
              all: {
                title: 'Мои боты',
                text: 'Сводная статистика и список ботов',
              },
              diary: {
                title: 'Торговый дневник',
                text: 'Отслеживайте и экспортируйте статистику',
              },
              marketplace: {
                title: 'Маркетплейс',
                text: 'Копируйте лучшие стратегии',
              },
              public: {
                title: 'Мои публичные боты',
                text: 'Следите за продажами своих ботов',
              },
              introduction: {
                title: 'Введение',
                text: 'Узнайте о возможностях ботов',
              },
            },
            button: 'Новый бот',
          },
          subscriptions: 'Подписки',
          invite: 'Пригласить друзей',
        },
        button: 'Тур по платформе',
        switch: 'Демо',
        popup: {
          message: 'Подключи Gate.io прямо сейчас! Приз до $4,000!',
          twoFactor: '2FA аутентификация',
          changePassword: 'Изменить пароль',
          logOut: 'Выйти',
          links: ['Оцените нас', 'Чат', 'Предложить идею', 'База знаний'],
          terms: 'Условия',
          and: 'и',
          policy: 'Политика конфиденциальности',
        },
      },
    },
  };

  return transaltion[type].header;
};
