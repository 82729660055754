import { useFormContext } from 'react-hook-form';

export const useLeverageInputsLogic = () => {
  const {
    setValue,
  } = useFormContext();
  
  const handleLeverageSliderChange = (value: number) => {
    
    setValue('leverageSlider', value);
    setValue('leverageInput', value);
  };
  
  const handleLeverageInputChange = (value: number) => {
    setValue('leverageInput', value);
    setValue('leverageSlider', value);
  };
  
  const handleLeverageTypeChange = (value: string) => {
    setValue('leverageType', value);
  };
  
  return {
    handleLeverageSliderChange,
    handleLeverageInputChange,
    handleLeverageTypeChange,
  };
};
