import { SxProps, Theme } from '@mui/material';

export const CARDS: SxProps<Theme> = {
  maxHeight: (420 * 2) + 30,
  overflowX: 'hidden',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(318px, 1fr))',
  gap: '30px 20px',
  padding: {
    xs: '4px',
    md: '20px',
  },
};
