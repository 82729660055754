import { Hidden, Stack } from '@mui/material';
import { useGetMenu } from '../hooks';
import { WRAPPER } from '../styles';

export const Horizontal = () => {
  const menu = useGetMenu();

  return (
    <Hidden lgDown={true}>
      <Stack sx={WRAPPER}>
        {menu()}
      </Stack>
    </Hidden>
  );
};
