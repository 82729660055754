import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import { Actions } from 'pages/trading-bots/configurator-v2/components';
import { getBotStatistic } from 'pages/trading-bots/my-bot/api/bot-statistic-api';
import { FormEvent, Fragment, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Label, Tabs } from 'widgets';
import { defaultDates } from 'shared/consts';
import { delay } from 'shared/helpers/delay';
import { getCapitalizedExchangeTitle } from 'shared/helpers/get-capitalized-exchange-title';
import { BotEligible, MultipleSelect, Text } from 'shared/ui';
import { publishBot } from '../api';
import { Amount, Author, Description, Name, Profit } from '../components';
import { IPublicBotParameters, IPublishFormParams } from '../interfaces';
import { scheme } from '../schema';
import { modal, modalChildren, TITLE, WRAPPER } from '../styles';

export const PublicBotParameters = (props: IPublicBotParameters) => {
  const {
    isOpen,
    closeHandler,
    bots,
    setChooseMarketModal,
    refetchBots,
    existsBots,
  } = props;

  const {
    t, 
  } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(scheme(t)),
  });

  const [selectedBots, setSelectedBots] = useState<string[]>([]);
  const [statistic, setStatistic] = useState<any>(null);

  const closeModalHandler = () => {
    closeHandler();
    setChooseMarketModal(false);
  };

  const chooseBotHandler = async (value: string[]) => {
    if (!value.length) {
      setSelectedBots([]);
      return;
    }

    const bot = bots.find((bot) => {
      return bot.botUuid === value.at(-1);
    });
    if (!bot) {
      return;
    }

    const botsPlatforms: any[] = [];
    for (let i = 0; i < selectedBots.length; i++) {
      const currentBot = bots.find((bot) => {
        return bot.botUuid === selectedBots[i];
      });
      if (!currentBot) {
        return;
      }
      
      if (currentBot.botUuid === bot.botUuid) {
        setSelectedBots((prev) => {
          return prev.filter((selectedBot) => {
            return selectedBot !== bot.botUuid;
          });
        });
        return;
      }
      
      if (currentBot.exchangeCode === bot.exchangeCode) {
        botsPlatforms.push(currentBot);
        continue;
      }
    }

    if (botsPlatforms.length) {
      toast.error('Bot with the same exchange is already selected');
      return;
    }

    const response = await getBotStatistic({
      botUuid: bot.botUuid,
      dates: defaultDates,
    });

    if (!response) {
      return;
    }

    const botCreatedDateDiff= dayjs().diff(bot.createdDate, 'day');
    const firstCondition = response.roi >= 1 && response.totalTrades >= 10;
    const secondCondition = response.maxDrawdown >= -15 && botCreatedDateDiff >= 30;
    if (firstCondition && secondCondition) {
      setSelectedBots(value);
      return;
    }

    setStatistic({
      ...response,
      days: botCreatedDateDiff,
    });
  };

  const deleteBots = () => {
    const deletedBots = existsBots?.filter((existsBot) => {
      return !selectedBots.includes(existsBot.botUuid);
    });
    for (let i = 0; i < (deletedBots || []).length; i++) {
      const bot = deletedBots[i];
      
      const data: any = {
        botUuid: bot,
        minInvestment: null,
        itemName: null,
        description: null,
        author: null,
        isDeleted: true,
        copyCommissionPercent: null,
      };
      
      new Promise(() => {
        return publishBot(data);
      });
    }
  };

  const publishHandler = async (values?: IPublishFormParams) => {
    if (!values) {
      return;
    }
    
    deleteBots();

    for (let i = 0; i < selectedBots.length; i++) {
      const bot = selectedBots[i];
      
      const data: any = {
        botUuid: bot,
        minInvestment: +values.amount,
        itemName: values.name,
        description: values.description,
        author: values.author,
      };

      if (values.profit) {
        data.copyCommissionPercent = +values.profit;
      }
      
      new Promise(() => {
        return publishBot(data);
      });
    }

    await delay(700);
    refetchBots();
    closeModalHandler();
  };

  const options = useCallback(() => {
    if (!bots?.length) {
      return [];
    }

    return bots.map((bot) => {
      const option = {
        label: bot.botName || bot.name,
        value: bot.botUuid,
        placeholder: `${bot.botName || bot.name}_${bot.exchangeCode}`,
      };

      return option;
    });
  }, [bots]);

  const optionRender = (option: any) => {
    const [name, code] = option.data.placeholder.split('_');

    return (
      <Stack
        key={`${option.label}_${option.value}_${Math.random()}`}
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: 1,
          width: '100%',
          pr: '8px',
        }}
      >
        <Text type='success'>
          {name}
        </Text>

        <Text type='success'>
          {getCapitalizedExchangeTitle(code)}
        </Text>
      </Stack>
    );
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    methods.handleSubmit(publishHandler)(event);
  };

  const getActions = () => {
    if (existsBots?.length) {
      return (
        <Actions
          button={{
            title: t('modal.bots.parameters.actions.save'),
            action: () => {},
          }}
        />
      );
    }

    return (
      <Actions
        handleBack={() => {
          closeHandler();
        }}
        button={{
          title: t('modal.bots.parameters.actions.publish'),
          action: () => {},
        }}
      />
    );
  };

  useEffect(() => {
    if (!isOpen) {
      methods.reset();
      return;
    }

    const currentBot = bots.find((bot) => {
      return bot.botUuid === existsBots[0]?.botUuid;
    });
    if (!currentBot) {
      return;
    }

    methods.setValue('amount', currentBot.minInvestment);
    methods.setValue('name', currentBot.itemName || currentBot.name);
    methods.setValue('description', currentBot.description || '');
    methods.setValue('author', currentBot.author || '');
    methods.setValue('profit', currentBot.copyCommissionPercent?.toString() || '');
    
    setSelectedBots(existsBots.map((bot) => {
      return bot.botUuid;
    }));
  }, [existsBots, isOpen]);

  return (
    <Fragment>
      <Modal
        open={isOpen}
        footer={null}
        style={modal}
        styles={modalChildren}
        closable={true}
        destroyOnClose={true}
        onCancel={closeModalHandler}
        width='100%'
        centered={true}
      >
        <FormProvider {...methods}>
          <Stack
            component='form'
            onSubmit={onSubmit}
          >
            <Stack sx={WRAPPER}>
              <Text styles={TITLE}>
                {t('modal.bots.parameters.title')}
              </Text>

              <Stack
                sx={{
                  gap: '18px',
                  width: '100%',
                  maxWidth: 387,
                }}
              >
                <MultipleSelect
                  label={(
                    <Label
                      title={t('modal.bots.parameters.bots.title')}
                    />
                  )}
                  options={options()}
                  select={{
                    value: selectedBots,
                    placeholder: t('modal.bots.parameters.bots.placeholder'),
                    onChange: chooseBotHandler,
                  }}
                  optionRender={optionRender}
                />

                <Amount />
                <Name />
                <Description />
                <Author />
              </Stack>

              <Tabs
                defaultValue={methods.getValues('profit') ? 0 : 1}
                tabs={[{
                  label: t('modal.bots.parameters.segments.paid'),
                  value: 0,
                }, {
                  label: t('modal.bots.parameters.segments.free'),
                  value: 1,
                }]}
                getTab={(tab) => {
                  if (tab === 1) {
                    // setProfit('');
                    methods.setValue('profit', '');
                  }
                }}
                content={[(<Profit />), null]}
                styles={{
                  width: '100%',
                  maxWidth: 387,
                  justifyContent: 'center',
                }}
              />

              {getActions()}
            </Stack>
          </Stack>
        </FormProvider>
      </Modal>

      <BotEligible
        isOpened={!!statistic}
        closeHandler={() => {
          setStatistic(null);
        }}
        botStatistic={statistic}
      />
    </Fragment>
  );
};
