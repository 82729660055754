import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { enLocalization, ruLocalization } from 'shared/consts/localization';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: enLocalization,
      ru: ruLocalization,
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', () => {
  dayjs.locale(i18n.language);
});
export default i18n;
