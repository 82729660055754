import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getSubscription = async () => {
  const userId = getSkyrexUuid();

  const response = await axios.request({
    url: '/subscription/getByFilter',
    data: {
      table: 'skyrex_subscriptions',
      skyrexUserUuids: [userId],
      'statuses': ['active'],
    },
  });

  return response.data?.data?.subscriptions;
};
