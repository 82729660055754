import { Stack } from '@mui/material';
import { getIs2faEnabled } from 'entities/user/model/selectors/get-is2faEnabled/get-is2faEnabled';
import { Dispatch, SetStateAction, useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Colors } from 'shared/consts/colors';
import { Locker } from 'shared/icons';
import { EmptySubscription, Icon, Switch, TwoFactorDisable, TwoFactor as TwoFactorModal } from 'shared/ui';
import { ListItem } from '../../../../list-item';
import { WRAPPER } from '../styles';

export const TwoFactor = () => {
  const [faModalOpen, setFaModalOpen] = useState<boolean>(false);
  const [twoFactorDisableModalOpen, setTwoFactorDisableModalOpen] = useState<boolean>(false);
  const [twoFactorResetModalOpen, setTwoFactorResetModalOpen] = useState<boolean>(false);
  
  const is2faEnabled = useSelector(getIs2faEnabled);
  
  const {
    t, 
  } = useTranslation();

  const modalStateHabdler = (action: Dispatch<SetStateAction<boolean>>) => {
    return () => {
      action((prev) => !prev);
    };
  };

  const changeModeHandler = (checked: boolean) => {
    if (checked) {
      setFaModalOpen(checked);
      return;
    } 
      
    setTwoFactorDisableModalOpen(true);
  };
  

  return (
    <Fragment>
      <Stack sx={WRAPPER}>
        <ListItem
          title={t('header.popup.twoFactor')}
          icon={(
            <Icon width={16}>
              {Locker}
            </Icon>
          )}
        />

        <Switch
          value={is2faEnabled}
          onChange={changeModeHandler}
        />
      </Stack>

      <TwoFactorModal
        isOpen={faModalOpen}
        onClose={modalStateHabdler(setFaModalOpen)}
      />
          
      <TwoFactorDisable
        isOpen={twoFactorDisableModalOpen}
        onClose={modalStateHabdler(setTwoFactorDisableModalOpen)}
        handleSetTwoFactorResetModalOpen={modalStateHabdler(setTwoFactorResetModalOpen)}
      />

      <EmptySubscription
        isOpen={twoFactorResetModalOpen}
        handleClose={modalStateHabdler(setTwoFactorResetModalOpen)}
        modalTitle={t('error.otp.ownership.title')}
        modalDescription={(
          <>
            {t('error.otp.ownership.message.preffix')}
            {' '}
            <a 
              style={{
                color: Colors.GREEN, 
                textDecoration: 'underline', 
              }} 
              href='mailto:info@skyrexio.com'
            > 
              info@skyrexio.com
            </a>
            {' '}
            {t('error.otp.ownership.message.suffix')}
          </>
        )}
        button={{
          title: t('error.otp.ownership.button'),
          action: modalStateHabdler(setTwoFactorResetModalOpen),
        }}
      />
    </Fragment>
  );
};
