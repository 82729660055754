import { TLanguage, TTranslation } from 'shared/types';

export const marketplace = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      marketplace: {
        title: 'Ready-made bots to automate your trading instantly',
        select: {
          exchange: {
            title: 'Exchange',
            placeholder: 'Select exchange',
            items: {
              all: 'All',
            },
          },
          market: {
            title: 'Market',
            placeholder: 'Select market',
            items: {
              all: 'All',
            },
          },
          roi: {
            title: 'Minimum ROI',
            placeholder: 'For example: 1',
          },
          days: {
            title: 'Minimum days running',
            placeholder: 'For example: 30',
          },
        },
        card: {
          available: 'Available on:',
          roi: 'ROI',
          winRate: 'Win rate',
          drawdown: 'Max drawdown',
          runtime: 'Runtime',
          investment: 'Min investment',
          free: 'Fee',
          button: {
            copy: 'Copy bot',
            view: 'View bot',
          },
        },
      },
    },
    ru: {
      marketplace: {
        title: 'Готовые боты для мгновенной автоматизации торговли',
        select: {
          exchange: {
            title: 'Биржа',
            placeholder: 'Выберите биржу',
            items: {
              all: 'Все',
            },
          },
          market: {
            title: 'Рынок',
            placeholder: 'Выберите рынок',
            items: {
              all: 'Все',
            },
          },
          roi: {
            title: 'Мин. ROI',
            placeholder: 'Напр.: 1',
          },
          days: {
            title: 'Мин. дней работы',
            placeholder: 'Напр.: 30',
          },
        },
        card: {
          available: 'Доступно на:',
          roi: 'ROI',
          winRate: 'Успешных',
          drawdown: 'Макс. просадка',
          runtime: 'В работе',
          investment: 'Мин. вложение',
          free: 'Комиссия',
          button: {
            copy: 'Копировать',
            view: 'Посмотреть',
          },
        },
      },
    },
  };

  return transaltion[type].marketplace;
};
