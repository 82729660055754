import { Box } from '@mui/material';
import { ConfigProvider, DatePicker } from 'antd';
import enUS from 'antd/locale/en_US';
import ruRU from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/ui';
import { IRangePicker } from '../interfaces';
import { labelText, RANGE, wrapper } from '../styles';

export const RangePicker = (props: IRangePicker) => {
  const {
    label,
    value,
    handleSetNewDate,
    size,
    maxWidth,
    style,
    disabledFutureDate = true,
  } = props;

  const {
    t,
    i18n,
  } = useTranslation();
  
  const handleDateChange = (dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    if ((dates?.[0] || dates?.[1])! < dayjs('01-01-2023', 'DD-MM-YYYY')) {
      return;
    }
    
    if (dates && dates[1]) {
      const adjustedDates: [dayjs.Dayjs | null, dayjs.Dayjs | null] = [dates[0], dates[1].endOf('day')];
      handleSetNewDate?.(adjustedDates);
    } else {
      handleSetNewDate?.(dates);
    }
  };
  
  const disabledDate = (current: dayjs.Dayjs) => {
    if (!disabledFutureDate) {
      return false;
    }

    return current && current > dayjs().endOf('day');
  };

  const panelRender = (originPanel: ReactNode) => {
    return (
      <Box sx={wrapper()}>
        {originPanel}
      </Box>
    );
  };
  
  return (
    <ConfigProvider locale={i18n.language === 'ru' ? ruRU : enUS}>
      <Box sx={wrapper(maxWidth)}>
        {label && (
          <Text
            type='secondary'
            styles={labelText}
          >
            {label}
          </Text>
        )}

        <DatePicker.RangePicker
          value={value}
          picker='date'
          size={size || 'middle'}
          separator='-'
          panelRender={panelRender}
          disabledDate={disabledDate}
          placeholder={[t('form.date.start'), t('form.date.end')]}
          popupAlign={{
            points: [
              'tl',
              'bl',
            ],
            offset: [
              0,
              8,
            ],
          }}
          format={{
            format: 'DD-MM-YYYY',
            type: 'mask',
          }}
          onChange={handleDateChange}
          style={RANGE(style)}
          minDate={dayjs('01-01-2023', 'DD-MM-YYYY')}
        />
      </Box>
    </ConfigProvider>
  );
};
