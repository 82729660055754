import { Fragment } from 'react/jsx-runtime';
import { Route } from 'react-router-dom';
import { GuestRoute } from 'app/providers/router/ui/guest-route';
import SuspenseWrapper from 'app/providers/router/ui/suspense-wrapper';
import { authRoutes } from '../consts';

export const AuthRoutes = () => {
  return (
    <Fragment>
      {authRoutes().map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={(
              <SuspenseWrapper>
                <GuestRoute>
                  {route.element}
                </GuestRoute>
              </SuspenseWrapper>
            )}
          />
        );
      })}
    </Fragment>
  );
};
