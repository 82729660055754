import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { TSide } from 'shared/types';
import { Bot } from '../model/types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface IGetMyBotsParams {
  accounts: string[];
  type?: TSide;
}
export const getMyBots = async (params: IGetMyBotsParams) => {
  try {
    const data: any = {
      skyrexUserUuids: [getSkyrexUuid()!], 
      exchangeAccountUuids: params.accounts, 
      statuses:['on', 'off'],
    };

    if (params.type) {
      data.strategy = params.type.toUpperCase();
    }

    const response = await axios.request({
      url: '/bot/getByFilterBot',
      data,
    });
  
    return response.data.data.bots as Bot[];
  } catch (error) {
    return [];
  }
};
