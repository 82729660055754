import { TLanguage, TTranslation } from 'shared/types';

export const validationPassword = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      password: {
        required: 'Please input your Password!',
        format: 'Password should contain a minimum of 6 characters.',
      },
    },
    ru: {
      password: {
        required: 'Введите пароль!',
        format: 'Пароль не короче 6 символов.',
      },
    },
  };

  return transaltion[type].password;
};
