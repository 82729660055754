import { useEffect, useState } from 'react';
import { ChooseExchange } from 'widgets';
import { getPlatform } from 'widgets/exchanges/helpers';
import { TType } from 'shared/types';

export const ConnectExchangeApiKeysPage = () => {
  const [platform, setPlatform] = useState<string>('');
  const [type, setType] = useState<TType>('spot');

  const handleChoose = (platform: string) => {
    setPlatform(platform);
  };

  const onChangeType = (type: string) => {
    setType(type as TType);
  };

  const returnToAllExchanges = () => {
    setPlatform('');
  };
  
  useEffect(() => () => {
    setPlatform('');
  }, []);

  if (!platform) {
    return (
      <ChooseExchange
        onChoose={handleChoose}
        onChangeType={onChangeType}
        type={type}
      />
    );
  }

  return getPlatform({
    platform,
    isModalOpen: false,
    returnToAllExchanges,
    type,
  });
};
