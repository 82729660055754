import createAxiosInstance from 'shared/api/axios/axios';
import { fetchPaginatedSmartTrades } from 'shared/api/axios/helpers/fetch-paginated';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface GetBotDealsFromTerminalParams {
  smartTradeUuids: string[];
}

export const getBotDealsFromTerminal = async (params: GetBotDealsFromTerminalParams) => {
  const {
    smartTradeUuids, 
  } = params;
  
  if (!smartTradeUuids?.length) {
    return [];
  }

  const endPoint = 'smartTrade/getByFilterTerminal';
  
  const requestData: any = {
    smartTradeUuids: smartTradeUuids,
    page: 1,
  };
  
  return fetchPaginatedSmartTrades(axios, endPoint, requestData);
};
