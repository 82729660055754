import { TLanguage, TTranslation } from 'shared/types';

export const successLogin = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      login: {
        title: 'Account is activated',
        message: 'You can now log in to your account',
      },
    },
    ru: {
      login: {
        title: 'Аккаунт активирован',
        message: 'Теперь можно войти в аккаунт',
      },
    },
  };

  return transaltion[type].login;
};
