import { IRiskManagmentClose } from 'entities/risk-managment-close/interfaces';

export const defaultCloseStepValues = () => {
  const defaultValues: IRiskManagmentClose = {
    isOpened: true,
    segment: {
      value: 'preset',
      preset: {
        takeProfit: {
          isOpened: true,
          orders: 1,
          priceChange: 5,
          orderSizeScale: 1,
          orderPriceChangeScale: 1,
          orderType: 'market',
        },
        stopLoss: {
          isOpened: true,
          priceChange: -5,
          orderType: 'market',
        },
      },
      alert: {
        orderType: 'market',
      },
    },
  };

  return defaultValues;
};
