import createAxiosInstance from 'shared/api/axios/axios';
import { fetchPaginatedSmartTrades } from 'shared/api/axios/helpers/fetch-paginated';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getDatePair } from 'shared/helpers';
import { TDates } from 'shared/types';
import { ActiveDeal } from '../model/types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface IBotGetPublicDealsParams {
  botUuid: string;
  tradingHistoryDates: TDates;
}
export const botGetPublicDeals = async (params: IBotGetPublicDealsParams): Promise<ActiveDeal[]> => {  
  const {
    botUuid,
    tradingHistoryDates,
  } = params;

  const endPoint = 'bot/getPublicBotTradesLandingMarketplace';

  const closedDates = getDatePair(tradingHistoryDates);

  const requestData = {
    botUuid,
    closedDateFrom: closedDates.start,
    closedDateTo: closedDates.end,
    page: 1,
  };
    
  return fetchPaginatedSmartTrades(axios, endPoint, requestData) as never;
};
