import { TLanguage, TTranslation } from 'shared/types';

export const validationWithdraw = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      withdraw: {
        amount: {
          required: 'Amount is required',
          number: 'Should be number',
          greater: 'Should be greater than 10 USDT',
          lower: 'Should be lower or equal than available',
        },
        wallet: {
          required: 'Wallet is required',
          existing: 'Your TRC-20 wallet address',
        },
      },
    },
    ru: {
      withdraw: {
        amount: {
          required: 'Сумма обязательна',
          number: 'Должно быть число',
          greater: 'Должно быть больше 10 USDT',
          lower: 'Не должно превышать доступное',
        },
        wallet: {
          required: 'Необходим адрес кошелька',
          existing: 'Ваш TRC-20 кошелек',
        },
      },
    },
  };

  return transaltion[type].withdraw;
};
