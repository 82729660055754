import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const background: SxProps<Theme> = {
  padding: getResponsiveValue({
    min: 24,
    max: 40,
  }),
  backgroundColor: '#F8FBF9',
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: '44px',
  flex: 1,
  width: '100%',
  maxWidth: '100%',
  position: 'relative',
};
