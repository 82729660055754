import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const FORM_WRAPPER: SxProps<Theme> = {
  placeItems: 'center',
  justifyContent: 'center',
  flex: 1,
  padding: getResponsiveValue({
    min: 16,
    max: 24,
  }),
  '&>form': {
    width: '100%',
    maxWidth: 504,
    flex: 1,
    display: 'flex',
  },
};
