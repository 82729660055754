import { SxProps, Theme } from '@mui/material';

export const background: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  position: 'relative',
  borderRadius: 2,
  gap: '10px',
};
