import { TLanguage, TTranslation } from 'shared/types';

export const errorFunds = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      funds: {
        copy: 'Failed to copy link',
      },
    },
    ru: {
      funds: {
        copy: 'Не удалось скопировать ссылку',
      },
    },
  };

  return transaltion[type].funds;
};
