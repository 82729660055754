import { TLanguage, TTranslation } from 'shared/types';

export const unknown = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      unknown: {
        title: 'Oh no! What have you done? \n You broke Skyrexio!',
        description: 'It’s a joke! Everything is fine but the page \n you were looking for can’t be found',
        back: 'Let’s go back to our Main page!',
        button: 'Go to the main page',
      },
    },
    ru: {
      unknown: {
        title: 'О нет! Что вы наделали? \n Вы сломали Skyrexio!',
        description: 'Шутка! Все в порядке, но страница, которую вы ищете не найдена',
        back: 'Давайте вернемся на Главную страницу!',
        button: 'Вернуться на Главную',
      },
    },
  };

  return transaltion[type].unknown;
};
