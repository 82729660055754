import { IStepProps } from 'pages/trading-bots/configurator-v2/interfaces';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'widgets';
import { Additional, Auto, Close } from '../tabs';

export const ConfigureRiskManagment = memo((props: IStepProps) => {
  const {
    watch,
    setValue,
  } = useFormContext();
  
  const auto = watch('auto');
  const segment = auto.segment;
  const {
    t, 
  } = useTranslation();

  const changeSegmentHandler = (value: number) => {
    auto.segment = value ? 'custom' : 'auto';
    setValue('auto', auto);

    if (!value) {
      props.changeCurrentStep?.(2);
    }
  };

  const getContent = () => {
    if (props.currentStep === 2) {
      return (
        <Additional
          handleBack={props.handleBack}
          changeCurrentStep={props.changeCurrentStep}
        />
      );
    }

    return (
      <Close
        handleBack={props.handleBack}
        createBot={props.createBot}
        changeCurrentStep={props.changeCurrentStep}
        currentStep={props.currentStep}
      />
    );
  };

  return (
    <Tabs
      defaultValue={segment === 'auto' ? 0 : 1}
      tabs={[{
        label: t('configurator.managment.tabs.auto'),
        value: 0,
      }, {
        label: t('configurator.managment.tabs.custom'),
        value: 1,
      }]}
      content={[(
        <Auto
          handleBack={props.handleBack!}
          createBot={props.createBot!}
        />
      ), getContent()]}
      styles={{
        alignItems: 'flex-start',
      }}
      maxWidth='400px'
      segmentStyles={{
        alignSelf: 'flex-start',
      }}
      getTab={changeSegmentHandler}
      label={{
        value: t('configurator.managment.tabs.label.title'),
        tooltiop: t('configurator.managment.tabs.label.tooltip'),
      }}
    />
  );
});

ConfigureRiskManagment.displayName = 'ConfigureRiskManagment';
