import { TLanguage, TTranslation } from 'shared/types';

export const source = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      source: {
        tabs: {
          indicator: 'Indicator',
          strategy: 'Strategy',
          custom: 'Custom',
          label: {
            title: 'Choose entry order alert source',
            tooltip: '',
          },
        },
        indicator: {
          url: {
            title: 'Webhook URL',
            description: 'Copy and paste this URL into your Alert notifications tab on',
          },
          message: {
            title: 'Alert message',
            description: 'Copy and paste this text into alert message on',
          },
        },
        strategy: {
          function: {
            title: 'Entry function parameter',
            description: 'Add the parameter to your PineScript strategy entry function on',
          },
          sccipt: {
            title: 'PineScript parameter',
            description: 'AAdd the parameter to your strategy exit function on',
          },
        },
        custom: {
          url: {
            title: 'Webhook URL',
            description: 'Send a POST request with JSON on this adress',
          },
          json: {
            title: 'JSON body to execute bot action',
            description: 'Configure a POST request with JSON body to execute base entry',
          },
          jsonEntry: {
            title: 'JSON body to execute base entry',
            description: 'Configure a POST request with JSON body to execute base entry',
          },
        },
        basic: {
          title: 'Execute bot actions by sending JSON payload to webhook URL with your app or manually',
          link: 'How to use custom alerts',
          headers: {
            field: 'Field',
            type: 'Type',
            description: 'Description',
          },
          items: {
            base: 'Symbol to trade for example ADA',
            quote: 'Market of your bot for example USDT',
            position: '"entry1", "entry2", etc. for entries, "close" for closure',
            price: 'Execution price, for Market orders use current price',
            sourceUuid: 'Unique identifier of your bot, see below',
            secretToken: 'Protection token for your bot, see below',
            timestamp: 'Current time in UNIX format',
          },
        },
      },
    },
    ru: {
      source: {
        tabs: {
          indicator: 'Индикатор',
          strategy: 'Стратегия',
          custom: 'Своё',
          label: {
            title: 'Выберите источник алерта для ордера входа',
            tooltip: '',
          },
        },
        indicator: {
          url: {
            title: 'Webhook URL',
            description:
              'Скопируйте и вставьте в окно настройки уведомлений на',
          },
          message: {
            title: 'Alert message',
            description:
              'Скопируйте и вставьте этот текст в сообщение алерта на',
          },
        },
        strategy: {
          function: {
            title: 'Параметр функции entry',
            description:
              'Добавьте этот параметр в функцию входа PineScript на',
          },
          sccipt: {
            title: 'Параметр PineScript',
            description:
              'Добавьте этот параметр в функцию выхода PineScript на',
          },
        },
        custom: {
          url: {
            title: 'Webhook URL',
            description:
              'Отправьте POST-запрос с JSON на этот адрес',
          },
          json: {
            title: 'JSON для действия бота',
            description:
              'Настройте POST-запрос с JSON, чтобы выполнить базовый вход',
          },
          jsonEntry: {
            title: 'JSON для выполнения базового входа',
            description:
              'Настройте POST с JSON для выполнения базового входа',
          },
        },
        basic: {
          title:
            'Выполняйте действия бота, отправляя JSON на webhook URL из своего приложения или вручную',
          link: 'Как использовать кастомные алерты',
          headers: {
            field: 'Поле',
            type: 'Тип',
            description: 'Описание',
          },
          items: {
            base: 'Торговый символ, напр. ADA',
            quote: 'Рынок бота, напр. USDT',
            position: '"entry1", "entry2" и т.д. для входа, "close" для выхода',
            price: 'Цена исполнения (для Market-ордеров текущая)',
            sourceUuid: 'Уникальный идентификатор бота (см. ниже)',
            secretToken: 'Токен защиты бота (см. ниже)',
            timestamp: 'Текущее время в UNIX формате',
          },
        },
      },
    },
  };

  return transaltion[type].source;
};
