import { SxProps, Theme } from '@mui/material';

export const background: SxProps<Theme> = {
  width: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  maxWidth: 894,
  borderRadius: 4,
  background: '#F8FBF9',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px 20px',
};
