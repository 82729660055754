import { TLanguage, TTranslation } from 'shared/types';

export const modalConfirmCreateBot = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      confirmCreateBot: {
        name: {
          title: 'Name your bot',
          placeholder: 'My perfect bot',
        },
        button: 'Confirm',
      },
    },
    ru: {
      confirmCreateBot: {
        name: {
          title: 'Назовите бота',
          placeholder: 'Мой супер бот',
        },
        button: 'Подтвердить',
      },
    },
  };

  return transaltion[type].confirmCreateBot;
};
