import { TLanguage, TTranslation } from 'shared/types';

export const modalTwoFactorDisable = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      twoFactorDisable: {
        title: 'Disable 2FA',
        otp: {
          title: 'Enter OTP  to confirm',
          placeholder: '',
        },
        button: {
          reset: 'Reset 2FA',
          ok: 'Okay',
        },
      },
    },
    ru: {
      twoFactorDisable: {
        title: 'Отключить 2FA',
        otp: {
          title: 'Введите OTP для подтверждения',
          placeholder: '',
        },
        button: {
          reset: 'Сбросить 2FA',
          ok: 'Ок',
        },
      },
    },
  };

  return transaltion[type].twoFactorDisable;
};
