import { Balance } from 'entities/exchange/model/types/currency-balance';
import { getOverallShare } from './get-overall-share';

export interface PieDataMapperProps {
  overallBalances: Balance[];
  isBtc: boolean;
  currentTotalBtc: number;
  currentTotalUsdt: number;
}

export function mapBalancesToPieData(props: PieDataMapperProps) {
  const {
    overallBalances, 
    isBtc, 
    currentTotalBtc, 
    currentTotalUsdt, 
  } = props;

  if (!overallBalances?.length) {
    return [];
  }
    
  return overallBalances.map((balance: Balance) => ({
    value: isBtc
      ? getOverallShare(balance.btc.total, currentTotalBtc)
      : getOverallShare(balance.usdt.total, currentTotalUsdt),
    amountForTooltip: balance.total,
    name: balance.symbol,
    totalUsdt: balance.usdt.total,
    totalBtc: balance.btc.total,
    overallBtcValue: currentTotalBtc,
    overallUsdtValue: currentTotalUsdt,
    overallBtcShare: getOverallShare(balance.btc.total, currentTotalBtc),
    overallUsdtShare: getOverallShare(balance.usdt.total, currentTotalUsdt),
  }));
}
  
