import { Stack } from '@mui/material';
import { Loader, Text } from 'shared/ui';
import { IActiveTradesProps } from '../interfaces';
import { HEADER, WRAPPER } from '../styles';

export const ActiveTrades = (props: IActiveTradesProps) => {
  const {
    header,
    isLoading,
    children,
  } = props;

  const getContent = () => {
    if (isLoading) {
      return (
        <Loader isContentOverflow={true} />
      );
    }

    return children;
  };

  return (
    <Stack sx={WRAPPER}>
      {isLoading && (
        <Loader isContentOverflow={true} />
      )}

      <Stack sx={HEADER}>
        {header.map((headerItem) => (
          <Text
            key={headerItem.title}
            className={headerItem.key}
          >
            {headerItem.title}
          </Text>
        ))}
      </Stack>

      {getContent()}
    </Stack>
  );
};
