import { TLanguage, TTranslation } from 'shared/types';

export const botsPublic = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      public: {
        title: 'My public bots',
        button: 'Publish new bot',
        subscriptions: {
          title: 'Subscriptions',
          select: {
            items: {
              all: 'All',
            },
          },
          items: {
            total: 'Total',
            active: 'Active',
            pending: 'Pending',
            unique: 'Unique users',
          },
        },
        sales: {
          title: 'Sales',
          items: {
            total: 'Total',
            withdrawn: 'Withdrawn',
            available: 'Available',
          },
        },
        table: {
          title: 'Withdrawals',
          export: 'Export',
          columns: {
            amount: 'Amount',
            transaction: 'Transaction',
            wallet: 'Wallet',
            date: {
              created: 'Created date',
              paid: 'Paid date',
            },
            status: {
              title: 'Status',
              items: {
                pending: 'pending',
                paid: 'paid',
                deleted: 'deleted' ,
              },
            },
          },
        },
        empty: {
          description: 'You don\'t have any shared bots yet. Create your own alert bot, share it with others to earn commissions and grow your community!',
        },
        card: {
          button: 'Edit item',
        },
      },
    },
    ru: {
      public: {
        title: 'Мои публичные боты',
        button: 'Опубликовать нового бота',
        subscriptions: {
          title: 'Подписки',
          select: {
            items: {
              all: 'Все',
            },
          },
          items: {
            total: 'Всего',
            active: 'Активные',
            pending: 'Ожидают',
            unique: 'Уник. пользователи',
          },
        },
        sales: {
          title: 'Продажи',
          items: {
            total: 'Всего',
            withdrawn: 'Выведено',
            available: 'Доступно',
          },
        },
        table: {
          title: 'Выводы',
          export: 'Экспорт',
          columns: {
            amount: 'Сумма',
            transaction: 'Транзакция',
            wallet: 'Кошелёк',
            date: {
              created: 'Дата создания',
              paid: 'Дата выплаты',
            },
            status: {
              title: 'Статус',
              items: {
                pending: 'обработка',
                paid: 'оплачен',
                deleted: 'удален' ,
              },
            },
          },
        },
        empty: {
          description:
            'У вас нет опубликованных ботов. Создайте алерт-бот, поделитесь им и зарабатывайте на комиссиях!',
        },
        card: {
          button: 'Изменить элемент',
        },
      },
    },
  };

  return transaltion[type].public;
};
