import { SxProps, Theme } from '@mui/material';

export const BLOCK: SxProps<Theme> = {
  gap: {
    xs: 0,
    sm: '8px',
  },
  position: 'absolute',
  width: '100%',
  maxWidth: {
    xs: 108,
    sm: 150,
  },
  left: '6%',
  top: '28%',
};
