import { TLanguage, TTranslation } from 'shared/types';

export const formLogin = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      login: {
        title: 'Welcome back',
        description: 'Login to your account',
        email: {
          label: 'E-mail',
          placeholder: '',
        },
        password: {
          label: 'Password',
          placeholder: '',
        },
        checkbox: 'Remember me',
        links: {
          forgot: 'Forgot your password?',
          register: {
            label: 'Don\'t have an account?',
            button: 'Register for a Free Trial',
          },
        },
        button: 'Login',
      },
    },
    ru: {
      login: {
        title: 'С возвращением',
        description: 'Войдите в свой аккаунт',
        email: {
          label: 'E-mail',
          placeholder: '',
        },
        password: {
          label: 'Пароль',
          placeholder: '',
        },
        checkbox: 'Запомнить меня',
        links: {
          forgot: 'Забыли пароль?',
          register: {
            label: 'Нет аккаунта?',
            button: 'Регистрация',
          },
        },
        button: 'Войти',
      },
    },
  };

  return transaltion[type].login;
};
