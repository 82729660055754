import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getExchanges = async () => {
  try {
    const response = await axios.request({
      url: '/exchangeAccount/getByFilter',
      data: {
        table: 'exchange_accounts',
        skyrexUserUuids: [getSkyrexUuid()!],
        statuses: ['active'],
        page: 1,
      },
    });

    return response.data?.data;
  } catch (error) {
    console.error('Error fetching exchange IDs:', error);
    return {
      success: false,
      accounts: [],
    };
  }
};
