import { TLanguage, TTranslation } from 'shared/types';

export const modalExchange = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      exchange: {
        connect: {
          success: {
            title: 'Account connected',
            message: 'Create or copy your first trading bot or smart terminal position',
            button: 'Okay',
          },
          error: {
            connection: {
              message: 'Maximum connected exchange accounts is reached',
            },
          },
        },
        list: {
          delete: {
            title: 'Delete account?',
            message: 'Ensure related bots are deleted and trades are completed',
            button: 'Delete',
          },
        },
      },
    },
    ru: {
      exchange: {
        connect: {
          success: {
            title: 'Аккаунт подключён',
            message: 'Создайте или скопируйте первого бота или смарт-позицию',
            button: 'Ок',
          },
          error: {
            connection: {
              message: 'Достигнут лимит подключенных аккаунтов',
            },
          },
        },
        list: {
          delete: {
            title: 'Удалить аккаунт?',
            message: 'Убедитесь, что боты удалены и сделки завершены',
            button: 'Удалить',
          },
        },
      },
    },
  };

  return transaltion[type].exchange;
};
