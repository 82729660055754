import { Box, Stack } from '@mui/material';
import { Segmented } from 'antd';
import { newTerminalActions } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { ErrorMessage } from 'pages/manual-trading/trading-terminal/components/error-message';
import { sliderWrapper } from 'pages/manual-trading/trading-terminal/components/fields/styles';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'widgets';
import { CloseSecondaryIcon, PlusGreen, SecondaryInfo } from 'shared/icons';
import { Input, LabelInput, MainButton, SingleSelect, Slider, Switch, Text, Title, Tooltip } from 'shared/ui';
import { SafetyOrders } from '../components';
import { segments } from '../consts';
import { ITakeProfit } from '../interfaces';
import { button, buttonTitle, header, headerTitle, priceInner, wrapper } from '../styles';

export const TakeProfit = (props: ITakeProfit) => {
  const {
    setIsSkipTakeProfit,
    onTakeProfitOrderPriceChange,
    onTakeProfitOrderPricePercentChange,
    onTakeProfitTriggerPriceChange,
    onAddEntry,
    onRemoveEntry,
    clearAllTakeProfitEntries,
    orderPanelDisabled,
  } = props;

  const {
    control,
    formState: {
      errors, 
    },
    trigger,
    setValue,
    watch,
    setError: setFormError,
    clearErrors,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();
  
  const dispatch = useDispatch();
  
  const [splitTarget, setSplitTarget] = useState<boolean>(false);
 
  const isTakeProfitEnabled = watch('takeProfitEnabled');
  const takeProfitOrderType = watch('takeProfitOrderType');
  const currentSymbol = watch('currentSymbol');
  const quoteAsset = watch('quoteAsset');
  const takeProfitTriggerPricePercent = watch('takeProfitTriggerPricePercent');
  const takeProfitEntries = watch('takeProfitEntries');
  const takeProfitOrderPrice = watch('takeProfitOrderPrice');
  const takeProfitTriggerPrice = watch('takeProfitTriggerPrice');
  const takeProfitPriceRecalculation = watch('takeProfitPriceRecalculation');
  
  const changePriceRecalculationHandler = (value: boolean) => {
    setValue('takeProfitPriceRecalculation', value ? 'average' : 'fixed');
  };

  const totalVolume = useMemo(() => {
    if (!takeProfitEntries?.length) return 0;
    
    return takeProfitEntries.reduce((sum: number, entry: { volume: number; }) => {
      const volume = Number(entry.volume) || 0;
      return sum + volume;
    }, 0);
  }, [takeProfitEntries]);
  
  const isAllTargetsSet = useMemo(() => {
    return totalVolume >= 100;
  }, [totalVolume]);
  
  const takeProfitPriceFromStore = useSelector((state: any) => state.newTerminal.takeProfitPrice);
  
  const lastDispatchedPriceRef = useRef<number | null>(null);
  const prevTakeProfitPriceRef = useRef<number | null>(null);
  
  // Эффект для обновления формы из Redux
  useEffect(() => {
    // Проверяем, изменилась ли цена в Redux
    const priceChanged = takeProfitPriceFromStore !== prevTakeProfitPriceRef.current;
    
    // Обновляем предыдущее значение
    prevTakeProfitPriceRef.current = takeProfitPriceFromStore;
    
    // Обновляем форму если:
    // 1. Цена изменилась И
    // 2. Либо это первое обновление, либо цена соответствует последней отправленной,
    //    либо предыдущая цена не соответствовала (т.е. обновление из графика)
    if (priceChanged && takeProfitPriceFromStore) {
      const shouldUpdate = 
        lastDispatchedPriceRef.current === null || 
        takeProfitPriceFromStore === lastDispatchedPriceRef.current ||
        prevTakeProfitPriceRef.current !== lastDispatchedPriceRef.current;
      
      if (shouldUpdate) {
        if (takeProfitOrderType === 'limit') {
          setValue('takeProfitOrderPrice', takeProfitPriceFromStore.toString());
          onTakeProfitOrderPriceChange(takeProfitPriceFromStore.toString());
        } else {
          setValue('takeProfitTriggerPrice', takeProfitPriceFromStore.toString());
          onTakeProfitTriggerPriceChange(takeProfitPriceFromStore.toString());
        }
      }
    }
  }, [
    takeProfitPriceFromStore, 
    takeProfitOrderType, 
    setValue, 
    onTakeProfitOrderPriceChange, 
    onTakeProfitTriggerPriceChange,
  ]);
  
  // Эффект для обновления цены в Redux из формы
  useEffect(() => {
    const priceToSet = takeProfitOrderType === 'limit' 
      ? takeProfitOrderPrice 
      : takeProfitTriggerPrice;
      
    if (isTakeProfitEnabled && !isAllTargetsSet) {
      if (priceToSet) {
        const numericPrice = Number(priceToSet);
        dispatch(newTerminalActions.setTakeProfitPrice(numericPrice));
        // Запоминаем последнюю отправленную цену
        lastDispatchedPriceRef.current = numericPrice;
      }
    } else {
      dispatch(newTerminalActions.setTakeProfitPrice(0));
      // Сбрасываем последнюю отправленную цену
      lastDispatchedPriceRef.current = 0;
    }
  }, [
    isAllTargetsSet,
    takeProfitOrderPrice, 
    takeProfitTriggerPrice, 
    takeProfitOrderType, 
    isTakeProfitEnabled,
    dispatch,
  ]);
  
  // 2. Добавим эффект для обновления splitTarget при наличии entries
  useEffect(() => {
    if (takeProfitEntries?.length > 0) {
      setSplitTarget(true);
    }
  }, [takeProfitEntries]);
  
  useEffect(() => {
    if (splitTarget && takeProfitEntries?.length > 0 && totalVolume < 100) {
      setFormError('takeProfitEntries', {
        type: 'custom',
        message: 'Targets volume sum should be 100%',
      });
    } else {
      clearErrors('takeProfitEntries');
    }
  }, [splitTarget, totalVolume, setFormError, clearErrors]);

  useEffect(() => {
    if (splitTarget) {
      const remainingVolume = 100 - totalVolume;
      setValue('takeProfitSlider', Math.min(remainingVolume, 100));
    }
  }, [splitTarget, totalVolume, setValue]);
  
  const changeSegmentHandler = (value: string) => {
    setValue('takeProfitOrderType', value);
  };

  const closeSplitTargetHandler = () => {
    setSplitTarget(false);
    setValue('takeProfitEntries', []);
    clearAllTakeProfitEntries?.();
  };

  const handleButtonClick = async () => {
    if (splitTarget) {
      const remainingVolume = 100 - totalVolume;
      const currentSliderValue = watch('takeProfitSlider');
      
      const volumeToAdd = Math.min(currentSliderValue, remainingVolume);
      setValue('takeProfitSlider', volumeToAdd);
      
      // Ждем следующего тика, чтобы значение успело обновиться
      await Promise.resolve();
      onAddEntry();
    } else {
      setSplitTarget(true);
    }
  };
  
  const renderOrderPriceField = () => {
    if (takeProfitOrderType === 'cond.market') {
      return null;
    }

    return (
      <Box sx={priceInner}>         
        <Controller
          name='takeProfitOrderPrice'
          control={control}
          rules={{
            required: t('validation.terminal.required'),
            validate: (value) => {
              const numValue = Number(value);
              if (isNaN(numValue)) {
                return t('validation.terminal.number');
              }
  
              if (numValue < Number(currentSymbol.priceMin)) {
                return t('validation.terminal.min', {
                  min: getTrailedZeroCutted(currentSymbol.priceMin),
                });
              }
              if (numValue > Number(currentSymbol.priceMax)) {
                return t('validation.terminal.max', {
                  max: getTrailedZeroCutted(currentSymbol.priceMax),
                });
              }
              return true;
            },
          }}
          render={({
            field, 
          }) => (
            <LabelInput
              label={(
                <Label title={t('terminal.panel.fields.price.origin')} />
              )}
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onTakeProfitOrderPriceChange(value);
                }
                trigger('takeProfitOrderPrice');
              }}
              onBlur={(value) => {
                const formattedValue = formatByPrecisionAndTrim(
                  value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                field.onChange(formattedValue);
                onTakeProfitOrderPriceChange(formattedValue);
                trigger('takeProfitOrderPrice');
              }}
              icon={quoteAsset}
              status={errors.takeProfitOrderPrice ? 'error' : undefined}
              error={errors?.takeProfitOrderPrice?.message?.toString()}
            />
          )}
        />
    
        <Controller
          name='takeProfitOrderPricePercent'
          control={control}
          rules={{
            required: 'This field is required',
          }}
          render={({
            field, 
          }) => (
            <Input
              value={field.value}
              onChange={(value) => {
                if (/^-?[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onTakeProfitOrderPricePercentChange(value);
                  trigger('takeProfitOrderPricePercent');
                  trigger('takeProfitOrderPrice');
                }
              }}
              maxWidth={58}
              icon='%'
              status={errors.takeProfitOrderPricePercent ? 'error' : undefined}
            />
          )}
        />
      </Box>
    );
  };
  
  const renderTriggerPriceField = () => {
    if (takeProfitOrderType === 'limit') {
      return null;
    }

    return (
      <Box sx={priceInner}>
        <Controller
          name='takeProfitTriggerPrice'
          control={control}
          rules={{
            required: t('validation.terminal.required'),
            validate: (value) => {
              const numValue = Number(value);
              if (isNaN(numValue)) {
                return t('validation.terminal.number');
              }
        
              if (numValue < Number(currentSymbol.priceMin)) {
                return t('validation.terminal.min', {
                  min: getTrailedZeroCutted(currentSymbol.priceMin),
                });
              }
              if (numValue > Number(currentSymbol.priceMax)) {
                return t('validation.terminal.max', {
                  max: getTrailedZeroCutted(currentSymbol.priceMax),
                });
              }
              return true;
            },
          }}
          render={({
            field,
          }) => (
            <LabelInput
              label={(
                <Label title={t('terminal.panel.fields.triggerPrice.title')} />
              )}
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onTakeProfitTriggerPriceChange(value);
                }
                trigger('takeProfitTriggerPrice');
              }}
              onBlur={(value) => {
                const formattedValue = formatByPrecisionAndTrim(value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                field.onChange(formattedValue);
                onTakeProfitTriggerPriceChange(formattedValue);
                trigger('takeProfitTriggerPrice');
              }}
              icon={quoteAsset}
              addonBefore={takeProfitTriggerPricePercent > 100 ? '>100%' : `${takeProfitTriggerPricePercent}%`}
              status={errors.takeProfitTriggerPrice ? 'error' : undefined}
              error={errors?.takeProfitTriggerPrice?.message?.toString()}
            />
          )}
        />
          
        <Controller
          name='takeProfitTriggerPriceType'
          control={control}
          render={({
            field, 
          }) => (
            <SingleSelect
              maxWidth='max-content'
              select={{
                value: field.value,
                placeholder: 'Last',
                onChange: (value) => {
                  field.onChange(value);
                  trigger('takeProfitTriggerPriceType');
                },
              }}
              options={[{
                label: t('terminal.panel.fields.types.last'),
                value: 'last', 
              }, {
                label: t('terminal.panel.fields.types.bid'),
                value: 'bid', 
              }, {
                label: t('terminal.panel.fields.types.ask'),
                value: 'ask', 
              }]}
            />
          )}
        />
      </Box>
    );
  };
  
  const renderSlider = () => {
    return (
      <Stack
        gap={0.5}
        height={53}
      >
        <Box sx={sliderWrapper}>
          <Controller
            name='takeProfitSlider'
            control={control}
            rules={{
              max: {
                value: 100,
                message: t('validation.terminal.slider.max'),
              },
              min: {
                value: 1,
                message: t('validation.terminal.slider.min'),
              },
            }}
            render={({
              field, 
            }) => (
              <Slider
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  trigger('takeProfitSlider');
                }}
                max={100 - totalVolume}
              />
            )}
          />
          
          <Controller
            name='takeProfitSlider'
            control={control}
            render={({
              field, 
            }) => (
              <Input
                value={Math.min(field.value, 100 - totalVolume).toString()} // Ограничиваем значение
                onChange={(value) => {
                  if (/^[0-9]+$/.test(value) || value === '') {
                    const numValue = value === '' ? 0 : parseInt(value);
                    const limitedValue = Math.min(numValue, 100 - totalVolume);
                    field.onChange(limitedValue);
                    trigger('takeProfitSlider');
                  }
                }}
                placeholder='100%'
                maxWidth={58}
                icon='%'
                status={errors.takeProfitSlider ? 'error' : undefined}
              />
            )}
          />
        </Box>
        
        <ErrorMessage message={errors?.takeProfitSlider?.message} />
      </Stack>
    );
  };
  
  return (
    <Box sx={wrapper}>
      <Box sx={header}>
        <Switch
          value={isTakeProfitEnabled}
          onChange={setIsSkipTakeProfit}
          suffixText={(
            <Title
              level={5}
              styles={headerTitle}
            >
              {t('terminal.panel.takeProfit.title')}
            </Title>
          )}
          size='small'
          disabled={orderPanelDisabled}
        />

        {isTakeProfitEnabled && (
          <Switch
            value={takeProfitPriceRecalculation === 'average'}
            checkedChildren={t('terminal.panel.takeProfit.slider.title')}
            unCheckedChildren={t('terminal.panel.takeProfit.slider.title')}
            onChange={changePriceRecalculationHandler}
            suffixText={(
              <Tooltip
                title={(
                  <Box maxWidth={250}>
                    {t('terminal.panel.takeProfit.slider.tooltip')}
                  </Box>
                )}
              >
                {SecondaryInfo}
              </Tooltip>
            )}
          />
        )}
      </Box>

      {isTakeProfitEnabled && (
        <>
          <Segmented
            options={segments(t)}
            value={takeProfitOrderType}
            onChange={changeSegmentHandler}
            block={true}
            disabled={isAllTargetsSet}
            size='large'
          />

          {takeProfitEntries.length > 0 && (
            <SafetyOrders 
              takeProfitEntries={takeProfitEntries}
              onRemoveEntry={onRemoveEntry}
            />
          )}

          {!isAllTargetsSet && (
            <>
              {renderOrderPriceField()}
              {renderTriggerPriceField()}
            </>
          )}

          {splitTarget && !isAllTargetsSet && (
            renderSlider()
          )}
          
          <Box
            display='flex'
            alignItems='center'
            gap={1}
          >
            {isAllTargetsSet ? (
              <Stack
                alignItems='center'
                flex={1}
              >
                <Text
                  type='success'
                  styles={{
                    fontWeight: 600,
                  }}
                >
                  {t('terminal.panel.takeProfit.all')}
                </Text>
              </Stack>
            ) : (
              <>
                <MainButton
                  icon={PlusGreen}
                  styles={button}
                  onClick={handleButtonClick}
                  disabled={isAllTargetsSet}
                  size='large'
                >
                  <Title
                    level={5}
                    styles={buttonTitle}
                  >
                    {t(`terminal.panel.takeProfit.button.${splitTarget ? 'add' : 'split'}`)}
                  </Title>
                </MainButton>

                {splitTarget && (
                  <MainButton onClick={closeSplitTargetHandler}>
                    {CloseSecondaryIcon}
                  </MainButton>
                )}
              </>
            )}
          </Box>

          {errors.takeProfitEntries && (
            <Text
              type='danger'
              styles={{
                fontSize: '12px',
                marginTop: '8px',
              }}
            >
              {errors.takeProfitEntries.message as string}
            </Text>
          )}
        </>
      )}
    </Box>
  );
};
