import { TLanguage, TTranslation } from 'shared/types';

export const formDate = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      date: {
        start: 'Start date',
        end: 'End date',
      },
    },
    ru: {
      date: {
        start: 'Начальная дата',
        end: 'Конечная дата',
      },
    },
  };

  return transaltion[type].date;
};
