import { Exchange } from '../exchange/ui/exchange';
import { IGetPlatformParams } from '../interfaces';

export const getPlatform = (params: IGetPlatformParams) => {
  const {
    platform,
    isModalOpen,
    returnToAllExchanges,
    handleClose,
    type,
  } = params;

  //@TODO make typings
  const unifiedExchanges = ['binance', 'okx', 'bybit', 'gateio', 'crypto', 'bitmart', 'htx', 'kucoin'];

  if (unifiedExchanges.includes(platform)) {
    return (
      <Exchange
        exchangeType={platform}
        handleClose={handleClose}
        returnToAllExchanges={returnToAllExchanges}
        isModalView={isModalOpen}
        type={type}
      />
    );
  }
  
  return null;
};
