import { TLanguage, TTranslation } from 'shared/types';

export const modalTwoFactor = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      twoFactor: {
        title: 'Connect Google authenticator',
        description: 'Don’t forget to back up your secret key',
        otp: {
          title: 'Enter OTP  to confirm connection',
          placeholder: '123456',
        },
        button: {
          cancel: 'Cancel',
          ok: 'Okay',
        },
      },
    },
    ru: {
      twoFactor: {
        title: 'Подключить Google Auth',
        description: 'Не забудьте сохранить свой секретный ключ',
        opt: {
          title: 'Введите OTP для подтверждения',
          placeholder: '123456',
        },
        button: {
          cancel: 'Отмена',
          ok: 'Ок',
        },
      },
    },
  };

  return transaltion[type].twoFactor;
};
