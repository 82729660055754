import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';

export const getCalculatedExchangeTotal = (exchanges: ExchangeAccount[]) => {
  const defaultAcc: Record<'usdt' | 'btc', number> = {
    usdt: 0,
    btc: 0,
  };

  const result = exchanges.reduce((acc: typeof defaultAcc, exchange: ExchangeAccount) => {
    return {
      ...acc,
      usdt: acc.usdt + parseFloat(exchange.totalUsdt),
      btc: acc.btc + parseFloat(exchange.totalBtc),
    };
  }, defaultAcc);

  return result;
};
