import { SxProps, Theme } from '@mui/material';
import { getFontSize } from 'shared/helpers';

export const TABLE_WRAPPER: SxProps<Theme> = {
  gap: '12px',
  width: '100%',
  '&>span': {
    fontSize: getFontSize(8, 12),
    fontWeight: 400,
    lineHeight: 'normal',
    '&:last-of-type': {
      fontSize: getFontSize(8, 10),
      fontWeight: 400,
      lineHeight: 'normal',
    },
  },
};
