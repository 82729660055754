import { TLanguage, TTranslation } from 'shared/types';

export const errorRegister = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      register: {
        'Oops! Account already exists': 'Oops! Account already exists',
        'Oops! There is no such referral code': 'Oops! There is no such referral code',
        'You already have an account, Check email to activate it': 'You already have an account. Check email to activate it',
        'Oops, try again': 'Oops, try again',
      },
    },
    ru: {
      register: {
        'Oops! Account already exists': 'Упс! Аккаунт уже существует',
        'Oops! There is no such referral code': 'Упс! Такого реферального кода не существует',
        'You already have an account, Check email to activate it': 'У вас уже есть аккаунт. Проверьте почту, чтобы активировать его',
        'Oops, try again': 'Упс, попробуйте снова',
      },
    },
  };

  return transaltion[type].register;
};
