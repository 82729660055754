import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MainButton, Text } from 'shared/ui';
import { platfroms } from '../consts';
import { ITabs } from '../interfaces';
import {
  background,
  binanceLogo,
  bitmartLogo,
  bybitLogo,
  cryptoLogo,
  gateLogo,
  htxLogo,
  icon,
  inner,
  item,
  kucoinLogo,
  okxLogo,
} from '../styles';

const iconStyles: any = {
  binance: binanceLogo,
  okx: okxLogo,
  bybit: bybitLogo,
  gateio: gateLogo,
  crypto: cryptoLogo,
  bitmart: bitmartLogo,
  kucoin: kucoinLogo,
  htx: htxLogo,
};

export const ExchangeTabs = (props: ITabs) => {
  const {
    onChoose,
    description,
    type,
  } = props;

  const {
    t, 
  } = useTranslation();

  const onClickHandler = (platform: string) => () => {
    onChoose(platform);
  };

  const disablePlatform = (title: string) => {
    if (type === 'spot') {
      return false;
    }

    return title !== 'binance' && title !== 'bybit';
  };

  return (
    <Box sx={background}>
      <Box sx={inner}>
        {platfroms.map((platfrom) => (
          <MainButton
            key={platfrom.title}
            styles={item}
            ghost={true}
            onClick={onClickHandler(platfrom.title)}
            disabled={disablePlatform(platfrom.title)}
          >
            <Box sx={icon}>
              <div style={iconStyles[platfrom.title]}>
                {platfrom.icon}
              </div>
            </Box>

            {platfrom.title === 'gateio' && (
              <Text
                type='danger'
                styles={{
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  fontSize: 11,
                }}
              >
                {t('exchange.connect.platformText')}
              </Text>
            )}
          </MainButton>
        ))}
      </Box>

      {description}
    </Box>
  );
};
