import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/material';
import { Modal } from 'antd';
import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';
import { Dispatch, FormEvent, SetStateAction } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TradingStatistic } from 'widgets';
import { exchangeIcons, IExchangeIcons } from 'shared/consts/exchange-icons';
import { IMarketIcons, marketIcons } from 'shared/consts/market-icon';
import { formatter } from 'shared/helpers';
import { getCapitalizedExchangeTitle } from 'shared/helpers/get-capitalized-exchange-title';
import { useSearch } from 'shared/hooks';
import { PlusWhite } from 'shared/icons';
import {
  Icon,
  Loader,
  MainButton,
  RangePicker,
  Search,
  Table,
  Title,
  Tooltip,
} from 'shared/ui';
import { Balance, BotName, Exchange } from '../components';
import { validationScheme } from '../consts';
import { columnsCopyBot } from '../consts/columns-copy-bot';
import { useCopyBot } from '../hooks';
import { action, inner, modal, modalChildren, title, titleInner, titleWrapper, topWrapper, wrapper } from '../styles';

interface CopyBotProps {
  isOpen: boolean;
  closeModal: () => void;
  isSignalSource?: boolean;
  bot: Bot;
  bots: Bot[];
  fee?: string;
  connectExchange?: Dispatch<SetStateAction<boolean>>;
}

export const CopyBot = (props: CopyBotProps) => {
  const {
    isOpen,
    closeModal,
    isSignalSource,
    bot,
    bots,
    fee,
    connectExchange,
  } = props;

  const {
    t, 
  } = useTranslation();
    
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationScheme(t, +(bot.minInvestment || 0))),
  });

  const {
    search,
    changeSearchValue,
    filterItemsBySearch,
  } = useSearch([
    'baseSymbol',
    'quoteSymbol',
  ]);

  const {
    values,
    handlers,
  } = useCopyBot({
    bot,
    bots,
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    methods.handleSubmit(handlers.createCopyBot)(event);
  };
  
  const statistic = values.statistic;

  const getTopNavigation = () => {
    if (isSignalSource) {
      return null;
    }

    return (
      <FormProvider {...methods}>
        <Stack
          component='form'
          onSubmit={onSubmit}
        >
          <Stack sx={topWrapper}>
            <Stack flex={1}>
              <Exchange
                isLoading={values.isLoading}
                options={values.exchangeOptions()}
                exchange={values.selectedExchange}
                exchanges={values.updatedExchanges}
                changeExcahnge={handlers.changeExchange}
                openModal={() => {
                  connectExchange?.(true);
                  closeModal();
                }}
              />
            </Stack>

            <Stack flex={1}>
              <BotName />
            </Stack>

            <Stack flex={1}>
              <Balance
                bot={bot}
                avialable={handlers.getAvailableAmount()}
              />
            </Stack>

            <Stack>
              <MainButton
                type='primary'
                icon={(
                  <Icon width={16}>
                    {PlusWhite}
                  </Icon>
                )}
                iconPosition='start'
                htmlType='submit'
                size='large'
              >
                {t('modal.copyBot.button')}
              </MainButton>
            </Stack>
          </Stack>
        </Stack>
      </FormProvider>
    );
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeModal}
      onOk={closeModal}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        {getTopNavigation()}

        <Box sx={inner(!!isSignalSource)}>
          <Box sx={titleWrapper}>
            <Title styles={title}>
              {bot?.name}
            </Title>

            <Box sx={titleInner}>
              <Tooltip title={getCapitalizedExchangeTitle(values.exchangeCode || bot.exchangeCode)}>
                <Box>
                  {exchangeIcons[(values.exchangeCode || bot.exchangeCode) as keyof IExchangeIcons]}
                </Box>
              </Tooltip>

              <Tooltip title={bot.quote}>
                <Box>
                  {marketIcons[bot.quote.toLowerCase() as keyof IMarketIcons]}
                </Box>
              </Tooltip>
            </Box>
          </Box>

          <TradingStatistic
            selectedQuote={bot.quote}
            disableTitle={true}
            customTitle={(
              <Title
                styles={{
                  ...title,
                  fontSize: 18,
                }}
              >
                {t('modal.copyBot.statistic.title')}
              </Title>
            )}
            listItems={[{
              title: t('modal.copyBot.statistic.items.roi.title'),
              tooltip: t('modal.copyBot.statistic.items.roi.tooltip'),
              value: formatter(statistic.roi),
              type: 'roi',
            }, {
              title: t('modal.copyBot.statistic.items.drawdown.title'),
              tooltip: t('modal.copyBot.statistic.items.drawdown.tooltip'),
              value: formatter(statistic.maxDrawdown),
              type: 'max-drawdown',
            }, {
              title: t('modal.copyBot.statistic.items.trades.title'),
              tooltip: t('statistic.copyBot.statistic.items.trades.tooltip'),
              value: statistic.totalTrades,
            }, {
              title: t('modal.copyBot.statistic.items.profit.title'),
              tooltip: t('modal.copyBot.statistic.items.profit.tooltip'),
              value: formatter(fee || 0),
            }, {
              title: t('modal.copyBot.statistic.items.amount.title'),
              tooltip: t('modal.copyBot.statistic.items.amount.tooltip'),
              value: formatter(bot.minInvestment ?? 0),
            }]}
            chartData={handlers.getChartData()}
            isCopyBot={true}
            selectedSegment={values.selectedPeriodOption}
            setSelectedSegment={handlers.changePeriod}
            chartAction={(
              <Box sx={action}>
                <RangePicker
                  value={values.dates!}
                  handleSetNewDate={handlers.changeDates}
                />
              </Box>
            )}
            loading={values.isStatisticLoading}
          />

          <Box position='relative'>
            {values.isTradingHistoryLoading && (
              <Loader isContentOverflow={true} />
            )}

            <Table
              title={t('modal.copyBot.statistic.table.title')}
              headerBreakPoint='md'
              columns={columnsCopyBot(t)}
              items={values.botTrades.filter(filterItemsBySearch).map((trade) => ({
                pair: {
                  first: trade.baseSymbol,
                  second: trade.quoteSymbol,
                },
                closeDate: trade.finishedDate,
                create: trade.createdDate,
                status: trade.status,
                total: formatter(trade.executedQuoteFirst),
                entryPrice: formatter(+trade.executedQuoteFirst / +trade.executedBaseFirst),
                closePrice: formatter(trade.status === 'completed' ? (+trade.executedQuoteSecond / +trade.executedBaseSecond) : 0),
                return: formatter(trade.sideFirstStep === 'BUY' ? trade?.returnQuote || 0 : trade?.returnBase || 0),
                returnPercent: formatter(trade.returnPercent || 0),
              }))}
              itemsCount={5}
              action={(
                <Box sx={action}>
                  <Search
                    value={search}
                    onChange={changeSearchValue}
                    placeholder={t('modal.copyBot.statistic.table.search.placeholder')}
                  />

                  <RangePicker
                    value={values.tradingHistoryDates!}
                    handleSetNewDate={handlers.changeTradingHistoryDates}
                  />
                </Box>
              )}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
