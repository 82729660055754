import { fetchPaginated } from 'shared/api/axios/helpers/fetch-pagindated';
import { Bot } from '../types/bots.types';

export const getAllPublicBots = async () => {
  const data = await fetchPaginated({
    endpoint: '/bot/getByFilterPublicBotsLandingMarketplace',
    requestData: {
      botType: 'CUSTOM',
      allowSharing: 'TRUE',
      page: 1,
    },
    field: 'bots',
  });
  
  return data?.bots || [] as Bot[];
};
