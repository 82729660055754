import { TLanguage, TTranslation } from 'shared/types';

export const successTwoFactor = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      twoFactor: {
        disable: 'Google authenticator disabled',
        enable: 'Google authenticator enabled',
        button: 'Okay',
      },
    },
    ru: {
      twoFactor: {
        disable: 'Google Auth отключён',
        enable: 'Google Auth включён',
        button: 'Ок',
      },
    },
  };

  return transaltion[type].twoFactor;
};
