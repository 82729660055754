import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  maxWidth: {
    xs: '100%',
    md: 747,
  },
  width: '100%',
  position: 'relative',
  '&>div': {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    width: '100%',
    height: '100%',
    aspectRatio: '16/9',
    zIndex: 100,
  },
  '&>video': {
    width: '100%',
    height: '100%',
    aspectRatio: '16/9',
  },
};
