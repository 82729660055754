import { Stack } from '@mui/material';
import { Segmented, Slider } from 'antd';
import { getEditingTrade } from 'entities/new-terminal/model/selectors/get-editing-trade';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Input, Switch, Text } from 'shared/ui';
import { text } from '../../trading-panel/styles/text';
import { futuresSegments, spotSegments } from '../consts';
import { useLeverageInputsLogic } from '../hooks/use-leverage-inputs';
import { ITopNavigationProps } from '../interfaces';


export const leverageTypeOptions = (t: any) => {
  return [
    {
      label: t('terminal.panel.leverageSettings.isolated'),
      value: 'isolated',
    },
    {
      label: t('terminal.panel.leverageSettings.cross'),
      value: 'cross',
    },
  ];
};

export const TopNavigation = (props: ITopNavigationProps) => {
  const {
    type,
  } = props;

  const {
    handleLeverageSliderChange, 
    handleLeverageInputChange, 
    handleLeverageTypeChange,
  } = useLeverageInputsLogic();
  
  const editingTrade = useSelector(getEditingTrade);
  const {
    t, 
  } = useTranslation();
  
  const methods = useFormContext();
    
  const {
    setValue,
    watch,
    control,
  } = methods;

  
  useEffect(() => {
    if (type === 'futures') {
      setValue('selectedSide', 'long');
    }
  }, [type, setValue]);
  
  const selectedSide = watch('selectedSide');
  const isSkipBaseOrder = watch('skipBaseOrder');
  const isLeverageTypeDisabled = watch('isLeverageTypeDisabled'); // состояние из формы для disabled
  
  const changeSegmentHandler = (value: string) => {
    if (type === 'futures') {
      setValue('selectedSide', value as 'long' | 'short');
      return;
    }

    setValue('selectedSide', value as 'buy' | 'sell');
  };
  
  const skipBaseOrderHandler = (value: boolean) => {
    setValue('skipBaseOrder', value);
  };

  if (type === 'futures') {
    return (
      <Stack gap='24px'>
        <Stack gap='8px'>
          <Segmented
            value={selectedSide}
            onChange={changeSegmentHandler}
            options={futuresSegments(t)}
            block={true}
            disabled={!!editingTrade}
            size='large'
          />
              
          <Switch
            value={isSkipBaseOrder}
            onChange={skipBaseOrderHandler}
            suffixText={(
              <Text
                type='secondary'
                styles={text}
              >
                {t('terminal.panel.skip')}
              </Text>
            )}
            disabled={!!editingTrade}
            size='small'
          />
        </Stack>

        <Stack gap='16px'>
          <Text>
            {t('terminal.panel.leverageSettings.leverage')}
          </Text>

          <Stack gap='10px'>
            <Controller
              name='leverageType'
              control={control}
              defaultValue='cross'
              render={({
                field, 
              }) => (
                <Segmented
                  {...field}
                  options={leverageTypeOptions(t).map(option => ({
                    ...option,
                    disabled: isLeverageTypeDisabled,
                  }))}
                  onChange={(value) => {
                    field.onChange(value);
                    handleLeverageTypeChange(value);
                  }}
                  block
                  size='large'
                />
              )}
            />

            <Stack
              direction='row'
              gap='14px'
            >
              <Stack flex={1}>
                
                <Controller
                  name='leverageSlider'
                  control={control}
                  rules={{
                    min: {
                      value: 1,
                      message: t('validation.terminal.min'),
                    },
                  }}
                  render={({
                    field,
                  }) => (
                    <Slider
                      defaultValue={1}
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                        handleLeverageSliderChange(value);
                      }}
                      min={1}
                      max={100}
                      marks={{
                        1: '1x',
                        25: '25x',
                        50: '50x',
                        100: '100x',
                      }}
                      disabled={false}
                      step={0.1}
                    />
                  )}
                />
              </Stack>

              <Controller
                name='leverageInput'
                control={control}
                rules={{
                  min: {
                    value: 1,
                    message: t('validation.terminal.min'),
                  },
                }}
                render={({
                  field,
                }) => (
                  <Input
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                      handleLeverageInputChange(Number(value));
                    }}
                    maxWidth={47}
                    disabled={false}
                    type='number'
                    inputStyles={{
                      textAlign: 'center',
                      padding: '7px 0',
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gap='8px'>
      <Segmented
        value={selectedSide}
        onChange={changeSegmentHandler}
        options={spotSegments(t)}
        block={true}
        disabled={!!editingTrade}
        size='large'
      />
              
      <Switch
        value={isSkipBaseOrder}
        onChange={skipBaseOrderHandler}
        suffixText={(
          <Text
            type='secondary'
            styles={text}
          >
            {t('terminal.panel.skip')}
          </Text>
        )}
        disabled={!!editingTrade}
        size='small'
      />
    </Stack>
  );
};
