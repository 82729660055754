import { Stack } from '@mui/material';
import { InfoIcon } from 'pages/manual-trading/trading-terminal/components/buy/icons';
import { useTranslation } from 'react-i18next';
import { Wallet as WALLET } from 'shared/icons';
import { Text, Title } from 'shared/ui';

export const Wallet = () => {
  const {
    t, 
  } = useTranslation();
  
  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent='space-between'
    >
      <Title
        styles={{
          fontSize: 20,
          fontWeight: 400,
        }}
      >
        {t('terminal.panel.title.buy.origin', {
          currency: 'USDT', 
        })}
      </Title>

      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px',
          '&>div': {
            maxWidth: 12,
            '&>svg': {
              width: '100%',
              height: '100%',
            },
          },
        }}
      >
        <Stack>
          {WALLET}
        </Stack>

        <Text
          type='success'
          styles={{
            fontSize: 10,
            fontWeight: 400,
          }}
        >
          10000 USDT
        </Text>

        <Stack>
          {InfoIcon}
        </Stack>
      </Stack>
    </Stack>
  );
};
