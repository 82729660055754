import { TLanguage, TTranslation } from 'shared/types';

export const auth = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      auth: {
        title: {
          register: 'Great choice!',
          common: 'Pro-level trading made simple',  
        },
        description: 'Unlock your trading potential with trading bots and multi-order terminal on major exchanges',
      },
    },
    ru: {
      auth: {
        title: {
          register: 'Отличный выбор!',
          common: 'Торгуйте как профи — просто',  
        },
        description: 'Раскройте потенциал: боты и мульти-ордерный терминал на лучших биржах',
      },
    },
  };

  return transaltion[type].auth;
};
