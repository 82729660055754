import { Hidden, Stack } from '@mui/material';
import { CopyRight, Language } from 'widgets';
import { domen } from 'shared/consts';
import { Logo } from 'shared/icons';
import { Link, Text, Title } from 'shared/ui';
import { IAuthProps } from '../interfaces';
import { BOTTOM_WRAPPER, COPY_RIGHT, TEXT, TITLE, TITLE_WRAPPER, TOP_WRAPPER, WRAPPER } from '../styles';

export const AuthForm = (props: IAuthProps) => {
  const {
    title,
    description,
    banner,
    items,
    extra,
    notify,
  } = props;

  return (
    <Stack sx={WRAPPER}>
      <Hidden mdUp>
        <Stack sx={TOP_WRAPPER}>
          {Logo}

          {banner}
        </Stack>
      </Hidden>

      <Stack gap='24px'>
        <Stack gap='4px'>
          <Stack sx={TITLE_WRAPPER}>
            <Title
              level={3}
              styles={TITLE}
            >
              {title}
            </Title>

            <Language />
          </Stack>

          <Text styles={TEXT}>
            {description}
          </Text>
        </Stack>

        {notify}

        <Stack gap='16px'>
          {items}
        </Stack>

        {extra}
      </Stack>

      <Hidden mdUp>
        <Stack sx={BOTTOM_WRAPPER}>
          <CopyRight />

          <Link
            type='success'
            styles={COPY_RIGHT}
            href={domen}
            target='_blank'
          >
            Skyrexio
          </Link>
        </Stack>
      </Hidden>
    </Stack>
  );
};
