import { Box } from '@mui/material';
import { Form, Modal } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelInput, MainButton, Text, Title } from 'shared/ui';
import { botNameValidator, tradingBalanceValidator } from '../../copy-bot/consts';
import { formItem } from '../../copy-bot/styles';
import { IUpdateCopyBot } from '../interfaces';
import { button, description, inner, modal, modalChildren, title, topWrapper, wrapper } from '../styles';

export const UpdateCopyBot = (props: IUpdateCopyBot) => {
  const {
    isOpen,
    handleClose,
    handleUpdate,
    bot,
  } = props;

  const [form] = Form.useForm();
  const {
    t, 
  } = useTranslation();
  
  const handleTradingAmountChange = (value: string) => {
    const numericValue = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
    if (/^\d/.test(numericValue)) {
      form.setFieldValue('tradingBalance', numericValue);
    } else if (numericValue === '') {
      form.setFieldValue('tradingBalance', '');
    }
  };
  
  const handleFormSubmit = async () => {
    try {
      await form.validateFields();
      
      handleUpdate({
        botUuid: bot.botUuid,
        name: form.getFieldValue('botName'),
        tradingAmount: +form.getFieldValue('tradingBalance'),
      });
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    form.setFieldValue('botName', bot.botName || bot.name);
    form.setFieldValue('tradingBalance', String(bot.tradingAmount).replace(' ', ''));
  }, [isOpen]);
  
  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      onOk={handleFormSubmit}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Form
          form={form}
          layout='vertical'
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '35px',
          }}
          initialValues={{
            botName: bot?.botName || bot?.name,
            tradingBalance: bot?.tradingAmount,
          }}
        >
          <Box sx={topWrapper}>
            <Title styles={title}>
              {t('modal.bot.update.title')}
            </Title>

            <Text styles={description}>
              {t('modal.bot.update.description')}
            </Text>
          </Box>

          <Box sx={inner}>
            <Form.Item
              name='botName'
              style={formItem}
              rules={botNameValidator(t)}
            >
              <LabelInput
                label={t('form.bot.update.name.title')}
                value={form.getFieldValue('botName')}
                onChange={(value) => form.setFieldValue('botName', value)}
                placeholder={t('form.bot.update.name.placeholder')}
              />
            </Form.Item>
          
            <Form.Item
              style={formItem}
              name='tradingBalance'
              rules={tradingBalanceValidator(+bot?.minInvestment, t)}
            >
              <LabelInput
                label={t('form.bot.update.balance.title')}
                value={form.getFieldValue('tradingBalance')}
                onChange={handleTradingAmountChange}
                placeholder={t('form.bot.update.balance.palceholder')}
              />
            </Form.Item>

            <MainButton
              type='primary'
              ghost={true}
              styles={button}
              onClick={handleFormSubmit}
            >
              {t('form.bot.update.button')}
            </MainButton>
          </Box>
        </Form>
      </Box>
    </Modal>
  );
};
