import createAxiosInstance from 'shared/api/axios/axios';
import { fetchPaginatedSmartTrades } from 'shared/api/axios/helpers/fetch-paginated';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const tradeGetAllRichData = async (smartTradeUuids: string[]) => {
  if (!smartTradeUuids.length) {
    return [];
  }

  const endPoint = 'smartTrade/getByFilterTerminal';
  const requestData = {
    smartTradeUuids: smartTradeUuids,
    page: 1,
  };

  return fetchPaginatedSmartTrades(axios, endPoint, requestData);
};
