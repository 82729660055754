import { TLanguage, TTranslation } from 'shared/types';

export const modalSubscription = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      subscription: {
        title: 'Order details',
        aggrement: {
          prefix: 'By making purchase, you agree to',
          and: 'and',
          link: {
            terms: 'Terms of use',
            policy: 'Refund Policy',
          },
        },
        button: 'Purchase',
        confirm: {
          end: {
            title: 'End subscription?',
            description: 'Profit-sharing will be calculated at the current moment results',
            button: 'Confirm',
          },
        },
      },
    },
    ru: {
      subscription: {
        title: 'Детали заказа',
        aggrement: {
          prefix: 'Оформляя покупку, вы соглашаетесь с',
          and: 'и',
          link: {
            terms: 'Правилами использования',
            policy: 'Политикой возврата',
          },
        },
        button: 'Оформить',
        confirm: {
          end: {
            title: 'Завершить подписку?',
            description: 'Комиссия за прибыль будет рассчитана по текущим результатам',
            button: 'Подтвердить',
          },
        },
      },
    },
  };

  return transaltion[type].subscription;
};
