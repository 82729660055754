import { useTranslation } from 'react-i18next';
import { Tabs } from 'widgets';
import { tabsItems } from '../consts';
import { Ai, Custom } from '../views';

const Introduction = () => {
  const {
    t, 
  } = useTranslation();

  return (
    <Tabs
      tabs={tabsItems(t)}
      content={[(
        <Custom />
      ), (
        <Ai />
      )]}
      styles={{
        gap: '48px',
        justifyContent: 'center',
      }}
    />
  );
};

export default Introduction;
