import { Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { IMenu } from 'widgets/header/components/menu/interfaces';
import { TAppPages } from 'shared/config/route-config/types/app-pages';
import { Icon, Text, Title } from 'shared/ui';
import { TEXT, TITLE, WRAPPER } from '../styles';

export const ExpandMenuItem = (props: Record<'item', NonNullable<IMenu<TAppPages>['children']>[0]>) => {
  const {
    item,
  } = props;

  return (
    <NavLink to={item.path}>
      <Stack sx={WRAPPER}>
        <Icon width={30}>
          {item.icon}
        </Icon>

        <Stack gap='4px'>
          <Title styles={TITLE}>
            {item.info.label}
          </Title>

          <Text
            type='secondary'
            styles={TEXT}
          >
            {item.info.text}
          </Text>
        </Stack>
      </Stack>
    </NavLink>
  );
};
