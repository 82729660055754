import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const WRAPPER: SxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: getResponsiveValue({
    min: 8,
    max: 24,
  }),
  gap: getResponsiveValue({
    min: 8,
    max: 24,
  }),
};
