import { createDemoExchangeAccount } from 'entities/exchange/api/create-demo-exchange-account';
import { getExchangeAccounts } from 'entities/exchange/api/get-exchange-accounts';
import { setExchangeData } from 'entities/exchange/helpers/set-exchanges-data';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { disableDemoAccount, enableDemoAccount } from 'entities/user/api';
import { getCurrentLanguage } from 'entities/user/model/selectors/get-language/get-language';
import { userActions } from 'entities/user/model/slices/user-slice';
import { changePassword, login, loginWithOtp, recover, register } from 'features/auth/api/auth-service';
import { logout } from 'features/auth/model/actions/logout';
import { useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import { appPath } from 'shared/config/route-config/app-routes/consts';
import { authPath } from 'shared/config/route-config/auth-routes/consts';
import { getDemoAccount } from 'shared/helpers';
import {
  getSkyrexUuid,
  removeDemoMode,
  setAuthToken,
  setCurrentLastLoginDate,
  setDemoMode,
  setLanguage,
  setRefreshToken,
  setSkyrexUuid,
  setUserEmail,
} from 'shared/helpers/storage-helper';
import { useQuery } from './use-query';

export const useAuth = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const language = useSelector(getCurrentLanguage);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    t, 
  } = useTranslation();
  const query = useQuery();

  const loginHandler = async (params: Record<'email' | 'password', string>) => {
    try {
      setError('');
      setIsLoading(true);
      
      const email = params.email.toLowerCase();
      const password = params.password;

      const response = await login(email, password);
      if (response?.data?.message === 'Please fill in OTP') {
        navigate(authPath['two-factor'].navigate(), {
          state: {
            email,
            password,
          },
        });
        return;
      }

      if (!response.success) {
        setError(t(`error.login.${response.data.message!}`) as string);
        return;
      }

      const {
        accessToken,
        refreshToken,
        currentLastLoginDate,
        language,
        skyrexUserUuid,
      } = response.data;

      setSkyrexUuid(skyrexUserUuid);
      setUserEmail(email);
      setAuthToken(accessToken);
      setRefreshToken(refreshToken);
      setCurrentLastLoginDate(currentLastLoginDate);
      setLanguage(language);

      const exchanges = await setExchangeData(skyrexUserUuid);

      dispatch(userActions.auth(true));
      dispatch(userActions.setSkyrexUuid(skyrexUserUuid));
      dispatch(userActions.setUserEmail(email));
      dispatch(userActions.setLanguage(language));

      ReactPixel.trackCustom('UserLogin');
      ym?.('reachGoal', 'UserLogin');

      navigate(exchanges.length ? appPath.home.navigate() : appPath.my_accounts.navigate());
    } finally {
      setIsLoading(false);
    }
  };

  const registerHandler = async (params: Record<'email' | 'password' | 'referralCode', string>) => {
    const {
      email,
      password,
      referralCode,
    } = params;

    try {
      setIsLoading(true);
      setError('');

      const response = await register({
        email: email.toLowerCase(), 
        password,
        language,
        referralCode,
        utm: query.toString(),
      });

      if (!response.success) {
        setError(t(`error.register.${response.data.message?.replace('.', ',')}`) as string);
        return;
      }
      
      return true;
    } finally {
      setIsLoading(false);
    }
  };

  const recoverPasswordHandler = async (email: string) => {
    try {
      setIsLoading(true);
      setError('');

      const response = await recover(email.toLowerCase());
      if (!response.success) {
        setError(response.data.message);
        return;
      }

      return true;
    } finally {
      setIsLoading(false);
    }
  };

  const changePasswordHandler = async (params: Record<'token' | 'password', string>) => {
    try {
      setIsLoading(true);
      setError('');

      const resposne = await changePassword({
        passwordResetToken: params.token,
        newPassword: params.password,
      });
            
      if (!resposne.success) {
        navigate(authPath.login.navigate());
        return;
      }
            
      setTimeout(() => {
        navigate(authPath.login.navigate());
      }, 800);
    } finally {
      setIsLoading(false);
      navigate(authPath.login.navigate());
    }
  };

  const otpLoginHandler = async (params: Record<'email' | 'password' | 'otp', string>) => {
    try {
      setIsLoading(true);
      setError('');
      
      const resposne = await loginWithOtp(params);
            
      if (!resposne.success) {
        setError(resposne.data.message);
        return;
      }
            
      const {
        accessToken,
        refreshToken,
        skyrexUserUuid,
        currentLastLoginDate,
      } = resposne.data;
            
      setSkyrexUuid(skyrexUserUuid);
      setAuthToken(accessToken);
      setRefreshToken(refreshToken);
      setSkyrexUuid(skyrexUserUuid);
      setUserEmail(params.email);
      setAuthToken(accessToken);
      setRefreshToken(refreshToken);
      setCurrentLastLoginDate(currentLastLoginDate);
      setLanguage(language);
      
      const exchanges = await setExchangeData(skyrexUserUuid);
      
      dispatch(userActions.auth(true));
      dispatch(userActions.setSkyrexUuid(skyrexUserUuid));
      dispatch(userActions.setUserEmail(params.email));
      dispatch(userActions.setLanguage(language));

      navigate(exchanges.length ? appPath.home.navigate() : appPath.my_accounts.navigate());
    } finally {
      setIsLoading(false);
    }
  };

  const disableDemoAccountHandle = async () => {
    try {
      setIsLoading(true);
      
      const response = await disableDemoAccount();
      
      if (!response?.data?.skyrexUserUuid) {
        return t('error.exchange.demo.disable.title');
      }
  
      removeDemoMode();
      dispatch(userActions.setIsDemoMode(false));
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const enableDemoAccountHandler = async () => {
    try {
      setIsLoading(true);
      
      const response = await enableDemoAccount();
      if (!response?.data?.skyrexUserUuid) {
        return t('error.exchange.demo.enable.title');
      }
  
      const accountsResponse = await getExchangeAccounts();
      const [demoAccount] = getDemoAccount(accountsResponse?.data?.accounts, true);
      if (demoAccount) {
        setDemoMode('demo');
        dispatch(exchangeActions.setAllExchanges(demoAccount));
        dispatch(userActions.setIsDemoMode(true));
        
        return null;
      }
  
      const demoAccountResponse = await createDemoExchangeAccount();
      if (!demoAccountResponse?.data?.fields) {
        return t('error.exchange.demo.create.title');
      }
  
      const createdAccounts = await setExchangeData(getSkyrexUuid()!, true);
      dispatch(exchangeActions.setAllExchanges(createdAccounts));
  
      setDemoMode('demo');
      dispatch(userActions.setIsDemoMode(true));
      ReactPixel.trackCustom('CreateDemoExchange');
      ym?.('reachGoal', 'CreateDemoExchange');
      return null;
    } catch(error) {
      return error?.message;
    }
    finally {
      setIsLoading(false);
    }
  };

  const logOutHandler = async () => {
    dispatch(logout());
    navigate(authPath.login.navigate());
  };

  return {
    isLoading,
    error,
    setError,
    handlers: {
      loginHandler,
      registerHandler,
      recoverPasswordHandler,
      changePasswordHandler,
      otpLoginHandler,
      disableDemoAccountHandle,
      enableDemoAccountHandler,
      logOutHandler,
    },
  };
};
