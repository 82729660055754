import { TLanguage, TTranslation } from 'shared/types';

export const inviteFriends = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      invite: {
        share: {
          title: {
            create: 'Create your referral code',
            origin: 'Share your link:',
          },
          placeholder: 'Enter referral code you want',
          button: 'Create',
        },
        payments: {
          title: 'Payments',
          button: 'Export',
          items: {
            share: 'Share',
            cliks: 'Clicks',
            registrations: 'Registrations',
            payments: 'Payments',
          },
          income: {
            title: 'Income',
            items: {
              total: 'Total',
              withdrawan: 'Withdrawn',
              available: 'Available',
            },
            text: 'Request will be processed in 24 hours',
            button: 'Withdraw',
          },
          columns: {
            subscription: 'Subscription',
            type: 'Type',
            paid: {
              date: 'Paid date',
              amount: 'Paid amount, USDT',
            },
            share: 'Your share, USDT',
          },
          level: '{{level}} level',
        },
        withdrawals: {
          title: 'Withdrawals',
          button: 'Export',
          columns: {
            amount: 'Amount',
            transaction: 'Transaction',
            wallet: 'Wallet',
            date: {
              create: 'Created date',
              paid: 'Paid date',
            },
            status: {
              title: 'Status',
              items: {
                pending: 'pending',
                paid: 'paid',
                deleted: 'deleted' ,
              },
            },
          },
        },
        branding: {
          title: 'Branding materials',
          logos: {
            title: 'Skyrexio logos',
            button: 'Download logo',
          },
          banners: {
            title: 'Skyrexio banners',
            button: 'Download banner',
          },
        },
      },
    },
    ru: {
      invite: {
        share: {
          title: {
            create: 'Создайте ваш реферальный код',
            origin: 'Поделитесь ссылкой:',
          },
          placeholder: 'Введите код, который хотите',
          button: 'Создать',
        },
        payments: {
          title: 'Выплаты',
          button: 'Экспорт',
          items: {
            share: 'Доля',
            cliks: 'Клики',
            registrations: 'Регистрации',
            payments: 'Оплаты',
          },
          income: {
            title: 'Доход',
            items: {
              total: 'Всего',
              withdrawan: 'Выведено',
              available: 'Доступно',
            },
            text: 'Заявка обработается за 24 часа',
            button: 'Вывести',
          },
          columns: {
            subscription: 'Подписка',
            type: 'Тип',
            paid: {
              date: 'Дата оплаты',
              amount: 'Оплачено, USDT',
            },
            share: 'Ваша доля, USDT',
          },
          level: '{{level}} уровень',
        },
        withdrawals: {
          title: 'Выводы',
          button: 'Экспорт',
          columns: {
            amount: 'Сумма',
            transaction: 'Транзакция',
            wallet: 'Кошелёк',
            date: {
              create: 'Дата создания',
              paid: 'Дата выплаты',
            },
            status: {
              title: 'Статус',
              items: {
                pending: 'обработка',
                paid: 'оплачен',
                deleted: 'удален' ,
              },
            },
          },
        },
        branding: {
          title: 'Бренд-материалы',
          logos: {
            title: 'Логотипы Skyrexio',
            button: 'Скачать лого',
          },
          banners: {
            title: 'Баннеры Skyrexio',
            button: 'Скачать баннер',
          },
        },
      },
    },
  };

  return transaltion[type].invite;
};
