import { IRiskManagmentAuto } from 'entities/risk-managment-auto/interfaces';

export const defaultAutoStepValues = () => {
  const defaultValues: IRiskManagmentAuto = {
    segment: 'auto',
    preset: 'conservative',
    values: {
      additionalOrderPriceChange: 0,
      additionalOrderPriceChangeScale: 0,
      additionalOrderSizeScale: 0,
      maxAdditionalOrders: 0,
      stopLossPercent: 0,
      taketProfitPercent: 0,
      baseOrderSize: 0,
      fullPositionSize: 0,
      takeProfitPercent: 0,
    },
  };

  return defaultValues;
};
