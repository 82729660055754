import { TLanguage, TTranslation } from 'shared/types';

export const statistic = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      statistic: {
        items: {
          total: {
            bots: {
              title: 'Total bots',
              tooltip: 'Number of unique enabled and disabled bots',
            },
            amount: {
              title: 'Total amount',
              tooltip: 'Trading balances sum of the current bots',
            },
            trades: {
              title: 'Total trades',
              tooltip: 'Profit taken from closed deals (resets at midnight UTC+0 each day). Please note: this statistic is affected by any filters you have active',
            },
          },
          pl: {
            total: {
              title: 'Total P&L',
              tooltip: 'Total lifetime profit generated from all closed deals. Please note: this statistic is affected by any filters you have active',
            },
            today: {
              title: 'Today P&L',
              tooltip: 'Profit from completed and active transactions today (resets to zero every day at midnight UTC). This statistics is affected by active filters',
            },
          },
        },
      },
    },
    ru: {
      statistic: {
        items: {
          total: {
            bots: {
              title: 'Всего ботов',
              tooltip: 'Количество уникальных включенных и выключенных ботов',
            },
            amount: {
              title: 'Общий баланс',
              tooltip: 'Сумма торговых балансов текущих ботов',
            },
            trades: {
              title: 'Всего сделок',
              tooltip: 'Прибыль, полученная от закрытых сделок (обнуляется каждый день в полночь по UTC). На эту статистику влияют активные фильтры',
            },
          },
          pl: {
            total: {
              title: 'Всего P&L',
              tooltip: 'Общая прибыль за выбранный период, полученная от всех закрытых сделок. На эту статистику влияют активные фильтры',
            },
            today: {
              title: 'Текущий P&L',
              tooltip: 'Прибыль от завершенных сегодня и активных сделок (обнуляется каждый день в полночь по UTC). На эту статистику влияют активные фильтры',
            },
          },
        },
      },
    },
  };

  return transaltion[type].statistic;
};
