import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  justifyContent: {
    xs: 'space-between',
    md: 'center',
  },
  flex: 1,
  width: '100%',
  gap: '24px',
  alignSelf: {
    xs: 'initial',
    md: 'center',
  },
};
