import { Stack } from '@mui/material';
import { Modal } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { MainButton, SingleSelect, Text } from 'shared/ui';
import { IPublicBotMarket } from '../interfaces';
import { BUTTON, modal, modalChildren, TITLE, WRAPPER } from '../styles';

export const PublicBotMarket = (props: IPublicBotMarket) => {
  const {
    isOpen,
    closeHandler,
    market,
    setMarket,
    bots,
  } = props;

  const {
    t, 
  } = useTranslation();

  const options = useCallback(() => {
    if (!bots) {
      return [];
    }

    const uniqueMarkets = (bots as any[]).reduce((acc, current) => {
      if (!acc.includes(current.quote)) {
        acc.push(current.quote);
      }

      return acc;
    }, []);

    return uniqueMarkets.map((market: any) => {
      const option = {
        label: market,
        value: market,
        key: market,
      };

      return option;
    });
  }, [bots]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Stack sx={WRAPPER}>
        <Text styles={TITLE}>
          {t('modal.bots.market.title')}
        </Text>

        <SingleSelect
          label={(
            <Label
              title={t('modal.bots.market.select.title')}
            />
          )}
          options={options()}
          select={{
            value: market || undefined,
            placeholder: t('modal.bots.market.select.placeholder'),
            onChange: setMarket,
          }}
        />

        <MainButton
          type='primary'
          styles={BUTTON}
        >
          {t('modal.bots.market.button')}
        </MainButton>
      </Stack>
    </Modal>
  );
};
