import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 188,
  alignSelf: 'center',
  gap: '4px',
  flexWrap: 'wrap',
};
