import { TLanguage, TTranslation } from 'shared/types';
import { diary } from './diary';
import { introduction } from './introduction';
import { panel } from './panel';
import { trades } from './trades';

export const terminal = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      terminal: {
        select: {
          exchange: 'Exchange account',
          market: 'Market',
          pair: {
            title: 'Trading Pair',
            placeholder: 'Select trading pair',
          },
        },
        table: {
          columns: {
            token: 'Token',
            share: 'Share',
            change: 'Change 24h',
            price: 'Price',
            amount: 'Amount',
            total: 'Total',
          },
        },
        panel: panel('en'),
        trades: trades('en'),
        diary: diary('en'),
        introduction: introduction('en'),
      },
    },
    ru: {
      terminal: {
        select: {
          exchange: 'Биржевой аккаунт',
          market: 'Рынок',
          pair: {
            title: 'Торговая пара',
            placeholder: 'Выберите торговую пару',
          },
        },
        table: {
          columns: {
            token: 'Токен',
            share: 'Доля',
            change: 'Изменение 24ч',
            price: 'Цена',
            amount: 'Количество',
            total: 'Сумма',
          },
        },
        panel: panel('ru'),
        trades: trades('ru'),
        diary: diary('ru'),
        introduction: introduction('ru'),
      },
    },
  };

  return transaltion[type].terminal;
};
