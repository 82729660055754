export const getSubscriptionStatistic = (params: Record<'subscription' | 't', any>) => {
  const {
    t,
    subscription,
  } = params;

  const items = [{
    title: t('bots.public.subscriptions.items.total'),
    value: subscription?.total || 0,
  }, {
    title: t('bots.public.subscriptions.items.active'),
    value: subscription?.active || 0,
  }, {
    title: t('bots.public.subscriptions.items.pending'),
    value: subscription?.pending || 0,
  }, {
    title: t('bots.public.subscriptions.items.unique'),
    value: subscription?.uniqueUsers || 0,
  }];

  return items;
};
