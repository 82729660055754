import { Box } from '@mui/material';
import { Modal } from 'antd';
import { getTerminalLoading } from 'entities/new-terminal/model/selectors/get-terminal-loading';
import { addNewTrade } from 'entities/new-terminal/model/services/add-new-trade';
import { editTradeUpdate } from 'entities/new-terminal/model/services/edit-trade-update';
import { useTranslation } from 'react-i18next';
import { MainButton, Text, Title } from 'shared/ui';
import { useAppDispatch, useAppSelector } from 'app/providers/store-provider/config/store';
import { ConfirmTransactionProps } from '../interfaces';
import {
  button,
  inner,
  modal,
  modalChildren,
  row,
  table,
  tableBodyCell,
  tableHeaderCell,
  tableInner,
  tableTitle,
  tableWrapper,
  title,
  wrapper,
} from '../styles';

export const ConfirmTransaction = (props: ConfirmTransactionProps) => {
  const {
    isOpened,
    closeHandler,
    //данные для модалки
    confirmationTableData,
    //данные для создания/обновления сделки
    collectedDataForSubmit,
    isEditing = false,
  } = props;
   
  const dispatch = useAppDispatch();
  const {
    t, 
  } = useTranslation();
  
  const isLoading = useAppSelector(getTerminalLoading);
  
  const handleCreateOrUpdateTrade = async () => {
    try {
      if (isEditing) {
        await dispatch(editTradeUpdate(collectedDataForSubmit)).unwrap();
      } else {
        await dispatch(addNewTrade(collectedDataForSubmit)).unwrap();
      }
      closeHandler();
    } catch (error) {
      // @TODO добавить обработку ошибок
      closeHandler();
      console.error('Ошибка при создании/обновлении сделки:', error);
    }
  };
  
  const getModalTitle = (isEditing: boolean): string => {
    return t(`modal.confirmTransaction.title.${isEditing ? 'update' : 'create'}`);
  };
  
  return (
    <Modal
      open={isOpened}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable
      destroyOnClose
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Title styles={title}>{getModalTitle(isEditing)}</Title>

        <Box sx={inner}>
          {confirmationTableData?.map((tableItem) => (
            <Box
              key={tableItem.title}
              sx={tableWrapper}
            >
              <Title styles={tableTitle}>
                {tableItem.title}
              </Title>

              <Box sx={tableInner}>
                <Box sx={table}>
                  <Box sx={row}>
                    {tableItem.columns.map((column) => (
                      <Text
                        key={column}
                        styles={tableHeaderCell}
                        type='secondary'
                      >
                        {column}
                      </Text>
                    ))}
                  </Box>

                  {tableItem.items.map((rowItems, rowIndex) => (
                    <Box
                      key={rowIndex + rowItems.length}
                      sx={row}
                    >
                      {rowItems.map((rowItem) => (
                        <Text
                          key={rowItem.value}
                          styles={tableBodyCell}
                          type={rowItem.type}
                        >
                          {rowItem.value}
                        </Text>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        <MainButton
          type='primary'
          size='large'
          styles={button}
          loading={isLoading}
          disabled={isLoading}
          onClick={handleCreateOrUpdateTrade}
        >
          {t('modal.confirmTransaction.button')}
        </MainButton>
      </Box>
    </Modal>
  );
};
