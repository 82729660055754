import { TLanguage, TTranslation } from 'shared/types';

export const modalTerminal = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      terminal: {
        funds: {
          add: {
            title: 'Add Funds',
            description: '',
          },
          reduce: {
            title: 'Reduce Funds',
            description: '',
          },
        },
        close: {
          description: {
            buy: 'Current price: {{price}} {{quote}} \n Return: {{returnQuote}} {{quote}} ({{returnPercent}}%)',
            other: 'Current price: {{price}} {{quote}} \n Return: {{returnBase}} {{baseSymbol}} ({{returnPercent}}%)',
          },
        },
        open: {
          description: 'Current price: {{price}} {{quote}}',
        },
        market: {
          close: {
            title: 'Close by market?',
          },
          open: {
            title: 'Open by market?',
          },
        },
        limit: {
          close: {
            title: 'Close by limit?',
          },
          open: {
            title: 'Open by limit?',
          },
        },
        edit: {
          title: 'Edit position',
          description: 'Edit position',
        },
        share: {
          title: 'Share',
          description: 'Your share link',
        },
        cancel: {
          title: 'Cancel position?',
          description: 'Trade will be canceled on Skyrexio, while assets stay untouched on your exchange account',
        },
        button: 'Confirm',
      },
    },
    ru: {
      terminal: {
        funds: {
          add: {
            title: 'Добавить средства',
            description: '',
          },
          reduce: {
            title: 'Уменьшить средства',
            description: '',
          },
        },
        close: {
          description: {
            buy: 'Текущая цена: {{price}} {{quote}}\nДоход: {{returnQuote}} {{quote}} ({{returnPercent}}%)',
            other:
              'Текущая цена: {{price}} {{quote}}\nДоход: {{returnBase}} {{baseSymbol}} ({{returnPercent}}%)',
          },
        },
        open: {
          description: 'Текущая цена: {{price}} {{quote}}',
        },
        market: {
          close: {
            title: 'Закрыть по рынку?',
          },
          open: {
            title: 'Открыть по рынку?',
          },
        },
        limit: {
          close: {
            title: 'Закрыть по лимиту?',
          },
          open: {
            title: 'Открыть по лимиту?',
          },
        },
        edit: {
          title: 'Редактировать позицию',
          description: 'Редактировать позицию',
        },
        share: {
          title: 'Поделиться',
          description: 'Ваша ссылка',
        },
        cancel: {
          title: 'Отменить позицию?',
          description: 'Сделка отменится на Skyrexio, активы на бирже не пострадают',
        },
        button: 'Подтвердить',
      },
    },
  };

  return transaltion[type].terminal;
};
