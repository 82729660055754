import { botGetAllTrades, getBotDealsFromTerminal, getMyBots } from 'entities/bot/api';
import { Bot } from 'entities/bot/model/types';
import { Deal } from 'pages/trading-bots/my-bot/api/get-bot-deals-uuids';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultDates } from 'shared/consts';
import { ExchangeName } from 'shared/consts/available-exchanges';
import { getExchangeAccountsUuids } from 'shared/helpers';
import { useExcahnges, useSearch } from 'shared/hooks';
import { TDates, TSide } from 'shared/types';
import { EnrichedBotSmartTrade } from '../helpers/get-all-bots-trading-diary-data';
import { getBotNames } from '../helpers/get-bot-names';
import { getTypeOptions } from '../helpers/get-type-options';
import { getUniqueBaseCurrency } from '../helpers/get-unique-base-currency';
import { getUniqueMarketOptions } from '../helpers/get-unique-market-options';
import { getUniqueDealsStatuses } from '../helpers/get-unique-statuses';

export const useTradingDiary = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bots, setBots] = useState<Bot[]>([]);
  const [trades, setTrades] = useState<EnrichedBotSmartTrade[]>([]);

  const [exchange, setExchange] = useState<string[]>([]);
  const [type, setType] = useState<TSide>('long');
  const [market, setMarket] = useState<string>('');
  const [bot, setBot] = useState<string[]>([]);
  const [currency, setCurrency] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  
  const [createDates, setCreateDates] = useState<TDates>(defaultDates);
  const [closeDates, setCloseDates] = useState<TDates>([null, null]);

  const {
    values: {
      exchanges,
    },
  } = useExcahnges();

  const {
    search: searchText,
    changeSearchValue,
    filterItemsBySearch,
  } = useSearch(['base', 'quote']);

  const {
    t, 
  } = useTranslation();

  const changeExchange = (values: string[]) => {
    setExchange(values);
  };

  const changeMarket = (market: string) => {
    setMarket(market);
    setCurrency([]);
    setBot([]);
  };

  const changeType = (side: string) => {
    setType(side as TSide);
  };

  const changeBots = (bots: string[]) => {
    setBot(bots);
  };

  const changeCreateDate = (dates: TDates) => {
    setCreateDates(dates);
  };

  const changeCloseDate = (dates: TDates) => {
    setCloseDates(dates);
  };

  const changeCurrency = (currencies: string[]) => {
    setCurrency(currencies);
  };

  const changeStatus = (statuses: string[]) => {
    setStatus(statuses);
  };

  const marketOptions = useCallback(() => {
    const options = getUniqueMarketOptions(bots);
    return options;
  }, [bots]);

  const typeOptions = useCallback(() => {
    return getTypeOptions(t);
  }, []);

  const exchangeOptions = useCallback(() => {
    if (!exchanges) {
      return [];
    }

    const options = exchanges.map((exchange) => {
      return {
        label: exchange.accountName,
        value: exchange.exchangeAccountUuid,
      };
    });

    return options;
  }, [exchanges]);

  const botNamesOptions = useCallback((market: string) => {
    const options = getBotNames(bots);
    return options.filter((option) => {
      return option.market === market.toUpperCase();
    });
  }, [bots]);

  const currencyOptions = useCallback((market: string) => {
    const options = getUniqueBaseCurrency(trades);
    return options.filter((option) => {
      return option.market === market.toUpperCase();
    });
  }, [trades]);

  const statuseOptions = useCallback(() => {
    const options = getUniqueDealsStatuses(trades, t);
    return options;
  }, [trades, t]);

  const filterByExchange = (trade: EnrichedBotSmartTrade) => {
    return !exchange.length || exchange.includes(trade.exchangeAccountUuid);
  };

  const filterByMarket = (trade: EnrichedBotSmartTrade) => {
    return market === trade.quote.toLowerCase();
  };

  const filterByBots = (trade: EnrichedBotSmartTrade) => {
    return !bot.length || bot.includes(trade.botUuid);
  };

  const filterByCurrency = (trade: EnrichedBotSmartTrade) => {
    return !currency.length || currency.includes(trade.base);
  };

  const filterByStatus = (trade: EnrichedBotSmartTrade) => {
    return !status.length || status.includes(trade.status.toLowerCase());
  };

  const getTrades = () => {
    if (!trades.length) {
      return [];
    }
    
    return trades
      .filter(filterItemsBySearch)
      .filter(filterByExchange)
      .filter(filterByMarket)
      .filter(filterByBots)
      .filter(filterByCurrency)
      .filter(filterByStatus);
  };

  const getDealsFromTerminal = async () => {
    try {
      setIsLoading(true);

      const deals: Deal[] = await botGetAllTrades({
        botUuids: bots.map((bot) => {
          return bot.botUuid;
        }),
        createDates,
        closeDates,
      });

      const dealsRichData = await getBotDealsFromTerminal({
        smartTradeUuids: deals.map((deal) => {
          return deal.smartTradeUuid;
        }),
      });

      const result = deals.map((deal): EnrichedBotSmartTrade => {
        const bot = bots.find((bot) => {
          return bot.botUuid === deal.botUuid;
        });

        const richData: any = dealsRichData.find((dealRichData) => {
          return dealRichData.smartTradeUuid === deal.smartTradeUuid;
        }) || {};

        return {
          ...deal,
          name: (bot?.name || bot?.botName || bot?.itemName) || 'Unknown bot',
          exchangeCode: (bot?.exchangeCode as ExchangeName) || '',
          ...richData,
        };
      });

      setTrades(result);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllBotsData = async () => {
    try {
      setIsLoading(true);

      const bots = await getMyBots({
        accounts: getExchangeAccountsUuids(exchanges!),
        type,
      });

      const markets = getUniqueMarketOptions(bots);
      setMarket(markets[0]?.value || '');

      setBots(bots);
    } catch (error) {
      setIsLoading(false);
    }
  };
    
  useEffect(() => {
    if (!exchanges) {
      setIsLoading(true);
      return;
    }

    if (!exchanges?.length) {
      setBots([]);
      setTrades([]);
      setExchange([]);
      setBot([]);
      setStatus([]);
      setCurrency([]);
      setIsLoading(false);
      return;
    }

    getAllBotsData();
  }, [exchanges?.length, type]);

  useEffect(() => {
    if (!bots.length) {
      return;
    }

    getDealsFromTerminal();
  }, [bots, createDates, closeDates]);
      
  return {
    values: {
      isLoading,
      bots,
      trades,
      exchanges,
      marketOptions,
      botNamesOptions,
      currencyOptions,
      statuseOptions,
      exchangeOptions,
      createDates,
      closeDates,
      exchange,
      market,
      bot,
      currency,
      status,
      searchText,
      type,
      typeOptions,
    },
    handlers: {
      setIsLoading,
      changeExchange,
      changeMarket,
      changeBots,
      changeCreateDate,
      changeCloseDate,
      changeCurrency,
      changeStatus,
      getTrades,
      changeSearchValue,
      changeType,
    },
  };
};
