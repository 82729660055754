import { Box } from '@mui/material';
import { Modal } from 'antd';
import { userActions } from 'entities/user/model/slices/user-slice';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { LabelInput, Loader, MainButton, Text, Title } from 'shared/ui';
import { enableTwoFactor } from '../api/enable-two-factor';
import { getTwoFactorToken } from '../api/get-two-factor-token';
import { button, buttons, inner, modal, modalChildren, text, title, wrapper } from '../styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  generatedToken?: string;
  qrImageSource?: string;
  handleSetTwoFactorEnabled?: () => void;
  handleSetTwoFactorDisabled?: () => void;
  handleSetTwoFactorEnableFailedOpen?: () => void;
}

export const TwoFactor = (props: Props) => {
  const {
    isOpen,
    onClose,
  } = props;
  
  const [otp, setOtp] = useState<string>('');
  const [generatedToken, setGeneratedToken] = useState<string>('');
  const [qrImageSource, setQrImageSource] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    t, 
  } = useTranslation();
  const dispatch = useDispatch();
  
  const handleSetOtp = (value: string) => {
    setOtp(value);    
  };
  
  const handleEnableTwoFactor = async () => {
    try {
      const responseFromEnableTwoFactor = await enableTwoFactor(otp);
      
      if (!responseFromEnableTwoFactor.success) {
        setOtp('');
        toast.error(t(`error.twoFactor.enable.message.${responseFromEnableTwoFactor.data.message}`) as string);
        return;
      }
      
      onClose();
      setOtp('');
      dispatch(userActions.setIs2faEnabled(true));
      toast.success(t('success.twoFactor.enable') as string);
    } catch (error) {
      toast.error(t('error.twoFactor.enable.message') as string);
      onClose();
      setOtp('');
    }
  };

  const generateTokenTwoFactor = async () => {
    try {
      const responseFromGenerateToken = await getTwoFactorToken();
  
      if (!responseFromGenerateToken.success) {
        toast.error(t('error.twoFactor.generate.message') as string);
        return;
      }
  
      const qrImageSource = responseFromGenerateToken.data.uri2fa;
  
      setQrImageSource(qrImageSource);
      setGeneratedToken(responseFromGenerateToken.data.token2fa);
    } catch (error) {
      toast.error(t('error.twoFactor.generate.message') as string);
      onClose();
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleClose = () => {
    setOtp('');
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    generateTokenTwoFactor();
  }, [isOpen]);
  
  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={onClose}
      onOk={onClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        {isLoading && (
          <Loader isContentOverflow={true} />
        )}

        <Title styles={title}>
          {t('modal.twoFactor.title')}
        </Title>

        <Box sx={inner}>
          <Text styles={text}>
            {t('modal.twoFactor.description')}: <br />
            {generatedToken}
          </Text>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <QRCode
              value={qrImageSource}
              size={200}
            />
          </div>
        </Box>

        <LabelInput
          label={t('modal.twoFactor.otp.title')}
          value={otp}
          placeholder={t('modal.twoFactor.otp.placeholder')}
          onChange={handleSetOtp}
        />

        <Box sx={buttons}>
          <MainButton
            type='primary'
            ghost={true}
            styles={button}
            onClick={handleClose}
          >
            {t('modal.twoFactor.button.cancel')}
          </MainButton>
          
          <MainButton
            type='primary'
            styles={button}
            onClick={handleEnableTwoFactor}
          >
            {t('modal.twoFactor.button.ok')}
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};
