import { CSSProperties } from 'react';

export const BUTTON = (type: 'button' | 'link') => {
  const styles: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    gap: '8px',
    padding: '12px 40px',
    width: '100%',
  };

  if (type === 'link') {
    styles.fontSize = 11;
    styles.color = '#fff';
  }

  return styles;
};
