import { NavLink, NavLinkProps } from 'react-router-dom';
import { IMenu } from 'widgets/header/components/menu/interfaces';
import { TAppPages } from 'shared/config/route-config/types/app-pages';
import { Bagde, MainButton } from 'shared/ui';
import { BUTTON } from '../styles';

export const MenuItem = (props: Record<'item', IMenu<TAppPages>>) => {
  const {
    item,
  } = props;

  const renderButton: NavLinkProps['children'] = (props) => {
    return (
      <MainButton
        type='text'
        styles={BUTTON(props.isActive)}
      >
        {item.label}
  
        {item.badge && (
          <Bagde title={item.badge} />
        )}
      </MainButton>
    );
  };

  return (
    <NavLink to={item.path!}>
      {renderButton}
    </NavLink>
  );
};
