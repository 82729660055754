import { Stack } from '@mui/material';
import { Chart, Panel } from 'pages/manual-trading/introduction/components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Ending, Info, Perfomance, Steps, Welcome } from 'widgets';
import { authPath } from 'shared/config/route-config/auth-routes/consts';
import { useQuery } from 'shared/hooks';

export const SmartTerminal = () => {
  const {
    t, 
  } = useTranslation();

  const navigate = useNavigate();
  const query = useQuery();

  const openTerminal = () => {
    query.delete('mt');
    navigate(`${authPath.register.navigate()}?${query.toString()}`);
  };
  
  return (
    <Stack gap='100px'>
      <Welcome
        title={t('terminal.introduction.welcome.title')}
        image='/images/manual-trading/introduction/1.jpg'
        blocks={[{
          title: t('terminal.introduction.welcome.automate.title'),
          text: t('terminal.introduction.welcome.automate.text'),
        }, {
          title: t('terminal.introduction.welcome.manage.title'),
          text: t('terminal.introduction.welcome.manage.text'),
        }, {
          title: t('terminal.introduction.welcome.share.title'),
          text: t('terminal.introduction.welcome.share.text'),
        }, {
          title: t('terminal.introduction.welcome.control.title'),
          text: t('terminal.introduction.welcome.control.text'),
        }]}
      />
    
      <Steps
        items={[
          t('terminal.introduction.steps.1'),
          t('terminal.introduction.steps.2'),
          t('terminal.introduction.steps.3'),
          t('terminal.introduction.steps.4'),
        ]}
      />
    
      <Info
        title={t('terminal.introduction.positions.title')}
        text={t('terminal.introduction.positions.text')}
        image='/images/manual-trading/introduction/2.jpg'
        button={{
          title: t('terminal.introduction.button.create'),
          action: openTerminal,
        }}
      />
    
      <Perfomance
        items={[{
          title: '500K+',
          text: t('terminal.introduction.perfomance.items.trades.placed'),
        }, {
          title: '100K+',
          text: t('terminal.introduction.perfomance.items.trades.executed'),
        }, {
          title: '10K+',
          text: t('terminal.introduction.perfomance.items.orders'),
        }, {
          title: '$10M+',
          text: t('terminal.introduction.perfomance.items.volume'),
        }]}
      />
    
      <Stack
        flexDirection={{
          xs: 'column',
          sm: 'row',
        }}
        gap='24px'
      >
        <Chart />
    
        <Panel openTerminal={openTerminal} />
      </Stack>
          
      <Info
        title={t('terminal.introduction.control.title')}
        text={t('terminal.introduction.control.text')}
        image='/images/manual-trading/introduction/4.png'
        isReverse={true}
        isColored={true}
        button={{
          title: t('terminal.introduction.button.create'),
          action: openTerminal,
        }}
      />
          
      <Info
        title={t('terminal.introduction.effortless.title')}
        text={t('terminal.introduction.effortless.text')}
        image='/images/manual-trading/introduction/5.jpg'
        isReverse={true}
        button={{
          title: t('terminal.introduction.button.create'),
          action: openTerminal,
        }}
      />
    
      <Ending
        title={t('terminal.introduction.ending.title')}
        text={t('terminal.introduction.ending.text')}
        button={{
          title: t('terminal.introduction.button.create'),
          action: openTerminal,
        }}
      />
    </Stack>
  );
};
