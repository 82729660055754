import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Search, TooltipSingleSelect } from 'shared/ui';
import { IMarketProps } from '../interfaces';

export const Market = (props: IMarketProps) => {
  const {
    value,
    onChange,
    options,
  } = props;

  const {
    t, 
  } = useTranslation();

  const changeMarketHandler = (market: string) => {
    onChange(market);
  };

  const marketDropDown= (menu: any) => {
    return (
      <Stack gap='4px'>
        <Search
          value=''
          maxWidth={450}
          placeholder='Search'
          onChange={() => {}}
        />

        {menu}
      </Stack>
    );
  };
  
  return (
    <TooltipSingleSelect
      tooltip={t('home.statistics.select.tooltips.1')}
      options={options}
      select={{
        value,
        placeholder: 'Market',
        onChange: changeMarketHandler,
      }}
      dropdown={marketDropDown}
    />
  );
};
