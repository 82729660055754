import { Player } from '@lottiefiles/react-lottie-player';
import { Stack } from '@mui/material';
import { getExchange, getMarket } from 'entities/choose-account';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCoins } from 'shared/consts';
import { Link, Text, Title } from 'shared/ui';
import { COIN, INNER, TEXT, TITLE, WRAPPER } from '../styles';

export const Execution = () => {
  const exchange = useSelector(getExchange);
  const market = useSelector(getMarket);
  const {
    t, 
  } = useTranslation();

  return (
    <Stack sx={WRAPPER}>
      <Stack
        gap='46px'
        alignItems='center'
      >
        <Title styles={TITLE}>
          {t('bots.introduction.alert.execution.title')}
        </Title>

        <Stack
          gap='34px'
          alignItems='center'
        >
          <Text styles={TEXT}>
            {t('bots.introduction.alert.execution.text')}
          </Text>

          <Stack sx={INNER}>
            {getCoins(exchange, market).map((item, index) => (
              <Stack
                key={index}
                sx={COIN}
              >
                <Link
                  href={item.link}
                  target='_blank'
                >
                  {item.coin}
                </Link>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>

      <Stack flex='1'>
        <Player
          autoplay
          loop
          src='/videos/alert-bot.json'
          style={{
            width: '100%',
            height: 250,
            maxWidth: 550,
          }}
        />
      </Stack>
    </Stack>
  );
};
