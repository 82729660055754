import dayjs from 'dayjs';
import createAxiosInstance from 'shared/api/axios/axios';
import { fetchPaginatedExchanges } from 'shared/api/axios/helpers/fetch-paginated-exchanges';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface GetSnapshotsByDateParams {
  skyrexUserUuid: string;
  exchangeAccountUuids: string[];
  dates: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;
}

export const getSnapshotsByDate = async (props: GetSnapshotsByDateParams) => {
  const {
    skyrexUserUuid,
    exchangeAccountUuids,
    dates, 
  } = props;
  
  const response = await fetchPaginatedExchanges({
    axios,
    endpoint: '/exchangeAccount/getByFilter',
    requestData: {
      table: 'exchange_account_snapshots',
      skyrexUserUuids: [skyrexUserUuid],
      exchangeAccountUuids: exchangeAccountUuids,
      createdDateFrom: dates?.[0] && dates[0].format('YYYY-MM-DD HH:mm:ss'),
      createdDateTo: dates?.[1] && dates[1].format('YYYY-MM-DD HH:mm:ss'),
    },
  });
    
  return response;
};
