import { SxProps, Theme } from '@mui/material';

export const filters: SxProps<Theme> = {
  display: 'grid',
  gridTemplate: `
    'exchange exchange type type market market market create_date'
    'bot base_currency status status status status status close_date'
  `,
  gap: {
    xs: '8px',
    lg: '24px',
  },
  flex: 1,
  width: '100%',
  '@media (max-width: 1085px)': {
    gridTemplate: `
    'exchange exchange exchange type market'
    'base_currency base_currency bot bot status'
    'create_date create_date close_date close_date close_date'
  `,
    '&>div>div': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  '@media (max-width: 510px)': {
    gridTemplate: `
    'exchange exchange exchange exchange'
    'type type type type'
    'market market market market'
    'bot bot bot bot'
    'base_currency base_currency base_currency base_currency'
    'status status status status'
    'create_date create_date create_date create_date'
    'close_date close_date close_date close_date'
  `,
  },
};
