import { TLanguage, TTranslation } from 'shared/types';

export const introduction = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      introduction: {
        tabs: {
          copy: 'Copy bots',
          alert: 'Alert bots',
        },
        copy: {
          welcome: {
            title: 'Welcome to Copy bots ',
            start: {
              title: 'Start for free',
              text: 'Copy any bot for free — no credit card or hidden fees to start trading',
            },
            copy: {
              title: 'Copy in a click',
              text: 'Simply set trading amount you want to use, rest is handled by the source bot',
            },
            monthly: {
              title: 'Monthly calculation',
              text: 'Profit sharing is monthly, includes active positions — no fees per closed trade',
            },
            high: {
              title: 'High-water mark protection',
              text: 'Negative results carry over to future periods, protecting from double fees',
            },
          },
          steps: {
            1: 'Choose a bot at Marketplace',
            2: 'Click copy bot',
            3: 'Set trading amount',
            4: 'Receive automated trades',
          },
          positions: {
            title: 'Get effortless trades',
            text: 'Multi-order positions on leading exchanges, opened automatically by bots - no more need to monitor charts!',
          },
          perfomance: {
            title: 'Performed by our users',
            items: {
              trades: 'Trades copied',
              bots: 'Copy bots launched',
              profit: 'Profit earned',
              volume: 'Volume traded',
            },
          },
          marketplaceTitle: 'Ready-made bots to automate your trading instantly',
          fair: {
            title: 'Fair copy trading standard',
            text: 'No upfront fees — only profit sharing based on monthly performance with \n high-water mark protection. Commissions are paid when your portfolio exceeds the starting amount, considering previous losses',
          },
          control: {
            title: 'Smart control over trades',
            text: 'Full real time management of active positions with progress bar and action buttons: control size, adjust \n risk management, close or cancel trade any time',
          },
          ending: {
            title: 'Copy bots in a click with Skyrexio',
            text: 'Get automated trades managed by professionals with no actions from your side under high-water mark and monthly profit sharing protection',
          },
        },
        alert: {
          welcome: {
            title: 'Welcome to Alert bots',
            universal: {
              title: 'Universal automation',
              text: 'Turn TradingView and other alerts into trades on 8 exchanges — supports strategies',
            },
            control: {
              title: 'Control and analytics',
              text: 'Manage trades in real time and analyze performance with charts and diary',
            },
            easy: {
              title: 'Easy configuration',
              text: 'Open and manage positions with script alerts or set risk management in the configurator',
            },
            share: {
              title: 'Share and earn',
              text: 'Boost your signals with seamless and scalable copy trading and unlock extra sales',
            },
          },
          steps: {
            1: 'Alerts triggered by your script',
            2: 'Bot processes alerts',
            3: 'Orders filled on exchange',
            4: 'Manage trades in real time',
          },
          relax: {
            title: 'Relax while bots handle routine',
            text: 'Skyrexio enables traders to trade smarter by reducing emotional decision-making and automating routine tasks',
          },
          perfomance: {
            title: 'Performed by our users',
            items: {
              alerts: 'Alerts executed',
              bots: 'Bots launched',
              trades: 'Trades created',
              volume: 'Volume traded',
            },
          },
          execution: {
            title: 'Automated alerts execution',
            text: 'Turn alerts from TradingView, Java and Python scripts into orders in your exchange account. Manage multiple pairs with a single bot',
          },
          control: {
            title: 'Smart control over trades',
            text: 'Full real time management of active positions with progress bar and action buttons: control size, adjust \n risk management, close or cancel trade any time',
          },
          configuratorTitle: 'All-in-one intuitive configurator for seamless setup',
          share: {
            title: 'Share your bots',
            text: 'Empower your trading community enabling to copy your bots with a single click. Trades and bot settings sync automatically across all members. Boost sales with Skyrexio Marketplace and earn profit-sharing rewards',
          },
          tradingView: {
            title: 'TradingView integrated',
            text: 'Skyrexio enables you to utilize all benefits of TradingView PineScript with webhook automated execution of any complexity Indicators and strategies',
          },
          ending: {
            title: 'Execute scripts on major exchages with Skyrexio',
            text: 'Take control of your trades with Alert Bot, which adapts to your preferences and handles the complexities—leaving emotions out of the equation',
          },
        },
        button: {
          create: {
            trade: 'Create trade',
            bot: 'Create Alert Bot',
          },
          choose: 'Choose bot',
          open: 'Open configurator',
        },
      },
    },
    ru: {
      introduction: {
        tabs: {
          copy: 'Копи боты',
          alert: 'Алерт боты',
        },
        copy: {
          welcome: {
            title: 'Добро пожаловать в Копи боты',
            start: {
              title: 'Начните бесплатно',
              text: 'Копируйте любых ботов бесплатно — без карты и скрытых комиссий',
            },
            copy: {
              title: 'Копируйте в один клик',
              text: 'Просто укажите желаемый торговый объём, остальное возьмёт на себя мастер бот',
            },
            monthly: {
              title: 'Ежемесячный расчёт',
              text: 'Распределение прибыли происходит ежемесячно, включая активные позиции — без сиюминутных комиссии за закрытые сделки',
            },
            high: {
              title: 'Защита high-water mark',
              text: 'Отрицательные результаты переносятся на будущие периоды, защищая от двойных комиссий',
            },
          },
          steps: {
            1: 'Выберите бота в Маркетплейсе',
            2: 'Нажмите «Копировать бота»',
            3: 'Укажите торговый объём',
            4: 'Получайте автоматические сделки',
          },
          positions: {
            title: 'Получайте сделки без усилий',
            text: 'Позиции с несколькими ордерами на ведущих биржах, открываемые автоматически ботами — больше не нужно следить за графиками!',
          },
          perfomance: {
            title: 'Результаты наших пользователей',
            items: {
              trades: 'Скопировано сделок',
              bots: 'Запущено Копи ботов',
              profit: 'Заработано прибыли',
              volume: 'Объём торговли',
            },
          },
          marketplaceTitle: 'Готовые боты для мгновенной автоматизации торговли',
          fair: {
            title: 'Справедливый стандарт копи-трейдинга',
            text: 'Никаких авансовых платежей — только разделение прибыли по итогам месяца с защитой high-water mark. Комиссии взимаются, когда ваш портфель превышает изначальную сумму, учитывая предыдущие убытки',
          },
          control: {
            title: 'Умный контроль над сделками',
            text: 'Полное управление активными позициями в реальном времени с прогресс-баром и кнопками действий: контролируйте объём, настраивайте риск-менеджмент, закрывайте или отменяйте сделку в любое время',
          },
          ending: {
            title: 'Копируйте боты одним кликом вместе со Skyrexio',
            text: 'Получайте автоматические сделки под управлением профессионалов без вашего участия с защитой high-water mark и ежемесячным разделением прибыли',
          },
        },              
        'alert': {
          'welcome': {
            'title': 'Добро пожаловать в Алерт боты',
            'universal': {
              'title': 'Универсальная автоматизация',
              'text': 'Преобразуйте сигналы из TradingView и других источников в сделки на крупнейших биржах — с поддержкой стратегий',
            },
            'control': {
              'title': 'Контроль и аналитика',
              'text': 'Управляйте сделками в реальном времени и анализируйте результаты с помощью графиков и журнала',
            },
            'easy': {
              'title': 'Простая настройка',
              'text': 'Открывайте и управляйте позициями с помощью алертов от скриптов или задавайте риск-менеджмент в конфигураторе',
            },
            'share': {
              'title': 'Делитесь и зарабатывайте',
              'text': 'Увеличьте прибыль с помощью простого и масштабируемого копитрейдинга на Маркетплейсе',
            },
          },
          'steps': {
            '1': 'Сигналы генерируются вашим скриптом',
            '2': 'Бот обрабатывает сигналы',
            '3': 'Ордеры исполняются на бирже',
            '4': 'Управляйте сделками в реальном времени',
          },
          'relax': {
            'title': 'Отдыхайте, пока боты занимаются рутиной',
            'text': 'Skyrexio помогает трейдерам торговать умнее, снижая эмоциональное влияние и автоматизируя рутинные задачи',
          },
          'perfomance': {
            'title': 'Достижения наших пользователей',
            'items': {
              'alerts': 'Исполнено алертов',
              'bots': 'Запущено ботов',
              'trades': 'Создано сделок',
              'volume': 'Объём торгов',
            },
          },
          'execution': {
            'title': 'Автоматическое исполнение сигналов',
            'text': 'Преобразуйте сигналы из TradingView, Java и Python-скриптов в ордера на вашем биржевом аккаунте. Управляйте несколькими парами с помощью одного бота',
          },
          'control': {
            'title': 'Умный контроль над сделками',
            'text': 'Полное управление активными позициями в реальном времени с прогресс-баром и кнопками действий: контролируйте объём, регулируйте \nриск-менеджмент, закрывайте или отменяйте сделку в любой момент',
          },
          'configuratorTitle': 'Универсальный и удобный конфигуратор для быстрой настройки',
          'share': {
            'title': 'Делитесь своими ботами',
            'text': 'Расширьте возможности своего трейдинг-сообщества, позволяя копировать ваших ботов одним кликом. Сделки и настройки синхронизируются автоматически у всех участников. Повышайте продажи с помощью Маркетплейса Skyrexio и получайте вознаграждения от прибыли',
          },
          'tradingView': {
            'title': 'Интеграция с TradingView',
            'text': 'Skyrexio позволяет использовать все преимущества PineScript от TradingView с автоматическим выполнением через вебхук индикаторов и стратегий любой сложности',
          },
          'ending': {
            'title': 'Исполняйте скрипты на ведущих биржах вместе со Skyrexio',
            'text': 'Возьмите под контроль свои сделки с помощью Alert Bot, который подстраивается под ваши предпочтения и решает сложные задачи, убирая эмоции из процесса',
          },
        },
        button: {
          create: {
            trade: 'Создать сделку',
            bot: 'Создать алерт бота',
          },
          choose: 'Выбрать бота',
          open: 'Открыть конфигуратор',
        },
      },
    },
  };

  return transaltion[type].introduction;
};
