import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  alignItems: {
    xs: 'initial',
    sm: 'center',
  },
  gap: '24px',
  flexDirection: {
    xs: 'column-reverse',
    sm: 'row',
  },
};
