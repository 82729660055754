import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  padding: '4px',
  backgroundColor: '#F6F6F6',
  borderRadius: 2,
  width: '100%',
  minWidth: 111,
  maxWidth: '100%',
  flex: 1,
};
