import { Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useExcahnges, useQuery } from 'shared/hooks';
import { MainButton, Onboarding } from 'shared/ui';

export const OnboardingButton = () => {
  const {
    t, 
  } = useTranslation();
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    values,
  } = useExcahnges();

  const openModalHandler = () => {
    navigate(`${location.pathname}?mt=onboarding`);

    localStorage.setItem('connection_type', 'onboarding');
    localStorage.setItem('prev_location', location.pathname);
  };

  const closeModalHandler = () => {
    navigate(location.pathname);

    localStorage.removeItem('connection_type');
    localStorage.removeItem('prev_location');
  };

  const isOpen = query.get('mt') === 'onboarding';
  const isSuccess = query.get('status');

  return (
    <Hidden smDown>
      <MainButton
        type='primary'
        ghost={true}
        onClick={openModalHandler}
      >
        {t('header.button')}
      </MainButton>

      <Onboarding
        isOpen={!!isOpen}
        closeHandler={values.exchanges?.length ? closeModalHandler : undefined}
        isSuccess={!!isSuccess}
      />
    </Hidden>
  );
};
