import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { AdaptedSymbol } from 'shared/helpers/adapt-exchange-symbol';
import { calculateDefaultValues } from './calculate-default-values';
import { formatByPrecisionAndTrim } from './helpers';

interface CalculateDefaultFormValuesProps {
    chartLastPrice: number;
    selectedSide: 'buy' | 'sell' | 'long' | 'short';
    currentSymbol: AdaptedSymbol;
    userWalletQuoteAssetBalance: WalletData;
  }
  
  interface DefaultFormValues {
    orderPrice: string;
    triggerPrice: string;
    userWalletQuoteAsset: WalletData;
  }
  
export const calculateDefaultFormValues = ({
  chartLastPrice,
  selectedSide,
  currentSymbol,
  userWalletQuoteAssetBalance,
}: CalculateDefaultFormValuesProps): DefaultFormValues => {
  const formattedPrice = formatByPrecisionAndTrim(
    chartLastPrice.toString(), 
    currentSymbol?.quoteAssetPrecision || 8, 
    Number(currentSymbol?.priceMin) || 0, 
    Number(currentSymbol?.priceMax) || 999999999,
  );
  
  const defaultValues = calculateDefaultValues({
    chartLastPrice,
    selectedSide,
    currentSymbol,
    userWalletQuoteAssetBalance,
  });
  
  return {
    orderPrice: formattedPrice,
    triggerPrice: formattedPrice,
    userWalletQuoteAsset: userWalletQuoteAssetBalance,
    ...defaultValues,
  };
};
