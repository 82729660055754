import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { getUser } from 'entities/user/api';
import { userActions } from 'entities/user/model/slices/user-slice';
import { Banner } from 'pages/home/components';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { appPath } from 'shared/config/route-config/app-routes/consts';
import { authTemplates } from 'shared/consts';
import {
  getCurrentLastLoginDate,
  getLanguage,
  getSkyrexUuid,
  setCurrentLastLoginDate,
  setDemoMode,
  setLanguage,
  setReferralCode,
} from 'shared/helpers/storage-helper';
import { useTrack } from 'shared/hooks';
import { Trial } from 'shared/ui';
import { Layout } from '../../shared/layout';
import { Footer, Header } from '../../widgets';

export const BaseLayout = (props: Record<'children', ReactNode>) => {
  const [isTrialModalOpened, setIsTrialModalOpened] = useState<boolean>(false);
  
  const navigate = useNavigate();
  const location = useLocation();
  const disptach = useDispatch();
  const {
    i18n, 
  } = useTranslation();
  
  useTrack();

  const closeModal = () => {
    setIsTrialModalOpened(false);
    setCurrentLastLoginDate(dayjs().format());
    navigate(`${location.pathname}?mt=onboarding`);
  };

  const getUserHandler = async () => {
    const response = await getUser([getSkyrexUuid()!]);
    if (!response?.data?.users?.length) {
      return;
    }

    const user = response?.data?.users?.[0];
    const isDemoEnabled = user.isDemoEnabled;
    const is2faEnabled = user.is2faEnabled;
    const language = user.language || getLanguage() || 'en';
    
    setDemoMode(isDemoEnabled ? 'demo' : '');
    setLanguage(language);

    disptach(userActions.setIsDemoMode(isDemoEnabled));
    disptach(userActions.setLanguage(language));
    disptach(userActions.setIs2faEnabled(is2faEnabled));
    i18n.changeLanguage(language);
    setReferralCode(user.referralCode);
  };

  useEffect(() => {
    const lastLoginDate = getCurrentLastLoginDate();
    if (lastLoginDate === null || lastLoginDate === 'null') {
      setIsTrialModalOpened(true);
    }
  }, []);

  useEffect(() => {
    if (authTemplates.includes(location.pathname)) {
      navigate(appPath.home.path);
    }
    
    getUserHandler();
  }, [location.pathname]);

  return (
    <Layout
      header={(
        <Header />
      )}
      footer={(
        <Footer />
      )}
    >
      <Trial
        isOpened={isTrialModalOpened}
        closeHandler={closeModal}
      />

      <Stack gap='24px'>
        <Banner />
      
        {props.children}
      </Stack>
    </Layout>
  );
};
