import { Stack } from '@mui/material';
import { Collapse } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Down } from 'shared/icons';
import { Icon, MainButton } from 'shared/ui';
import { AddBot } from '../../../components';
import { menus } from '../../../consts';
import { ExpandMenuItem, MenuItem } from '../components';
import { BUTTON } from '../components/menu-item/styles';

export const useGetMenu = () => {
  const {
    t, 
  } = useTranslation();

  const getMenu = () => {
    const items = menus(t).map((menuItem): ReactNode => {
      if (!menuItem.path) {
        return (
          <Collapse
            key={menuItem.key}
            ghost={true}
            accordion={true}
            items={[{
              key: menuItem.key,
              showArrow: false,
              label: (
                <MainButton
                  size='large'
                  styles={BUTTON()}
                  icon={(
                    <Icon width={16}>
                      {Down}
                    </Icon>
                  )}
                  iconPosition='end'
                >
                  {menuItem.label}
                </MainButton>
              ),
              children: (
                <Stack gap='4px'>
                  {menuItem.children!.map((item) => {
                    return (
                      <ExpandMenuItem item={item} />
                    );
                  })}

                  {menuItem.key === 'trading_bots_my_bots' && (
                    <AddBot type='link' />
                  )}
                </Stack>
              ),
            }]}
          />
        );
      }
      
      return (
        <MenuItem
          key={menuItem.key}
          item={menuItem}
        />
      );
    });

    return items;
  };

  return getMenu;
};
