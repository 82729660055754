import { TLanguage, TTranslation } from 'shared/types';

export const successFunds = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      funds: {
        copy: 'Link copied to clipboard',
      },
    },
    ru: {
      funds: {
        copy: 'Ссылка скопирована',
      },
    },
  };

  return transaltion[type].funds;
};
