import { TLanguage, TTranslation } from 'shared/types';

export const modalConfirmTransaction = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      confirmTransaction: {
        title: { 
          create: 'Confirm your transaction',
          update: 'Confirm updating trade',
        },
        table: {
          title: {
            base: 'Base order',
            additional: 'Additional base orders',
            takeProfit: 'Take profit targets',
            stopLoss: 'Stop loss',
          },
          columns: {
            common: {
              side: 'Side',
              price: 'Price',
              units: 'Units',
              total: 'Total',
              type: 'Type',
            },
            takeProfit: {
              volume: 'Volume',
              price: 'Price',
              percent: 'Percent',
              calculation: 'Calculation',
              type: 'Type',
            },
            stopLoss: {
              percent: 'Percent',
              price: 'Price',
              calculation: 'Calculation',
              type: 'Type',
            },
          },
          type: {
            base: {
              buy: 'Buy',
              sell: 'Sell',
            },
            limit: 'Limit',
            market: 'Market',
            conditional: 'Conditional',
            'cond_limit': 'Cond.limit',
            'cond_market': 'Cond.market',
          },
        },
        button: 'Confirm',
      },
    },
    ru: {
      confirmTransaction: {
        title: { 
          create: 'Подтвердите вашу сделку',
          update: 'Подтвердите обновление сделки',
        },
        table: {
          title: {
            base: 'Базовый ордер',
            additional: 'Дополнительные ордера',
            takeProfit: 'Цели тейк-профита',
            stopLoss: 'Стоп-лосс',
          },
          columns: {
            common: {
              side: 'Сторона',
              price: 'Цена',
              units: 'Количество',
              total: 'Сумма',
              type: 'Тип',
            },
            takeProfit: {
              volume: 'Объем',
              price: 'Цена',
              percent: 'Процент',
              calculation: 'Расчет',
              type: 'Тип',
            },
            stopLoss: {
              percent: 'Процент',
              price: 'Цена',
              calculation: 'Расчет',
              type: 'Тип',
            },
          },
          type: {
            base: {
              buy: 'Покупка',
              sell: 'Продажа',
            },
            limit: 'Лимитный',
            market: 'Рыночный',
            conditional: 'Условный',
            'cond_limit': 'Усл. лимит',
            'cond_market': 'Усл. маркет',
          },
        },
        button: 'Подтвердить',
      },
    },
  };

  return transaltion[type].confirmTransaction;
};
