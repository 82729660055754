import { Stack } from '@mui/material';
import { getUserEmail } from 'shared/helpers/storage-helper';
import { AccountSolid } from 'shared/icons';
import { Icon, Text } from 'shared/ui';
import { EMAIL, WRAPPER } from '../styles';

export const Profile = () => {
  return (
    <Stack sx={WRAPPER}>
      <Icon width={16}>
        {AccountSolid}
      </Icon>

      <Text styles={EMAIL}>
        {getUserEmail()}
      </Text>
    </Stack>
  );
};
