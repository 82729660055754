import { SxProps, Theme } from '@mui/material';

export const button: SxProps<Theme> = {
  height: 'auto',
  flex: 1,
  padding: '20px 18px',
  borderRadius: '6px',
  backgroundColor: '#fff',
  border: '1px solid #D2D2D2',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '14px',
  transition: 'border-color .125s linear',
  cursor: 'pointer',
  '&:hover': {
    borderColor: '#2ECD99',
  },
};
