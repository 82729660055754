import { OauthBinance, OauthBybit, OauthCryptoCom, OauthGateio, OauthHtx, OauthKucoin, OauthOkx } from 'features/oauth/ui/create-oauth';
import { MiddleOauthKucoin } from 'features/oauth/ui/middle-oauth';
import { appPath } from '../app-routes/consts';
import { IPage } from '../interfaces';

export const oathCreateRoutes = () => {
  const routes: IPage[] = [{
    title: 'Crypto-com',
    path: appPath.cryptocom_oauth.path,
    element: (
      <OauthCryptoCom />
    ),
  }, {
    title: 'ByBit',
    path: appPath.bybit_create_oauth.path,
    element: (
      <OauthBybit />
    ),
  }, {
    title: 'Binance',
    path: appPath.bybit_create_oauth.path,
    element: (
      <OauthBinance />
    ),
  }, {
    title: 'Okx',
    path: appPath.okx_create_oauth.path,
    element: (
      <OauthOkx />
    ),
  }, {
    title: 'Gateio',
    path: appPath.gateio_create_oauth.path,
    element: (
      <OauthGateio />
    ),
  }, {
    title: 'Htx',
    path: appPath.htx_create_oauth.path,
    element: (
      <OauthHtx />
    ),
  }, {
    title: 'Kucoin',
    path: appPath.kucoin_create_oauth.path,
    element: (
      <OauthKucoin />
    ),
  }, {
    title: 'Kucoin',
    path: appPath.kucoin_oauth.path,
    element: (
      <MiddleOauthKucoin />
    ),
  }];

  return routes;
};
