import { createAsyncThunk } from '@reduxjs/toolkit';
import { setShortExchangesData } from 'entities/exchange/helpers/set-short-exchanges-data';
import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { calculateMarketOptions, getFilteredTradingPairs, setDefaultTradingPairAndSymbol } from 'pages/manual-trading/trading-terminal/helpers/trading-data-symbols-utils';
import { getUniqueSymbolCodes } from 'pages/manual-trading/trading-terminal/helpers/trading-data-symbols-utils';
import { getTerminalSnapshotsData } from 'pages/trading-terminal-page/order-panel/ExchangeForm/helpers/get-snapshots-terminal-page';
import { getExchangeSymbols } from 'pages/trading-terminal-page/trading-chart/helpers/get-symbols';
import { getExchanges } from 'widgets/exchanges/api/get-exchanges';
import { getDemoAccount } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

interface InitializeParams {
  isDemoMode: boolean;
  sharedTradeData?: any;
}

export const initializeTradingData = createAsyncThunk(
  'trading/initializeData', 
  async (params: InitializeParams, thunkParams) => {
    const {
      isDemoMode, sharedTradeData, 
    } = params;
    const {
      rejectWithValue, 
    } = thunkParams;

    try {
      const skyrexUserUuid = getSkyrexUuid();
      const exchangesResponse = await setShortExchangesData(skyrexUserUuid ?? '');
      const filteredAccounts = getDemoAccount(exchangesResponse, isDemoMode);
      const allExchangeAccountUuids = filteredAccounts.map((exchange: any) => exchange.exchangeAccountUuid);
      const allExchangesFinanceData = await getExchanges(allExchangeAccountUuids);
      
      const balancesMap = allExchangesFinanceData?.data?.accounts?.reduce((acc: any, account: any) => {
        acc[account.exchangeAccountUuid] = account?.balances;
        return acc;
      }, {});

      const leverageTypeMap = allExchangesFinanceData.data.accounts.reduce((acc: any, account: any) => {
        acc[account.exchangeAccountUuid] = account.marginMode;
        return acc;
      }, {});

      let transformedExchanges = filteredAccounts.map((exchange: any) => ({
        label: exchange.accountName,
        value: exchange.exchangeAccountUuid,
        exchangeCode: exchange.exchangeCode,
        balances: balancesMap[exchange.exchangeAccountUuid] || [],
        leverageType: leverageTypeMap[exchange.exchangeAccountUuid]?.toLowerCase() || null,
      }));

      // Если биржи отсутствуют, возвращаем пустую биржу
      if (transformedExchanges.length === 0) {
        transformedExchanges = [{
          label: 'Нет подключенных бирж',
          value: 'no_exchange',
          exchangeCode: 'binance',
          balances: [],
        }];
      }
    
      // Выбор биржи с учетом shared trade данных
      let defaultExchange;

      const getQuoteAssetBalance = (exchange: any, quoteAsset = 'USDT') => {
        const balance = exchange.balances.find(
          (b: any) => b.symbol === quoteAsset,
        );
        return balance ? parseFloat(balance.free) : 0;
      };
      
      if (sharedTradeData?.exchangeCode) {
        const exchangesWithSameCode = transformedExchanges.filter(
          (exchange: any) => exchange.exchangeCode === sharedTradeData.exchangeCode,
        );
        
        const otherExchanges = transformedExchanges.filter(
          (exchange: any) => exchange.exchangeCode !== sharedTradeData.exchangeCode,
        );
      
        const sortedTargetExchanges = exchangesWithSameCode.sort((a: any, b: any) => {
          const aBalance = getQuoteAssetBalance(a, sharedTradeData.quoteAsset);
          const bBalance = getQuoteAssetBalance(b, sharedTradeData.quoteAsset);
          return bBalance - aBalance;
        });
      
        const sortedOtherExchanges = otherExchanges.sort((a: any, b: any) => {
          const aBalance = getQuoteAssetBalance(a, sharedTradeData.quoteAsset);
          const bBalance = getQuoteAssetBalance(b, sharedTradeData.quoteAsset);
          return bBalance - aBalance;
        });
      
        transformedExchanges = [...sortedTargetExchanges, ...sortedOtherExchanges];
        
        defaultExchange = transformedExchanges[0];
      } else {
        const sortedExchanges = transformedExchanges.sort((a: any, b: any) => {
          const aBalance = getQuoteAssetBalance(a);
          const bBalance = getQuoteAssetBalance(b);
          return bBalance - aBalance;
        });
        defaultExchange = sortedExchanges[0];
      }
            
      // Если defaultExchange по-прежнему не найден, задаём пустую биржу
      if (!defaultExchange) {
        defaultExchange = {
          label: 'Нет подключенных бирж',
          value: 'no_exchange',
          exchangeCode: 'binance',
          balances: [],
        };
      }
      
      const exchangeSymbols = await getExchangeSymbols(defaultExchange.exchangeCode);
      const uniqueSymbolCodes = getUniqueSymbolCodes(exchangeSymbols);
      const financeData = allExchangesFinanceData?.data?.accounts?.length ? await getExchanges([defaultExchange.value]) : [];
      const chosenExchange = financeData?.data?.accounts[0];
      const userWalletBalances = chosenExchange?.balances ?? [];
      
      // Определение market options и default market с учетом shared trade
      const marketOptions = calculateMarketOptions(
        chosenExchange?.balances ?? [],
        uniqueSymbolCodes,
      );

      let defaultMarket = marketOptions.find((el: any) => el.label === 'USDT') || marketOptions[0];
      
      if (sharedTradeData?.quoteAsset) {
        defaultMarket = marketOptions.find((el: any) => el.label === sharedTradeData.quoteAsset) || defaultMarket;
      }

      const tradingPairs = getFilteredTradingPairs(
        exchangeSymbols,
        defaultMarket,
        chosenExchange?.balances ?? [],
      );

      // Определение trading pair и symbol с учетом shared trade
      let defaultTradingPair: any, currentSymbol: any;
      
      if (sharedTradeData?.baseAsset && sharedTradeData?.quoteAsset) {
        const targetSymbol = exchangeSymbols.find(
          (symbol: any) => 
            symbol.baseAsset === sharedTradeData.baseAsset && 
            symbol.quoteAsset === sharedTradeData.quoteAsset,
        );
        
        if (targetSymbol) {
          currentSymbol = targetSymbol;
          defaultTradingPair = tradingPairs.find(
            (pair: any) => pair.formattedPair === targetSymbol.symbol,
          );
        } else {
          // Если пара не найдена, используем стандартную логику
          const defaultData = setDefaultTradingPairAndSymbol(tradingPairs, exchangeSymbols);
          defaultTradingPair = defaultData.defaultTradingPair;
          currentSymbol = defaultData.currentSymbol;
        }
      } else {
        const defaultData = setDefaultTradingPairAndSymbol(tradingPairs, exchangeSymbols);
        defaultTradingPair = defaultData.defaultTradingPair;
        currentSymbol = defaultData.currentSymbol;
      }

      const userWalletQuoteAssetBalance = userWalletBalances.find(
        (el: WalletData) => el.symbol === currentSymbol?.quoteAsset,
      ) || {
        free: '0', 
      };
      
      const userWalletBaseAssetBalance = userWalletBalances.find(
        (el: WalletData) => el.symbol === currentSymbol?.baseAsset,
      ) || {
        free: '0', 
      };
      
      const dailyChange = allExchangesFinanceData?.data?.accounts?.length ? await getTerminalSnapshotsData(
        chosenExchange, 
        skyrexUserUuid ?? '', 
        chosenExchange?.exchangeAccountUuid ?? '',
      ) : {
        usdtDailyChangePercent: 0,
        usdtDailyChangeValue: 0,
      };

      return {
        exchanges: transformedExchanges,
        selectedExchange: defaultExchange,
        financeData,
        dailyChange,
        symbolCodes: uniqueSymbolCodes,
        marketOptions,
        selectedMarket: defaultMarket,
        tradingPairs,
        chartSymbol: currentSymbol,
        selectedTradingPair: defaultTradingPair,
        currentSymbol,
        userWalletBalances,
        allExchangeSymbols: exchangeSymbols,
        userWalletQuoteAssetBalance,
        userWalletBaseAssetBalance,
        chosenExchange,
      };
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  },
);
