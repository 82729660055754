import { CSSProperties } from 'react';

export const CONNECT_EXCHANGE_BUTTON: CSSProperties = {
  height: 'auto',
  fontSize: 14,
  fontWeight: 400,
  lineHeight: 'normal',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '400px',
  maxWidth: '450px',
  padding: '10px 16px',
  marginTop: '23px',
};
