import { Box, Stack } from '@mui/material';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAuth } from 'shared/hooks';
import { Verify } from 'shared/icons';
import { Link, MainButton, Text, Title } from 'shared/ui';
import { ExchangeTabs } from '../components';
import { IChooseExchange } from '../interfaces';
import {
  contentActions,
  contentButton,
  contentButtons,
  contentInner,
  contentLinks,
  inner,
  subTitle,
  text,
  title,
  titleWrapper,
  verifyIcon,
  wrapper,
} from '../styles';

export const ChooseExchange = (props: IChooseExchange) => {
  const {
    onChoose,
    changeStep,
    onChangeType,
    type,
  } = props;
  
  const {
    t, 
  } = useTranslation();
  const {
    isLoading,
    handlers,
  } = useAuth();

  const handleCreateCryptoAccount = () => {
    window.open('https://www.gate.io/referral/invite/SKYREXIO_0_102', '_blank', 'noopener,noreferrer');
  };

  const handleSwitchDemoAccount = async () => {
    const error = await handlers.enableDemoAccountHandler();
    if (error) {
      toast.error(error);
      return;
    }

    changeStep?.(2);
    onChoose?.('');
  };

  return (
    <Box sx={wrapper}>
      <Stack
        gap='16px'
        alignItems='center'
      >
        <Box sx={titleWrapper}>
          <Title styles={title}>
            {t('exchange.list.title')}
          </Title>

          <Box sx={subTitle}>
            {t('exchange.list.description')}
          </Box>
        </Box>

        <Stack
          width='100%'
          maxWidth={320}
        >
          <Segmented
            size='large'
            options={[{
              label: 'Spot',
              value: 'spot',
            }, {
              label: 'Futures',
              value: 'futures',
            }]}
            onChange={onChangeType}
            block={true}
          />
        </Stack>
      </Stack>

      <Box sx={inner}>
        <ExchangeTabs
          onChoose={onChoose}
          type={type}
          description={(
            <Text
              type='secondary'
              styles={text}
            >
              {t('exchange.list.parnter')}

              <span style={verifyIcon}>
                {Verify}
              </span>
            </Text>
          )}
        />

        <Box sx={contentInner}>
          <Box sx={contentActions}>
            <Text styles={title}>
              {t('exchange.list.exchange.title')}
            </Text>

            <Box sx={contentButtons}>
              <MainButton
                size='large'
                styles={contentButton}
                onClick={handleCreateCryptoAccount}
              >
                {t('exchange.list.exchange.create', {
                  account: 'Gate.io',
                })}
              </MainButton>

              <MainButton
                size='large'
                styles={contentButton}
                onClick={handleSwitchDemoAccount}
                loading={isLoading}
              >
                {t('exchange.list.exchange.switch')}
              </MainButton>
            </Box>
          </Box>

          <Box sx={contentLinks}>
            <Text type='secondary'>
              <Link href='https://www.gate.io/referral/invite/SKYREXIO_0_102'>
                {t('exchange.list.offer.link')}
              </Link>
              {' '}
              {t('exchange.list.offer.text')}
            </Text>

            <Text type='secondary'>
              {t('exchange.list.review.prefix')}
              {' '}
              <Link href='https://kamvvjb40tw.typeform.com/to/HvdNnr8Q?typeform-source=app.skyrexio.com'>
                {t('exchange.list.review.link')}
              </Link>
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
