import { TLanguage, TTranslation } from 'shared/types';

export const errorChangePassword = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      changePassword: {
        title: 'Failed to update password',
        message: '',
        button: 'Okay',
      },
    },
    ru: {
      changePassword: {
        title: 'Не удалось обновить пароль',
        message: '',
        button: 'Ок',
      },
    },
  };

  return transaltion[type].changePassword;
};
