import * as localization from 'shared/localization';

export const enLocalization = {
  translation: {
    auth: localization.auth('en'),
    copyRight: 'All rights reserved',
    banner: localization.banner('en'),
    pages: localization.pages('en'),
    error: {
      login: localization.errorLogin('en'),
      register: localization.errorRegister('en'),
      twoFactor: localization.errorTwoFactor('en'),
      wrong: localization.errorWrong('en'),
      otp: localization.errorOtp('en'),
      exchange: localization.errorExchange('en'),
      bots: localization.errorBots('en'),
      copyBot: localization.errorCopuBot('en'),
      subscription: localization.errorSubscription('en'),
      payment: localization.errorPayment('en'),
      changePassword: localization.errorChangePassword('en'),
      withdraw: localization.errorWithdraw('en'),
      funds: localization.errorFunds('en'),
      bot: localization.errorBot('en'),
    },
    success: {
      login: localization.successLogin('en'),
      register: localization.successRegister('en'),
      recover: localization.successRecover('en'),
      changePassword: localization.successChangePassword('en'),
      exchange: localization.successExchange('en'),
      payment: localization.successPayment('en'),
      twoFactor: localization.successTwoFactor('en'),
      withdraw: localization.successWithdraw('en'),
      funds: localization.successFunds('en'),
    },
    validation: {
      email: localization.validationEmail('en'),
      password: localization.validationPassword('en'),
      otp: localization.validationOtp('en'),
      referralCode: localization.validationRederralCode('en'),
      withdraw: localization.validationWithdraw('en'),
      changePassword: localization.validationChangePassword('en'),
      funds: localization.validationFunds('en'),
      bot: localization.validationBot('en'),
      configurator: localization.validationConfigurator('en'),
      terminal: localization.validationTerminal('en'),
    },
    modal: {
      exchange: localization.modalExchange('en'),
      terminal: localization.modalTerminal('en'),
      bots: localization.modalBots('en'),
      copyBot: localization.modalCopyBot('en'),
      trial: localization.modalTrial('en'),
      subscription: localization.modalSubscription('en'),
      payment: localization.modalPayment('en'),
      plans: localization.modalPlans('en'),
      withdraw: localization.modalWithdraw('en'),
      twoFactor: localization.modalTwoFactor('en'),
      twoFactorDisable: localization.modalTwoFactorDisable('en'),
      changePassword: localization.modalChangePassword('en'),
      onboarding: localization.modalOnboarding('en'),
      addBot: localization.modalAddBot('en'),
      addFunds: localization.modalAddFunds('en'),
      shareResult: localization.modalShareResult('en'),
      bot: localization.modalBot('en'),
      alertSource: localization.modalAlertSource('en'),
      confirmTransaction: localization.modalConfirmTransaction('en'),
      confirmCreateBot: localization.modalConfirmCreateBot('en'),
    },
    tradingStatistics: localization.tradingStatistics('en'),
    aggregateBalance: localization.aggregateBalance('en'),
    withdrawal: localization.withdraw('en'),
    form: {
      login: localization.formLogin('en'),
      register: localization.formRegister('en'),
      recover: localization.formRecover('en'),
      change: localization.formChange('en'),
      twoFactor: localization.formTwoFactor('en'),
      exchange: localization.formExchange('en'),
      payment: localization.formPayment('en'),
      configurator: localization.formConfigurator('en'),
      date: localization.formDate('en'),
      funds: localization.formFunds('en'),
      bot: localization.formBot('en'),
    },
    header: localization.header('en'),
    home: localization.home('en'),
    exchange: localization.exchange('en'),
    terminal: localization.terminal('en'),
    bots: localization.bots('en'),
    bot: localization.bot('en'),
    marketplace: localization.marketplace('en'),
    subscriptions: localization.subscriptions('en'),
    invite: localization.inviteFriends('en'),
    configurator: localization.configurator('en'),
    unknown: localization.unknown('en'),
    emptyChart: localization.emptyChartData('en'),
  },
};
