import dayjs from 'dayjs';
import { TDates } from 'shared/types';

export const getDatePair = (dates: TDates) => {
  const startDate = dates?.[0];
  const endDate = dates?.[1];

  if (startDate && endDate) {
    return {
      start: dayjs(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end: dayjs(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    };
  }

  if (startDate) {
    return {
      start: dayjs(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end: null,
    };
  }

  if (endDate) {
    return {
      start: null,
      end: dayjs(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    };
  }

  return {
    start: null,
    end: null,
  };
};
