import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const connectButton: CSSProperties = {
  height: 'auto',
  padding: '14px 40px',
  fontSize: getFontSize(12, 14),
  display: 'flex',
  alignItems: 'center',
};
