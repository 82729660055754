import { TLanguage, TTranslation } from 'shared/types';

export const aggregateBalance = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      aggregateBalance: {
        title: 'Aggregate balance',
        total: 'Total Assets',
        change: 'Change 24h',
      },
    },
    ru: {
      aggregateBalance: {
        title: 'Совокупный баланс',
        total: 'Всего активов',
        change: 'Изм. 24ч',
      },
    },
  };

  return transaltion[type].aggregateBalance;
};
