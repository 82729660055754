import { ENV } from 'shared/config/environment';
import { exchangeIcons } from 'shared/consts/exchange-icons';
import { TType } from 'shared/types';
import { ExchangeConfig, ExchangeMode } from '../interfaces';

interface BaseField {
    label: string;
    key: string;
    placeholder: string;
    mask?: boolean;
  }

const commonFields: BaseField[] = [{
  label: 'Enter any name',
  key: 'exchangeTitle',
  placeholder: 'Name',
}, {
  label: 'Paste your API key',
  key: 'apiKey',
  placeholder: 'Key',
},{
  label: 'Paste your API secret',
  key: 'apiSecret',
  mask: true,
  placeholder: 'Secret',
}];

const getRedirectUrl = (path: string, mode: ExchangeMode = 'create') => {
  return `${ENV['APP_CLIENT_URL']}${path.replace('{mode}', mode)}`;
};

export const exchangeConfigs: Record<string, ExchangeConfig> = {
  binance: {
    name: 'Binance',
    logo: exchangeIcons['binance'],
    paths: {
      create: '/oauth/create/binance',
      update: '/oauth/update/binance',
    },
    oauthUrl: ({
      mode = 'create', 
      type,
    }: { 
      mode?: ExchangeMode; 
      type?: TType; 
    }) => 
      `https://accounts.binance.com/oauth/authorize?client_id=9PUQLoXYrS&redirect_uri=${encodeURIComponent(getRedirectUrl(`/oauth/{mode}/binance?type=${type}`, mode))}&response_type=code&scope=user%3AopenId%2Ccreate%3Aapikey%2Caccount%3Astatus&state=`,
    createAccountUrl: 'https://accounts.binance.com/register?ref=QB4A8B0X',
    fields: commonFields,
  },
  okx: {
    name: 'OKX',
    logo: exchangeIcons['okx'],
    paths: {
      create: '/oauth/create/okx',
      update: '/oauth/update/okx',
    },
    oauthUrl: ({
      mode = 'create', 
    }: { 
      mode?: ExchangeMode; 
    }) => 
      `https://www.okx.com/account/oauth?access_type=offline&client_id=5456c77cf4d24df885ecc2ab88b90f268XsZQIsq&redirect_uri=${encodeURIComponent(getRedirectUrl('/oauth/{mode}/okx', mode))}&response_type=code&scope=fast_api&state=`,
    createAccountUrl: 'https://www.okx.com/join/82800770',
    fields: [...commonFields, {
      label: 'Passphrase',
      key: 'passphrase',
      placeholder: 'Input here',
    }],
  },
  bybit: {
    name: 'Bybit',
    logo: exchangeIcons['bybit'],
    paths: {
      create: '/oauth/create/bybit',
      update: '/oauth/update/bybit',
    },
    oauthUrl: ({
      mode = 'create', 
      type,
    }: { 
      mode?: ExchangeMode; 
      type?: TType; 
    }) => 
      `https://www.bybit.com/en/oauth?client_id=c1d92cd6aab75&redirect_uri=${encodeURIComponent(getRedirectUrl(`/oauth/{mode}/bybit?type=${type}`, mode))}&response_type=code&scope=openapi&state=`,
    createAccountUrl: 'https://partner.bybit.com/b/skyrexio',
    fields: commonFields,
  },
  gateio: {
    name: 'Gate.io',
    logo: exchangeIcons['gateio'],
    paths: {
      create: '/oauth/create/gateio',
      update: '/oauth/update/gateio',
    },
    oauthUrl: ({
      mode = 'create', 
    }: { 
      mode?: ExchangeMode; 
    }) => 
      `https://www.gate.io/skyrexio/oauth/authorize?response_type=code&client_id=cAQwEpdmCzkmYAdB&redirect_uri=${encodeURIComponent(getRedirectUrl('/oauth/{mode}/gateio', mode))}&scope=read_openid,read_account_status,create_apikey&state=`,
    createAccountUrl: 'https://www.gate.io/referral/invite/SKYREXIO_0_102',
    fields: commonFields,
  },
  //@TODO add prop for exchange code mapping
  crypto: {
    name: 'Crypto.com',
    logo: exchangeIcons['crypto-com'],
    paths: {
      create: '/oauth/create/crypto-com',
      update: '/oauth/update/crypto-com',
    },
    oauthUrl: ({
      mode = 'create', userId = '', exchangeAccountUuid = '', 
    }: {
      mode?: ExchangeMode;
      userId?: string;
      exchangeAccountUuid?: string;
    }) => {
      const referenceId = mode === 'create' 
        ? `create_${userId}` 
        : `update_${exchangeAccountUuid}`;
      
      const referenceUuid = mode === 'create' ? userId : exchangeAccountUuid;
      
      return `https://www.crypto.com/exchange/authorize_broker?broker_id=SKRX&broker_reference_id=${referenceId}&redirect_uri=https%3A%2F%2Fapi.skyrexio.com%2FoauthCallback%2Fcrypto-com%3Freference_uuid%3D${referenceUuid}`;
    },
    createAccountUrl: 'https://crypto.com/exch/q23nneube7',
    fields: commonFields,
  },
  bitmart: {
    name: 'Bitmart',
    logo: exchangeIcons['bitmart'],
    isOauthDisabled: true,
    oauthUrl: '',
    createAccountUrl: 'https://www.bitmart.com/invite/VShg8M/en',
    fields: [...commonFields, {
      label: 'Memo',
      key: 'passphrase',
      placeholder: 'Input here',
    }],
  },
  htx: {
    name: 'HTX',
    logo: exchangeIcons['htx'],
    paths: {
      create: '/oauth/create/htx',
      update: '/oauth/update/htx',
    },
    oauthUrl: ({
      mode = 'create',
    }: {
      mode?: ExchangeMode;
    }) => {
      const urls = {
        create: 'https://www.huobi.com/en-us/broker/empower?brokerId=AA21941d0a&brokerSecret=VUxxWDkvZzhZcjlCbnlyS1F0TkM3YnQ5WS9lMnNPZUZqWVEzRGpjZWtWOWxISWJFV3R6dmlJeUUydmdzMUx5Kw',
        update: 'https://www.huobi.com/en-us/broker/empower?brokerId=AA21941d0a&brokerSecret=M1p4SWtBUUlCRW5DK3ltaWVZU0VCUlFRV0VqRVRiS3pJdVJ1UEhpN2U5QmVQcS9CUGJkbWZjSUVqNlhvbVViRg',
      };
      return urls[mode];
    },
    createAccountUrl: 'https://www.htx.com/en-us/v/register/double-invite/web/?inviter_id=11345710&invite_code=hy7r9223',
    fields: commonFields,
  },
  kucoin: {
    name: 'Kucoin',
    logo: exchangeIcons['kucoin'],
    paths: {
      create: '/oauth/create/kucoin',
      update: '/oauth/update/kucoin',
    },
    oauthUrl: ({
      mode = 'create',
      userId,
      exchangeAccountUuid,
    }: {
      mode?: ExchangeMode;
      userId?: string;
      exchangeAccountUuid?: string;
    }) => {
      const url = ENV['APP_CLIENT_URL'].replace(/htt(p|ps):\/\//, '');
      const state = mode === 'create' ? `create-${userId}` : `update-${exchangeAccountUuid}`;

      return `https://www.kucoin.com/oauth?response_type=code&client_id=Skyrexio&redirect_uri=https%3A%2F%2F${url}%2Foauth%2Fkucoin&scope=OAUTH_CREATE_API&state=${state}`;
    },
    createAccountUrl: 'https://www.kucoin.com/r/af/skyrexio',
    fields: [...commonFields, {
      label: 'Passphrase',
      key: 'passphrase',
      placeholder: 'Input passphrase',
      mask: true,
    }],
  },
};
