import { SxProps, Theme } from '@mui/material';

export const imgWrapper: SxProps<Theme> = {
  width: '100%',
  flex: 1,
  maxWidth: '500px',
  position: 'relative',
  '&>img': {
    width: '100%',
    height: '100%',
    display: 'block',
  },
};
