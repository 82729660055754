import { getUserAuthenticated } from 'entities/user/model/selectors/get-user-authenticated/get-user-authenticated';
import { Fragment, memo, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppRoutes, AuthRoutes } from 'shared/config/route-config';
import { appPath } from 'shared/config/route-config/app-routes/consts';

const AppRouter = (props: Record<'yandex', ReactNode>) => {
  const isAuthorized = useSelector(getUserAuthenticated);

  const getRoutes = () => {
    if (isAuthorized) {
      return AppRoutes();
    }

    return AuthRoutes();
  };

  return (
    <Fragment>
      {props.yandex}
      
      <Routes>
        <Route
          path='/'
          element={(
            <Navigate to={appPath.home.path} />
          )}
        />

        {getRoutes()}
      </Routes>
    </Fragment>
  );
};

export default memo(AppRouter);
