import { Deal } from 'pages/trading-bots/my-bot/api/get-bot-deals-uuids';

export const getUniqueDealsStatuses = (deals: Deal[], t: any): { label: string; value: string; }[] => {
  const uniqueStatuses = Array.from(new Set(deals.map(deal => deal.status)));
  
  const statusOptions = uniqueStatuses.map(symbol => ({
    label: t(`bots.diary.select.status.items.${symbol}`),
    value: symbol,
  }));
  
  return statusOptions;
};
