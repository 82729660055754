import { TLanguage, TTranslation } from 'shared/types';

export const modalBots = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      bots: {
        confirm: {
          delete: {
            title: 'Are you sure you want to delete the bot?',
            description: 'All active trades will be closed',
            button: 'Delete',
          },
          cancel: {
            title: 'Are you sure you want to cancel all trades of the bot?',
            description: 'All active trades will be canceled on Skyrexio, but assets will remain unaffected in exchange account',
            button: 'Okay',
          },
          close: {
            trades: {
              title: 'Are you sure you want to close all trades of the bot by market orders?',
              description: 'All active trades will be closed using market orders',
              button: 'Okay',
            },
          },
        },
        market: {
          title: 'Choose market from your alert bots',
          select: {
            title: 'Market',
            placeholder: 'Choose market for your item',
          },
          button: 'Next step',
        },
        parameters: {
          title: 'Set marketplace item parameters',
          bots: {
            title: 'Choose bots',
            placeholder: 'Each bot adds avilable exchange to your item',
          },
          amount: {
            title: 'Set minimum amount',
            placeholder: 'Minimum amount to copy the bot',
          },
          name: {
            title: 'Marketplace item name',
            placeholder: 'Name of the bot card',
          },
          description: {
            title: 'Description (max 200 symbols)',
            placeholder: 'Short description of the bot strategy',
          },
          author: {
            title: 'Author',
            placeholder: 'Name of creator',
          },
          profit: {
            title: 'Profit sharing, %',
            placeholder: 'Percentage of monthly earnings shared to you',
          },
          segments: {
            paid: 'Paid',
            free: 'Free',
          },
          actions: {
            publish: 'Publish',
            save: 'Save changes',
          },
        },
        eligible: {
          title: 'Bot does not meet all criteria to be shared at Marketplace',
          header: {
            criteria: 'Criteria',
            kpi: 'KPI',
            now: 'Now',
            status: 'Status',
          },
          button: 'Okay',
        },
      },
    },
    ru: {
      bots: {
        confirm: {
          delete: {
            title: 'Точно удалить бота?',
            description: 'Все активные сделки закроются',
            button: 'Удалить',
          },
          cancel: {
            title: 'Точно отменить все сделки бота?',
            description:
              'Все сделки отменятся на Skyrexio, активы на бирже не затронутся',
            button: 'Ок',
          },
          close: {
            trades: {
              title: 'Закрыть все сделки бота рыночными ордерами?',
              description: 'Все активные сделки закроются маркет-ордерами',
              button: 'Ок',
            },
          },
        },
        market: {
          title: 'Выберите рынок из ваших алерт-ботов',
          select: {
            title: 'Рынок',
            placeholder: 'Выберите рынок для публикации',
          },
          button: 'Следующий шаг',
        },
        parameters: {
          title: 'Параметры элемента на маркетплейсе',
          bots: {
            title: 'Выберите ботов',
            placeholder: 'Каждый бот добавляет доступную биржу',
          },
          amount: {
            title: 'Минимальная сумма',
            placeholder: 'Минимальная сумма для копирования бота',
          },
          name: {
            title: 'Название карточки',
            placeholder: 'Название бота',
          },
          description: {
            title: 'Описание (до 200 символов)',
            placeholder: 'Коротко о стратегии бота',
          },
          author: {
            title: 'Автор',
            placeholder: 'Имя создателя',
          },
          profit: {
            title: 'Доля прибыли, %',
            placeholder: 'Процент месячной прибыли, который идёт вам',
          },
          segments: {
            paid: 'Платный',
            free: 'Бесплатный',
          },
          actions: {
            publish: 'Опубликовать',
            save: 'Сохранить изменения',
          },
        },
        eligible: {
          title: 'Бот не соответствует критериям для публикации',
          header: {
            criteria: 'Критерии',
            kpi: 'KPI',
            now: 'Сейчас',
            status: 'Статус',
          },
          button: 'Ок',
        },
      },
    },
  };

  return transaltion[type].bots;
};
