import { TLanguage, TTranslation } from 'shared/types';

export const basic = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      basic: {
        title: 'Turn alerts from strategies and indicators into orders at your exchange accounts with alert bots',
        links: {
          read: 'Read articles',
          use: 'Ready-to-use scripts',
        },
        pairs: {
          preffix: 'Bot can manage multiple pairs — set alerts on recommended coins or pick from the',
          and: 'and',
          links: {
            screener: 'Screener',
            heatmap: 'Heatmap',
          },
        },
      },
    },
    ru: {
      basic: {
        title:
          'Алерт-боты превращают сигналы индикаторов и стратегий в ордера на ваших биржевых аккаунтах',
        links: {
          read: 'Читать статьи',
          use: 'Готовые скрипты',
        },
        pairs: {
          preffix:
            'Бот может работать с несколькими парами — настройте алерты на рекомендуемые монеты или используйте',
          and: 'и',
          links: {
            screener: 'Скринер',
            heatmap: 'Карта валют',
          },
        },
      },
    },
  };

  return transaltion[type].basic;
};
