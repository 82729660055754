import { Box } from '@mui/material';
import { Progress } from 'antd';
import { BaseType } from 'antd/es/typography/Base';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/ui';
import { IWinRate } from '../interfaces';
import { background, left, progressText, right, row, text, title } from '../styles';

export const WinRate = (props: IWinRate) => {
  const {
    percent,
    items,
  } = props;

  const {
    t, 
  } = useTranslation();

  const renderListItem = (item: IWinRate['items'][0]) => {
    let type: BaseType = 'secondary';
    const currency = item.currency?.toUpperCase() || '';
    let value = `${item.value} ${currency}`;

    const isFunnds = item.type === 'funds';

    if (+item.value > 0) {
      type = 'success';
      value = `+${value}`;
    }

    if (+item.value < 0) {
      type = 'danger';
    }

    if (!item.type || isFunnds) {
      value = `${item.value} ${currency}`;
      type = 'secondary'; 
    }

    return (
      <Box
        key={item.title}
        sx={row}
      >
        <Text
          type='secondary'
          styles={title}
        >
          {item.title}
        </Text>

        <Text
          styles={text}
          type={type}
        >
          {value ?? '-'}
        </Text>
      </Box>
    );
  };

  return (
    <Box sx={background}>
      <Box sx={left}>
        {items?.map((item: IWinRate['items'][0]) => (renderListItem(item)))}
      </Box>

      <Box sx={right}>
        <Progress
          percent={percent || 0}
          type='circle'
          status='normal'
          size={93}
        />

        <Text styles={progressText}>
          {t('terminal.diary.winRate.title')}
        </Text>
      </Box>
    </Box>
  );
};
