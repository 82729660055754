import { Box } from '@mui/material';
import { TableColumnsType } from 'antd';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { numberSorter, stringSorter } from 'shared/helpers/sorters';
import { SortIcon, Text } from 'shared/ui';
import { getTextType } from '../helpers/get-text-type';

export const columnsUsdt = (t: any) => {
  const columns: TableColumnsType = [{
    title: t('home.table.columns.token'),
    dataIndex: 'symbol',
    key: 'symbol',
    sorter: (a, b) => stringSorter(a.symbol, b.symbol),
    width: 190,
    render: (value: string) => (
      <Box
        display='flex'
        alignItems='center'
        gap={0.5}
      >
        <Text>{value}</Text>
      </Box>
    ),
    sortIcon: (props: any) => {
      const {
        sortOrder,
      } = props;
  
      return (
        <SortIcon sortOrder={sortOrder} />
      );
    },
    showSorterTooltip: false,
  }, {
    title: t('home.table.columns.share'),
    dataIndex: 'share',
    key: 'share',
    sorter: (a, b) => numberSorter(a.share, b.share),
    width: 190,
    render: (value: string) => {
      const share = parseFloat(value);
      return (
        <Text>{`${share.toFixed(2)}%`}</Text>
      );
    },
    sortIcon: (props: any) => {
      const {
        sortOrder,
      } = props;
  
      return (
        <SortIcon sortOrder={sortOrder} />
      );
    },
    showSorterTooltip: false,
  }, {
    title: t('home.table.columns.change'),
    dataIndex: 'usdt',
    key: 'usdtChange',
    sorter: (a, b) => numberSorter(a?.usdt?.priceChangePercent, b?.usdt?.priceChangePercent),
    width: 190,
    render: (value: any) => {
      const priceChangePercent = parseFloat(value.priceChangePercent);
      const textType = getTextType(priceChangePercent);
      return (
        <Text type={textType}>
          {priceChangePercent.toFixed(2)}
          %
        </Text>
      );
    },
    sortIcon: (props: any) => {
      const {
        sortOrder,
      } = props;
  
      return (
        <SortIcon sortOrder={sortOrder} />
      );
    },
    showSorterTooltip: false,
  }, {
    title: t('home.table.columns.price'),
    dataIndex: 'usdt',
    key: 'usdtPrice',
    width: 190,
    render: (value: any) => {
      const price = parseFloat(value.price);
      return (
        <Text>{currencyFormatter(price, 2)}</Text>
      );
    },
  }, {
    title: t('home.table.columns.amount'),
    dataIndex: 'total',
    key: 'usdtAmount',
    width: 190,
    render: (value: any) => {
      const amount = currencyFormatter(parseFloat(value), 4);
      return (
        <Text>{amount}</Text>
      );
    },
  }, {
    title: t('home.table.columns.total'),
    dataIndex: 'usdt',
    key: 'usdtTotal',
    sorter: (a, b) => numberSorter(a.usdt.total, b.usdt.total),
    width: 190,
    render: (value: any) => (
      <Text>{currencyFormatter(+value.total, 2)}</Text>
    ),
    sortIcon: (props: any) => {
      const {
        sortOrder,
      } = props;
  
      return (
        <SortIcon sortOrder={sortOrder} />
      );
    },
    showSorterTooltip: false,
  }];

  return columns;
};
