import { Stack } from '@mui/material';
import { Divider } from 'antd';
import { getAdditionalCopyValue, getTakeProfitPriceChange, getTakeProfitValume } from 'pages/trading-bots/configurator-v2/helpers';
import { getAdditionalEntries } from 'pages/trading-bots/configurator-v2/helpers/get-additional-entries';
import { getBaseOrder } from 'pages/trading-bots/configurator-v2/helpers/get-base-order';
import { getFullPosition } from 'pages/trading-bots/configurator-v2/helpers/get-full-position';
import { Fragment, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatter } from 'shared/helpers';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { Block } from '../../../../block';
import { WRAPPER } from '../../../styles';

const divider = (
  <Divider
    style={{
      margin: 0,
    }}
  />
);

export const ModalPanel = () => {
  const {
    watch,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();

  const tradingAmount = watch('tradingAmount');
  const activeTradingPairs = watch('activeTradingPairs');
  const market = watch('market').value;
  const exchange = watch('exchange').value;

  const additional = watch('additional');
  
  const isAdditionalOpened = additional.isOpened;
  const additionalSegment = additional.segment.value;
  const additionalOrders = additional.segment.preset.orders;
  const additionalOrdersSizeScale = additional.segment.preset.ordersSizeScale;
  const additionalOrdersPriceChangeScale = additional.segment.preset.ordersPriceChangeScale;
  const additionalPriceChange = additional.segment.preset.priceChange;
  const additionalAlertOrders = additional.segment.alert.orders;
  const additionalAlertOrdersSizeScale = additional.segment.alert.ordersSizeScale;

  const auto = watch('auto');
  const values = auto.values;
  
  const close = watch('close');
  const orders = close.segment.preset.takeProfit.orders;
  const takeProfitPriceChange = close.segment.preset.takeProfit.priceChange;
  const takeProfitOrdersSizeScale = close.segment.preset.takeProfit.orderSizeScale;
  const takeProfitOrdersPriceChangeScale = close.segment.preset.takeProfit.orderPriceChangeScale;
  
  const closeOrdersPriceChange = close.segment.preset.stopLoss.priceChange;
  const closeOrdersSegment = close.segment.value;
  
  const isCloseOrdersAlert = closeOrdersSegment === 'alert';
  
  const segment = auto.segment;
  const alertSource = auto.alertSource;
  
  const isAutoSegment = segment === 'auto';
  const isAlert = additionalSegment === 'alert';

  const baseOrder = useMemo(() => {
    const result = getBaseOrder({
      additional: {
        isOpened: isAdditionalOpened,
        orders: additionalOrders,
        ordersSizeScale: additionalOrdersSizeScale,
      },
      chooseAccount: {
        tradingAmount: +tradingAmount,
        activeTradingPairs: activeTradingPairs,
      },
    });

    return result;
  }, [
    isAdditionalOpened,
    additionalOrders,
    additionalOrdersSizeScale,
    tradingAmount,
    activeTradingPairs,
  ]);

  const fullPosition = useMemo(() => {
    const result = getFullPosition({
      additional: {
        isOpened: isAutoSegment || isAdditionalOpened,
        orders: isAutoSegment ? values.maxAdditionalOrders : additionalOrders,
        priceChange: isAutoSegment ? values.additionalOrderPriceChange : additionalPriceChange,
        ordersSizeScale: isAutoSegment ? values.additionalOrderSizeScale : additionalOrdersSizeScale,
        ordersPriceChangeScale: isAutoSegment ? values.additionalOrderPriceChangeScale : additionalOrdersPriceChangeScale,
        segment: additionalSegment,
      },
      chooseAccount: {
        tradingAmount: +tradingAmount,
        activeTradingPairs: activeTradingPairs,
      },
    });

    return result;
  }, [
    isAdditionalOpened,
    additionalOrders,
    additionalPriceChange,
    additionalOrdersSizeScale,
    additionalOrdersPriceChangeScale,
    additionalSegment,
    tradingAmount,
    activeTradingPairs,
    values,
    isAutoSegment,
  ]);

  const additiobalHeaders = useMemo(() => {
    if (!isAlert) {
      return [{
        label: '№',
        key: 'order',
      }, {
        label: t('configurator.managment.panel.common.additional.headers.order'),
        key: 'order-size',
      }, {
        label: t('configurator.managment.panel.common.additional.headers.price'),
        key: 'price-change',
      }];
    }

    switch (alertSource) {
    case 'indicator':
      return [{
        label: '№',
        key: 'order',
      }, {
        label: t('configurator.managment.panel.additional.additional.headers.order'),
        key: 'order-size',
      }, {
        label: t('configurator.managment.panel.additional.additional.headers.alert'),
        key: 'alert-message',
      }];
    case 'strategy':
      return [{
        label: '№',
        key: 'order',
      }, {
        label: t('configurator.managment.panel.additional.additional.headers.order'),
        key: 'order-size',
      }, {
        label: t('configurator.managment.panel.additional.additional.headers.script'),
        key: 'pineScript-parameter',
      }];
    case 'custom':
      return [{
        label: '№',
        key: 'order',
      }, {
        label: t('configurator.managment.panel.additional.additional.headers.order'),
        key: 'order-size',
      }, {
        label: t('configurator.managment.panel.additional.additional.headers.json'),
        key: 'json-body',
      }];
    default:
      return [];
    }
  }, [alertSource, isAlert, t]);

  const items = useMemo(() => {
    const autoParams = {
      isOpened: true,
      segment: 'auto',
      orders: values.maxAdditionalOrders,
      priceChange: values.additionalOrderPriceChange,
      ordersSizeScale: values.additionalOrderSizeScale,
      ordersPriceChangeScale: values.additionalOrderPriceChangeScale,
      tradingAmount: +tradingAmount,
      activeTradingPairs: activeTradingPairs,
    };

    const customParams = {
      isOpened: isAdditionalOpened,
      segment: additionalSegment,
      orders: isAlert ? additionalAlertOrders : additionalOrders,
      priceChange: additionalPriceChange,
      ordersSizeScale: isAlert ? additionalAlertOrdersSizeScale : additionalOrdersSizeScale,
      ordersPriceChangeScale: additionalOrdersPriceChangeScale,
      tradingAmount: +tradingAmount,
      activeTradingPairs: activeTradingPairs,
    };

    const entries = getAdditionalEntries(isAutoSegment ? autoParams : customParams);

    const formatedItems = entries.map((item, index) => {
      return [{
        value: item.position,
      }, {
        value: `${currencyFormatter(item.orderSize, market.presition)} ${market.code}`,
      }, {
        value: isAlert ? t('configurator.managment.panel.additional.additional.button') : `${item.priceChange}%`,
        copy: isAlert ? getAdditionalCopyValue({
          exchangeAccountUuid: exchange.id,
          source: alertSource,
          position: (++index) + 1,
        }) : '',
      }];
    });

    return formatedItems;
  }, [
    tradingAmount,
    activeTradingPairs,
    market,
    isAdditionalOpened,
    additionalSegment,
    additionalAlertOrders,
    additionalOrders,
    additionalPriceChange,
    additionalAlertOrdersSizeScale,
    additionalOrdersSizeScale,
    additionalOrdersPriceChangeScale,
    isAutoSegment,
    isAlert,
    values,
    alertSource,
    exchange.id,
    t,
  ]);

  const {
    formatedItems: takeProfitItems,
  } = useMemo(() => {
    const arraySettings = {
      length: orders || 1, 
    };
    const result = Array.from(arraySettings).map((_, index) => {
      const position = ++index;

      const priceChange = getTakeProfitPriceChange({
        orders: orders,
        priceChange: takeProfitPriceChange,
        ordersPriceChangeScale: takeProfitOrdersPriceChangeScale,
        position,
      });
      const volume = getTakeProfitValume({
        orders: orders,
        ordersSizeScale: takeProfitOrdersSizeScale,
        position,
      });

      return {
        position,
        priceChange,
        volume: (!isFinite(volume) || isNaN(volume)) ? 0 : volume,
      };
    });

    const formatedItems = result.map((item) => {
      return [{
        value: item.position,
      }, {
        value: `${item.volume.toFixed(2)}%`,
      }, {
        value: isCloseOrdersAlert ? 'Alert' : `${item.priceChange.toFixed(2)}%`,
      }];
    });

    return {
      formatedItems,
      result,
    };
  }, [
    orders,
    takeProfitPriceChange,
    takeProfitOrdersPriceChangeScale,
    takeProfitOrdersSizeScale,
    isCloseOrdersAlert,
  ]);

  const getCloseOrdersContent = () => {
    if (!isAutoSegment) {
      return {
        headers: [{
          label: '№',
          key: 'order',
        }, {
          label: t('configurator.managment.panel.close.table.headers.volume'),
          key: 'volume',
        }, {
          label: t('configurator.managment.panel.close.table.headers.price'),
          key: 'price-change',
        }],
        items: takeProfitItems as any,
        prefixTitle: t('configurator.managment.panel.close.table.title'),
      };
    }

    const rows = [null, {
      title: t('configurator.managment.panel.common.close.take.title'),
      value: `${values.takeProfitPercent || 0}%`,
    }];

    if (values?.stopLossPercent) {
      return [...rows, {
        title: t('configurator.managment.panel.common.close.stop.title'),
        value: `${values?.stopLossPercent}%`,
      }];
    }
    
    return rows;
  };

  return (
    <Stack sx={WRAPPER}>
      <Block
        title={t('configurator.managment.panel.common.entry.title')}
        layout='row'
        content={[{
          title: t('configurator.managment.panel.common.entry.item.title'),
          value: `${formatter(isAutoSegment ? (values?.baseOrderSize || 0) : baseOrder)} ${market.code}`,
        }]}
      />


      {!!items.length && (
        <Fragment>
          {divider}
          
          <Block
            title={t('configurator.managment.panel.common.additional.title')}
            layout='table'
            content={{
              headers: additiobalHeaders,
              items: items as any,
            }}
          />
        </Fragment>
      )}

      {divider}

      <Block
        layout='row'
        content={[{
          title: t('configurator.managment.panel.common.full'),
          value: `${formatter(isAutoSegment ? (values?.fullPositionSize || 0) : fullPosition)} ${market.code}`,
        }]}
      />

      <Block
        title={t('configurator.managment.panel.common.close.title')}
        layout={isAutoSegment ? 'row' : 'table'}
        content={getCloseOrdersContent()}
      />

      {!isAutoSegment && (
        <Block
          title=''
          layout='row'
          content={[{
            title: 'Stop loss',
            value: isCloseOrdersAlert ? 'Alert' : `${-Math.abs(closeOrdersPriceChange)}%`,
          }]}
        />
      )}
    </Stack>
  );
};
