import { appPath } from 'shared/config/route-config/app-routes/consts';
import { TAppPages } from 'shared/config/route-config/types/app-pages';
import { Bot, Friends, History, Info, PublicBots } from 'shared/icons';
import { IMenu } from '../interfaces';

export const botsMenu = (t: any) => {
  const item: IMenu<TAppPages> = {
    key: 'trading_bots_my_bots',
    label: t('header.menu.bots.title'),
    children: [{
      key: 'trading_bots_my_bots',
      icon: Bot,
      info: {
        label: t('header.menu.bots.items.all.title'),
        text: t('header.menu.bots.items.all.text'),
      },
      path: appPath.trading_bots_my_bots.path,
    }, {
      key: 'trading_bots_trading_history',
      icon: History,
      info: {
        label: t('header.menu.bots.items.diary.title'),
        text: t('header.menu.bots.items.diary.text'),
      },
      path: appPath.trading_bots_trading_history.path,
    }, {
      key: 'trading_bots_marketplace',
      icon: Friends,
      info: {
        label: t('header.menu.bots.items.marketplace.title'),
        text: t('header.menu.bots.items.marketplace.text'),
      },
      path: appPath.trading_bots_marketplace.path,
    }, {
      key: 'trading_bots_sales',
      icon: PublicBots,
      info: {
        label: t('header.menu.bots.items.public.title'),
        text: t('header.menu.bots.items.public.text'),
      },
      path: appPath.trading_bots_sales.path,
    }, {
      key: 'trading_bots_introduction',
      icon: Info,
      info: {
        label: t('header.menu.bots.items.introduction.title'),
        text: t('header.menu.bots.items.introduction.text'),
      },
      path: appPath.trading_bots_introduction.path,
    }],
  };

  return item;
};
