import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Text } from 'shared/ui';
import { TEXT, WRAPPER } from '../styles';

export const Banner = (props: Record<'text', ReactNode>) => {
  return (
    <Stack sx={WRAPPER}>
      <Text styles={TEXT}>
        {props.text}
      </Text>
    </Stack>
  );
};
