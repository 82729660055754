import { Stack } from '@mui/material';
import { Text, TooltipSingleSelect } from 'shared/ui';
import { ITypeProps } from '../interfaces';

export const Type = (props: ITypeProps) => {
  // const {
  //   t, 
  // } = useTranslation();

  const changeSourceHandler = (type: string) => {
    props.onChange(type as ITypeProps['value']);
    props.onChangeSide(type === 'spot' ? 'buy' : 'long');
  };
  
  return (
    <TooltipSingleSelect
      tooltip={(
        <Stack>
          <Text>
            Spot - Statistic of your spot exchange accounts
          </Text>
          <Text>
            Futures - Statistic of your futures exchange accounts
          </Text>
        </Stack>
      )}
      options={[{
        label: 'Spot',
        value: 'spot',
      }, {
        label: 'Futures',
        value: 'futures',
      }]}
      disabled={props.disabled}
      select={{
        value: props.value,
        placeholder: 'Select type',
        onChange: changeSourceHandler,
      }}
    />
  );
};
