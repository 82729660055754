import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const botCloseAllTradesByMarket = async (botUuids: string) => {
  const response = await axios.request({
    url: 'bot/marketCloseBotTrades',
    data: {
      botUuids: [botUuids],
    },
  });

  return response?.data;
};
