import { SxProps, Theme } from '@mui/material';

export const WRAPPER = (width?: number) => {
  const styles: SxProps<Theme> = {
    placeItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: width || 'auto',
    cursor: 'pointer',
    '&>svg': {
      width: '100%',
      height: '100%',
    },
  };

  return styles;
};
