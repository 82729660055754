import { TLanguage, TTranslation } from 'shared/types';

export const banner = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      banner: {
        title: 'Step Up Your Game for a {{price}} Prize Pool!',
        text: 'Link your {{platform}} account to Skyrexio, start trading, and watch your share of the reward grow as you increase your activity.',
      },
    },
    ru: {
      banner: {
        title: 'Забери часть призового фонда {{price}}!',
        text: 'Подключи аккаунт {{platform}} к Skyrexio, начни торговать и увеличивай свою награду.',
      },
    },
  };

  return transaltion[type].banner;
};
