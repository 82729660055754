import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { formatByPrecisionAndTrim } from './helpers';

interface CalculateDefaultValuesParams {
  chartLastPrice: number;
  selectedSide: 'buy' | 'sell' | 'long' | 'short';
  currentSymbol: any;
  userWalletQuoteAssetBalance: WalletData;
}

export const calculateDefaultValues = ({
  chartLastPrice,
  selectedSide,
  currentSymbol,
  userWalletQuoteAssetBalance,
}: CalculateDefaultValuesParams) => {
  const price = chartLastPrice || 0; 
  const additionalMultiplier = selectedSide === 'buy' ? 0.95 : 1.05;
  
  const precision = currentSymbol?.quoteAssetPrecision || 8;
  const priceMin = Number(currentSymbol?.priceMin || 0);
  const priceMax = Number(currentSymbol?.priceMax || 999999999);

  const initialTotal = parseFloat(userWalletQuoteAssetBalance.free) * 0.1;
  const initialUnits = initialTotal / price;
  const formattedUnits = formatByPrecisionAndTrim(
    initialUnits.toString(),
    currentSymbol.baseAssetPrecision,
    Number(currentSymbol.lotMin),
    Number(currentSymbol.lotMax),
  );
  
  const recalculatedTotal = parseFloat(formattedUnits) * price;
  const formattedTotal = formatByPrecisionAndTrim(
    recalculatedTotal.toString(),
    currentSymbol.quoteAssetPrecision,
    Number(currentSymbol.minNotional),
    Number(currentSymbol.maxNotional),
  );

  const additionalOrderPrice = formatByPrecisionAndTrim(
    (price * additionalMultiplier).toString(),
    precision,
    priceMin,
    priceMax,
  );

  // Для take profit - противоположная логика относительно выбранной стороны
  const takeProfitMultiplier = selectedSide === 'buy' ? 1.1 : 0.9;
  const takeProfitPrice = formatByPrecisionAndTrim(
    (price * takeProfitMultiplier).toString(),
    precision,
    priceMin,
    priceMax,
  );

  // Для stop loss - противоположная логика относительно take profit
  const stopLossMultiplier = selectedSide === 'buy' ? 0.9 : 1.1;
  const stopLossPrice = formatByPrecisionAndTrim(
    (price * stopLossMultiplier).toString(),
    precision,
    priceMin,
    priceMax,
  );

  return {
    units: formattedUnits,
    total: formattedTotal,
    additionalOrderPrice,
    takeProfitOrderPrice: takeProfitPrice,
    takeProfitTriggerPrice: takeProfitPrice,
    stopLossOrderPrice: stopLossPrice,
    stopLossTriggerPrice: stopLossPrice,
    currentSymbol,
    additionalEntries: [],
    takeProfitEntries: [],
    addEntryEnabled: false,
    takeProfitEnabled: false,
    stopLossEnabled: false,
  };
};
