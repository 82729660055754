interface IGetActiveTradingPairsError {
  tradingAmount: number;
  activeTradingPairs: number;
  minNotional: number;
  t: any;
}
export const getActiveTradingPairsError = (params: IGetActiveTradingPairsError) => {
  const {
    tradingAmount,
    activeTradingPairs,
    minNotional,
    t,
  } = params;

  if ((+tradingAmount / activeTradingPairs) < minNotional) {
    return t('validation.configurator.orderSize');
  }

  return '';
};
