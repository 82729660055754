import { capitalize } from '@mui/material';
import dayjs from 'dayjs';
import { getTradingStatistics } from 'entities/exchange/api';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { TradingStatisticCalculations } from 'pages/trading-bots/my-bots/types/bots-calculations';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getExchangeAccountsUuids } from 'shared/helpers';
import { defaultDates } from '../consts';
import { TDates, TPeriod, TSide, TSource, TType } from '../types';

interface IUseStatisticsParams {
  exchanges: ExchangeAccount[] | null;
  defaultSource?: TSource;
  trigger?: any;
}
export const useStatistics = (params: IUseStatisticsParams) => {
  const {
    exchanges,
    defaultSource = 'all',
    trigger,
  } = params;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [statistics, setStatistics] = useState<TradingStatisticCalculations[]>([]);
  const [dates, setDates] = useState<TDates>(defaultDates);
  const [period, setPeriod] = useState<TPeriod>('summary');
  const [source, setSource] = useState<TSource>(defaultSource);
  const [market, setMarket] = useState<string>('USDT');
  const [type, setType] = useState<TType>('spot');
  const [side, setSide] = useState<TSide>('buy');

  const location = useLocation();
  const isMyAccounts = location.pathname.includes('my-accounts');

  const getCurrentStatistics = () => {
    const defaultData = statistics.find((statistic) => {
      return statistic.market === (market || 'USDT');
    });

    return defaultData || statistics[0] || {};
  };

  const getChartData = () => {
    if (!statistics?.length) {
      return [];
    }
    
    const defaultData = getCurrentStatistics();

    const transformedPeriod = period === 'summary' ? 'sum' : period;
    const transformedSource = capitalize(source === 'bots' ? 'bot' : source);

    const key = `${transformedPeriod}ChartData${transformedSource}` as keyof TradingStatisticCalculations;
    
    const currentStatisticsData = (defaultData[key] as any[]).map((data): [string, number] => {
      if (period === 'day' || period === 'summary') {
        return [dayjs(data.date).format('DD-MM-YY'), data.return];
      }

      return [data.pair, data.return];
    });

    return currentStatisticsData;
  };

  const updateStatistics = () => {
    if (!exchanges) {
      return;
    }

    if (!exchanges.length) {
      return;
    }
    
    setIsLoading(true);
    fetchStatistics();
  };

  const filterAccountsByType = () => {
    if (isMyAccounts) {
      return exchanges;
    }
    
    return exchanges?.filter((exchange) => {
      if (type === 'spot') {
        return !exchange.exchangeCode.includes('futures');
      }

      return exchange.exchangeCode.includes('futures');
    });
  };

  const fetchStatistics = async () => {
    try {
      setIsLoading(true);
      const response = await getTradingStatistics({
        exchangeAccountUuids: getExchangeAccountsUuids(filterAccountsByType() || []),
        sideFirstStep: (side === 'buy' || side === 'long') ? 'BUY' : 'SELL',
        dates,
      });
      setStatistics(response || []);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!exchanges) {
      setIsLoading(true);
      return;
    }

    if (!exchanges?.length) {
      setIsLoading(false);
      return;
    }
    
    if (isMyAccounts) {
      const isFutures = exchanges[0].exchangeCode.includes('futures');
      setType(isFutures ? 'futures' : 'spot');
      setSide(isFutures ? 'long' : 'buy');
    }
  }, [exchanges, isMyAccounts]);

  useEffect(() => {
    if (!exchanges) {
      setIsLoading(true);
      return;
    }

    if (!exchanges?.length) {
      setStatistics([]);
      setIsLoading(false);
      return;
    }

    fetchStatistics();
  }, [
    exchanges,
    dates,
    type,
    side,
    trigger,
  ]);

  return {
    values: {
      statistics,
      source,
      market,
      period,
      dates,
      isLoading,
      type,
      side,
      isMyAccounts,
    },
    handlers: {
      setSource,
      setMarket,
      setDates,
      setPeriod,
      getChartData,
      getCurrentStatistics,
      updateStatistics,
      setType,
      setSide,
    },
  };
};
