import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { logout } from 'features/auth/model/actions/logout';
import { UserSchema } from '../types/user-schema';

const initialState: UserSchema = {
  isAuthenticated: false,
  isExchangeConnected: false,
  skyrexUuid: '',
  userEmail: '',
  is2faEnabled: false,
  isDemoMode: false,
  language: 'en',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    auth: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
    },
    setIs2faEnabled: (state, action: PayloadAction<boolean>) => {
      state.is2faEnabled = action.payload;
    },
    connectExchange: (state, action: PayloadAction<boolean>) => {
      state.isExchangeConnected = action.payload;
    },
    setSkyrexUuid: (state, action: PayloadAction<string>) => {
      state.skyrexUuid = action.payload;
    },
    setIsDemoMode: (state, action: PayloadAction<boolean>) => {
      state.isDemoMode = action.payload;
    },
    setLanguage: (state, action: PayloadAction<UserSchema['language']>) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      localStorage.clear();
      return initialState;
    });
  },
});

export const {
  actions: userActions,
} = userSlice;
export const {
  reducer: userReducer,
} = userSlice;
