import { TLanguage, TTranslation } from 'shared/types';

export const errorTwoFactor = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      twoFactor: {
        empty: {
          title: '',
          message: 'Email or password is missing. Try to login once again',
        },
        generate: {
          title: 'Failed to generate 2FA',
          message: 'Something went wrong. Try again or contact support',
          button: 'Okay',
        },
        disable: {
          title: 'Failed to disable 2FA',
          message: '',
          button: 'Okay',
        },
        enable: {
          title: 'Failed to enable 2FA',
          message: {
            'incorrect OTP': 'incorrect OTP',
          },
          button: 'Okay',
        },
      },
    },
    ru: {
      twoFactor: {
        empty: {
          title: '',
          message: 'Отсутствует email или пароль. Повторите вход',
        },
        generate: {
          title: 'Не удалось сгенерировать 2FA',
          message: 'Ошибка. Повторите попытку или свяжитесь с поддержкой',
          button: 'Ок',
        },
        disable: {
          title: 'Не удалось отключить 2FA',
          message: '',
          button: 'Ок',
        },
        enable: {
          title: 'Не удалось включить 2FA',
          message: '',
          button: 'Ок',
        },
      },
    },
  };

  return transaltion[type].twoFactor;
};
