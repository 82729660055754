import { TLanguage, TTranslation } from 'shared/types';

export const formBot = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      bot: {
        update: {
          name: {
            title: 'Name',
            placeholder: '',
          },
          balance: {
            title: 'Trading amount',
            placeholder: '',
          },
          button: 'Update',
        },
      },
    },
    ru: {
      'bot': {
        'update': {
          'name': {
            'title': 'Название',
            'placeholder': '',
          },
          'balance': {
            'title': 'Торговая сумма',
            'placeholder': '',
          },
          'button': 'Обновить',
        },
      },
    },
  };

  return transaltion[type].bot;
};
