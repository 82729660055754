import { TLanguage, TTranslation } from 'shared/types';

export const modalAlertSource = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      alertSource: {
        title: 'Alert messages',
        description: 'Control your bot with TradingView or other webhook alerts',
        copy: {
          title: 'Webhook URL',
        },
        tabs: {
          indicator: 'Indicator',
          strategy: 'Strategy',
          other: 'Other',
        },
        indicator: {
          title: 'Create alert with the message to open a trade',
          description: 'Replace "entry1" with "entry2", "entry3", etc. and set new alerts for additional entries',
          copy: {
            title: 'Create alert with the message to close a trade',
          },
        },
        strategy: {
          title: 'Add the parameter to your strategy entry functions',
          description: 'Replace "entry1" with "entry2", "entry3", etc. in the parameter and put it to additional entries',
          copy: {
            items: {
              function: 'Add the parameter to your strategy exit or close functions',
              message: 'Create alert with the message to trigger strategy actions',
            },
          },
          note: 'Note: replace str.tostring with tostring for PineScript v4 strategies',
        },
        other: {
          table: {
            headers: {
              field: 'Field',
              type: 'Type',
              description: 'Description',
            },
            columns: {
              base: 'Symbol to trade for example ADA',
              quote: 'Market of your bot for example USDT',
              position: '"entry1", "entry2", etc. for entries, "close" for closure',
              price: 'Execution price, for Market orders use current price',
              sourceUuid: 'Unique identifier of your bot, see below',
              secretToken: 'Protection token for your bot, see below',
              time: 'Current time in UNIX format',
            },
          },
          copy: {
            items: {
              source: 'Source UUID',
              secret: 'Secret Token',
              json: {
                title: 'Send a POST request with JSON body to execute bot action',
                description: 'Replace "entry1" with "entry2", "entry3", etc. for additional entries, and with “close” to exit trade',
              },
            },
          },
          note: 'Note: send requests using your scripts or manually with Postman',
        },
      },
    },
    ru: {
      'alertSource': {
        'title': 'Сообщения сигналов',
        'description': 'Управляйте ботом с помощью сигналов из TradingView или других вебхуков',
        'copy': {
          'title': 'Webhook URL',
        },
        'tabs': {
          'indicator': 'Индикатор',
          'strategy': 'Стратегия',
          'other': 'Другое',
        },
        'indicator': {
          'title': 'Создайте сигнал с сообщением для открытия сделки',
          'description': 'Замените "entry1" на "entry2", "entry3" и т.д., и установите новые сигналы для дополнительных входов',
          'copy': {
            'title': 'Создайте сигнал с сообщением для закрытия сделки',
          },
        },
        'strategy': {
          'title': 'Добавьте параметр в функции входа вашей стратегии',
          'description': 'Замените "entry1" на "entry2", "entry3" и т.д. в параметре и примените его к дополнительным входам',
          'copy': {
            'items': {
              'function': 'Добавьте параметр в функции выхода или закрытия вашей стратегии',
              'message': 'Создайте сигнал с сообщением для активации действий стратегии',
            },
          },
          'note': 'Примечание: замените str.tostring на tostring для стратегий PineScript v4',
        },
        'other': {
          'table': {
            'headers': {
              'field': 'Поле',
              'type': 'Тип',
              'description': 'Описание',
            },
            'columns': {
              'base': 'Торговый символ, например ADA',
              'quote': 'Рынок бота, например USDT',
              'position': '"entry1", "entry2" и т.д. для входов, "close" для закрытия',
              'price': 'Цена исполнения, для рыночных ордеров используйте текущую цену',
              'sourceUuid': 'Уникальный идентификатор вашего бота, см. ниже',
              'secretToken': 'Защитный токен для вашего бота, см. ниже',
              'time': 'Текущее время в формате UNIX',
            },
          },
          'copy': {
            'items': {
              'source': 'Источник (Source UUID)',
              'secret': 'Защитный токен (Secret Token)',
              'json': {
                'title': 'Отправьте POST-запрос с телом в формате JSON для выполнения действия бота',
                'description': 'Замените "entry1" на "entry2", "entry3" и т.д. для дополнительных входов и на "close" для выхода из сделки',
              },
            },
          },
          'note': 'Примечание: отправляйте запросы, используя ваши скрипты или вручную через Postman',
        },
      },
    },
  };

  return transaltion[type].alertSource;
};
