import { BaseLayout } from 'app/layouts';

interface IGetRouterParams {
  router: JSX.Element;
  isAuthorized: boolean;
}
export const getRouter = (params: IGetRouterParams): JSX.Element => {
  const {
    router,
    isAuthorized,
  } = params;

  if (isAuthorized) {
    return (
      <BaseLayout>
        {router}
      </BaseLayout>
    );
  }

  return router;
};
