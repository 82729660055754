import { TLanguage, TTranslation } from 'shared/types';

export const formExchange = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      exchange: {
        fields: {
          exchangeTitle: {
            title: 'Enter any name',
            placeholder: 'Name',
          },
          apiKey: {
            title: 'Paste your API key',
            placeholder: 'Key',
          },
          apiSecret: {
            title: 'Paste your API secret',
            placeholder: 'Secret',
          },
          memo: {
            title: 'Memo',
            placeholder: 'Input here',
          },
          passphrase: {
            title: 'Passphrase',
            placeholder: 'Input passphrase',
          },
        },
      },
    },
    ru: {
      exchange: {
        fields: {
          exchangeTitle: {
            title: 'Введите любое название',
            placeholder: 'Название',
          },
          apiKey: {
            title: 'Вставьте API ключ',
            placeholder: 'Публичный ключ',
          },
          apiSecret: {
            title: 'Вставьте секретный ключ',
            placeholder: 'Секретный ключ',
          },
          memo: {
            title: 'Memo',
            placeholder: 'Введите Memo',
          },
          passphrase: {
            title: 'Passphrase',
            placeholder: 'Введите Passphrase',
          },
        },
      },
    },
  };

  return transaltion[type].exchange;
};
