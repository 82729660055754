import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Ending, Info, Perfomance, Steps, Welcome } from 'widgets';
import { TRADING_BOTS } from 'shared/consts';
import { Title } from 'shared/ui';
import { ConfiguratorPreview, Execution } from '../components';

export const Ai = () => {
  const navigate = useNavigate();
  const {
    t, 
  } = useTranslation();

  const navigatePage = (path: string) => {
    return () => {
      navigate(path);
    };
  };

  return (
    <Stack gap='100px'>
      <Welcome
        title={t('bots.introduction.alert.welcome.title')}
        image='/images/trading-bots/alert-bots/1.jpg'
        blocks={[{
          title: t('bots.introduction.alert.welcome.universal.title'),
          text: t('bots.introduction.alert.welcome.universal.text'),
        }, {
          title: t('bots.introduction.alert.welcome.control.title'),
          text: t('bots.introduction.alert.welcome.control.text'),
        }, {
          title: t('bots.introduction.alert.welcome.easy.title'),
          text: t('bots.introduction.alert.welcome.easy.text'),
        }, {
          title: t('bots.introduction.alert.welcome.share.title'),
          text: t('bots.introduction.alert.welcome.share.text'),
        }]}
      />

      <Steps
        items={[
          t('bots.introduction.alert.steps.1'),
          t('bots.introduction.alert.steps.2'),
          t('bots.introduction.alert.steps.3'),
          t('bots.introduction.alert.steps.4'),
        ]}
      />

      <Info
        title={t('bots.introduction.alert.relax.title')}
        text={t('bots.introduction.alert.relax.text')}
        image='/images/trading-bots/alert-bots/2.png'
        isReverse={true}
        isColored={true}
        button={{
          title: t('bots.introduction.button.create.bot'),
          action: navigatePage(`/${TRADING_BOTS}/create`),
        }}
      />

      <Perfomance
        items={[{
          title: '100K+',
          text: t('bots.introduction.alert.perfomance.items.alerts'),
        }, {
          title: '1K+',
          text: t('bots.introduction.alert.perfomance.items.bots'),
        }, {
          title: '10K+',
          text: t('bots.introduction.alert.perfomance.items.trades'),
        }, {
          title: '$10M+',
          text: t('bots.introduction.alert.perfomance.items.volume'),
        }]}
      />

      <Execution />

      <Info
        title={t('bots.introduction.alert.control.title')}
        text={t('bots.introduction.alert.control.text')}
        image='/images/trading-bots/alert-bots/3.png'
        isReverse={true}
        isColored={true}
        button={{
          title: t('bots.introduction.button.create.bot'),
          action: navigatePage(`/${TRADING_BOTS}/create`),
        }}
      />

      <Stack gap='60px'>
        <Title>
          {t('bots.introduction.alert.configuratorTitle')}
        </Title>

        <ConfiguratorPreview />
      </Stack>

      <Info
        title={t('bots.introduction.alert.share.title')}
        text={t('bots.introduction.alert.share.text')}
        image='/images/trading-bots/alert-bots/4.jpg'
        button={{
          title: t('bots.introduction.button.create.bot'),
          action: navigatePage(`/${TRADING_BOTS}/create`),
        }}
      />

      <Info
        title={t('bots.introduction.alert.tradingView.title')}
        text={t('bots.introduction.alert.tradingView.text')}
        image='/images/trading-bots/alert-bots/5.png'
        isReverse={true}
        isColored={true}
      />

      <Ending
        title={t('bots.introduction.alert.ending.title')}
        text={t('bots.introduction.alert.ending.text')}
        button={{
          title: t('bots.introduction.button.create.bot'),
          action: navigatePage(`/${TRADING_BOTS}/create`),
        }}
      />
    </Stack>
  );
};
