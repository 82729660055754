import { TLanguage, TTranslation } from 'shared/types';

export const subscriptions = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      subscriptions: {
        title: 'My plan',
        plan: {
          title: {
            no: 'No plan',
            payment: 'Payment today',
            current: '{{plan}} plan',
            left: ' {{days}} left',
          },
          text: {
            no: 'Get basic subscription for free',
            lifetime: 'Lifetime access, no expiration',
            trial: 'Enjoy Pro plan features for free, trial ends on {{countedPaymentDate}}',
            next: 'Next payment: {{fee}} {{countedPaymentDate}}',
          },
          included: 'What\’s included?',
          button: 'Change plan',
        },
        table: {
          title: 'Subscriptions',
          select: {
            all: 'All',
            skyrexio: 'Skyrexio',
            copy: 'Copy bot',
          },
          button: 'Export',
          columns: {
            name: 'Name',
            type: 'Type',
            date: {
              start: 'Date start',
              end: 'Date end',
            },
            fee: 'Fee',
            status: {
              title: 'Status',
              items: {
                active: 'Active',
                pendingPayment: 'Pending',
                finished: 'Finished',
              },
            },
            actions: {
              title: 'Actions',
              button: {
                end: 'End',
                pay: 'Pay',
              },
            },
          },
        },
        about: {
          title: 'About plans',
          plans: {
            basic: {
              title: 'basic',
              text: 'Essential tools, copy bot with a standard fee or create your own, all with no monthly fees — completely free',
            },
            advanced: {
              title: 'advanced',
              text: 'Unlock multiple connected accounts, active smart trades and bots, share at Marketplace and get real-time support',
            },
            pro: {
              title: 'pro',
              text: 'Ultimate features, reduced Skyrexio bots fee, private PineScript strategies and premium support',
            },
          },
        },
        questions: {
          title: 'Have questions?',
          items: [{
            title: 'Can I upgrade, downgrade or cancel my Skyrexio plan?',
            text: 'You can upgrade to higher or longer plan, unused time of current subscription will be converted and credited. Downgrades aren’t available, but you can cancel via support, switch to Basic, get a refund for unused time, and then start the desired plan',
          }, {
            title: 'What’s the difference between Skyrexio plan and copy bot subscription?',
            text: 'Skyrexio plan has a fixed payment for higher platform features usage limits and lower Skyrexio bots profit-sharing percent, while a copy bot subscription has performance-based fee for access to strategies, you only share a part of profits once period is finished, with losses tracked and counted via the high-water mark system',
          }, {
            title: 'Do I need to have paid Skyrexio plan and copy bot subscription?',
            text: 'Paid Skyrexio plans are designed for traders with multiple accounts, active bots and larger portfolios, offering higher features limits and lower profit-sharing fees at Skyrexio bots. Users with a single account and smaller trading amount can benefit from the free Basic plan, only paying profit-sharing fees for the bot they copy',
          }, {
            title: 'Can I enable recurring subscription payments?',
            text: 'You can enable recurring payments for Skyrexio plan subscriptions using Stripe, but Marketplace bots recurring subscriptions are not supported at the moment',
          }],
        },
      },
    },
    ru: {
      subscriptions: {
        title: 'Мой план',
        plan: {
          title: {
            no: 'Нет плана',
            payment: 'Оплата сегодня',
            current: '{{plan}} план',
            left: ' {{days}} осталось',
          },
          text: {
            no: 'Бесплатный Базовый план',
            lifetime: 'Бессрочный доступ навсегда',
            trial: 'Бесплатные возможности Pro плана до {{countedPaymentDate}}',
            next: 'Следующая оплата: {{fee}} {{countedPaymentDate}}',
          },
          included: 'Что входит?',
          button: 'Сменить план',
        },
        table: {
          title: 'Подписки',
          select: {
            all: 'Все',
            skyrexio: 'Skyrexio',
            copy: 'Копи-боты',
          },
          button: 'Экспорт',
          columns: {
            name: 'Название',
            type: 'Тип',
            date: {
              start: 'Дата начала',
              end: 'Дата окончания',
            },
            fee: 'Плата',
            status: {
              title: 'Статус',
              items: {
                active: 'Активна',
                pendingPayment: 'Ожидает оплату',
                finished: 'Завершена',
              },
            },
            actions: {
              title: 'Действия',
              button: {
                end: 'Завершить',
                pay: 'Оплатить',
              },
            },
          },
        },
        about: {
          title: 'О планах',
          plans: {
            basic: {
              title: 'базовый',
              text: 'Базовые инструменты, копи-бот со стандартной комиссией или собственный бот — без подписки',
            },
            advanced: {
              title: 'продвинутый',
              text: 'Увеличьте кол-во аккаунтов, активных сделок и ботов, публикуйте ботов, получайте поддержку в реальном времени',
            },
            pro: {
              title: 'про',
              text: 'Полный функционал, сниженная комиссия ботов Skyrexio, приватные PineScript-стратегии и премиум-поддержка',
            },
          },
        },
        questions: {
          title: 'Есть вопросы?',
          items: [{
            title: 'Можно ли улучшить, понизить или отменить план Skyrexio?',
            text: 'Вы можете перейти на более высокий или длительный план, неиспользованное время конвертируется. Понизить нельзя, но можно отменить через поддержку, перейти на Basic, вернуть средства за остаток и заново выбрать нужный план',
          }, {
            title: 'Чем отличается план Skyrexio от подписки на копи-бот?',
            text: 'План Skyrexio — фиксированная оплата за расширенные лимиты и уменьшенный процент комиссии ботов, а подписка на копи-бот оплачивается по результату: доля прибыли списывается по окончании периода, убытки учитываются через high-water mark',
          }, {
            title: 'Нужен ли мне платный план Skyrexio и подписка на копи-бот одновременно?',
            text: 'Платные планы удобны для нескольких аккаунтов, больших портфелей и низкой комиссии ботов. Один аккаунт с небольшим депозитом может обойтись бесплатным Basic-планом и лишь платить процент за копи-бот',
          }, {
            title: 'Можно ли включить автоплатёж подписки?',
            text: 'Для планов Skyrexio через Stripe можно, но для подписок на боты (Marketplace) автоплатёж пока недоступен',
          }],
        },
      },
    },
  };

  return transaltion[type].subscriptions;
};
