import { TLanguage, TTranslation } from 'shared/types';

export const errorCopuBot = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      copyBot: {
        demo: 'Bot has no demo mode',
      },
    },
    ru: {
      copyBot: {
        demo: 'У бота нет демо-режима',
      },
    },
  };

  return transaltion[type].copyBot;
};
