import { TLanguage, TTranslation } from 'shared/types';

export const successExchange = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      exchange: {
        connect: {
          title: 'Account connected',
          message: 'Create or copy your first trading bot or smart terminal position',
          button: 'Okay',
        },
        update: {
          title: 'Exchange account updated successfully',
          message: 'Now you are in one click to start trading bot or create manual trade using your account',
        },
        list: {
          connected: {
            title: 'Account connected',
            message: 'Pro-level trading with limitless opportunities is just a click away!',
            button: 'Ok',
          },
          delete: {
            title: 'Account is deleted',
            message: 'No worries, it\'s just a link to your account. Create a new one!',
            button: 'Okay',
          },
          completed: {
            title: 'Request completed',
            message: 'Crypto.com API issued. Contact support if account isn’t linked',
            button: 'Okay',
          },
        },
        demo: {
          create: {
            title: 'Account is re-created',
            button: 'Okay',
          },
        },
      },
    },
    ru: {
      exchange: {
        connect: {
          title: 'Аккаунт подключён',
          message: 'Создайте или скопируйте первого бота либо смарт-позицию',
          button: 'Ок',
        },
        update: {
          title: 'Аккаунт обновлён',
          message: 'Теперь вы в одном клике от запуска бота или смарт-сделки!',
        },
        list: {
          connected: {
            title: 'Аккаунт подключён',
            message: 'Теперь вы в одном клике от запуска бота или смарт-сделки!',
            button: 'Ок',
          },
          delete: {
            title: 'Аккаунт удалён',
            message: 'Не беда, привяжите новый аккаунт!',
            button: 'Ок',
          },
          completed: {
            title: 'Запрос выполнен',
            message: 'API получены, если аккаунт не привязался, обратитесь в поддержку',
            button: 'Ок',
          },
        },
        demo: {
          create: {
            title: 'Аккаунт пересоздан',
            button: 'Ок',
          },
        },
      },
    },
  };

  return transaltion[type].exchange;
};
