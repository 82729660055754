import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getDatePair } from 'shared/helpers';
import { TDates } from 'shared/types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface IGetBotPublicStatisticParams {
  botUuid: string;
  dates: TDates;
}
export const getBotPublicStatistic = async (params: IGetBotPublicStatisticParams): Promise<any> => {
  const {
    botUuid,
    dates,
  } = params;

  const closedDates = getDatePair(dates);

  const data = {
    botUuid: botUuid, 
    closedDateFrom: closedDates.start,
    closedDateTo: closedDates.end,
  };

  const response = await axios.request({
    url: '/bot/calculateStatisticLandingMarketplace',
    data: data,
  });
  
  return response.data.data;
};
  
