import { SegmentedLabeledOption } from 'antd/es/segmented';

export const futuresSegments = (t: any): SegmentedLabeledOption<string>[] => {
  return [{
    label: t('terminal.panel.switch.buy.futures'),
    value: 'long',
  }, {
    label: t('terminal.panel.switch.sell.futures'),
    value: 'short',
  }];
};
