import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { AdaptedSymbol } from 'shared/helpers/adapt-exchange-symbol';
import { DEFAULT_SYMBOL } from './form-hook-helpers';

interface GetFormDefaultValuesProps {
    currentSymbol?: AdaptedSymbol;
    userWalletQuoteAssetBalance?: WalletData;
  }
  
export const getFormDefaultValues = ({
  currentSymbol,
  // editingTrade,
  userWalletQuoteAssetBalance,
}: GetFormDefaultValuesProps) => ({
  // selectedSide: 'buy' as 'buy' | 'sell',
  selectedSide: 'buy' as 'buy' | 'sell' | 'long' | 'short',
  skipBaseOrder: false,
  currentSymbol: {
    ...DEFAULT_SYMBOL,
    ...currentSymbol,
  },
  baseAsset: currentSymbol?.baseAsset,
  quoteAsset: currentSymbol?.quoteAsset,
  orderPrice: '',
  userWalletQuoteAsset: userWalletQuoteAssetBalance,
  lastChangedField: undefined,
  
  // Базовый ордер
  orderType: 'limit',
  conditionalOrderType: 'limit',
  units: '',
  total: '',
  slider: 10,
  boughtPrice: '',
  triggerPrice: '',
  triggerPriceType: 'last',
  skipBaseUnits: '',
  skipBaseTotal: '',
  skipBaseSlider: 100,
    
  // Дополнительные ордера
  additionalOrderPrice: '',
  additionalOrderPricePercent: '',
  additionalTriggerPricePercent: '',
  additionalSlider: 10,
  additionalUnits: '',
  additionalTotal: '',
  additionalOrderType: 'limit',
  additionalTriggerPriceType: 'last',
  additionalEntries: [],
  additionalTriggerPrice: '',
  addEntryEnabled: false,
    
  // Take Profit
  takeProfitEntries: [],
  takeProfitPriceRecalculation: 'fixed',
  takeProfitOrderPrice: '',
  takeProfitTriggerPrice: '',
  takeProfitOrderType: 'limit',
  takeProfitOrderPricePercent: '',  
  takeProfitTriggerPricePercent: '',
  takeProfitTriggerPriceType: 'last',
  takeProfitSlider: 100,
  takeProfitEnabled: false,
    
  // Stop Loss
  stopLossPriceRecalculation: 'fixed',
  stopLossTriggerPrice: '',
  stopLossOrderPrice: '',
  stopLossOrderPricePercent: '',
  stopLossTriggerPricePercent: '',
  stopLossOrderType: 'cond.limit',
  stopLossTriggerPriceType: 'last',
  stopLossEnabled: false,
    
  // Прочее
  userWalletBaseAsset: {},
  conditionalSegment: '',
  additionalEntryDuplicatePriceError: '',
  takeProfitDuplicatePriceError: '',
  isSharedTrade: false,
  leverageSlider: 1,
  leverageInput: 1,
  leverageType: 'cross',
  isLeverageTypeDisabled: false,
  computedWallet: userWalletQuoteAssetBalance,
});
