import { appPath } from 'shared/config/route-config/app-routes/consts';
import { TAppPages } from 'shared/config/route-config/types/app-pages';
import { IMenu } from '../interfaces';
import { botsMenu } from './bots-menu';
import { termnialMenu } from './terminal-menu';

export const menus = (t: any) => {
  const items: IMenu<TAppPages>[] = [{
    key: 'home',
    label: t('header.menu.home'),
    path: appPath.home.path,
  }, {
    key: 'my_accounts',
    label: t('header.menu.accounts'),
    path: appPath.my_accounts.path,
  }, termnialMenu(t), botsMenu(t), {
    key: 'subscriptions',
    label: t('header.menu.subscriptions'),
    badge: 'Sale',
    path: appPath.subscriptions.path,
  }, {
    key: 'invite_friends',
    label: t('header.menu.invite'),
    path: appPath.invite_friends.path,
  }];

  return items;
};
