import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const CONTENT: SxProps<Theme> = {
  placeItems: 'center',
  justifyContent: 'center',
  gap: getResponsiveValue({
    min: 24,
    max: 60,
  }),
  flex: 1,
};
