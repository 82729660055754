import { verifyReferalCode } from 'features/auth/api/auth-service';
import { useEffect } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'shared/hooks';
import { LabelInput } from 'shared/ui';

export const ReferralCode = () => {
  const {
    t, 
  } = useTranslation();
  const {
    control,
    formState: {
      errors,
    },
    setValue,
  } = useFormContext();
  const query = useQuery();

  const ref = query.get('ref');

  useEffect(() => {
    if (!ref) {
      return;
    }

    setValue('referralCode', ref);
    verifyReferalCode(ref);
  }, [ref, setValue]);

  const renderFiled: ControllerProps['render'] = (params) => {
    const {
      field,
    } = params;

    return (
      <LabelInput
        label={t('form.register.referalCode.label')}
        placeholder={t('form.register.referalCode.placeholder')}
        value={field.value}
        onChange={field.onChange}
        type='text'
        disabled={!!ref}
        error={errors.referralCode?.message?.toString()}
      />
    );
  };

  return (
    <Controller
      name='referralCode'
      control={control}
      render={renderFiled as any}
    />
  );
};
