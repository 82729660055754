import { Modal } from 'antd';
import { useState } from 'react';
import { ChooseExchange, Exchanges } from 'widgets';
import { IExchanges } from 'widgets/exchanges/interfaces';
import { TType } from 'shared/types';
import { modal, modalChildren } from '../styles';

interface ConnectNewExchangeProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
}

export const ConnectNewExchange = (props: ConnectNewExchangeProps) => {
  const {
    isModalOpen,
    setIsModalOpen,
  } = props;
  
  const [platform, setPlatform] = useState<string>('');
  const [type, setType] = useState<TType>('spot');

  const onChooseExchange = (platform: string) => {
    if (!platform) {
      handleCancel();
      return;
    }

    setPlatform(platform);
  };

  const onChangeType = (type: string) => {
    setType(type as TType);
  };

  const returnToAllExchanges = () => {
    setPlatform('');
  };
  
  const handleCancel = () => {
    setIsModalOpen(false);
    setPlatform('');
  };

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      style={modal}
      closable
      destroyOnClose
      onCancel={handleCancel}
      styles={modalChildren}
      width='100%'
      centered={true}
    >
      {!platform && (
        <ChooseExchange
          onChoose={onChooseExchange}
          onChangeType={onChangeType}
          type={type}
        />
      )}

      {platform && (
        <Exchanges
          handleClose={handleCancel}
          returnToAllExchanges={returnToAllExchanges}
          platform={platform as IExchanges['platform']}
          isModalOpen={isModalOpen}
          type={type}
        />
      )}
    </Modal>
  );
};
