import { Box } from '@mui/material';
import { EnrichedTrade } from 'entities/terminal/model/types/terminal-schema';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WinRate } from 'widgets';
import { exportToCsv, formatter } from 'shared/helpers';
import { ExportGreen } from 'shared/icons';
import { Loader, MainButton, MultipleSelect, RangePicker, Search, ShareTrade, SingleSelect, Table, Title } from 'shared/ui';
import { columns } from '../consts';
import { getFundsInTrades } from '../helpers/get-funds-in-trades';
import { getTotalPnl, getUnrealizedPnl } from '../helpers/get-pnl';
import { getWinRatePercent } from '../helpers/get-winrate-percent';
import { useTradingDiary } from '../hooks';
import {
  exportButton,
  FILTER_WRAPPER,
  filtersWrapper,
  inner,
  title,
  titleWrapper,
  wrapper,
} from '../styles';

const ManualTradingHistory = () => {
  const [selectedTrade, setSelectedTrade] = useState<any>(null);

  const {
    t, 
  } = useTranslation();

  const {
    values,
    handlers,
  } = useTradingDiary();

  const trades = handlers.getTrades();

  const openShareTradeModal = (tradeUuid: string) => {
    return () => {
      const trade = trades.find((trade) => {
        return trade.smartTradeUuid === tradeUuid;
      });
      if (!trade) {
        return;
      }

      trade.currentExchangePrice = +formatter(+trade.executedQuoteSecond / +trade.executedBaseSecond);
      
      setSelectedTrade(trade);
    };
  };

  const closeShareTradeModal = () => {
    setSelectedTrade(null);
  };

  const handleExportToCsv = () => {
    const transformedTrades = trades.map((trade) => {
      const {
        orders,
        ...rest
      } = trade;

      return rest;
    });

    exportToCsv(transformedTrades, 'ManualTrades');
  };

  const getCurrencySelect = () => {
    if (values.selectedSide === 'sell') {
      return (
        <Box gridArea='base_currency'>
          <SingleSelect
            label={t('terminal.diary.select.currency.title')}
            options={values.currencyOptions()}
            select={{
              value: values.selectedBaseCurrencies[0],
              placeholder: 'Select base currency',
              onChange: handlers.changeCurrencies,
            }}
            size='large'
          />
        </Box>
      );
    }

    return (
      <Box gridArea='base_currency'>
        <MultipleSelect
          label={t('terminal.diary.select.currency.title')}
          options={values.currencyOptions()}
          select={{
            value: values.selectedBaseCurrencies,
            placeholder: t('terminal.diary.select.currency.placeholder'),
            onChange: handlers.changeCurrencies,
          }}
        />
      </Box>
    );
  };

  const getMarketSelect = () => {
    if (values.selectedSide === 'sell') {
      return (
        <Box gridArea='market'>
          <MultipleSelect
            label={t('terminal.diary.select.market.title')}
            options={values.marketOptions()}
            select={{
              value: values.selectedMarkets,
              placeholder: t('terminal.diary.select.market.placeholder'),
              onChange: handlers.changeMarkets,
            }}
          />
        </Box>
      );
    }
    
    return (
      <Box gridArea='market'>
        <SingleSelect
          label={t('terminal.diary.select.market.title')}
          options={values.marketOptions()}
          select={{
            value: values.selectedMarkets[0],
            placeholder: t('terminal.diary.select.market.placeholder'),
            onChange: handlers.changeMarkets,
          }}
          size='large'
        />
      </Box> 
    );
  };

  const getSideOptions = () => {
    if (values.selectedType === 'spot') {
      return [{
        label: t('terminal.diary.select.side.items.buy'),
        value: 'buy',
      }, {
        label: t('terminal.diary.select.side.items.sell'),
        value: 'sell',
      }];
    }

    return [{
      label: 'Long',
      value: 'long',
    }, {
      label: 'Short',
      value: 'short',
    }];
  };

  const calculatedValues = useMemo(() => {
    if (!trades.length) {
      return {
        winRate: 0,
        total: 0,
        funds: 0,
        unrealized: 0,
      };
    }

    const winRate = +formatter(getWinRatePercent(trades));
    const funds = +formatter(getFundsInTrades(trades, values.selectedSide));
    const total = formatter(getTotalPnl(trades, values.selectedSide));
    const unrealized = formatter(getUnrealizedPnl(trades,values.selectedSide));

    return {
      winRate,
      funds,
      total,
      unrealized,
    };
  }, [trades]);

  const currency = values[values.selectedSide === 'sell' ? 'selectedBaseCurrencies' : 'selectedMarkets'][0];

  if (values.isLoading) {
    return (
      <Loader isContentOverflow={true} />
    );
  }

  return (
    <Box sx={wrapper}>
      <Box sx={titleWrapper}>
        <Title styles={title}>
          {t('terminal.diary.title')}
        </Title>

        <MainButton
          type='primary'
          ghost={true}
          icon={ExportGreen}
          iconPosition='start'
          styles={exportButton}
          onClick={handleExportToCsv}
          size='large'
        >
          {t('terminal.diary.button')}
        </MainButton>
      </Box>

      <Box sx={inner}>
        <Box sx={filtersWrapper}>
          <Box sx={FILTER_WRAPPER}>
            <Box gridArea='exchange'>
              <MultipleSelect
                label={t('terminal.diary.select.exchange.title')}
                options={values.exchangeOptions()}
                select={{
                  value: values.selectedExchanges,
                  placeholder: t('terminal.diary.select.exchange.placeholder'),
                  onChange: handlers.changeExchanges,
                }}
              />
            </Box>
          
            <Box gridArea='side'>
              <SingleSelect
                label={t('terminal.diary.select.side.title')}
                options={getSideOptions()}
                select={{
                  value: values.selectedSide,
                  placeholder: t('terminal.diary.select.side.placeholder'),
                  onChange: handlers.changeSide,
                }}
                size='large'
              />
            </Box>

            <Box gridArea='type'>
              <SingleSelect
                label='Account type'
                options={[{
                  label: 'Spot',
                  value: 'spot',
                }, {
                  label: 'Futures',
                  value: 'futures',
                }]}
                select={{
                  value: values.selectedType,
                  placeholder: t('terminal.diary.select.side.placeholder'),
                  onChange: handlers.changeType,
                }}
                size='large'
              />
            </Box>
          
            <Box gridArea='create_date'>
              <RangePicker
                label={t('terminal.diary.select.date.create')}
                value={values.startDates!}
                handleSetNewDate={handlers.changeStartDates}
              />
            </Box>

            {getCurrencySelect()}
            
            {getMarketSelect()}
          
            <Box gridArea='status'>
              <MultipleSelect
                label={t('terminal.diary.select.status.title')}
                options={[{
                  label: t('terminal.diary.select.status.items.completed'),
                  value: 'completed',
                }, {
                  label: t('terminal.diary.select.status.items.active'),
                  value: 'active',
                }, {
                  label: t('terminal.diary.select.status.items.cancelled'),
                  value: 'cancelled',
                }]}
                select={{
                  value: values.selectedStatuses,
                  placeholder: t('terminal.diary.select.status.placeholder'),
                  onChange: handlers.changeStatus,
                }}
              />
            </Box>
          
            <Box gridArea='close_date'>
              <RangePicker
                label={t('terminal.diary.select.date.close')}
                value={values.finishDates!}
                handleSetNewDate={handlers.changeFinishDates}
              />
            </Box>
          </Box>
          
          <WinRate
            percent={calculatedValues.winRate}
            items={[{
              title: t('terminal.diary.winRate.items.total'),
              value: calculatedValues.total,
              currency,
              type: 'total',
            }, {
              title: t('terminal.diary.winRate.items.upl'),
              value: calculatedValues.unrealized,
              currency,
              type: 'unrealized',
            }, {
              title: t('terminal.diary.winRate.items.trades'),
              value: trades.length,
            }, {
              title: t('terminal.diary.winRate.items.inTrades'),
              value: calculatedValues.funds,
              type: 'funds',
              currency,
            }]}
          />
        </Box>
        
        <Table
          title=''
          columns={columns(t, openShareTradeModal)}
          items={handlers.getTrades()?.map((trade: EnrichedTrade) => ({
            smartTradeUuid: trade.smartTradeUuid,
            pair: {
              firstPair: trade.baseSymbol,
              secondPair: trade.quoteSymbol,
            },
            account: trade.accountName,
            createDate: trade.createdDate,
            closeDate: trade.finishedDate,
            status: trade.status,
            total: formatter(trade.executedQuoteFirst),
            entryPrice: formatter(+trade.executedQuoteFirst / +trade.executedBaseFirst),
            closePrice: formatter(trade.status === 'completed' ? (+trade.executedQuoteSecond / +trade.executedBaseSecond) : 0),
            return: formatter(trade.sideFirstStep === 'BUY' ? trade?.returnQuote || 0 : trade?.returnBase || 0),
            return_pr: formatter(trade.returnPercent || 0),
          }),
          )}
          action={(
            <Search
              value={values.searchText}
              maxWidth={480}
              onChange={handlers.changeSearchValue}
              placeholder={t('terminal.diary.table.search.placeholder')}
            />
          )}
        />

        <ShareTrade
          isOpen={!!selectedTrade}
          trade={selectedTrade}
          closeHandler={closeShareTradeModal}
        />
      </Box>
    </Box>
  );
};

export default ManualTradingHistory;
