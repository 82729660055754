import { capitalize } from '@mui/material';
import { ITradingStatistic } from 'widgets/trading-statistic/interfaces';
import { formatter } from 'shared/helpers';
import { TSource } from 'shared/types';

interface IGetListItemsParams {
  t: any;
  statistics: any;
  source: TSource;
}
export const getListItems = (params: IGetListItemsParams) => {
  const {
    t,
    statistics,
    source,
  } = params;

  const currency = statistics.market || 'USDT';
  const postfix = capitalize(source === 'bots' ? 'bot' : source);

  const items: ITradingStatistic['listItems'] = [{
    title: t('home.statistics.total.title'),
    tooltip: t('home.statistics.total.tooltip'),
    value: formatter(statistics[`totalPnl${postfix}`]),
    currency,
    type: 'total',
  }, {
    title: t('home.statistics.upl.title'),
    tooltip: t('home.statistics.upl.tooltip'),
    value: formatter(statistics[`unrealizedPnl${postfix}`]),
    currency,
    type: 'unrealised',
  }, {
    title: t('home.statistics.today.title'),
    tooltip: t('home.statistics.today.tooltip'),
    value: formatter(statistics[`todayPnl${postfix}`]),
    currency,
    type: 'today',
  }, {
    title: t('home.statistics.trades.title'),
    value: t('home.statistics.trades.value', {
      activeTrades: formatter(statistics[`activeTrades${postfix}`]),
      totalTrades: formatter(statistics[`totalTrades${postfix}`]),
    }),
    tooltip: t('home.statistics.trades.tooltip'),
  }, {
    title: t('home.statistics.inTrades.title'),
    tooltip: t('home.statistics.inTrades.tooltip'),
    value: formatter(statistics[`fundsInTrades${postfix}`]),
    currency,
    type: 'funds',
  }];

  return items;
};
