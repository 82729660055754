import { TLanguage, TTranslation } from 'shared/types';

export const errorLogin = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      login: {
        failed: {
          title: 'Activation failed',
          text: 'An error occurred during account activation. Please contact support',
        },
        'account is not activated': 'account is not activated',
        'incorrect login or password': 'incorrect login or password',
        'account is deleted': 'account is deleted',
      },
    },
    ru: {
      login: {
        failed: {
          title: 'Активация не удалась',
          text: 'Произошла ошибка активации. Обратитесь в поддержку',
        },
        'account is not activated': 'Аккаунт не активирован',
        'incorrect login or password': 'Неверный логин или пароль',
        'account is deleted': 'Аккаунт удалён',
      },
    },
  };

  return transaltion[type].login;
};
