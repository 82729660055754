import { ActivateAccountPage } from 'features/activate-account';
import { ChangePasswordPage } from 'pages/change-password';
import { LoginPage } from 'pages/login';
import { RecoverPasswordPage } from 'pages/recover-password';
import { RegisterPage } from 'pages/register';
import { TwoFactorPage } from 'pages/two-factor-auth';
import { Navigate } from 'react-router-dom';
import { IPage } from '../../interfaces';
import { authPath } from './auth-path';

export const authRoutes = () => {
  const items: IPage[] = [{
    title: 'Login',
    path: authPath.login.path,
    element: (
      <LoginPage />
    ),
  }, {
    title: 'Change password',
    path: authPath['password-reset'].path,
    element: (
      <ChangePasswordPage />
    ),
  }, {
    title: 'Recover Password',
    path: authPath['recover-password'].path,
    element: (
      <RecoverPasswordPage />
    ),
  }, {
    title: 'Register',
    path: authPath.register.path,
    element: (
      <RegisterPage />
    ),
  }, {
    title: 'Two factor',
    path: authPath['two-factor'].path,
    element: (
      <TwoFactorPage />
    ), 
  }, {
    title: 'Activate account',
    path: authPath['user-activate'].path,
    element: (
      <ActivateAccountPage />
    ), 
  }, {
    title: '',
    path: '*',
    element: (
      <Navigate
        to={authPath.login.path}
        replace={true}
      />
    ), 
  }];

  return items;
};
