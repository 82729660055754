import { TLanguage, TTranslation } from 'shared/types';

export const formRecover = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      recover: {
        title: 'Recover password',
        description: 'Enter your e-mail and we\'ll send you a recovery link',
        email: {
          label: 'E-mail',
          placeholder: '',
        },
        button: 'Email me a recovery link',
        links: {
          login: 'Login',
          or: 'or',
          register: 'Register',
        },
      },
    },
    ru: {
      recover: {
        title: 'Восстановить пароль',
        description: 'Введите e-mail и мы вышлем ссылку для восстановления',
        email: {
          label: 'E-mail',
          placeholder: '',
        },
        button: 'Отправить ссылку',
        links: {
          login: 'Войти',
          or: 'или',
          register: 'Зарегистрироваться',
        },
      },
    },
  };

  return transaltion[type].recover;
};
