import { Box, Stack } from '@mui/material';
// import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Text, Title, Tooltip } from 'shared/ui';
import { formatByPrecisionAndTrim } from '../../helpers/helpers';
//@TODO перенести импорты в рамках оптимизации
import { InfoIcon, Wallet } from '../buy/icons';
import { price, priceWrapper, title, tooltipText } from '../buy/styles';

interface WalletInfoFuturesProps {
  selectedSide: 'long' | 'short';
  isSkipBaseOrder: boolean;
  currentSymbol: {
    quoteAsset: string;
    baseAsset: string;
    chartPrecision: number;
    quoteAssetPrecision: number;
    baseAssetPrecision: number;
    minNotional: string;
    maxNotional: string;
    lotMin: string;
    lotMax: string;
  };
  userWalletQuoteAsset: {
    free: string;
  };
  userWalletBaseAsset: {
    free: string;
  };
}

export const WalletInfoFutures = (props: WalletInfoFuturesProps) => {
  const {
    selectedSide,
    currentSymbol,
    userWalletQuoteAsset,
  } = props;
  
  const {
    t, 
  } = useTranslation();

  // Функция для вычисления числового значения
  const computeNumericQuoteAssetValue = (): number => {
    if (!userWalletQuoteAsset?.free) {
      console.warn('Quote Asset Free Balance is missing');
      return 0;
    }
    const rawValue = userWalletQuoteAsset.free.toString();
        
    if (!currentSymbol?.chartPrecision) {
      console.warn('Quote Asset Precision is missing');
      return Number(rawValue);
    }
        
    const formattedValueStr = formatByPrecisionAndTrim(
      rawValue,
      currentSymbol.chartPrecision,
      Number(currentSymbol.minNotional),
      Number(currentSymbol.maxNotional),
    );
    console.log('Computed Numeric Quote Asset Value:', formattedValueStr);
    return Number(formattedValueStr);
  };

  // Функция для получения форматированного значения с валютой
  const getValue = (): string => {
    const numericValue = computeNumericQuoteAssetValue();
    
    return `${numericValue} ${currentSymbol?.quoteAsset}`;
  };

  return (
    <Stack flex={1}>
      <Stack
        direction='row'
        flex={1}
        justifyContent='space-between'
      >
        <Title styles={title}>
          {t(`terminal.panel.title.${selectedSide}.origin`, {
            currency: currentSymbol?.baseAsset, 
          })}
        </Title>
      
        <Box sx={priceWrapper}>
          <Box
            sx={{
              width: 12,
              height: 12,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&>svg': {
                width: '100%',
                height: '100%',
              },
            }}
          >
            {Wallet}
          </Box>

          <Text
            type='success'
            styles={price}
          >
            {getValue()}
          </Text>

          <Tooltip
            title={(
              <Box maxWidth={188}>
                <Title
                  level={5}
                  styles={tooltipText}
                >
                  {t('terminal.panel.title.buy.tooltip', {
                    currency: currentSymbol?.quoteAsset, 
                  })}
                </Title>
              </Box>
            )}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&>svg': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              {InfoIcon}
            </Box>
          </Tooltip>
        </Box>
      </Stack>

      <Stack alignItems='flex-end'>
        <Text
          type='secondary'
          styles={{
            fontSize: 8,
          }}
        >
        </Text>
      </Stack>
    </Stack>
  );
};
