import { CSSProperties } from 'react';

export const avatar: CSSProperties = {
  borderRadius: 47,
  backgroundColor: 'rgba(255, 255, 255, 0.80)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#5F5F5F',
};
