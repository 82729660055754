import { Box, Stack } from '@mui/material';
import { Segmented } from 'antd';
import { newTerminalActions } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { sliderWrapper } from 'pages/manual-trading/trading-terminal/components/fields/styles';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useEffect } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'widgets';
import { PlusGreen } from 'shared/icons';
import {
  Input,
  LabelInput,
  MainButton,
  SingleSelect,
  Slider,
  Switch,
  Text,
  Title,
} from 'shared/ui';
import { SafetyOrders } from '../../buy/components/additional-entry/components';
import { segments } from '../../buy/components/additional-entry/consts';
import { IAdditionalEntry } from '../../buy/components/additional-entry/interfaces';
import { actions, button, buttonTitle, suffixText, wrpaper } from '../../buy/components/additional-entry/styles';
import { ErrorMessage } from '../../error-message';

export const AdditionalEntrySell = (props: IAdditionalEntry) => {
  const {
    onAdditionalOrderPriceChange,
    onAdditionalUnitsChange,
    onAdditionalTotalChange,
    onAdditionalSliderChange,
    onAdditionalTriggerPriceChange,
    setIsSkipAdditionalEntry,
    onAdditionalOrderPricePercentChange,
    onAddEntry,
    onRemoveEntry,
  } = props;
  
  const {
    control,
    formState: {
      errors, 
    },
    trigger,
    setValue,
    watch,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();
    
  const dispatch = useDispatch();
  
  const additionalOrderType = watch('additionalOrderType');
  const isAddEntryEnabled = watch('addEntryEnabled');
  const currentSymbol = watch('currentSymbol');
  const baseAsset = watch('baseAsset');
  const quoteAsset = watch('quoteAsset');
  const additionalTriggerPricePercent = watch('additionalTriggerPricePercent');  
  const additionalEntries = watch('additionalEntries');
  const selectedSide = watch('selectedSide');
  const additionalOrderPrice = watch('additionalOrderPrice');
  const additionalTriggerPrice = watch('additionalTriggerPrice');
  
  const additionalOrderPriceFromStore = useSelector((state: any) => state.newTerminal.additionalOrderPrice);
  
  
  useEffect(() => {
    if (additionalOrderPriceFromStore) {
      if (additionalOrderType === 'cond.market') {
        setValue('additionalTriggerPrice', additionalOrderPriceFromStore.toString());
        onAdditionalTriggerPriceChange(additionalOrderPriceFromStore.toString());
      } else {
        setValue('additionalOrderPrice', additionalOrderPriceFromStore.toString());
        onAdditionalOrderPriceChange(additionalOrderPriceFromStore.toString());
      }
    }
  }, [additionalOrderPriceFromStore]);
  
    
  useEffect(() => {
    if (isAddEntryEnabled) {
      if (additionalOrderType === 'cond.market') {
        dispatch(newTerminalActions.setAdditionalOrderPrice(Number(additionalTriggerPrice)));
      } else {
        dispatch(newTerminalActions.setAdditionalOrderPrice(Number(additionalOrderPrice)));
      }
    } else {
      dispatch(newTerminalActions.setAdditionalOrderPrice(0));
    }
  }, [
    additionalOrderType, 
    additionalOrderPrice, 
    additionalTriggerPrice, 
    isAddEntryEnabled, 
    dispatch,
  ]);
  
  const changeSegmentHandler = (value: string) => {
    setValue('additionalOrderType', value);
  };

  const renderOrderPriceField = () => {
    if (additionalOrderType === 'cond.market') {
      return null;
    }

    return (
      <Box sx={actions}>
        <Controller
          name='additionalOrderPrice'
          control={control}
          rules={{
            required: t('validation.terminal.required'),
            validate: (value) => {
              const numValue = Number(value);
              if (isNaN(numValue)) {
                return t('validation.terminal.number');
              }
          
              if (numValue < Number(currentSymbol.priceMin)) {
                return t('validation.terminal.min', {
                  min: getTrailedZeroCutted(currentSymbol.priceMin),
                });
              }
              if (numValue > Number(currentSymbol.priceMax)) {
                return t('validation.terminal.max', {
                  max: getTrailedZeroCutted(currentSymbol.priceMax),
                });
              }
              return true;
            },
          }}
          render={({
            field, 
          }) => (
            <LabelInput
              label={(
                <Label title={t('terminal.panel.fields.price.origin')} />
              )}
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onAdditionalOrderPriceChange(value);
                }
                trigger('additionalOrderPrice');
                trigger('additionalTotal');
                trigger('additionalUnits');
                trigger('additionalSlider');
              }}
              icon={quoteAsset}
              status={errors.additionalOrderPrice ? 'error' : undefined}
              onBlur={() => {
                const formattedValue = formatByPrecisionAndTrim(
                  field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                field.onChange(formattedValue);
                onAdditionalOrderPriceChange(formattedValue);
                trigger('additionalOrderPrice');
                trigger('additionalTotal');
                trigger('additionalUnits');
                trigger('additionalSlider');
              }}
              error={errors?.additionalOrderPrice?.message?.toString()}
            />
          )}
        />

        <Controller
          name='additionalOrderPricePercent'
          control={control}
          rules={{
            required: 'This field is required',
          }}
          render={({
            field, 
          }) => (
            <Input
              value={field.value}
              onChange={(value) => {
                // @TODO separate validations in helpers (dirrerent rules for percent and etc)
                if (/^-?[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onAdditionalOrderPricePercentChange(value);
                  trigger('additionalOrderPricePercent');
                  trigger('additionalOrderPrice');
                  trigger('additionalTotal');
                  trigger('additionalUnits');
                  trigger('additionalSlider');
                }
              }}
              maxWidth={58}
              icon='%'
              status={errors.additionalOrderPricePercent ? 'error' : undefined}
            />
          )}
        />
      </Box>
    );
  };
  
  const renderUnitsField = () => {
    return (
      <Controller
        name='additionalUnits'
        control={control}
        rules={{
          required: t('validation.terminal.required'),
          validate: (value) => {
            const numValue = Number(value);
            if (isNaN(numValue)) {
              return t('validation.terminal.number');
            }
                
            if (numValue < Number(currentSymbol.lotMin)) {
              return t('validation.terminal.min', {
                min: getTrailedZeroCutted(currentSymbol.lotMin),
              });
            }
            if (numValue > Number(currentSymbol.lotMax)) {
              return t('validation.terminal.max', {
                max: getTrailedZeroCutted(currentSymbol.lotMax),
              });
            }
            return true;
          },
        }}
        render={({
          field,
        }) => (
          <LabelInput
            label={(
              <Label title={t('terminal.panel.fields.units')} />
            )}
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onAdditionalUnitsChange(value);
              }
              trigger('additionalUnits');
              trigger('additionalTotal');
              trigger('additionalSlider');
            }}
            icon={baseAsset}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value, currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
              field.onChange(formattedValue);
              onAdditionalUnitsChange(formattedValue);
              trigger('additionalUnits');
              trigger('additionalTotal');
              trigger('additionalSlider');
            }}
            status={errors.additionalUnits ? 'error' : undefined}
            error={errors?.additionalUnits?.message?.toString()}
          />
        )}
      />
    );
  };
  
  const renderTotalField = () => (
    <Controller
      name='additionalTotal'
      control={control}
      rules={{
        required: t('validation.terminal.required'),
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) {
            return t('validation.terminal.number');
          }
                
          if (numValue < Number(currentSymbol.minNotional)) {
            return t('validation.terminal.min', {
              min: getTrailedZeroCutted(currentSymbol.minNotional),
            });
          }
          if (numValue > Number(currentSymbol.maxNotional)) {
            return t('validation.terminal.max', {
              max: getTrailedZeroCutted(currentSymbol.maxNotional),
            });
          }
          return true;
        },
      }}
      render={({
        field, 
      }) => (
        <LabelInput
          label={(
            <Label title={t('terminal.panel.fields.total')} />
          )}
          value={field.value}
          onChange={(value) => {
            if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
              field.onChange(value);
              onAdditionalTotalChange(value);
            }
            trigger('additionalTotal');
            trigger('additionalUnits');
            trigger('additionalSlider');
          }}
          icon={quoteAsset}
          status={errors.additionalTotal ? 'error' : undefined}
          onBlur={() => {
            const formattedValue = formatByPrecisionAndTrim(
              field.value,
              currentSymbol.quoteAssetPrecision,
              Number(currentSymbol.minNotional),
              Number(currentSymbol.maxNotional),
            );
            field.onChange(formattedValue);
            onAdditionalTotalChange(formattedValue);
            trigger('additionalTotal');
            trigger('additionalUnits');
            trigger('additionalSlider');
          }}
          error={errors?.additionalTotal?.message?.toString()}
        />
      )}
    />
  );
  
  const renderSlider = () => (
    <Stack
      gap={0.5}
      height={53}
    >
      <Box sx={sliderWrapper}>
        <Controller
          name='additionalSlider'
          control={control}
          rules={{
            max: {
              value: 100,
              message: t('validation.terminal.slider.max'),
            },
            min: {
              value: 1,
              message: t('validation.terminal.slider.min'),
            },
          }}
          render={({
            field, 
          }) => (
            <Slider
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                onAdditionalSliderChange(value);
                trigger('additionalSlider');
                trigger('additionalTotal');
                trigger('additionalUnits');
              }}
            />
          )}
        />

        <Controller
          name='additionalSlider'
          control={control}
          render={({
            field, 
          }) => (
            <Input
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]+$/.test(value) || value === '') {
                  field.onChange(value);
                  onAdditionalSliderChange(parseFloat(value));
                  trigger('additionalSlider');
                  trigger('additionalTotal');
                  trigger('additionalUnits');
                }
              }}
              placeholder='100%'
              maxWidth={58}
              icon='%'
              status={errors.additionalSlider ? 'error' : undefined}
            />
          )}
        />
      </Box>

      <ErrorMessage message={errors?.additionalSlider?.message} />
    </Stack>
  );
  
  const renderTriggerPriceField = () => {
    if (additionalOrderType !== 'cond.market' && additionalOrderType !== 'cond.limit') {
      return null;
    }

    return (
      <Box sx={actions}>
        <Controller
          name='additionalTriggerPrice'
          control={control}
          rules={{
            required: t('validation.terminal.required'),
            validate: (value) => {
              const numValue = Number(value);
              if (isNaN(numValue)) {
                return t('validation.terminal.number');
              }
                
              if (numValue < Number(currentSymbol.priceMin)) {
                return t('validation.terminal.min', {
                  min: getTrailedZeroCutted(currentSymbol.priceMin),
                });
              }
              if (numValue > Number(currentSymbol.priceMax)) {
                return t('validation.terminal.max', {
                  max: getTrailedZeroCutted(currentSymbol.priceMax),
                });
              }
              return true;
            },
          }}
          render={({
            field,
          }) => (
            <LabelInput
              label={(
                <Label title={t('terminal.panel.fields.triggerPrice.title')} />
              )}
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onAdditionalTriggerPriceChange(value);
                }
                trigger('additionalTriggerPrice');
              }}
              onBlur={() => {
                const formattedValue = formatByPrecisionAndTrim(
                  field.value,
                  currentSymbol.quoteAssetPrecision,
                  Number(currentSymbol.priceMin),
                  Number(currentSymbol.priceMax),
                );
                field.onChange(formattedValue);
                onAdditionalTriggerPriceChange(formattedValue);
                trigger('additionalTriggerPrice');
              }}
              icon={quoteAsset}
              addonBefore={additionalTriggerPricePercent > 100 ? '>100%' : `${additionalTriggerPricePercent}%`}
              status={errors.additionalTriggerPrice ? 'error' : undefined}
              error={errors?.additionalTriggerPrice?.message?.toString()}
            />
          )}
        />

        <Controller
          name='additionalTriggerPriceType'
          control={control}
          render={({
            field, 
          }) => (
            <SingleSelect
              maxWidth='max-content'
              select={{
                value: field.value,
                placeholder: 'Last',
                onChange: (value) => {
                  field.onChange(value);
                  trigger('additionalTriggerPriceType');
                },
              }}
              options={[{
                label: t('terminal.panel.fields.types.last'),
                value: 'last', 
              }, {
                label: t('terminal.panel.fields.types.bid'),
                value: 'bid', 
              }, {
                label: t('terminal.panel.fields.types.ask'),
                value: 'ask', 
              }]}
            />
          )}
        />
      </Box>
    );
  };

  const getFields = () => {
    if (selectedSide === 'short') {
      return (
        <Fragment>
          {renderOrderPriceField()}
          {renderUnitsField()}
          {renderTotalField()}
          {renderSlider()}
        </Fragment>
      );
    }

    return (
      <Fragment>
        {renderTriggerPriceField()}
        {renderOrderPriceField()}
        {renderTotalField()}
        {renderUnitsField()}
        {renderSlider()}
      </Fragment>
    );
  };
  
  return (
    <Box sx={wrpaper}>
      <Switch
        value={isAddEntryEnabled}
        onChange={setIsSkipAdditionalEntry}
        suffixText={(
          <Title
            level={5}
            styles={suffixText}
          >
            {t('terminal.panel.additional.title')}
          </Title>
        )}
        size='small'
      />

      {isAddEntryEnabled && (
        <>
          <Segmented
            value={additionalOrderType}
            onChange={changeSegmentHandler}
            options={segments(t)}
            block={true}
            size='large'
          />
  
          {additionalEntries.length > 0 && (
            <SafetyOrders
              additionalEntries={additionalEntries}
              onRemoveEntry={onRemoveEntry}
            />
          )}

          {getFields()}

          {errors.additionalEntryDuplicatePriceError && (
            <Text
              type='danger'
              styles={{
                fontSize: '12px', 
                marginTop: '4px',
                marginBottom: '8px',
              }}
            >
              {errors.additionalEntryDuplicatePriceError.message as string}
            </Text>
          )}
          
          {errors.additionalEntryInsufficientBalanceError && (
            <Text
              type='danger'
              styles={{
                fontSize: '12px', 
                marginTop: '4px',
                marginBottom: '8px',
              }}
            >
              {errors.additionalEntryInsufficientBalanceError.message as string}
            </Text>
          )}

          <MainButton
            icon={PlusGreen}
            styles={button}
            onClick={onAddEntry}
            size='large'
          >
            <Title
              level={5}
              styles={buttonTitle}
            >
              {t('terminal.panel.additional.button')}
            </Title>
          </MainButton>
        </>
      )}
    </Box>
  );
};
