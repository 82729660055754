import { ActivateAccountPage } from 'features/activate-account';
import { HomePage } from 'pages/home';
import { MyAccountsPage } from 'pages/my-accounts';
import { appPath } from '../app-routes/consts';
import { IPage } from '../interfaces';

export const accountsRoutes = () => {
  const routes: IPage[] = [{
    title: 'Activate account',
    path: appPath.activate_account.path,
    element: (
      <ActivateAccountPage />
    ),
  }, {
    title: 'My accounts',
    path: appPath.my_accounts.path,
    element: (
      <MyAccountsPage />
    ),
  }, {
    title: 'Account',
    path: appPath.my_account.path,
    element: (
      <HomePage />
    ),
  }];

  return routes;
};
