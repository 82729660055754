import { calculateUnits, calculateTotal, formatByPrecisionAndTrim, roundPercentage, calculatePercentDifference, calculatePercentageWithCeil } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useCallback } from 'react';

export const useBaseOrderSellInputsLogic = (currentSymbol: any, userWallet: any, userWalletBaseAsset: any, watch: any, setValue: any, isSkipBaseOrder: boolean, chartLastPrice: number, limitLastPrice: number) => {
  // move to form state
  const lastChangedField = watch('lastChangedField');
  
  const triggerPrice = watch('triggerPrice');
  const orderPrice = watch('orderPrice');
  const boughtPrice = watch('boughtPrice');
  const total = watch('total');
  const units = watch('units');
  
  const additionalOrderPrice = watch('additionalOrderPrice');
  const additionalTriggerPrice = watch('additionalTriggerPrice');
  
  const takeProfitOrderPrice = watch('takeProfitOrderPrice');
  const takeProfitTriggerPrice = watch('takeProfitTriggerPrice');
  
  const stopLossOrderPrice = watch('stopLossOrderPrice');
  const stopLossTriggerPrice = watch('stopLossTriggerPrice');
  
  const skipBaseUnits = watch('skipBaseUnits');
  const skipBaseTotal = watch('skipBaseTotal');
  
  const orderType = watch('orderType');
  const conditionalOrderType = watch('conditionalOrderType');
  
  const handleTriggerPriceChange = useCallback((value: string) => {
    if (conditionalOrderType === 'limit') {
      return;
    }
    
    const triggerPrice = parseFloat(value);
    if (!isNaN(triggerPrice) && orderType === 'conditional' && conditionalOrderType === 'market') {
      
      if (lastChangedField === null || lastChangedField === 'units') {
        const newTotal = calculateTotal(parseFloat(units), triggerPrice, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('total', formattedTotal);
      } else {
        const newUnits = calculateUnits(parseFloat(total), triggerPrice, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('units', formattedUnits);
            
        const percentageValue = (parseFloat(newUnits) / userWalletBaseAsset.free) * 100;
        setValue('slider', roundPercentage(percentageValue));
      }
    }
    
    if (orderType === 'conditional' && conditionalOrderType === 'market') {
    
      const additionalOrderPricePercentDifference = calculatePercentDifference(+additionalOrderPrice, triggerPrice);
      setValue('additionalOrderPricePercent', additionalOrderPricePercentDifference.toString());

      const additionalTriggerPricePercentDifference = calculatePercentDifference(+additionalTriggerPrice, triggerPrice);
      setValue('additionalTriggerPricePercent', additionalTriggerPricePercentDifference.toString());
    
      const stopLossOrderPricePercentDifference = calculatePercentDifference(+stopLossOrderPrice, triggerPrice);
      setValue('stopLossOrderPricePercent', stopLossOrderPricePercentDifference.toString());
    
      const stopLossTriggerPricePercentDifference = calculatePercentDifference(+stopLossTriggerPrice, triggerPrice);
      setValue('stopLossTriggerPricePercent', stopLossTriggerPricePercentDifference.toString());
    
      const takeProfitTriggerPricePercentDifference = calculatePercentDifference(+takeProfitTriggerPrice, triggerPrice);
      setValue('takeProfitTriggerPricePercent', takeProfitTriggerPricePercentDifference.toString());
    
      const takeProfitOrderPricePercentDifference = calculatePercentDifference(+takeProfitOrderPrice, triggerPrice);
      setValue('takeProfitOrderPricePercent', takeProfitOrderPricePercentDifference.toString());
    
    }
    
  }, [
    orderType, 
    conditionalOrderType, 
    lastChangedField, 
    total, 
    units,
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    userWalletBaseAsset.free, 
    setValue]);
  
  const handleOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    
    if (!isNaN(price)) {
      if (lastChangedField === null || lastChangedField === 'units') {
        const newTotal = calculateTotal(parseFloat(units), price, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('total', formattedTotal);
      } else {
        const newUnits = calculateUnits(parseFloat(total), price, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('units', formattedUnits);
        
        const percentageValue = (parseFloat(newUnits) / userWalletBaseAsset.free) * 100;
        setValue('slider', roundPercentage(percentageValue));
      }
    }
    
    const additionalOrderPricePercentDifference = calculatePercentDifference(+additionalOrderPrice, price);
    setValue('additionalOrderPricePercent', additionalOrderPricePercentDifference.toString());

    const additionalTriggerPricePercentDifference = calculatePercentDifference(+additionalTriggerPrice, price);
    setValue('additionalTriggerPricePercent', additionalTriggerPricePercentDifference.toString());
    
    const stopLossOrderPricePercentDifference = calculatePercentDifference(+stopLossOrderPrice, price);
    setValue('stopLossOrderPricePercent', stopLossOrderPricePercentDifference.toString());
    
    const stopLossTriggerPricePercentDifference = calculatePercentDifference(+stopLossTriggerPrice, price);
    setValue('stopLossTriggerPricePercent', stopLossTriggerPricePercentDifference.toString());
    
    const takeProfitTriggerPricePercentDifference = calculatePercentDifference(+takeProfitTriggerPrice, price);
    setValue('takeProfitTriggerPricePercent', takeProfitTriggerPricePercentDifference.toString());
    
    const takeProfitOrderPricePercentDifference = calculatePercentDifference(+takeProfitOrderPrice, price);
    setValue('takeProfitOrderPricePercent', takeProfitOrderPricePercentDifference.toString());
  }, [
    lastChangedField,
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    additionalOrderPrice, 
    total, 
    units,
    userWalletBaseAsset.free,
    stopLossOrderPrice,
    stopLossTriggerPrice, 
    takeProfitTriggerPrice, 
    additionalTriggerPrice, 
    takeProfitOrderPrice, 
    setValue]);
  
  
  const handleTotalChange = useCallback((value: string) => {
    setValue('lastChangedField', 'total');
    const newTotal = parseFloat(value);
    
    if (!isNaN(newTotal)) {
      const priceToUse = orderType === 'conditional' && conditionalOrderType === 'market' 
        ? parseFloat(triggerPrice) 
        : parseFloat(orderPrice);
      
      const newUnits = calculateUnits(newTotal, priceToUse, currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('units', formattedUnits);
      
      const userWalletFree = parseFloat(userWalletBaseAsset.free);
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = (+newUnits / userWalletFree) * 100;
      }
      setValue('slider', roundPercentage(percentageValue));
    }
  }, [
    userWalletBaseAsset.free,
    currentSymbol.baseAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    orderPrice, 
    orderType, 
    conditionalOrderType, 
    triggerPrice, 
    setValue,
  ]);
  
  
  const handleUnitsChange = useCallback((value: string) => {
    setValue('lastChangedField', 'units');
    const newUnits = parseFloat(value);
    
    if (!isNaN(newUnits)) {
      const priceToUse = orderType === 'conditional' && conditionalOrderType === 'market' 
        ? parseFloat(triggerPrice) 
        : parseFloat(orderPrice);

      const newTotal = calculateTotal(newUnits, priceToUse, currentSymbol.quoteAssetPrecision);
      const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      setValue('total', formattedTotal);

      const userWalletFree = parseFloat(userWalletBaseAsset.free);
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = calculatePercentageWithCeil(newUnits, userWalletFree);
      }
      setValue('slider', percentageValue);
    }
  }, [
    orderPrice, 
    orderType,
    currentSymbol.maxNotional,
    currentSymbol.minNotional,
    currentSymbol.quoteAssetPrecision,
    conditionalOrderType, 
    userWalletBaseAsset.free, 
    triggerPrice,
    setValue,
  ]);
  
  const handleBoughtPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    if (!isNaN(price)) {
      if (lastChangedField === null || lastChangedField === 'total') {
        const newUnits = calculateUnits(parseFloat(skipBaseTotal), price, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('skipBaseUnits', formattedUnits);
      } else {
        const newTotal = calculateTotal(parseFloat(skipBaseUnits), price, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('skipBaseTotal', formattedTotal);
          
        const percentageValue = (parseFloat(newTotal) / userWallet.free) * 100;
        setValue('skipBaseSlider', roundPercentage(percentageValue));
      }
    }
    
    const additionalOrderPricePercentDifference = calculatePercentDifference(+additionalOrderPrice, price);
    setValue('additionalOrderPricePercent', additionalOrderPricePercentDifference.toString());

    const additionalTriggerPricePercentDifference = calculatePercentDifference(+additionalTriggerPrice, price);
    setValue('additionalTriggerPricePercent', additionalTriggerPricePercentDifference.toString());
    
    const stopLossOrderPricePercentDifference = calculatePercentDifference(+stopLossOrderPrice, price);
    setValue('stopLossOrderPricePercent', stopLossOrderPricePercentDifference.toString());
    
    const stopLossTriggerPricePercentDifference = calculatePercentDifference(+stopLossTriggerPrice, price);
    setValue('stopLossTriggerPricePercent', stopLossTriggerPricePercentDifference.toString());
    
    const takeProfitTriggerPricePercentDifference = calculatePercentDifference(+takeProfitTriggerPrice, price);
    setValue('takeProfitTriggerPricePercent', takeProfitTriggerPricePercentDifference.toString());
    
    const takeProfitOrderPricePercentDifference = calculatePercentDifference(+takeProfitOrderPrice, price);
    setValue('takeProfitOrderPricePercent', takeProfitOrderPricePercentDifference.toString());
  }, [
    skipBaseTotal,
    skipBaseUnits,
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    userWallet.free,
    setValue,
    lastChangedField,
    stopLossOrderPrice,
    additionalOrderPrice, 
    stopLossTriggerPrice, 
    takeProfitTriggerPrice, 
    additionalTriggerPrice, 
    takeProfitOrderPrice,
  ]);
  
  const handleSkipBaseTotalChange = useCallback((value: string) => {
    setValue('lastChangedField', 'total');
    const newTotal = parseFloat(value);
    if (!isNaN(newTotal)) {
      const newUnits = calculateUnits(newTotal, parseFloat(boughtPrice), currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('skipBaseUnits', formattedUnits);
      
      const percentageValue = ((newTotal) / userWallet.free) * 100;
      setValue('skipBaseSlider', roundPercentage(percentageValue));
    }
  }, [
    setValue, 
    currentSymbol.baseAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    userWallet.free, 
    boughtPrice,
  ]);
  
  const handleSkipBaseUnitsChange = useCallback((value: string) => {
    setValue('lastChangedField', 'units');
    const newUnits = parseFloat(value);
    if (!isNaN(newUnits)) {
      const newTotal = calculateTotal(newUnits, parseFloat(boughtPrice), currentSymbol.quoteAssetPrecision);
      const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      setValue('skipBaseTotal', formattedTotal);
      
      const percentageValue = ((+newTotal) / userWallet.free) * 100;
      setValue('skipBaseSlider', roundPercentage(percentageValue));
    }
  }, [
    boughtPrice, 
    setValue, 
    userWallet.free,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
  ]);
  
  
  
  //////////////////////////////////////////////////////////////
  const handleSliderChange = useCallback((value: number) => {
    setValue('lastChangedField', 'units');
    
    const newUnits = (userWalletBaseAsset.free * value) / 100;
    const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
    setValue('units', formattedUnits);
    
    const newTotal = calculateTotal(newUnits, parseFloat(orderPrice), currentSymbol.quoteAssetPrecision);
    const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
    setValue('total', formattedTotal);
  }, [
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    userWalletBaseAsset.free,
    orderPrice,
    setValue,
  ]);

  const handleSkipBaseSliderChange = useCallback((value: number) => {
    setValue('lastChangedField', 'total');
    const newTotal = (userWallet.free * value) / 100;
    const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
    
    const newUnits = calculateUnits(newTotal, parseFloat(boughtPrice), currentSymbol.baseAssetPrecision);
    const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
    
    setValue('skipBaseUnits', formattedUnits);
    setValue('skipBaseTotal', formattedTotal);
  }, [
    userWallet.free,
    boughtPrice,
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    setValue,
  ]);
  

  const setInitialValues = useCallback(() => {
    
    let initialOrderPrice;
    if (isSkipBaseOrder) {
      initialOrderPrice = chartLastPrice;
    } else {
      initialOrderPrice = orderType === 'market' ? limitLastPrice : chartLastPrice;
    }
    
    const formattedOrderPrice = formatByPrecisionAndTrim(
      initialOrderPrice.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    );
  
    setValue('orderPrice', formattedOrderPrice, {
      shouldValidate: true, 
    });
  
    if (orderType === 'conditional') {
      setValue('triggerPrice', formattedOrderPrice);
    }
  
    if (isSkipBaseOrder) {
      setValue('skipBaseSlider', 100);     
      const initialTotal = userWallet.free;
      
      const formattedSkipBaseTotal = formatByPrecisionAndTrim(
        initialTotal.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.minNotional),
        Number(currentSymbol.maxNotional),
      );
      
      setValue('skipBaseTotal', formattedSkipBaseTotal);

      const initialUnits = calculateUnits(parseFloat(formattedSkipBaseTotal), parseFloat(formattedOrderPrice), currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(
        initialUnits.toString(),
        currentSymbol.baseAssetPrecision,
        Number(currentSymbol.lotMin),
        Number(currentSymbol.lotMax),
      );
      setValue('skipBaseUnits', formattedUnits);
    } else {
      const initialUnits = userWalletBaseAsset.free * 0.1;
        
      if (initialUnits > 0) {
        const formattedUnitsRaw = formatByPrecisionAndTrim(
          initialUnits.toString(),
          currentSymbol.baseAssetPrecision,
          Number(currentSymbol.lotMin),
          Number(currentSymbol.lotMax),
        );
        
        const initialTotal = calculateTotal(parseFloat(formattedUnitsRaw), parseFloat(formattedOrderPrice), currentSymbol.quoteAssetPrecision);
        
        const formattedTotal = formatByPrecisionAndTrim(
          initialTotal.toString(),
          currentSymbol.quoteAssetPrecision,
          Number(currentSymbol.minNotional),
          Number(currentSymbol.maxNotional),
        );
      
        setValue('total', formattedTotal);
      
        const newUnits = calculateUnits(parseFloat(formattedTotal), parseFloat(formattedOrderPrice), currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(
          newUnits.toString(),
          currentSymbol.baseAssetPrecision,
          Number(currentSymbol.lotMin),
          Number(currentSymbol.lotMax),
        );
        setValue('units', formattedUnits);
        setValue('slider', 10);
      } else {
        setValue('units', '0');
        setValue('total', '0');
        setValue('slider', 0);
      }
    }
  
    setValue('lastChangedField', null);
  }, [
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.priceMin,
    currentSymbol.priceMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    orderType,
    chartLastPrice,
    isSkipBaseOrder,
    userWallet.free,
    userWalletBaseAsset.free,
    setValue,
  ]);
  
  return {
    handleTriggerPriceChange,
    handleOrderPriceChange,
    handleBoughtPriceChange,
    handleTotalChange,
    handleUnitsChange,
    handleSliderChange,
    handleSkipBaseTotalChange,
    handleSkipBaseUnitsChange,
    handleSkipBaseSliderChange,
    setInitialValues,
  };
};
