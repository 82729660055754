import createAxiosInstance from 'shared/api/axios/axios';
import { fetchPaginatedSmartTrades } from 'shared/api/axios/helpers/fetch-paginated';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getDatePair } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { TDates } from 'shared/types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface AllExchangesTradesRequestProps {
  exchangeAccountUuids: string[];
  startDates: TDates;
  finishDates: TDates;
}
export const tradeGetAll = async (props: AllExchangesTradesRequestProps) => {
  const {
    exchangeAccountUuids,
    startDates,
    finishDates,
  } = props;

  const endPoint = 'smartTrade/getByFilter';

  const createdDate = getDatePair(startDates);
  const finishedDate = getDatePair(finishDates);
  
  const requestData: { [key: string]: any; } = {
    table: 'terminal_smart_trades',
    skyrexUserUuids: [getSkyrexUuid()!],
    exchangeAccountUuids: exchangeAccountUuids,
    createdDateFrom: createdDate.start,
    createdDateTo: createdDate.end,
    finishedDateFrom: finishedDate.start,
    finishedDateTo: finishedDate.end,
    page: 1,
  };
  
  return fetchPaginatedSmartTrades(axios, endPoint, requestData);
};
