import { SxProps, Theme } from '@mui/material';

export const LIST = (isLoading: boolean) => {
  const styles: SxProps<Theme> = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(318px, 1fr))',
    alignItems: 'flex-start',
    gap: '30px 20px',
    minHeight: 431,
    maxHeight: (411 * 2) + 60,
    overflowX: 'hidden',
    padding: '10px',
    position: 'relative',
  };

  if (isLoading) {
    styles.overflow = 'hidden';
  }

  return styles;
};
