import { TLanguage, TTranslation } from 'shared/types';

export const modalAddBot = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      addBot: {
        title: 'Choose bot type',
        copy: {
          title: 'Copy bot',
          description: 'Copy top performing bots with protected profit sharing',
        },
        alert: {
          title: 'Alert bot',
          description: 'Turn webhook alerts into automated trades at exchanges',
        },
      },
    },
    ru: {
      addBot: {
        title: 'Выберите тип бота',
        copy: {
          title: 'Копи-бот',
          description: 'Копируйте лучших ботов с защищённой моделью прибыли',
        },
        alert: {
          title: 'Алерт-бот',
          description: 'Преобразуйте вебхуки в автотрейды на бирже',
        },
      },
    },
  };

  return transaltion[type].addBot;
};
