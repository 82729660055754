
import { Box } from '@mui/material';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { Preview } from 'pages/register/components';
import { TradingViewChart } from 'pages/trading-terminal-page/trading-chart/trading-view-chart/trading-view-chart';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { appPath } from 'shared/config/route-config/app-routes/consts';
import { ConnectNewExchange, Loader, MainButton } from 'shared/ui';
import { ActiveTrades as ActiveTradesComponent } from '../components';
import { ExchangeSelect } from '../components/exchange-select/ui/exchange-select';
import { MarketSelect } from '../components/market-select/ui/market-select';
import { TradingPairSelect } from '../components/trading-pair-select/ui/trading-pair-select';
import { TradingPanel } from '../components/trading-panel/trading-panel';
import { WalletTable } from '../components/wallet-table/wallet-table';
import { useTrading } from '../hooks/use-terminal-page-trading-data';
import { filters, graphicWrapper, tableWrapper, wrapper } from '../styles';
import { CONNECT_EXCHANGE_BUTTON } from '../styles/connect-exchange-button';

const MemoizedWalletTable = memo(WalletTable);

export const TradingTerminal = () => {
  const {
    exchanges,
    selectedExchange,
    currentSymbol,
    dailyChange,
    marketOptions,
    selectedMarket,
    tradingPairs,
    chartSymbol,
    selectedTradingPair,
    userWalletBalances,
    isLoading,
    handleExchangeSelect,
    handleMarketSelect,
    userWalletQuoteAssetBalance,
    userWalletBaseAssetBalance,
    chosenExchange,
    handleTradingPairSelect,
  } = useTrading();

  const [isConnectExchangeModalOpen, setIsConnectExchangeModalOpen] = useState(false);
  const isDemoMode = useSelector(getIsDemoMode);
  const noExchange = selectedExchange?.value === 'no_exchange';

  const navigate = useNavigate();

  const getTradingPanel = () => {
    return (
      <TradingPanel
        currentSymbol={currentSymbol}
        selectedExchange={selectedExchange}
        userWalletQuoteAssetBalance={userWalletQuoteAssetBalance}
        userWalletBaseAssetBalance={userWalletBaseAssetBalance}
        exchangeAccountUuid={selectedExchange?.value}
      />
    );
  };

  const getChart = () => {
    if (!selectedExchange?.exchangeCode) {
      return (
        <Loader isContentOverflow={true} />
      );
    }
    
    const defaultChartSymbol = 'BTCUSDT';
    
    return  (
      <TradingViewChart
        exchangeName={isDemoMode ? 'binance' : selectedExchange?.exchangeCode || 'binance'}
        currentSymbol={chartSymbol ?? defaultChartSymbol}
      />
    );
  };

  return (
    <Box sx={wrapper}>
      <Box sx={tableWrapper}>
        <Box sx={filters}>
          
          <ConnectNewExchange 
            isModalOpen={isConnectExchangeModalOpen} 
            setIsModalOpen={setIsConnectExchangeModalOpen} 
          />
          
          {noExchange ? (
            <MainButton
              type='primary'
              ghost={true}
              styles={CONNECT_EXCHANGE_BUTTON}
              onClick={() => setIsConnectExchangeModalOpen(true)}
            >
              {'Connect exchange'}
            </MainButton>
          ) : ( 
            <ExchangeSelect
              exchanges={exchanges}
              selectedExchange={selectedExchange}
              isLoading={isLoading}
              onSelect={handleExchangeSelect}
            />)}
          
          <MarketSelect
            marketOptions={marketOptions}
            selectedMarket={selectedMarket}
            isLoading={isLoading}
            onSelect={handleMarketSelect}
            disabled={noExchange}
          />
          
          <TradingPairSelect
            tradingPairs={tradingPairs}
            selectedTradingPair={selectedTradingPair}
            isLoading={isLoading}
            onSelect={handleTradingPairSelect}
            disabled={noExchange}
          />
        </Box>

        <MemoizedWalletTable
          userWalletBalances={userWalletBalances}
          chosenExchange={chosenExchange}
          dailyChange={dailyChange}
        />
      </Box>

      <Box sx={graphicWrapper}>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            gap: 3,
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            position: 'relative',
          }}
        > 
          <Box
            sx={{
              flexGrow: 1,
              overflow: 'hidden',
              height: '100dvh',
              maxHeight: '661px',
            }}
          > 
            {getChart()}
          </Box>
          
          {getTradingPanel()}
        </Box>
      </Box>

      <ActiveTradesComponent exchanges={exchanges} />

      <Preview
        onClose={() => {
          navigate(appPath.manual_trading_trading_terminal.navigate());
        }}
      />
    </Box>
  );
};

