import { Text } from 'shared/ui';
import { STEP } from '../styles';

export const strategyCustomSteps = (t: any, currentStep: number) => {
  const steps = [{
    title: (
      <Text
        styles={STEP}
        type={currentStep === 3 ? 'success' : undefined}
      >
        {t('configurator.steps.4')}
      </Text>
    ),
  }, {
    title: (
      <Text
        styles={STEP}
        type={currentStep === 4 ? 'success' : undefined}
      >
        {t('configurator.steps.5')}
      </Text>
    ),
  }];

  return steps;
};
