import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getDatePair } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { TDates } from 'shared/types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface IBotGetAllTradesParams {
  botUuids: string[];
  createDates: TDates;
  closeDates: TDates;
}
export const botGetAllTrades = async (params: IBotGetAllTradesParams) => {
  try {
    const {
      botUuids,
      createDates,
      closeDates,
    } = params;
  
    const createdDatePair = getDatePair(createDates);
    const closeDatePair = getDatePair(closeDates);

    const response = await axios.request({
      url: '/bot/getByFilterDeal',
      data: {
        skyrexUserUuids: [getSkyrexUuid()],
        botUuids,
        createdDateFrom: createdDatePair.start,
        createdDateTo: createdDatePair.end,
        closedDateFrom: closeDatePair.start,
        closedDateTo: closeDatePair.end,
      },
    });
      
    return response?.data?.data?.deals;
  } catch (error) {
    return [];
  }
};
