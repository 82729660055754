import { Stack } from '@mui/material';
import { BlockTitle } from 'pages/trading-bots/configurator-v2/components/block-title';
import { IStepProps } from 'pages/trading-bots/configurator-v2/interfaces';
import { AdditionalEntry } from 'pages/trading-bots/configurator-v2/views/additional-entry';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { options, tooltip } from '../consts';

type IAdditionalProps = IStepProps;
export const Additional = (props: IAdditionalProps) => {
  const {
    watch,
    setValue,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();

  const additional = watch('additional');
  const isOpened = additional.isOpened;
  const segment = additional.segment.value;

  const toggleHandler = (value: boolean) => {
    additional.isOpened = value;
    setValue('additional', additional);
  };

  const changeCustomSegmentHandler = (value: string) => {
    additional.segment.value = value;
    setValue('additional', additional);
  };

  const getSegment = () => {
    if (!isOpened) {
      return undefined;
    }

    return {
      selected: segment,
      options: options(t),
      change: changeCustomSegmentHandler,
      tooltip: tooltip(t),
    };
  };

  return (
    <Stack gap='20px'>
      <BlockTitle
        title={t('configurator.managment.custom.additional.title')}
        toggle={{
          toggled: isOpened,
          change: toggleHandler,
        }}
        segment={getSegment()}
      />
        
      <AdditionalEntry
        handleBack={props.handleBack}
        changeCurrentStep={props.changeCurrentStep}
      />
    </Stack>
  );
};
