import { BaseOptionType } from 'antd/es/select';
import { getExchangeType } from 'pages/trading-bots/configurator-v2/helpers';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { SingleSelect } from 'shared/ui';
import { Exchange as ExchangeView } from 'shared/ui/selects/single-select/views';

export const Exchange = () => {
  const {
    t, 
  } = useTranslation();

  const selectExchangeHandler = useCallback((value: string) => {
    console.log('value', value);
  }, []);

  const exchangeView = useCallback(() => {
    const exchange = {
      name: 'My binance account',
      code: 'binance',
      type: 'SPOT',
      totalUsdt: 10000,
    };

    return (
      <ExchangeView
        title={exchange.name}
        code={exchange.code}
        label={exchange.type}
        balance={exchange.totalUsdt}
      />
    );
  }, []);

  const exchangeOptionView = useCallback((option: BaseOptionType) => {
    const [name, code, totalUsdt] = (option.label as string || '').split('_');
    return (
      <ExchangeView
        title={name}
        code={code}
        label={getExchangeType(code || '')}
        balance={+totalUsdt || 0}
      />
    );
  }, []);

  return (
    <SingleSelect
      label={(
        <Label
          title={t('form.configurator.exchange.title')}
        />
      )}
      options={[{
        label: 'My binance account_binance_10000',
        value: 1,
      }]}
      select={{
        value: 1,
        placeholder: '',
        onChange: selectExchangeHandler,
      }}
      labelRender={exchangeView}
      optionRender={exchangeOptionView}
      size='large'
    />
  );
};
