import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const inner: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: getResponsiveValue({
    min: 12,
    max: 24,
  }),
};
