import { Fragment } from 'react/jsx-runtime';
import { IMenuProps } from 'widgets/header/interfaces';
import { Horizontal, Vertical } from '../views';

export const Menu = (props: IMenuProps) => {
  return (
    <Fragment>
      <Horizontal />
      
      <Vertical
        isPopupOpened={props.isPopupOpened}
        closePopupHandler={props.closePopupHandler}
      />
    </Fragment>
  );
};
