import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text, Title, Tooltip } from 'shared/ui';
import { formatByPrecisionAndTrim } from '../../helpers/helpers';
//@TODO перенести импорты в рамках оптимизации
import { InfoIcon, Wallet } from '../buy/icons';
import { price, priceWrapper, title, tooltipText } from '../buy/styles';

interface WalletInfoProps {
  isSkipBaseOrder: boolean;
  selectedSide: 'buy' | 'sell';
  currentSymbol: {
    quoteAsset: string;
    baseAsset: string;
    chartPrecision: number;
    quoteAssetPrecision: number;
    baseAssetPrecision: number;
    minNotional: string;
    maxNotional: string;
    lotMin: string;
    lotMax: string;
  };
  userWalletQuoteAsset: {
    free: string;
  };
  userWalletBaseAsset: {
    free: string;
  };
}

export const WalletInfo = (props: WalletInfoProps) => {
  const {
    isSkipBaseOrder,
    currentSymbol,
    userWalletQuoteAsset,
    userWalletBaseAsset,
    selectedSide,
  } = props;

  const {
    t, 
  } = useTranslation();

  const getValue = () => {
    const formatQuoteAssetValue = () => {
      if (!userWalletQuoteAsset?.free) {
        console.warn('Quote Asset Free Balance is missing');
        return '0 ' + currentSymbol?.quoteAsset;
      }
  
      const rawValue = userWalletQuoteAsset.free.toString();
      
      if (!currentSymbol?.chartPrecision) {
        console.warn('Quote Asset Precision is missing');
        return rawValue + ' ' + currentSymbol?.quoteAsset;
      }
      
      const formattedValue = formatByPrecisionAndTrim(
        rawValue,
        currentSymbol.chartPrecision,
        Number(currentSymbol.minNotional),
        Number(currentSymbol.maxNotional),
      );
      console.log('Formatted Quote Asset Value:', formattedValue);
      
      return `${formattedValue} ${currentSymbol?.quoteAsset}`;
    };
  
    const formatBaseAssetValue = () => {
      if (!userWalletBaseAsset?.free) {
        console.warn('Base Asset Free Balance is missing');
        return '0 ' + currentSymbol?.baseAsset;
      }
  
      const rawValue = userWalletBaseAsset.free.toString();
      console.log('Base Asset Raw Value:', rawValue);
      
      if (!currentSymbol?.baseAssetPrecision) {
        console.warn('Base Asset Precision is missing');
        return rawValue + ' ' + currentSymbol?.baseAsset;
      }
      
      const formattedValue = formatByPrecisionAndTrim(
        rawValue,
        currentSymbol.baseAssetPrecision,
        Number(currentSymbol.lotMin),
        Number(currentSymbol.lotMax),
      );
      console.log('Formatted Base Asset Value:', formattedValue);
      
      return `${formattedValue} ${currentSymbol?.baseAsset}`;
    };
  
    const value = !isSkipBaseOrder 
      ? formatQuoteAssetValue()
      : formatBaseAssetValue();
      
    console.log('Final Value:', value);
    return value;
  };

  return (
    <Stack flex={1}>
      <Stack
        direction='row'
        flex={1}
        justifyContent='space-between'
      >
        <Title styles={title}>
          {/* TODO: написать условие по которому будет меняться spot или futures */}
          {t(`terminal.panel.title.${selectedSide}.${isSkipBaseOrder ? 'skip' : 'origin'}`, {
            currency: currentSymbol?.baseAsset, 
          })}
        </Title>
      
        <Box sx={priceWrapper}>
          <Box
            sx={{
              width: 12,
              height: 12,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              '&>svg': {
                width: '100%',
                height: '100%',
              },
            }}
          >
            {Wallet}
          </Box>

          <Text
            type='success'
            styles={price}
          >
            {getValue()}
          </Text>

          <Tooltip
            title={(
              <Box maxWidth={188}>
                <Title
                  level={5}
                  styles={tooltipText}
                >
                  {t('terminal.panel.title.buy.tooltip', {
                    currency: currentSymbol?.quoteAsset, 
                  })}
                </Title>
              </Box>
            )}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&>svg': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              {InfoIcon}
            </Box>
          </Tooltip>
        </Box>
      </Stack>

      <Stack alignItems='flex-end'>
        <Text
          type='secondary'
          styles={{
            fontSize: 8,
          }}
        >
        </Text>
      </Stack>
    </Stack>
  );
};
