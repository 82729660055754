import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const WRAPPER: SxProps<Theme> = {
  '&>span[class*="ant-input-group-wrapper"]': {
    padding: '0 !important',
    '&>span[class*="ant-input-wrapper"]>[class*="ant-input-affix-wrapper"]': {
      padding: getResponsiveValue({
        min: 8,
        max: 10,
      }),
    },
  },
};
