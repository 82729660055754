import { CSSProperties } from 'react';
import { getResponsiveValue } from 'shared/helpers';

export const input: CSSProperties = {
  backgroundColor: '#fff',
  // padding: '14px 24px',
  fontSize: 14,
  padding: getResponsiveValue({
    min: 8,
    max: 8.5,
  }),
};
