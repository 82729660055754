import { TLanguage, TTranslation } from 'shared/types';

export const modalPayment = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      payment: {
        title: '{{planName}} Plan',
        notify: 'Your {{finishedDate}} days left in {{planName}} plan will be converted into <strong>{{days}} days</strong> of {{planName}} plan after purchase',
        choose: 'Choose a payment method',
      },
    },
    ru: {
      payment: {
        title: '{{planName}} План',
        notify:
          'Ваши {{finishedDate}} оставшиеся дни в {{planName}} плане будут конвертированы в <strong>{{days}} дней</strong> после покупки',
        choose: 'Выберите способ оплаты',
      },
    },
  };

  return transaltion[type].payment;
};
