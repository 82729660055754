import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { calculatePercentageWithCeil, calculateTotal, calculateUnits, formatByPrecisionAndTrim, roundPercentage } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export const useReduceFundsMethods = ({
  currentSymbol,
  userWalletBaseAssetBalance,
  userWalletQuoteAssetBalance,
}: {
    currentSymbol: any;
    userWalletBaseAssetBalance: Partial<WalletData>;
    userWalletQuoteAssetBalance: Partial<WalletData>;
  }) => {
  const {
    watch, setValue,
  } = useFormContext();
  
  // Специфичные для reduce funds поля
  const lastChangedField = watch('lastChangedField');
  const orderPrice = watch('orderPrice');
  const onReduceFundsTotal = watch('onReduceFundsTotal');
  const onReduceFundsUnits = watch('onReduceFundsUnits');
  
  const handleReduceFundsBuyTradeOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    
    if (!isNaN(price)) {
      if (!lastChangedField || lastChangedField === 'onReduceFundsUnits') {
        const newTotal = calculateTotal(parseFloat(onReduceFundsUnits), price, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('onReduceFundsTotal', formattedTotal);
      } else {
        const newUnits = calculateUnits(parseFloat(onReduceFundsTotal), price, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('onReduceFundsUnits', formattedUnits);
        
        const percentageValue = (parseFloat(newUnits) / parseFloat(userWalletBaseAssetBalance.free || '0')) * 100;
        setValue('onReduceFundsSlider', roundPercentage(percentageValue));
      }
    }
  }, [
    userWalletBaseAssetBalance.free,
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    onReduceFundsUnits,
    onReduceFundsTotal,
    setValue,
    lastChangedField,
  ]);
  
  const handleReduceFundsBuyTradeTotalChange = useCallback((value: string) => {
    setValue('lastChangedField', 'onReduceFundsTotal');
    const newTotal = parseFloat(value);

    if (!isNaN(newTotal)) {
      
      const priceToUse = parseFloat(orderPrice);
      
      const newUnits = calculateUnits(newTotal, priceToUse, currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('onReduceFundsUnits', formattedUnits);
      
      const userWalletFree = parseFloat(userWalletBaseAssetBalance.free || '0');
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = (+newUnits / userWalletFree) * 100;
      }
      setValue('onReduceFundsSlider', roundPercentage(percentageValue));
    }
  }, [
    currentSymbol.baseAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    userWalletBaseAssetBalance.free,
    setValue,
  ]);
  
  const handleReduceFundsBuyTradeUnitsChange = useCallback((value: string) => {
    setValue('lastChangedField', 'onReduceFundsUnits');
    const newUnits = parseFloat(value);
    
    if (!isNaN(newUnits)) {
      const priceToUse = parseFloat(orderPrice);

      const newTotal = calculateTotal(newUnits, priceToUse, currentSymbol.quoteAssetPrecision);
      const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      setValue('onReduceFundsTotal', formattedTotal);

      const userWalletFree = parseFloat(userWalletBaseAssetBalance.free || '0');
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = calculatePercentageWithCeil(newUnits, userWalletFree);
      }
      setValue('onReduceFundsSlider', percentageValue);
    }
  }, [
    userWalletBaseAssetBalance.free,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    setValue,
  ]);

  const handleReduceFundsBuyTradeSliderChange = useCallback((value: number) => {
    setValue('lastChangedField', 'onReduceFundsSlider');
    
    const newUnits = (parseFloat(userWalletBaseAssetBalance.free || '0') * value) / 100;
    const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
    setValue('onReduceFundsUnits', formattedUnits);
    
    const newTotal = calculateTotal(newUnits, parseFloat(watch('orderPrice')), currentSymbol.quoteAssetPrecision);
    const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
    setValue('onReduceFundsTotal', formattedTotal);
  }, [
    userWalletBaseAssetBalance.free,
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    currentSymbol.lotMax,
    currentSymbol.lotMin,
    setValue,
    watch,
  ]);
  
  ////////////////////////////////////////////////////////////////////////////////
  
  const handleReduceFundsSellTradeOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    
    if (!isNaN(price)) {
      if (!lastChangedField || lastChangedField === 'onReduceFundsTotal') {
        const newUnits = calculateUnits(parseFloat(onReduceFundsTotal), price, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('onReduceFundsUnits', formattedUnits);
      } else {
        const newTotal = calculateTotal(parseFloat(onReduceFundsUnits), price, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('onReduceFundsTotal', formattedTotal);
        const percentageValue = (parseFloat(newTotal) / parseFloat(userWalletQuoteAssetBalance.free || '0')) * 100;
        setValue('onReduceFundsSlider', roundPercentage(percentageValue));
      }
    }
  }, [
    userWalletQuoteAssetBalance.free,
    currentSymbol.baseAssetPrecision,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    lastChangedField,
    onReduceFundsUnits,
    onReduceFundsTotal,
    setValue,
  ]);
  
  
  const handleReduceFundsSellTradeUnitsChange = useCallback((value: string) => {
    setValue('lastChangedField', 'onReduceFundsUnits');
    const newUnits = parseFloat(value);
    
    if (!isNaN(newUnits)) {
      
      const priceToUse = parseFloat(orderPrice);
      const newTotal = calculateTotal(newUnits, priceToUse, currentSymbol.quoteAssetPrecision);
   
      let formattedTotal;
      
      if (newUnits === 0) {
        formattedTotal = '0';
      } else {
        formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      }
      
      setValue('onReduceFundsTotal', formattedTotal);

      const userWalletFree = parseFloat(userWalletQuoteAssetBalance.free || '0');
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = (parseFloat(formattedTotal) / userWalletFree) * 100;
      }
      setValue('onReduceFundsSlider', roundPercentage(percentageValue));
    }
  }, [
    userWalletQuoteAssetBalance.free,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    orderPrice,
    setValue,
  ]);
  
  const handleReduceFundsSellTradeTotalChange = useCallback((value: string) => {
    setValue('lastChangedField', 'onReduceFundsTotal');
    const newTotal = parseFloat(value);
    
    if (!isNaN(newTotal)) {
      
      const priceToUse = parseFloat(orderPrice);
      const newUnits = calculateUnits(newTotal, priceToUse, currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('onReduceFundsUnits', formattedUnits);
      
      const userWalletFree = parseFloat(userWalletQuoteAssetBalance.free || '0');
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = calculatePercentageWithCeil(newTotal, userWalletFree);
      }
      
      setValue('onReduceFundsSlider', percentageValue);
    }
  }, [
    userWalletQuoteAssetBalance.free,
    currentSymbol.baseAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    orderPrice,
    setValue,
  ]);
  
  const handleReduceFundsSellTradeSliderChange = useCallback((value: number) => {
    setValue('lastChangedField', 'onReduceFundsTotal');
    ///???????
    const newTotal = (parseFloat(userWalletQuoteAssetBalance.free || '0') * value) / 100;
    const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
    setValue('onReduceFundsTotal', formattedTotal);
    
    const newUnits = calculateUnits(newTotal, parseFloat(orderPrice), currentSymbol.baseAssetPrecision);
    const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
    setValue('onReduceFundsUnits', formattedUnits);
  }, [
    userWalletQuoteAssetBalance.free,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.baseAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
    orderPrice,
    setValue,
  ]);
  
  return {
    handleReduceFundsBuyTradeOrderPriceChange,
    handleReduceFundsBuyTradeTotalChange,
    handleReduceFundsBuyTradeUnitsChange,
    handleReduceFundsBuyTradeSliderChange,
    handleReduceFundsSellTradeOrderPriceChange,
    handleReduceFundsSellTradeUnitsChange,
    handleReduceFundsSellTradeTotalChange,
    handleReduceFundsSellTradeSliderChange,
  };
};
