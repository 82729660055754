import { SxProps, Theme } from '@mui/material';

export const wrapper: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: {
    xs: '65px',
    md: '24px',
  },
  flexDirection: {
    xs: 'column-reverse',
    md: 'initial',
  },
  padding: {
    xs: '44px 0',
    md: '90px 0',
  },
};
