import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const changeMode = async (params: Record<'exchangeAccountUuid' | 'marginMode', string>) => {
  const response = await axios.request({
    url: '/exchangeAccount/changeMarginMode',
    data: {
      exchangeAccountUuid: params.exchangeAccountUuid,
      marginMode: params.marginMode,
    },
  });
    
  return response.data;
};
