import { Deal } from 'pages/trading-bots/my-bot/api/get-bot-deals-uuids';

export const getUniqueBaseCurrency = (deals: Deal[]): { label: string; value: string; market: string; }[] => {
  const uniqueBaseMap = deals.reduce((acc, deal) => {
    if (!acc.has(deal.base)) {
      acc.set(deal.base, {
        label: deal.base.toUpperCase(),
        value: deal.base,
        market: deal.quote,
      });
    }
    return acc;
  }, new Map<string, { label: string; value: string; market: string; }>());
  
  const baseOptions = Array.from(uniqueBaseMap.values());
  
  return baseOptions;
};
