export const convertMarket = (market: any) => {
  return {
    name: market?.label || '',
    code: market?.value || '',
    value: market?.value || '',
    presition: market?.chartPrecision || '',
    balance: market?.balance || '',
    minNotional: market?.filter || '',
  };
};
