import { Box } from '@mui/material';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowDown } from 'shared/icons';
import { Text } from 'shared/ui';
import { IMultipleSelect } from '../interfaces';
import { labelText, selectWrapper, wrapper } from '../styles';

export const MultipleSelect = (props: IMultipleSelect) => {
  const {
    label,
    select,
    options,
    dropdown,
    maxWidth,
    maxTagPlaceholder,
    optionRender,
  } = props;

  const {
    t, 
  } = useTranslation();

  const getValues = () => {
    if (!select.value.length) {
      return [{
        label: t('bots.diary.select.currency.items.all'),
        value: 'all',
      }];
    }

    return (select.value as any[]).filter((item: string | number) => {
      if (typeof item === 'string') {
        return item !== 'all';
      }

      return true;
    });
  };

  return (
    <Box sx={wrapper(maxWidth)}>
      {label && (
        <Text
          type='secondary'
          styles={labelText}
        >
          {label}
        </Text>
      )}

      <Select
        value={getValues()}
        placeholder={select.placeholder}
        options={options}
        onChange={select.onChange}
        optionRender={optionRender}
        variant='outlined'
        style={selectWrapper}
        suffixIcon={ArrowDown}
        dropdownRender={dropdown}
        size='large'
        mode='multiple'
        maxTagCount='responsive'
        maxTagPlaceholder={maxTagPlaceholder}
      />
    </Box>
  );
};
