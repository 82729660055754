import { CSSProperties } from 'react';
import { ProgressMarkProps } from '../active-trade/types/active-trade.types';
import { baseStyles } from './progress-mark.styles';

export const getPercentageColor = (label: string): string => {
  const numValue = parseFloat(label);
      
  if (isNaN(numValue)) return '#2ECD99'; // дефолтный цвет для нечисловых значений
      
  if (numValue < 0) return 'red';
  if (numValue === 0) return '#9E9E9E';
  return '#2ECD99'; // зеленый цвет для положительных значений
};
  
export const getLabelStyles = ({
  label,
  isExchangePrice,
  t,
}: ProgressMarkProps & { label: string; t: any; }): CSSProperties => {
  return ({
    ...baseStyles.label,
    color: isExchangePrice 
      ? getPercentageColor(label)
      : label === t('terminal.trades.progress.marks.sl') ? 'red' : '#2ECD99',
  });
};
  
export const getValueStyles = (): CSSProperties => ({
  ...baseStyles.value,
  color: 'black',
});
