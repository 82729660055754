import { Stack } from '@mui/material';
import { getCurrentLanguage } from 'entities/user/model/selectors/get-language/get-language';
import { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

export const Chart = memo(() => {
  const container = useRef<HTMLDivElement | null>(null);
  const currentLanguage = useSelector(getCurrentLanguage);

  useEffect(() => {
    if (!container.current) {
      return;
    }

    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js';
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = JSON.stringify({
      autosize: true,
      symbol: 'BINANCE:BTCUSDT',
      interval: '60',
      timezone: 'Etc/UTC',
      theme: 'light',
      style: '1',
      locale: currentLanguage || 'en',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      gridColor: 'rgba(255, 255, 255, 0.06)',
      withdateranges: true,
      hide_side_toolbar: false,
      allow_symbol_change: false,
      save_image: false,
      calendar: false,
      support_host: 'https://www.tradingview.com',
    });

    container.current.innerHTML = '';
    container.current.appendChild(script);
  }, [currentLanguage]);

  return (
    <Stack
      sx={{
        flex: 1,
        '&>div': {
          aspectRatio: {
            xs: '1/1 !important',
            sm: '16/9 !important',
          },
        },
      }}
    >
      <div
        className='tradingview-widget-container'
        ref={container}
        style={{
          height: 'auto',
          width: '100%', 
          aspectRatio: '16/9',
        }}
      >
        <div
          className='tradingview-widget-container__widget'
          style={{
            width: '100%', 
          }}
        />
      </div>
    </Stack>
  );
});

Chart.displayName = 'Chart';
