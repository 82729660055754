import { TLanguage, TTranslation } from 'shared/types';

export const formPayment = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      payment: {
        promo: {
          button: {
            cancel: 'Cancel',
            apply: 'Apply',
          },
          placeholder: 'Discount code',
        },
      },
    },
    ru: {
      payment: {
        promo: {
          button: {
            cancel: 'Отмена',
            apply: 'Применить',
          },
          placeholder: 'Промокод',
        },
      },
    },
  };

  return transaltion[type].payment;
};
