import { useTranslation } from 'react-i18next';
import { TooltipSingleSelect } from 'shared/ui';
import { ISoruceProps } from '../interfaces';

export const Source = (props: ISoruceProps) => {
  const {
    t, 
  } = useTranslation();

  const changeSourceHandler = (source: ISoruceProps['value']) => {
    props.onChange(source);
  };
  
  return (
    <TooltipSingleSelect
      tooltip={t('home.statistics.select.tooltips.0')}
      options={[{
        label: t('home.statistics.select.all'),
        value: 'all',
      }, {
        label: t('home.statistics.select.manual'),
        value: 'manual',
      }, {
        label: t('home.statistics.select.bots'),
        value: 'bots',
      }]}
      select={{
        value: props.value,
        placeholder: t('home.statistics.select.placeholder'),
        onChange: changeSourceHandler,
      }}
    />
  );
};
