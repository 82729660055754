import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const TEXT: CSSProperties = {
  fontSize: getFontSize(12, 16),
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#3A3A3C',
  textAlign: 'center',
  maxWidth: 545,
};
