import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { EnrichedTrade, TradeDetails } from 'entities/terminal/model/types/terminal-schema';
import { tradeGetAll, tradeGetAllRichData } from 'entities/trade/api';
import { useCallback, useEffect, useState } from 'react';
import { defaultDates } from 'shared/consts';
import { filterExchangesByType, getExchangeAccountsUuids } from 'shared/helpers';
import { useExcahnges, useSearch } from 'shared/hooks';
import { TDates, TSide, TType } from 'shared/types';
import { getCurrencies } from '../helpers/get-currencies';
import { getMarkets } from '../helpers/get-markets';

export const useTradingDiary = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [startDates, setStartDates] = useState<TDates>(defaultDates);
  const [finishDates, setFinishDates] = useState<TDates>([null, null]);
  const [trades, setTrades] = useState<EnrichedTrade[]>([]);

  const [selectedType, setSelectedType] = useState<TType>('spot');  
  const [selectedExchanges, setSelectedExchanges] = useState<string[]>([]);  
  const [selectedSide, setSelectedSide] = useState<TSide>('buy');
  const [selectedMarkets, setSelectedMarkets] = useState<string[]>([]);
  const [selectedBaseCurrencies, setSelectedBaseCurrencies] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);

  const {
    values: {
      exchanges,
    },
  } = useExcahnges();

  const {
    search: searchText,
    changeSearchValue,
    filterItemsBySearch,
  } = useSearch('');

  const changeType = (type: string) => {
    setSelectedType(type as TType);
    setSelectedSide(type === 'spot' ? 'buy' : 'long');

    if (type === 'futures') {
      setSelectedBaseCurrencies([]);
    }
  };

  const changeExchanges = (exchanges: string[]) => {
    setSelectedExchanges(exchanges);
  };

  const changeSide = (selected: string) => {
    setSelectedSide(selected as TSide);

    if (selectedType === 'spot') {
      if (!selectedMarkets.length) {
        const [market] = getMarkets(trades);
        setSelectedMarkets([(market?.value || 'USDT')]);
      }
  
      if (!selectedBaseCurrencies.length) {
        const [currency] = getCurrencies(trades);
        setSelectedBaseCurrencies([currency?.value || 'USDT']);
      }

      return;
    }

    setSelectedBaseCurrencies([]);
  };

  const changeStartDates = (date: TDates) => {
    setStartDates(date);
  };

  const changeMarkets = (selected: string | string[]) => {
    setSelectedMarkets(typeof selected === 'string' ? [selected] : selected);
  };

  const changeCurrencies = (selected: string | string[]) => {
    setSelectedBaseCurrencies(typeof selected === 'string' ? [selected] : selected);
  };

  const changeStatus = (selected: string[]) => {
    setSelectedStatuses(selected);
  };

  const changeFinishDates = (date: TDates) => {
    setFinishDates(date);
  };

  const exchangeOptions = useCallback(() => {
    if (!exchanges?.length) {
      return [];
    }
    
    const options = filterExchangesByType(exchanges)(selectedType).map((account) => {
      return {
        label: account.accountName,
        value: account.exchangeAccountUuid,
      };
    });

    return options;
  }, [exchanges, selectedType]);

  const marketOptions = useCallback(() => {
    if (!trades.length) {
      return [];
    }
    return getMarkets(trades);
  }, [trades]);

  const currencyOptions = useCallback(() => {
    return getCurrencies(trades.filter((trade) => {
      const side = (selectedSide === 'buy' || selectedSide === 'long') ? 'buy' : 'sell';
      return trade.sideFirstStep === side.toUpperCase();
    }));
  }, [trades, selectedSide]);

  const filterByExchange = (trade: EnrichedTrade) => {
    return !selectedExchanges.length || selectedExchanges.includes(trade.exchangeAccountUuid);
  };

  const filterBySide = (trade: EnrichedTrade) => {
    const side = (selectedSide === 'buy' || selectedSide === 'long') ? 'buy' : 'sell';
    return side.toUpperCase() === trade.sideFirstStep;
  };

  const filterByCurrency = (trade: EnrichedTrade) => {
    return !selectedBaseCurrencies.length || selectedBaseCurrencies.includes(trade.baseSymbol.toLowerCase());
  };

  const filterByMarkets = (trade: EnrichedTrade) => {
    return !selectedMarkets.length || selectedMarkets.includes(trade.quoteSymbol.toLowerCase());
  };
  
  const filterByStatus = (trade: EnrichedTrade) => {
    return !selectedStatuses.length || selectedStatuses.includes(trade.status.toLowerCase());
  };

  const getTrades = () => {
    return trades
      .filter(filterBySide)
      .filter(filterItemsBySearch)
      .filter(filterByExchange)
      .filter(filterByCurrency)
      .filter(filterByMarkets)
      .filter(filterByStatus);
  };

  const filterAccountsByType = () => {
    return exchanges?.filter((exchange) => {
      if (selectedType === 'spot') {
        return !exchange.exchangeCode.includes('futures');
      }

      return exchange.exchangeCode.includes('futures');
    });
  };

  const fetchDiary = async () => {
    try {
      setIsLoading(true);

      const exchangeAccountUuids = getExchangeAccountsUuids(filterAccountsByType() || []);
      if (!exchangeAccountUuids.length) {
        setTrades([]);
        return;
      }

      const allAccountsAllTradesData = await tradeGetAll({
        exchangeAccountUuids,
        startDates,
        finishDates,
      });

      const smartTradeUuids = allAccountsAllTradesData.map((trade: TradeDetails) => {
        return trade.smartTradeUuid;
      });

      const allAccountsAllTradesRichData = await tradeGetAllRichData(smartTradeUuids);
      const accountDetailsMap = exchanges?.reduce((map: Record<string, Record<'accountName' | 'exchangeCode', string>>, account: ExchangeAccount) => {
        map[account.exchangeAccountUuid] = {
          accountName: account.accountName,
          exchangeCode: account.exchangeCode,
        };
          
        return map;
      }, {});
        
      const enrichedTrades = allAccountsAllTradesRichData?.map((trade: TradeDetails) => {
        const accountDetails = accountDetailsMap![trade.exchangeAccountUuid];

        return {
          ...trade,
          accountName: accountDetails?.accountName,
          exchangeCode: accountDetails?.exchangeCode,
        };
      });
            
      const [market] = getMarkets(enrichedTrades);

      setTrades(enrichedTrades);
      setSelectedMarkets([market?.value || 'USDT']);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!exchanges) {
      setIsLoading(true);
      return;
    }

    if (!exchanges.length) {
      setSelectedBaseCurrencies([]);
      setSelectedExchanges([]);
      setSelectedMarkets([]);
      setSelectedStatuses([]);
      setTrades([]);
      setIsLoading(false);
      return;
    }

    fetchDiary();
  }, [exchanges, selectedType, startDates, finishDates]);

  return {
    values: {
      isLoading,
      trades,
      startDates,
      finishDates,
      exchangeOptions,
      selectedExchanges,
      selectedSide,
      marketOptions,
      selectedMarkets,
      currencyOptions,
      selectedBaseCurrencies,
      selectedStatuses,
      searchText,
      selectedType,
    },
    handlers: {
      setIsLoading,
      changeExchanges,
      changeSide,
      changeStartDates,
      changeMarkets,
      changeCurrencies,
      changeStatus,
      changeFinishDates,
      getTrades,
      changeSearchValue,
      changeType,
    },
  };
};
