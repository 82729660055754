import { SxProps, Theme } from '@mui/material';

export const HEADER: SxProps<Theme> = {
  flexDirection: 'row',
  display: {
    xs: 'none',
    lg: 'flex',
  },
  alignItems: 'center',
  gap: 1,
  justifyContent: 'space-between',
  padding: '0px 16px',
  '&>span': {
    display: 'block',
    width: '100%',
    fontSize: 12,
    '&[class*="pair"]': {
      maxWidth: 64,
      marginLeft: '28px',
    },
    '&[class*="account"], &[class*="bot"]': {
      maxWidth: 85,
    },
    '&[class*="start-date"]': {
      maxWidth: 75,
    },
    '&[class*="volume"]': {
      maxWidth: 120,
    },
    '&[class*="progress"]': {
      maxWidth: 346,
    },
    '&[class*="status"], &[class*="entries"]': {
      maxWidth: 70,
    },
    '&[class*="return"]': {
      maxWidth: 95,
    },
    '&[class*="actions"]': {
      maxWidth: 60,
    },
  },
};
