import { HomePage } from 'pages/home';
import { InviteFriends } from 'pages/invite-friends';
import NotFound from 'pages/not-found/ui/not-found';
import { SubscriptionPage } from 'pages/subscriptions/subscription';
import { authTemplates } from 'shared/consts';
import { accountsRoutes, botsRoutes, oathCreateRoutes, oathUpdateRoutes, terminalRoutes } from '../../consts';
import { IPage } from '../../interfaces';
import { appPath } from './app-path';

export const appRoutes = (path: string) => {
  const items: IPage[] = [{
    title: 'Home',
    path: appPath.home.path,
    element: (
      <HomePage />
    ),
  }, {
    title: 'Subscriptions',
    path: appPath.subscriptions.path,
    element: (
      <SubscriptionPage />
    ),
  }, {
    title: 'Invite friends',
    path: appPath.invite_friends.path,
    element: (
      <InviteFriends />
    ),
  }, {
    title: 'Not fount',
    path: authTemplates.includes(path) ? appPath.home.path : '*',
    element: (
      <NotFound />
    ),
  }];

  return items
    .concat(accountsRoutes())
    .concat(terminalRoutes())
    .concat(botsRoutes())
    .concat(oathCreateRoutes())
    .concat(oathUpdateRoutes());
};
