import { Stack } from '@mui/material';
import { Dropdown } from 'antd';
import { UserMenuDropdown } from 'widgets/header/components';
import {
  Account,
  ArrowDown,
} from 'shared/icons';
import { Icon } from 'shared/ui';
import { WRAPPER } from '../styles';

export const UserMenu = () => {
  const dropdownRender = () => {
    return (
      <UserMenuDropdown />
    );
  };

  return (
    <Dropdown
      trigger={['click']}
      dropdownRender={dropdownRender}
    >
      <Stack
        direction='row'
        gap='4px'
        alignItems='center'
      >
        <Stack
          sx={{
            display: 'block',
            width: 6,
            height: 6,
            backgroundColor: '#F32222',
            borderRadius: '50%',
          }}
        />
        
        <Stack sx={WRAPPER}>
          <Icon width={16}>
            {Account}
          </Icon>

          <Icon width={16}>
            {ArrowDown}
          </Icon>
        </Stack>
      </Stack>
    </Dropdown>
  );
};
