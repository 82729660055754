import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';

export const updateBotsAccounts = (params: Record<'accounts' | 'balances', ExchangeAccount[]>) => {
  const accounts = params.accounts.map((account: any) => {
    const existeedAccount = params.balances.find((accountBalance: any) => {
      return accountBalance?.exchangeAccountUuid === account.exchangeAccountUuid;
    });
    
    account.totalUsdt = existeedAccount?.totalUsdt;
    account.balances = existeedAccount?.balances;
    return account;
  });
  
  return accounts;
};
