import { CSSProperties } from 'react';
import { getResponsiveValue } from 'shared/helpers';

export const BUTTON = () => {
  const styles: CSSProperties = {
    padding: getResponsiveValue({
      min: 8,
      max: 14,
    }),
    height: 'auto',
    display: 'flex',
    placeItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.10)',
    color: '#fff',
    borderColor: 'transparent',
  };

  // if (active) {
  //   styles.backgroundColor = '#F6F6F6';
  //   styles.color = '#3A3A3C';
  // }

  return styles;
};
