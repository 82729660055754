import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const contentButtons: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  gap: getResponsiveValue({
    min: 8,
    max: 16,
  }),
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
};
