import { Stack } from '@mui/material';
import { Input, Slider as Origin } from 'shared/ui';

export const Slider = () => {
  return (
    <Stack
      direction='row'
      alignItems='center'
      gap='20px'
    >
      <Origin />

      <Input
        value=''
        onChange={() => {}}
        placeholder='20%'
        maxWidth={58}
        icon='%'
      />
    </Stack>
  );
};
