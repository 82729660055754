import { EnrichedBotSmartTrade } from './get-all-bots-trading-diary-data';

export const botsTradesGetFundsInTrades = (trades: EnrichedBotSmartTrade[]) => {
  const validStatuses = ['new', 'request_completed', 'requested_cancelled', 'active', 'in_progress_update', 'failed'];
  const relevantTrades = trades.filter(trade =>
    validStatuses.includes(trade.status),
  );

  let totalFundsInTrades = 0;

  relevantTrades.forEach(trade => {
    const currentExchangePrice = trade.currentExchangePrice;

    if (!currentExchangePrice) {
      return;
    }

    const executedBaseFirst = parseFloat(trade.executedBaseFirst);
    const executedBaseSecond = parseFloat(trade.executedBaseSecond);

    if (isNaN(executedBaseFirst) || isNaN(executedBaseSecond)) {
      return;
    }

    const funds = (executedBaseFirst - executedBaseSecond) * currentExchangePrice;
    totalFundsInTrades += funds;
  });

  return totalFundsInTrades;
};
