import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { Bot } from '../model/types';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const botGetEnrichedSource = async (bot: Bot): Promise<Bot> => {
  const statisticResponse = await axios.request({
    url: '/bot/calculateStatistic',
    data: {
      botUuid: bot.botUuid,
    },
  });

  const activeCopyBotsResponse = await axios.request({
    url: '/bot/calculateActiveCopyBotsLandingMarketplace',
    data: {
      sourceUuid: bot.sourceUuid,
    },
  });

  const statistics = statisticResponse.data.data;
  const activeCopyBots = activeCopyBotsResponse.data.data.activeCopyBots;

  return {
    ...bot,
    statistics,
    activeCopyBots,
  };
};
