import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface IEditBotParams {
  botUuid: string;
  name?: string;
  tradingAmount?: number;
}
export const editBotData = async (params: IEditBotParams) => {
  const response = await axios.request({
    url: 'bot/updateBot',
    data: params,
  });
  
  return response?.data;
};
