interface IGetResponsiveValue {
  min: number;
  max: number;
}

export const getResponsiveValue = (params: IGetResponsiveValue) => {
  const {
    min,
    max,
  } = params;

  const mid = max - min;
  return `min(calc(${min}px + (${mid + (mid * 0.7)}) * ((100vw - 390px) / 1280)), ${max}px)`;
};
