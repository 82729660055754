import { Box, Stack } from '@mui/material';
import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';
import { useTranslation } from 'react-i18next';
import { ExportGreen, PlusGreen } from 'shared/icons';
import { EmptySubscription, Icon, Loader, MainButton, PublicBotMarket, PublicBotParameters, RangePicker, SingleSelect, Table, Text, Title } from 'shared/ui';
import { Card as MarketplaceCard } from '../../marketplace/components';
import { Card, WithDraw } from '../components';
import { columns } from '../consts';
import { getSalesStatistics, getSubscriptionStatistic } from '../helpers';
import { useSales } from '../hooks';
import { BLOCK_WRAPPER, button, EMPTY_BOTS_TITLE, EMPTY_WRAPPER, inner, LIST, tableTitle, tableWrapper, title, titleWrapper, wrapper } from '../styles';

export const PublicBotSales = () => {
  const {
    t, 
  } = useTranslation();

  const {
    values,
    handlers,
  } = useSales();

  const getPublicBotsContent = () => {
    if (!values.bots.length && !values.isBotsLoading) {
      return (
        <Stack sx={EMPTY_WRAPPER}>
          <Text styles={EMPTY_BOTS_TITLE}>
            {t('bots.public.empty.description')}
          </Text>

          <img
            src='/images/empty-public-bots.png'
            alt='empty-public-bots'
          />
        </Stack>
      );
    }

    return (
      <Box sx={LIST(values.isBotsLoading)}>
        {values.isBotsLoading && (
          <Loader isContentOverflow={true} />
        )}

        {Object.entries<Bot[]>(values.combinedBots || []).map(([key, bots]) => (
          <MarketplaceCard
            key={key}
            bots={bots}
            subscription={values.subscription}
            button={{
              title: t('bots.public.card.button'),
              action: () => {
                handlers.changeSelectedCombineBots(bots);
              },
            }}
          />
        ))}
      </Box>
    );

  };

  return (
    <Box sx={wrapper}>
      <Stack
        direction='row'
        sx={titleWrapper}
      >
        <Title styles={title}>
          {t('bots.public.title')}
        </Title>

        <MainButton
          icon={(
            <Icon width={16}>
              {PlusGreen}
            </Icon>
          )}
          iconPosition='start'
          type='primary'
          ghost={true}
          onClick={handlers.chooseMarketModalHandler}
          styles={button}
          size='large'
        >
          {t('bots.public.button')}
        </MainButton>
      </Stack>

      {getPublicBotsContent()}

      <Stack sx={BLOCK_WRAPPER}>
        <RangePicker
          value={values.withDrawalDates!}
          handleSetNewDate={handlers.changeWithDrawDates}
          maxWidth={385}
        />

        <Box sx={inner}>
          <Box
            position='relative'
            display='flex'
          >
            {values.isSubscriptionLoading && (
              <Loader isContentOverflow={true} />
            )}

            <Card
              title={t('bots.public.subscriptions.title')}
              items={getSubscriptionStatistic({
                t,
                subscription: values.subscription,
              })}
              action={!!values.subscriptions?.length && (
                <SingleSelect
                  select={{
                    value: values.subscription?.bot,
                    placeholder: '',
                    onChange: handlers.onSelectChange,
                  }}
                  options={values.subscriptionOptions()}
                  maxWidth={130}
                />
              )}
            />
          </Box>

          <Stack position='relative'>
            {values.isSalesLoading && (
              <Loader isContentOverflow={true} />
            )}

            <Card
              title={t('bots.public.sales.title')}
              items={getSalesStatistics({
                t,
                sales: values.sales,
              })}
            />
          </Stack>

          <WithDraw
            available={values.sales.withDrawal || 0}
            fetchData={() => {
              handlers.fetchBots();
              handlers.getDrawalsHandler();
            }}
          />
        </Box> 
      </Stack>

      <Box sx={tableWrapper}>
        <Box sx={titleWrapper}>
          <Title styles={tableTitle}>
            {t('bots.public.table.title')}
          </Title>

          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
            gap='20px'
            flex={1}
            justifyContent='flex-end'
          >
            <RangePicker
              value={values.dates!}
              handleSetNewDate={handlers.changeDates}
            />
        
            <MainButton
              type='primary'
              ghost={true}
              icon={(
                <Icon width={16}>
                  {ExportGreen}
                </Icon>
              )}
              styles={button}
              onClick={handlers.exportHandler}
              size='large'
            >
              {t('bots.public.table.export')}
            </MainButton>
          </Box>
        </Box>

        <Box position='relative'>
          {values.isLoading && (
            <Loader isContentOverflow={true} />
          )}
          
          <Table
            columns={columns(t)}
            items={(values.providerPayouts || [])}
            itemsCount={10}
          />
        </Box>
      </Box>

      <PublicBotMarket
        isOpen={values.chooseMarketModal}
        closeHandler={handlers.chooseMarketModalHandler}
        bots={values.bots}
        market={values.market}
        setMarket={handlers.changeMarket}
      />

      <PublicBotParameters
        isOpen={!!values.market}
        closeHandler={() => {
          handlers.changeMarket('');
        }}
        refetchBots={handlers.fetchBots}
        setChooseMarketModal={handlers.setChooseMarketModal}
        bots={values.resultBots()}
        existsBots={values.selectedCombinedBots}
      />

      <EmptySubscription
        isOpen={values.isEmptyBotsModalOpened}
        modalTitle={t('error.bots.alert.title')}
        modalDescription={t('error.bots.alert.description')}
        handleClose={handlers.subscriptionModalHandler}
        button={{
          title: t('error.bots.alert.button'),
          action: handlers.subscriptionModalHandler,
        }}
        isError={true}
      />
    </Box>
  );
};
