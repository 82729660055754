import { removeAuthToken, removeSkyrexUuid, removeUserEmail } from 'shared/helpers/storage-helper';

export function handleUnauthorized() {
  removeUserEmail();
  removeSkyrexUuid();
  removeAuthToken();
  localStorage.clear();
  window.location.href = '/login';
}
  
