import { Badge } from 'antd';
import { IProps } from '../interfaces';
import { mainStyles } from '../styles';

export const Bagde = (props: IProps) => {
  const {
    title,
    styles,
  } = props;

  return (
    <Badge
      count={title}
      style={{
        ...mainStyles,
        ...styles,
      }}
    />
  );
};
