import { TLanguage, TTranslation } from 'shared/types';

export const errorWrong = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      wrong: {
        title: 'Something went wrong',
        message: 'We will fix it soon, try again later or contact support',
        button: 'Ok',
        recover: {
          message: 'Ahh, the service is temporarily unavailable. The fix team is on the way! Take a deep breath and try to reload the page in few minutes',
        },
        account: {
          message: 'We will fix it soon, try again later or contact support',
        },
      },
    },
    ru: {
      wrong: {
        title: 'Что-то пошло не так',
        message: 'Мы скоро всё исправим. Попробуйте позже или пишите в поддержку',
        button: 'Ок',
        recover: {
          message: 'Сервис временно недоступен. Попробуйте обновить страницу через пару минут',
        },
        account: {
          message: 'Мы скоро всё исправим. Повторите позже или свяжитесь с поддержкой',
        },
      },
    },
  };

  return transaltion[type].wrong;
};
