import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'shared/hooks';
import { LogOut } from 'shared/icons';
import { Icon } from 'shared/ui';
import { ListItem } from '../../list-item';
import { ChangePassword, TwoFactor } from '../components';

export const Options = () => {
  const {
    t, 
  } = useTranslation();

  const {
    handlers,
  } = useAuth();
  
  return (
    <Stack
      gap='16px'
      padding='0px 20px'
    >
      <TwoFactor />

      <ChangePassword />

      <ListItem
        title={t('header.popup.logOut')}
        icon={(
          <Icon width={16}>
            {LogOut}
          </Icon>
        )}
        handler={handlers.logOutHandler}
      />
    </Stack>
  );
};
