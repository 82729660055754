import { Stack } from '@mui/material';
import { Skeleton } from 'antd';
import { useState } from 'react';
import { IPlayerProps } from '../interfaces';
import { WRAPPER } from '../styles';

export const Player = (props: IPlayerProps) => {
  const {
    url,
  } = props;

  const [isLoading, setIsLoading] = useState(true);

  return (
    <Stack sx={WRAPPER}>
      {isLoading && (
        <Skeleton.Node
          active={true}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      )}
      
      <video
        src={url}
        controls={true}
        autoPlay={true}
        muted={true}
        loop={true}
        preload='auto'
        onCanPlayThrough={() => {
          setIsLoading(false);
        }}
        onError={() => {
          setIsLoading(true);
        }}
      />
    </Stack>
  );
};
