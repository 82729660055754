import { Stack } from '@mui/material';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Language } from 'widgets';
import { userMenuLinks } from 'widgets/header/consts';
import { IUserMenuLink } from 'widgets/header/interfaces';
import { Link, Text } from 'shared/ui';
import { Options, Profile, Socials } from '../components';
import { privacyPolicy, terms } from '../consts';
import { BACKGROUND } from '../styles';

export const UserMenuDropdown = () => {
  const {
    t, 
  } = useTranslation();

  return (
    <Stack sx={BACKGROUND}>
      <Profile />

      <Stack alignItems='center'>
        <Text
          type='danger'
          styles={{
            textAlign: 'center',
            maxWidth: 180,
          }}
        >
          {t('header.popup.message')}
        </Text>
      </Stack>


      <Options />

      <Socials />

      <Space
        wrap
        size={16}
        style={{
          maxWidth: 235,
          justifyContent: 'center',
          alignSelf: 'center',
        }}
      >
        {userMenuLinks.map((userMenuLink: IUserMenuLink, index: number) => (
          <Link
            key={userMenuLink.label}
            href={userMenuLink.link}
            type='success'
          >
            {t(`header.popup.links.${index}`)}
          </Link>
        ))}
      </Space>

      <Space
        size={3}
        style={{
          justifyContent: 'center',
        }}
      >
        <Link
          href={terms}
          type='success'
        >
          {t('header.popup.terms')}
        </Link>

        <Text type='secondary'>
          {t('header.popup.and')}
        </Text>

        <Link
          href={privacyPolicy}
          type='success'
        >
          {t('header.popup.policy')}
        </Link>
      </Space>

      <Stack alignItems='center'>
        <Language />
      </Stack>
    </Stack>
  );
};
