import { Stack } from '@mui/material';
import { TradingViewLink } from 'pages/trading-bots/configurator-v2/components';
import { useTranslation } from 'react-i18next';
import { Copy, Label } from 'widgets';
import { generateIndicatorAlertMessage } from 'shared/helpers';
import { Text } from 'shared/ui';
import { WRAPPER } from '../styles';

export const Indicator = () => {
  const {
    t, 
  } = useTranslation();

  return (
    <Stack sx={WRAPPER}>
      <Copy
        label={(
          <Label
            title={t('configurator.source.indicator.url.title')}
            desciption={(
              <Text>
                {t('configurator.source.indicator.url.description')} {' '}
                
                <TradingViewLink />
              </Text>
            )}
          />
        )}
        value='https://panel.skyrexio.com/api/v1/deal/alert'
        display='row'
      />
      
      <Copy
        label={(
          <Label
            title={t('configurator.source.indicator.message.title')}
            desciption={(
              <Text>
                {t('configurator.source.indicator.message.description')} {' '}

                <TradingViewLink />
              </Text>
            )}
          />
        )}
        value={generateIndicatorAlertMessage('props.sourceUuid', 'props.secretToken', 'props.position')}
        display='row'
      />
    </Stack>
  );
};
