import { TLanguage, TTranslation } from 'shared/types';

export const modalCopyBot = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      copyBot: {
        exchange: {
          title: 'Choose your account',
          placeholder: 'Choose from connected accounts',
        },
        name: {
          title: 'Name your bot',
          placeholder: 'My perfect bot',
        },
        amount: {
          title: 'Trading amount (available: {{balance}} {{quote}})',
          placeholder: 'Set amount (minimum: {{minInvestment}} {{quote}})',
          tooltip: 'This amount will be managed by bot. Make sure you have that amount on selected exchange account',
        },
        button: 'Copy Bot',
        statistic: {
          title: 'Statistic',
          items: {
            roi: {
              title: 'ROI',
              tooltip: 'Profit taken from closed deals (resets at midnight UTC+0 each day). Please note: this statistic is affected by any filters you have active',
            },
            drawdown: {
              title: 'Max drawdown',
              tooltip: 'Currently open deals. Please note: this statistic is affected by any filters you have active',
            },
            trades: {
              title: 'Total trades',
              tooltip: 'Total amount of funds locked in active deals or on limit orders in the exchange orderbooks for active deals. Please note: this statistic is affected by any filters you have active',
            },
            profit: {
              title: 'Profit sharing fee',
              tooltip: 'Total lifetime profit generated from all closed deals. Please note: this statistic is affected by any filters you have active',
            },
            amount: {
              title: 'Min. trading amount',
              tooltip: 'Current profit or loss total for any active deals your have open. Please note, this statistic is affected by any filters you have active',
            },
          },
          table: {
            title: 'Trading history',
            search: {
              placeholder: 'Search by pair',
            },
            columns: {
              pair: 'Pair',
              date: {
                create: 'Create date',
                close: 'Close date',
              },
              status: 'Status',
              price: {
                entry: 'Entry price',
                close: 'Close price',
              },
              return: 'Return %',
            },
          },
        },
      },
    },
    ru: {
      copyBot: {
        exchange: {
          title: 'Выберите аккаунт',
          placeholder: 'Из подключённых аккаунтов',
        },
        name: {
          title: 'Назовите бота',
          placeholder: 'Мой идеальный бот',
        },
        amount: {
          title: 'Торговая сумма (доступно: {{balance}} {{quote}})',
          placeholder: 'Укажите сумму (мин: {{minInvestment}} {{quote}})',
          tooltip:
            'Сумма, которой будет управлять бот. Убедитесь, что на аккаунте есть эти средства',
        },
        button: 'Копировать',
        statistic: {
          title: 'Статистика',
          items: {
            roi: {
              title: 'ROI',
              tooltip: 'Прибыль по закрытым сделкам (обновляется в полночь UTC+0). Статистика учитывает фильтры',
            },
            drawdown: {
              title: 'Максимальная просадка',
              tooltip: 'Текущие открытые сделки. Обратите внимание: эта статистика зависит от активных фильтров',
            },
            trades: {
              title: 'Всего сделок',
              tooltip: 'Общая сумма средств, заблокированных в активных сделках или лимитных ордерах на биржевых стаканах для активных сделок. Обратите внимание: эта статистика зависит от активных фильтров',
            },
            profit: {
              title: 'Комиссия',
              tooltip: 'Общая прибыль за все закрытые сделки за весь период. Обратите внимание: эта статистика зависит от активных фильтров',
            },
            amount: {
              title: 'Мин. баланс',
              tooltip: 'Текущая общая прибыль или убыток для всех открытых сделок. Обратите внимание: эта статистика зависит от активных фильтров',
            },
          },
          table: {
            title: 'История торговли',
            search: {
              placeholder: 'Поиск по паре',
            },
            columns: {
              pair: 'Пара',
              date: {
                create: 'Дата создания',
                close: 'Дата закрытия',
              },
              status: 'Статус',
              price: {
                entry: 'Цена входа',
                close: 'Цена закрытия',
              },
              return: 'Доход %',
            },
          },
        },
      },
    },
  };

  return transaltion[type].copyBot;
};
