import { Layout as StyledLayout } from 'antd';
import { ReactNode } from 'react';
import { layout, layoutContent } from '../styles';

export const Layout = (props: Record<('header' | 'children' | 'footer'), ReactNode>) => {
  const {
    header,
    children,
    footer,
  } = props;

  return (
    <StyledLayout style={layout}>
      <div className='container'>
        {header}

        <StyledLayout.Content style={layoutContent}>
          {children}
        </StyledLayout.Content>
      </div>

      {footer}
    </StyledLayout>
  );
};
