import { TLanguage, TTranslation } from 'shared/types';

export const emptyChartData = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      emptyChart: {
        button: {
          connect: 'Connect exchange',
          switch: 'Switch to demo',
        },
      },
    },
    ru: {
      emptyChart: {
        button: {
          connect: 'Подключить аккаунт',
          switch: 'Включить демо',
        },
      },
    },
  };

  return transaltion[type].emptyChart;
};
