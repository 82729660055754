import { Stack } from '@mui/material';
import { IChooseSource } from 'entities/choose-source/interfaces';
import { IStepProps } from 'pages/trading-bots/configurator-v2/interfaces';
import { memo, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'widgets';
import { TabIcon } from 'shared/icons';
import { Icon, MainButton } from 'shared/ui';
import { Actions, OrderType, Player } from '../../../components';
import { BUTTON, INNER, TAB_LABEL, WRAPPER } from '../styles';
import { Custom, Indicator, Strategy } from '../tabs';
import { BasicInfo } from '../tabs/custom/components';

const defaultValue = {
  indicator: 0,
  strategy: 1,
  custom: 2,
};

export const ChooseSource = memo((props: IStepProps) => {
  const {
    t, 
  } = useTranslation();

  const {
    watch,
    setValue,
    getValues,
  } = useFormContext();

  const bot = watch('bot');
  const alertSource = watch('alertSource');
  const orderType = watch('orderType');

  const selectAlertSourceHandler = useCallback((tab: number) => {
    setValue('alertSource', Object.keys(defaultValue)[tab]);
  }, [setValue]);

  const selectOrderTypeHandler = useCallback((value: string) => {
    setValue('orderType', value);
  }, [setValue]);

  const changeCurrentStep = () => {
    setValue('currentStep', getValues('currentStep') + 1);
  };

  const navigateBuilder = () => {
    window.open('https://www.tradingview.com/script/RzGuxful-Multi-indicator-Signal-Builder-Skyrexio/', '_blank');
  };

  const getContent = useCallback(() => {
    if (alertSource === 'custom') {
      return (
        <BasicInfo />
      );
    }

    return (
      <Stack
        gap='16px'
        alignItems='center'
      >
        <Player url={`/videos/${alertSource}.mp4`} />

        {(alertSource === 'indicator') && (
          <MainButton
            type='primary'
            ghost={true}
            onClick={navigateBuilder}
            styles={BUTTON({
              maxWidth: 200,
            })}
          >
            {t('configurator.button.builder')}
          </MainButton>
        )}
      </Stack>
    );
  }, [alertSource, t]);

  const getTableLabel = (label: string) => {
    return (
      <Stack sx={TAB_LABEL}>
        <Icon width={16}>
          {TabIcon}
        </Icon>

        {label}
      </Stack>
    );
  };

  useEffect(() => {
    if (!bot?.botUuid) {
      return;
    }

    setValue('alertSource', bot.signalSource || 'indicator');
    setValue('orderType', bot.baseOrderType.toLowerCase());
  }, [bot, setValue]);

  return (
    <Stack sx={WRAPPER}>
      <Stack sx={INNER}>
        <Tabs
          defaultValue={defaultValue[alertSource as IChooseSource['alertSource']]}
          tabs={[{
            label: getTableLabel(t('configurator.source.tabs.indicator')),
            value: 0,
          }, {
            label: getTableLabel(t('configurator.source.tabs.strategy')),
            value: 1,
          }, {
            label: t('configurator.source.tabs.custom'),
            value: 2,
          }]}
          getTab={selectAlertSourceHandler}
          label={{
            value: t('configurator.source.tabs.label.title'),
            tooltiop: t('configurator.source.tabs.label.tooltip'),
          }}
          content={[(
            <Indicator
              secretToken={props.secretToken!}
              sourceUuid={props.sourceUuid!}
              position={1}
            />
          ), (
            <Strategy
              secretToken={props.secretToken!}
              sourceUuid={props.sourceUuid!}
              position={1}
            />
          ), (
            <Custom
              secretToken={props.secretToken!}
              sourceUuid={props.sourceUuid!}
              position={1}
            />
          )]}
          styles={{
            gap: '28px',
          }}
        />

        <OrderType
          value={orderType}
          onChange={selectOrderTypeHandler}
        />
          
        <Actions
          handleBack={props.handleBack}
          button={{
            title: t('configurator.button.next'),
            action: changeCurrentStep,
          }}
        />
      </Stack>

      {getContent()}
    </Stack>
  );
});

ChooseSource.displayName = 'ChooseSource';
