import { TLanguage, TTranslation } from 'shared/types';

export const exchange = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      exchange: {
        list: {
          title: 'Select exchange',
          description: 'Connect your exchange account securely with fast OAuth or API keys',
          parnter: 'Skyrexio is an official exchanges partner with no withdrawal or transfer access',
          exchange: {
            title: 'Don\'t have an exchange?',
            create: 'Create {{account}} account',
            switch: 'Switch to demo account',
          },
          offer: {
            link: 'Sign up',
            text: 'at Gate.io with Skyrexio to get 15 USDT bonus and 500 USDT rebate',
          },
          review: {
            prefix: 'Don’t see your exchange?',
            link: 'Suggest us to add',
          },
        },
        connected: {
          title: 'My exchange accounts',
          button: 'Connect new exchange',
          total: 'Total',
          change: '24h changes',
          actions: {
            trade: 'Trade',
            view: 'View',
            reset: 'Reset',
            update: 'Update API Key',
            delete: 'Delete',
          },
          lessBalance: 'Balance is less than $10. Deposit funds to start trading',
          futures: 'Unified exchange has account-level margin system, changing will affect existing bots and active positions, enabling switch will set Cross mode, disabling will set Isolated type',
        },
        connect: {
          platformText: 'Up to $4,000 Prize!',
          title: {
            connect: 'Connect',
            exchange: 'exchange',
          },
          tabs: {
            fast: 'Fast OAuth',
            api: 'API keys',
          },
          text: 'Connect your exchange account securely with {{type}}',
          button: 'Connect',
          account: {
            prefix: 'Don’t have an account?',
            text: 'Sign up at {{name}} with Skyrexio and get bonuses',
          },
          back: 'Back to exchanges',
          steps: {
            title: {
              prefix: 'Connect keys securely',
              link: 'Full guide',
            },
            1: '1. Log in to your exchange account and go to',
            2: {
              ip: '2. Turn on IP whitelisting and copy/paste these IP addresses:',
              thirdParty: '2. Select third-party apps and choose Skyrexio:',
            },
            3: '3. Paste generated data in inputs on right',
          },
        },
        update: {
          title: {
            update: 'Update',
            exchange: 'exchange',
          },
          tabs: {
            fast: 'Fast OAuth',
            api: 'API keys',
          },
          button: 'Update',
        },
      },
    },
    ru: {
      exchange: {
        list: {
          title: 'Выберите биржу',
          description:
            'Подключите биржевой аккаунт безопасно через OAuth или API ключи',
          parnter:
            'Skyrexio — официальный партнёр бирж без доступа к выводу или переводам',
          exchange: {
            title: 'Нет аккаунта?',
            create: 'Создать {{account}} аккаунт',
            switch: 'Переключиться на демо-аккаунт',
          },
          offer: {
            link: 'Зарегистрируйтесь',
            text: 'на Gate.io через Skyrexio и получите 15 USDT на баланс и 500 USDT скидку на комиссию',
          },
          review: {
            prefix: 'Нет нужной биржи?',
            link: 'Предложите добавить',
          },
        },
        connected: {
          title: 'Мои биржевые аккаунты',
          button: 'Подключить новую биржу',
          total: 'Всего',
          change: 'Изм 24ч',
          actions: {
            trade: 'Торговать',
            view: 'Просмотр',
            reset: 'Сброс',
            update: 'Обновить API',
            delete: 'Удалить',
          },
          lessBalance: 'Баланс меньше $10. Пополните счёт, чтобы начать торговлю',
          futures: 'Унифицированная биржа имеет систему маржи на уровне аккаунта, изменение повлияет на существующих ботов и активные позиции, включение переключателя установит кросс-режим, отключение изолированный тип',
        },
        connect: {
          platformText: 'Приз до $4,000!',
          title: {
            connect: 'Подключить',
            exchange: 'биржу',
          },
          tabs: {
            fast: 'Быстрый OAuth',
            api: 'API ключи',
          },
          text: 'Подключите биржу безопасно с помощью {{type}}',
          button: 'Подключить',
          account: {
            prefix: 'Нет аккаунта?',
            text: 'Зарегистрируйтесь на {{name}} через Skyrexio и получите бонусы',
          },
          back: 'Назад к списку бирж',
          steps: {
            title: {
              prefix: 'Безопасное подключение ключей',
              link: 'Полное руководство',
            },
            1: '1. Войдите на биржу и откройте',
            2: {
              ip: '2. Включите белый список IP и вставьте эти адреса:',
              thirdParty: '2. Выберите сторонние приложения и отметьте Skyrexio:',
            },
            3: '3. Вставьте сгенерированные данные в поля справа',
          },
        },
        update: {
          title: {
            update: 'Обновить',
            exchange: 'биржу',
          },
          tabs: {
            fast: 'Быстрый OAuth',
            api: 'API ключи',
          },
          button: 'Обновить',
        },
      },
    },
  };

  return transaltion[type].exchange;
};
