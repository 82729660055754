import { IRoute } from '../../interfaces';
import { TAuthPages } from '../../types';

export const authPath: Record<TAuthPages, IRoute<TAuthPages>> = {
  login: {
    path: '/login',
    navigate: () => '/login',
  },
  register: {
    path: '/register',
    navigate: () => '/register',
  },
  'password-reset': {
    path: '/password-reset',
    navigate: () => '/password-reset',
  },
  'recover-password': {
    path: '/recover-password',
    navigate: () => '/recover-password',
  },
  'user-activate': {
    path: '/user-activate',
    navigate: () => '/user-activate',
  },
  'two-factor': {
    path: '/two-factor',
    navigate: () => '/two-factor',
  },
};
