import { TLanguage, TTranslation } from 'shared/types';

export const errorSubscription = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      subscription: {
        downgrade: {
          title: 'Can’t downgrade',
          message: 'Subscription can’t be downgraded please choose higher plan to upgrade',
          button: 'Ok',
        },
      },
    },
    ru: {
      subscription: {
        downgrade: {
          title: 'Нельзя понизить',
          message: 'Подписку нельзя понизить. Выберите более высокий план',
          button: 'Ок',
        },
      },
    },
  };

  return transaltion[type].subscription;
};
