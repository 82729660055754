import dayjs from 'dayjs';
import { mapBalancesToPieData } from 'pages/home/helpers/map-balances-to-pie-data';
import { mapSnapshotsToAreaData } from 'pages/home/helpers/map-snapshots-to-area-data';
import { Fragment } from 'react/jsx-runtime';
import { AggregateBalance } from 'widgets';
import { useAggregate } from '../hooks';
import { IAggregateProps } from '../interfaces';

export const Aggregate = (props: IAggregateProps) => {
  const {
    values: {
      selectedCurrency,
      dates,
      modifiedSnapshots,
      calculatedPairs,
      dailyChange,
      balances,
      isLoading,
    },
    handlers: {
      setDates,
      setSelectedCurrency,
      updateAggregate,
    },
  } = useAggregate(props.exchanges);

  const handleSetCurrency = (value: string) => {
    setSelectedCurrency(value);
  };

  const handleSetNewDate = (date: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null) => {
    setDates(date);
  };

  const isBtc = selectedCurrency === 'BTC';

  const pieDataHelperProps = {
    overallBalances: balances, 
    isBtc, 
    currentTotalBtc: calculatedPairs?.btc || 0, 
    currentTotalUsdt: calculatedPairs?.usdt || 0,
  };

  return (
    <Fragment>
      <AggregateBalance
        setCurrency={handleSetCurrency}
        selectedCurrency={selectedCurrency}
        totalBtc={pieDataHelperProps.currentTotalBtc}
        totalUsdt={pieDataHelperProps.currentTotalUsdt}
        usdtDailyChangePercent={dailyChange?.usdtDailyChange?.percent || ''}
        btcDailyChangePercent={dailyChange?.btcDailyChange?.percent || ''}
        isAreaChartLoading={isLoading}
        dates={dates}
        handleSetNewDate={handleSetNewDate} 
        pieData={mapBalancesToPieData(pieDataHelperProps)}
        areaData={mapSnapshotsToAreaData(modifiedSnapshots, isBtc)}
        handleUpdateFinancialData={updateAggregate}
        exchanges={props.exchanges}
      />

      {props.children({
        balances: pieDataHelperProps.overallBalances,
        total:{ 
          usdt: pieDataHelperProps.currentTotalUsdt,
          btc: pieDataHelperProps.currentTotalBtc,
        },
        isBtc,
        isLoading,
      })}
    </Fragment>
  );
};
