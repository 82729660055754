import { TLanguage, TTranslation } from 'shared/types';

export const trades = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      trades: {
        header: {
          pair: 'Pair',
          account: 'Account',
          bot: 'Bot',
          date: 'Start date',
          volume: 'Volume',
          entries: 'Entries',
          progress: 'Progress',
          status: 'Status',
          return: 'Return',
          actions: 'Actions',
        },
        columns: {
          price: {
            origin: 'Price',
            conditional: 'Cond.price',
          },
          units: 'Units',
          total: 'Total',
          type: 'Type',
          status: {
            title: 'Status',
            items: {
              active: 'active',
              completed: 'completed',
              new: 'pending',
              await_send: 'pending',
              request_active: 'pending',
              cancel_after_active: 'pending',
              request_cancel: 'pending',
              cancelled: 'cancelled',
              create_failed: 'failed',
              cancel_failed: 'failed',
              requested_cancelled: 'pending',
              failed: 'failed',
              request_completed: 'Pending',
            },
          },
        },
        actions: {
          funds: {
            add: 'Add funds',
            reduce: 'Reduce funds',
          },
          market: {
            close: 'Close by Market',
            open: 'Open by Market',
          },
          limit: {
            close: 'Close by Limit',
            open: 'Open by Limit',
          },
          edit: 'Edit',
          share: {
            origin: 'Share setup',
            result: 'Share result',
          },
          cancel: 'Cancel',
        },
        progress: {
          marks: {
            entry: 'Entry',
            ae: 'AE',
            sl: 'SL',
            tp: 'TP',
          },
        },
      },
    },
    ru: {
      trades: {
        header: {
          pair: 'Пара',
          account: 'Аккаунт',
          bot: 'Бот',
          date: 'Открытие',
          volume: 'Объём',
          entries: 'Входы',
          progress: 'Прогресс',
          status: 'Статус',
          return: 'Доход',
          actions: 'Действия',
        },
        columns: {
          price: {
            origin: 'Цена',
            conditional: 'Усл. цена',
          },
          units: 'Единицы',
          total: 'Итого',
          type: 'Тип',
          status: {
            title: 'Статус',
            items: {
              active: 'активен',
              completed: 'завершен',
              new: 'обработка',
              await_send: 'обработка',
              request_active: 'обработка',
              cancel_after_active: 'обработка',
              request_cancel: 'обработка',
              cancelled: 'отменен',
              create_failed: 'ошибка',
              cancel_failed: 'ошибка',
              requested_cancelled: 'обработка',
              failed: 'ошибка',
              request_completed: 'обработка',
            },
          },
        },
        actions: {
          funds: {
            add: 'Добавить средства',
            reduce: 'Убавить средства',
          },
          market: {
            close: 'Закрыть по Маркету',
            open: 'Открыть по Маркету',
          },
          limit: {
            close: 'Закрыть по Лимиту',
            open: 'Открыть по Лимиту',
          },
          edit: 'Редактировать',
          share: {
            origin: 'Поделиться сделкой',
            result: 'Поделиться результатом',
          },
          cancel: 'Отменить',
        },
        progress: {
          marks: {
            entry: 'Вход',
            ae: 'ДВ',
            sl: 'СЛ',
            tp: 'ТП',
          },
        },
      },
    },
  };

  return transaltion[type].trades;
};
