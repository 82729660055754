import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MainButton, Text, Title } from 'shared/ui';
import { IWelcome } from '../interfaces';
import {
  bottomWrapper,
  button,
  description,
  image,
  inner,
  skipButton,
  title,
  topWrapper,
  wrapper,
} from '../styles';

export const Welcome = (props: IWelcome) => {
  const {
    changeStep,
    skipHandler,
  } = props;

  const {
    t, 
  } = useTranslation();

  return (
    <Box
      display='flex'
      justifyContent='center'
    >
      <Box sx={wrapper}>
        <Box sx={inner}>
          <Box sx={topWrapper}>
            <Title styles={title}>
              {t('modal.onboarding.welcome.title')}
            </Title>

            <Text styles={description}>
              {t('modal.onboarding.welcome.description')}
            </Text>
          </Box>

          <Box sx={bottomWrapper}>
            <MainButton
              type='primary'
              styles={button}
              onClick={changeStep}
            >
              {t('modal.onboarding.welcome.button.start')}
            </MainButton>

            {skipHandler && (
              <Text
                type='success'
                styles={skipButton}
                onClick={skipHandler}
              >
                {t('modal.onboarding.welcome.button.skip')}
              </Text>
            )}
          </Box>
        </Box>

        <Box sx={image}>
          <img src='/images/subscriptions/w-bg.jpg' alt='bg' loading='lazy' />
        </Box>
      </Box>
    </Box>
  );
};
