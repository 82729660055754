import { Box } from '@mui/material';
import { Select } from 'antd';
import { ArrowDown, SecondaryInfo } from 'shared/icons';
import { Tooltip } from 'shared/ui';
import { ITooltipSingleSelect } from '../interfaces';
import { selectWrapper, tooltipWrapper, wrapper } from '../styles';

export const TooltipSingleSelect = (props: ITooltipSingleSelect) => {
  const {
    tooltip,
    select,
    options,
    dropdown,
    disabled,
  } = props;

  return (
    <Box sx={wrapper}>
      <Tooltip
        title={tooltip}
        styles={tooltipWrapper}
      >
        {SecondaryInfo}
      </Tooltip>

      <Select
        value={select.value}
        placeholder={select.placeholder}
        options={options}
        onChange={select.onChange}
        variant='borderless'
        style={selectWrapper}
        suffixIcon={ArrowDown}
        dropdownRender={dropdown}
        size='middle'
        disabled={disabled}
        dropdownStyle={{
          minWidth: 'max-content',
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </Box>
  );
};
