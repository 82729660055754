import { Bot } from 'entities/bot/model/types';
import { IChooseAccount } from 'entities/choose-account/interfaces';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { TSide } from 'shared/types';

interface IDefaultValues {
  exchange: {
    value: IChooseAccount['exchange'];
    items: ExchangeAccount[];
  };
  bot?: Bot;
  market: {
    value: IChooseAccount['market'];
    minNotional: number;
    items: any[];
  };
  futures: {
    strategy: TSide;
    leverage: number;
    marginMode: 'CROSS' | 'ISOLATED';
  };
  tradingAmount: number;
  activeTradingPairs: number;
}
export const defaultFirstStepValues = () => {
  const defaultValues: IDefaultValues = {
    exchange: {
      value: {
        code: '',
        id: '',
        name: '',
        totalUsdt: 0,
        type: '',
      },
      items: [],
    },
    market: {
      value: {
        name: '',
        code: '',
        value: '',
        presition: 0,
        minNotional: 0,
      },
      minNotional: 0,
      items: [],
    },
    futures: {
      strategy: 'short',
      leverage: 5,
      marginMode: 'CROSS',
    },
    tradingAmount: 0,
    activeTradingPairs: 1,
  };

  return defaultValues;
};
