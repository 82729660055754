import { UpdateOauthBinance, UpdateOauthBybit, UpdateOauthCryptoCom, UpdateOauthHtx, UpdateOauthKucoin, UpdateOauthOkx } from 'features/oauth/ui/update-oauth';
import { appPath } from '../app-routes/consts';
import { IPage } from '../interfaces';

export const oathUpdateRoutes = () => {
  const routes: IPage[] = [{
    title: 'Crypto-com',
    path: appPath.crypto_com_update_oauth.path,
    element: (
      <UpdateOauthCryptoCom />
    ),
  }, {
    title: 'ByBit',
    path: appPath.bybit_update_oauth.path,
    element: (
      <UpdateOauthBybit />
    ),
  }, {
    title: 'Binance',
    path: appPath.binance_update_oauth.path,
    element: (
      <UpdateOauthBinance />
    ),
  }, {
    title: 'Okx',
    path: appPath.okx_update_oauth.path,
    element: (
      <UpdateOauthOkx />
    ),
  }, {
    title: 'Htx',
    path: appPath.htx_update_oauth.path,
    element: (
      <UpdateOauthHtx />
    ),
  }, {
    title: 'Kucoin',
    path: appPath.kucoin_update_oauth.path,
    element: (
      <UpdateOauthKucoin />
    ),
  }];

  return routes;
};
