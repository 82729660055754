import { Box, Stack } from '@mui/material';
import { getWinRatePercent } from 'pages/manual-trading/trading-history/helpers/get-winrate-percent';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, WinRate } from 'widgets';
import { exportToCsv, formatter } from 'shared/helpers';
import { ExportGreen } from 'shared/icons';
import {
  Loader,
  MainButton,
  MultipleSelect,
  RangePicker,
  Search,
  ShareTrade,
  SingleSelect,
  Table,
  Title,
} from 'shared/ui';
import { columns } from '../consts';
import { botsTradesGetFundsInTrades } from '../helpers/bots-trades-get-funds-in-trades';
import { botsTradesGetTotalPnl } from '../helpers/bots-trades-get-total-pnl';
import { botsTradesGetUnrealizedPnl } from '../helpers/bots-trades-get-upl';
import { useTradingDiary } from '../hooks';
import {
  exportButton,
  filters,
  filtersWrapper,
  title,
  titleWrapper,
  wrapper,
} from '../styles';

const BotsTradingHistory = () => {
  const [selectedTrade, setSelectedTrade] = useState<any>(null);

  const {
    t, 
  } = useTranslation();

  const {
    values,
    handlers,
  } = useTradingDiary();

  const openShareTradeModal = (tradeUuid: string) => {
    return () => {
      const trade = values.trades.find((trade) => {
        return trade.smartTradeUuid === tradeUuid;
      });
      if (!trade) {
        return;
      }
      
      trade.currentExchangePrice = +formatter(+trade.executedQuoteSecond / +trade.executedBaseSecond);
      
      setSelectedTrade(trade);
    };
  };

  const closeShareTradeModal = () => {
    setSelectedTrade(null);
  };
  
  const handleExportToCsv = () => {
    const transformedTrades = values.trades.map((trade) => {
      const {
        orders,
        ...rest 
      } = trade;
      return rest;
    });
    
    exportToCsv(transformedTrades, 'BotTrades');
  };
  
  const winRatePercent = getWinRatePercent(values.trades);
  const trades = handlers.getTrades();
  
  return (
    <Box sx={wrapper}>
      {values?.isLoading && (
        <Loader isContentOverflow={true} />
      )}

      <Box sx={titleWrapper}>
        <Title styles={title}>
          {t('bots.diary.title')}
        </Title>

        <MainButton
          type='primary'
          ghost={true}
          icon={ExportGreen}
          iconPosition='start'
          styles={exportButton}
          onClick={handleExportToCsv}
          size='large'
        >
          {t('bots.diary.button')}
        </MainButton>
      </Box>

      <Stack gap='40px'>
        <Box sx={filtersWrapper}>
          <Box sx={filters}>
            <Box gridArea='exchange'>
              <MultipleSelect
                label={(
                  <Label title={t('bots.diary.select.exchange.title')} />
                )}
                options={values.exchangeOptions()}
                select={{
                  value: values.exchange,
                  placeholder: t('bots.diary.select.exchange.palceholder'),
                  onChange: handlers.changeExchange,
                }}
              />
            </Box>

            <Box gridArea='type'>
              <SingleSelect
                label={(
                  <Label title='Strategy' />
                )}
                options={values.typeOptions()}
                select={{
                  value: values.type || undefined,
                  placeholder: '',
                  onChange: handlers.changeType,
                }}
                size='large'
              />
            </Box>

            <Box gridArea='market'>
              <SingleSelect
                label={(
                  <Label title={t('bots.diary.select.market.title')} />
                )}
                options={values.marketOptions()}
                select={{
                  value: values.market || undefined,
                  placeholder: t('bots.diary.select.market.placeholder'),
                  onChange: handlers.changeMarket,
                }}
                size='large'
              />
            </Box>

            <Box gridArea='create_date'>
              <RangePicker
                label={(
                  <Label title={t('bots.diary.select.date.create')} />
                )}
                value={values.createDates!}
                handleSetNewDate={handlers.changeCreateDate}
              />
            </Box>

            <Box gridArea='bot'>
              <MultipleSelect
                label={(
                  <Label title={t('bots.diary.select.bot.title')} />
                )}
                options={values.botNamesOptions(values.market)}
                select={{
                  value: values.bot,
                  placeholder: t('bots.diary.select.bot.placeholder'),
                  onChange: handlers.changeBots,
                }}
              />
            </Box>

            <Box gridArea='base_currency'>
              <MultipleSelect
                label={(
                  <Label title={t('bots.diary.select.currency.title')} />
                )}
                options={values.currencyOptions(values.market)}
                select={{
                  value: values.currency,
                  placeholder: t('bots.diary.select.currency.placeholder'),
                  onChange: handlers.changeCurrency,
                }}
              />
            </Box>

            <Box gridArea='status'>
              <MultipleSelect
                label={(
                  <Label title={t('bots.diary.select.status.title')} />
                )}
                options={values.statuseOptions()}
                select={{
                  value: values.status,
                  placeholder: t('bots.diary.select.status.placeholder'),
                  onChange: handlers.changeStatus,
                }}
              />
            </Box>

            <Box gridArea='close_date'>
              <RangePicker
                label={(
                  <Label title={t('bots.diary.select.date.close')} />
                )}
                value={values.closeDates!}
                handleSetNewDate={handlers.changeCloseDate}
              />
            </Box>
          </Box>

          <WinRate
            percent={+winRatePercent.toFixed(2)}
            items={[{
              title: t('bots.diary.winRate.items.total'),
              value: +formatter(botsTradesGetTotalPnl(trades)),
              currency: values.market,
              type: 'total',
            }, {
              title: t('bots.diary.winRate.items.upl'),
              value: +botsTradesGetUnrealizedPnl(trades),
              currency: values.market,
              type: 'unrealized',
            }, {
              title: t('bots.diary.winRate.items.trades'),
              value: trades.length,
            }, {
              title: t('bots.diary.winRate.items.inTrades'),
              value: botsTradesGetFundsInTrades(trades),
              currency: values.market,
              type: 'funds',
            }]}
          />
        </Box>
        
        <Table
          columns={columns(t, openShareTradeModal)}
          items={trades?.map((trade) => ({
            smartTradeUuid: trade.smartTradeUuid,
            pair: {
              firstPair: trade.base,
              secondPair: trade.quote,
            },
            name: trade.name,
            createDate: trade.createdDate,
            closeDate: trade.finishedDate,
            status: trade.status,
            total:  +formatter(trade.executedQuoteFirst),
            entryPrice: +formatter(+trade.executedQuoteFirst / +trade.executedBaseFirst),
            closePrice: +formatter(+trade.executedQuoteSecond / +trade.executedBaseSecond),
            return: +formatter(trade.returnQuote || 0),
            return_pr: formatter(trade.returnPercent || 0),
            entries: `${trade.position}/${(trade.maxSafetyOrders || 0) + 1}`,
          }))}
          action={(
            <Search
              value={values.searchText}
              placeholder={t('bots.diary.table.search.placeholder')}
              onChange={handlers.changeSearchValue}
              maxWidth={480}
            />
          )}
        />

        <ShareTrade
          isOpen={!!selectedTrade}
          trade={selectedTrade}
          closeHandler={closeShareTradeModal}
        />  
      </Stack>
    </Box>
  );
};

export default BotsTradingHistory;
