import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const getExchange = async (params: Record<'skyrexUserUuid' | 'exchangeAccountUuid', string[]>) => {
  const {
    skyrexUserUuid,
    exchangeAccountUuid, 
  } = params;
    
  const response = await axios.request({
    url: 'exchangeAccount/getByFilter',
    data: {
      table: 'exchange_accounts',
      statuses: ['active'],
      skyrexUserUuids: skyrexUserUuid,
      exchangeAccountUuids: exchangeAccountUuid,
    },
  });
  
  return response.data.data.accounts[0]?.accountName;
};
