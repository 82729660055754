import { NavLink } from 'react-router-dom';
import { IMenu } from 'widgets/header/components/menu/interfaces';
import { TAppPages } from 'shared/config/route-config/types/app-pages';
import { TextButton } from 'shared/ui';
import { TEXT } from '../styles';

export const ExpandMenuItem = (props: Record<'item', NonNullable<IMenu<TAppPages>['children']>[0]>) => {
  const {
    item,
  } = props;

  return (
    <NavLink to={item.path}>
      <TextButton styles={TEXT}>
        {item.info.label}
      </TextButton>
    </NavLink>
  );
};
