import { TLanguage, TTranslation } from 'shared/types';

export const modalTrial = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      trial: {
        title: ' Activate 7-day trial',
        description: 'Thank you for choosing Skyrexio! During trial, you’ll enjoy:',
        items: {
          accounts: {
            title: 'All in one dashboard',
            description: 'Connect and manage exchange accounts in one window ',
          },
          bots: {
            title: 'Powerful bots',
            description: 'Copy bots and custom Alert bots for TradingView',
          },
          terminal: {
            title: 'Smart terminal',
            description: 'Create and manage multi order positions in few clicks',
          },
        },
        button: 'Activate trial',
      },
    },
    ru: {
      trial: {
        title: '7-дневный пробный период',
        description: 'Спасибо за выбор Skyrexio! На время триала вы получите:',
        items: {
          accounts: {
            title: 'Все на одной странице',
            description: 'Подключайте и управляйте своими биржами в одном окне',
          },
          bots: {
            title: 'Мощные боты',
            description: 'Копи боты и настраиваемые алерт боты для TradingView',
          },
          terminal: {
            title: 'Умный терминал',
            description: 'Создавайте мульти-ордерные позиции и управляйте ими',
          },
        },
        button: 'Активировать триал',
      },
    },
  };

  return transaltion[type].trial;
};
