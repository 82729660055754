import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const WRAPPER: SxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'center',
  gap: getResponsiveValue({
    min: 14,
    max: 24,
  }),
  padding: '8px 0',
};
