import { Box, Stack } from '@mui/material';
import { Divider } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { CustomSlider, OrderType } from '../../../../../components';
import { INNER, WRAPPER } from '../styles';

export const Preset = () => {
  const {
    watch,
    setValue,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();
  
  const additional = watch('additional');

  const orders = additional.segment.preset.orders;
  const ordersSizeScale = additional.segment.preset.ordersSizeScale;
  const priceChange = additional.segment.preset.priceChange;
  const ordersPriceChangeScale = additional.segment.preset.ordersPriceChangeScale;
  const orderType = additional.segment.preset.orderType;

  const changePresetOrdersHandler = (value: number) => {
    if (value === 1) {
      changePresetOrdersPriceChangeScaleHandler(1);
    }

    additional.segment.preset.orders = value;
    setValue('additional', additional);
  };

  const changePresetOrdersSizeScaleHandler = (value: number) => {
    additional.segment.preset.ordersSizeScale = value;
    setValue('additional', additional);
  };

  const changePresetPriceChangeHandler = (value: number) => {
    additional.segment.preset.priceChange = value;
    setValue('additional', additional);
  };

  const changePresetOrdersPriceChangeScaleHandler = (value: number) => {
    additional.segment.preset.ordersPriceChangeScale = value;
    setValue('additional', additional);
  };

  const changePresetOrderTypeHandler = (value: string) => {
    additional.segment.preset.orderType = value;
    setValue('additional', additional);
  };

  const isOneOrder = orders === 1;

  return (
    <Stack sx={INNER}>
      <Box sx={WRAPPER}>
        <CustomSlider
          title={(
            <Label
              title={t('form.configurator.additional.orders')}
            />
          )}
          slider={{
            value: orders,
            max: 10,
            marks: {
              1: 1,
              4: 4,
              10: 10,
            },
            onChange: changePresetOrdersHandler,
          }}
          input={{
            value: orders,
            placeholder: '100%',
            onChange: changePresetOrdersHandler,
          }}
        />

        <CustomSlider
          title={(
            <Label
              title={t('form.configurator.additional.ordersSize')}
            />
          )}
          slider={{
            value: ordersSizeScale,
            min: 0.1,
            max: 2.5,
            marks: {
              0.1: 0.1,
              1: 1,
              2.5: 2.5,
            },
            onChange: changePresetOrdersSizeScaleHandler,
          }}
          input={{
            value: ordersSizeScale,
            placeholder: '100%',
            onChange: changePresetOrdersSizeScaleHandler,
          }}
          step={0.1}
        />
      </Box>

      <Divider
        style={{
          margin: 0, 
        }}
      />

      <Box sx={WRAPPER}>
        <CustomSlider
          title={(
            <Label
              title={t('form.configurator.additional.priceChange')}
            />
          )}
          slider={{
            value: priceChange,
            max: 10,
            marks: {
              1: -1,
              3: -3,
              10: -10,
            },
            reversed: true,
            onChange: changePresetPriceChangeHandler,
          }}
          input={{
            value: priceChange,
            placeholder: '100%',
            onChange: changePresetPriceChangeHandler,
          }}
          step={0.1}
        />

        <CustomSlider
          title={(
            <Label
              title={t('form.configurator.additional.priceChangeScale')}
            />
          )}
          slider={{
            value: ordersPriceChangeScale,
            min: 0.1,
            max: 2.5,
            marks: {
              0.1: 0.1,
              1: 1,
              2.5: 2.5,
            },
            onChange: changePresetOrdersPriceChangeScaleHandler,
          }}
          input={{
            value: ordersPriceChangeScale,
            placeholder: '100%',
            onChange: changePresetOrdersPriceChangeScaleHandler,
          }}
          disabled={isOneOrder}
          step={0.1}
        />
      </Box>

      <Stack
        sx={{
          maxWidth: {
            xs: '100%',
            md: 425,
          },
        }}
      >
        <OrderType
          value={orderType}
          onChange={changePresetOrderTypeHandler}
        />
      </Stack>
    </Stack>
  );
};
