import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { ReactNode } from 'react';
import { TAppPages } from 'shared/config/route-config/types/app-pages';
import { IMenu } from '../../../interfaces';
import { ExpandMenuItem } from '../components';

interface IGetMenuItemsParams {
  menuItems: NonNullable<IMenu<TAppPages>['children']>;
  button?: ReactNode;
}
export const getMenuItems = (params: IGetMenuItemsParams) => {
  const {
    menuItems,
    button,
  } = params;

  const items = menuItems.map((item): ItemType => {
    return {
      key: item.key,
      label: (
        <ExpandMenuItem item={item} />
      ),
    };
  });

  if (!button) {
    return items;
  }

  return items.concat({
    key: 'add-bot',
    label: button,
  });
};
