import { createSelector } from '@reduxjs/toolkit';
import { getDemoMode } from 'shared/helpers/storage-helper';
import { UserSchema } from '../../types/user-schema';
import { getUser } from '../get-user/get-user';

export const getIsDemoMode = createSelector(
  getUser,
  (user: UserSchema) => {
    return user.isDemoMode || (getDemoMode() === 'demo');
  },
);
