import { EnrichedTrade } from 'entities/terminal/model/types/terminal-schema';

export const getCurrencies = (trades: EnrichedTrade[]) => {
  const uniqueBaseSymbols = Array.from(new Set(trades.map((trade) => {
    return trade.baseSymbol.toLowerCase();
  })));

  const options = uniqueBaseSymbols.map((symbol) => ({
    label: symbol.toUpperCase(),
    value: symbol,
  }));

  return options;
};
