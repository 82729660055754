import { Stack } from '@mui/material';
import { Divider } from 'antd';
import { getTakeProfitPriceChange, getTakeProfitValume } from 'pages/trading-bots/configurator-v2/helpers';
import { getAdditionalEntries } from 'pages/trading-bots/configurator-v2/helpers/get-additional-entries';
import { Block } from 'pages/trading-bots/configurator-v2/views/configure-risk-managment/components';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { calibrateEntryOrdersParameters } from 'shared/helpers/calibrateAutoParams';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { ICalibrate, IPanelProps } from '../interfaces';

const divider = (
  <Divider
    style={{
      margin: 0,
    }}
  />
);

export const Panel = (props: IPanelProps) => {
  const {
    preset,
    tradingAmount,
    activeTradingPairs,
  } = props;

  const {
    t, 
  } = useTranslation();

  const {
    result,
  } = useMemo((): ICalibrate => {
    if (!preset) {
      return {
        result: null,
        item: null,
      };
    }

    const settings = preset.settings.values;
  
    const {
      result,
      bestMatchingParameters,
    } = calibrateEntryOrdersParameters({
      tradingAmount: +tradingAmount,
      activeTradingPairs: activeTradingPairs,
  
      minNotional: 5,
  
      overlap: settings.overlap,
      initialAdditionalOrderSizeScale: settings.initialAdditionalOrderSizeScale,
      initialAdditionalOrderPriceChange: settings.initialAdditionalOrderPriceChange,
      initialAdditionalOrderPriceChangeScale: settings.initialAdditionalOrderPriceChangeScale,
      initialMaxAdditionalOrders: settings.initialMaxAdditionalOrders,
      stopLossPercent: settings.stopLossPercent,
        
      maxIterations: 1000,
      percentRange: 20,
      iterationStep: 0.005,
      overlapDifferenceTolerance: 1,
      alpha: 0.001,
      globalSearchSamples: 50,
    });

    return {
      result: result || bestMatchingParameters,
      item: preset,
    };
  }, [
    preset,
    tradingAmount,
    activeTradingPairs,
  ]);

  const items = useMemo(() => {
    if (!result) {
      return [];
    }
  
    const entries = getAdditionalEntries({
      isOpened: true,
      segment: 'auto',
      orders: result.calibratedMaxAdditionalOrders,
      priceChange: result.calibratedAdditionalOrderPriceChange,
      ordersSizeScale: result.calibratedAdditionalOrderSizeScale,
      ordersPriceChangeScale: result.calibratedAdditionalOrderPriceChangeScale,
      tradingAmount: +tradingAmount,
      activeTradingPairs: activeTradingPairs,
    });
  
    const formatedItems = entries.map((item) => {
      return [{
        value: item.position,
      }, {
        value: `${currencyFormatter(item.orderSize, 2)} USDT`,
      }, {
        value: `${item.priceChange}%`,
      }];
    });
  
    return formatedItems;
  }, [
    result,
    tradingAmount,
    activeTradingPairs,
  ]);

  const {
    items: takeProfitItems,
  } = useMemo(() => {
    if (!preset) {
      return {
        items: [],
      };
    }
    const arraySettings = {
      length: 1, 
    };
      
    const result = Array.from(arraySettings).map((_, index) => {
      const position = ++index;
  
      const priceChange = getTakeProfitPriceChange({
        orders: 1,
        priceChange: preset.settings?.values?.takeProfitPercent || 0,
        ordersPriceChangeScale: 1,
        position,
      });

      const volume = getTakeProfitValume({
        orders: 1,
        ordersSizeScale: 1,
        position,
      });
  
      return {
        position,
        priceChange,
        volume: (!isFinite(volume) || isNaN(volume)) ? 0 : volume,
      };
    });
  
    const formatedItems = result.map((item) => {
      return [{
        value: item.position,
      }, {
        value: `${item.volume.toFixed(2)}%`,
      }, {
        value:  `${item.priceChange.toFixed(2)}%`,
      }];
    });
  
    return {
      items: formatedItems,
    };
  }, [preset]);

  return (
    <Stack gap='48px'>
      <Block
        title={t('configurator.managment.panel.common.entry.title')}
        layout='row'
        content={[{
          title: t('configurator.managment.panel.common.entry.item.title'),
          value: `${currencyFormatter((result?.baseOrderSize || 0), 2)} USDT`,
        }]}
      />


      {!!items.length && (
        <Fragment>
          {divider}

          <Block
            title={t('configurator.managment.panel.common.additional.title')}
            layout='table'
            content={{
              headers: [{
                label: '№',
                key: 'order',
              }, {
                label: t('configurator.managment.panel.common.additional.headers.order'),
                key: 'order-size',
              }, {
                label: t('configurator.managment.panel.common.additional.headers.price'),
                key: 'price-change',
              }],
              items: (items ?? []) as any[],
            }}
          />
        </Fragment>
      )}

      {divider}

      <Block
        layout='row'
        content={[{
          title: t('configurator.managment.panel.common.full'),
          value: result?.fullPositionSize ? `${result?.fullPositionSize} USDT` : '',
        }]}
      />

      <Block
        title={t('configurator.managment.panel.common.close.title')}
        layout='table'
        content={{
          headers: [{
            label: '№',
            key: 'order',
          }, {
            label: t('configurator.managment.panel.close.table.headers.volume'),
            key: 'volume',
          }, {
            label: t('configurator.managment.panel.close.table.headers.price'),
            key: 'price-change',
          }],
          prefixTitle: t('configurator.managment.panel.close.table.title'),
          items: (takeProfitItems ?? []) as any[],
        }}
      />

      <Block
        layout='row'
        content={[{
          title: t('configurator.managment.panel.close.stop'),
          value: result?.stopLossPercent ? `${-Math.abs(result?.stopLossPercent)}%` : '',
        }]}
      />
    </Stack>
  );
};
