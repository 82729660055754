import { SxProps, Theme } from '@mui/material';

export const img: SxProps<Theme> = {
  width: '100%',
  // height: '100%',
  maxWidth: 345,
  alignSelf: 'center',
  display: 'block',
  '&>img': {
    width: '100%',
    height: '100%',
    aspectRatio: '4/3',
    objectFit: 'contain',
    objectPosition: 'center',
  },
};
