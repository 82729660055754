import { Box, Stack } from '@mui/material';
import { Dropdown } from 'antd';
import { More, Update } from 'shared/icons';
import { actionsWrapper } from '../styles';
import { TradeActionsProps } from '../types/active-trade.types';

export const TradeActions = ({
  menuItems, onUpdate, 
}: TradeActionsProps) => (
  <Box sx={actionsWrapper}>
    <Dropdown
      menu={{
        items: menuItems.map(item => ({
          key: item.key,
          label: item.label,
          icon: (
            <Stack
              sx={{
                placeItems: 'center',
                flex: 1,
                maxWidth: 16,
                '&>svg': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              {item.icon}
            </Stack>
          ),
          onClick: item.onClick,
        })),
      }}
    >
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
        }}
      >
        {More}
      </div>
    </Dropdown>

    {onUpdate && (
      <div
        onClick={onUpdate}
        style={{
          cursor: 'pointer',
          display: 'flex',
        }}
      >
        {Update}
      </div>
    )}
  </Box>
);
