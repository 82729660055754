import { TLanguage, TTranslation } from 'shared/types';
import { diary } from './diary';
import { introduction } from './introduction';
import { botsPublic } from './public';
import { statistic } from './statistic';

export const bots = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      bots: {
        title: 'My bots',
        button: 'Add new bot',
        table: {
          title: 'Bots',
          search: {
            placeholder: 'Search by bot',
          },
          columns: {
            name: 'Name',
            exchange: 'Exchange',
            market: 'Market',
            type: {
              title: 'Type',
              alert: 'Alert',
              copy: 'Copy',
            },
            balance: 'Balance',
            trades: 'Trades',
            inTrades: 'In trades',
            upl: {
              origin: 'UP&L',
              month: '30d P&L',
            },
            actions: {
              title: 'Actions',
              items: {
                close: 'Close trades by Market',
                cancel: 'Cancel trades',
                edit: 'Edit',
                delete: 'Delete',
              },
            },
          },
        },
        statistic: statistic('en'),
        diary: diary('en'),
        public: botsPublic('en'),
        introduction: introduction('en'),
      },
    },
    ru: {
      bots: {
        title: 'Мои боты',
        button: 'Добавить бота',
        table: {
          title: 'Боты',
          search: {
            placeholder: 'Поиск по боту',
          },
          columns: {
            name: 'Название',
            exchange: 'Биржа',
            market: 'Рынок',
            type: {
              title: 'Тип',
              alert: 'Алерт бот',
              copy: 'Копи бот',
            },
            balance: 'Баланс',
            trades: 'Сделки',
            inTrades: 'В сделках',
            upl: {
              origin: 'UP&L',
              month: '30д P&L',
            },
            actions: {
              title: 'Действия',
              items: {
                close: 'Закрыть сделки по Маркету',
                cancel: 'Отменить сделки',
                edit: 'Редактировать',
                delete: 'Удалить',
              },
            },
          },
        },
        statistic: statistic('ru'),
        diary: diary('ru'),
        public: botsPublic('ru'),
        introduction: introduction('ru'),
      },
    },
  };

  return transaltion[type].bots;
};
