import { TLanguage, TTranslation } from 'shared/types';

export const formTwoFactor = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      twoFactor: {
        title: '2FA confirmation',
        otp: {
          label: 'Enter OTP from the app',
          placeholder: '',
        },
        button: 'Submit',
        link: 'Reset 2FA',
      },
    },
    ru: {
      twoFactor: {
        title: '2FA подтверждение',
        otp: {
          label: 'Введите OTP из приложения',
          placeholder: '',
        },
        button: 'Отправить',
        link: 'Сбросить 2FA',
      },
    },
  };

  return transaltion[type].twoFactor;
};
