import { Box, capitalize, Stack } from '@mui/material';
import { Dropdown, Input } from 'antd';
import { ExchangeCode } from 'entities/exchange/model/types/exchange-account';
import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LineChart } from 'widgets';
import { appPath } from 'shared/config/route-config/app-routes/consts';
import { formatter } from 'shared/helpers';
import { CheckIcon, CloseIcon, Delete, EditIcon, More, SecondaryEdit, SecondaryInfo, Update } from 'shared/icons';
import { Icon, MainButton, Switch, Text, Title, Tooltip } from 'shared/ui';
import { updateExchangeTitle } from '../../exchanges/api/update-exchange-title';
import { Error, Loading } from '../components';
import { item } from '../components/more-button-content/styles';
import { formatChangeText, getTextType } from '../helpers/platform-card-helpers';
import { IPlatformCardProps } from '../interfaces';
import {
  action,
  background,
  changesRight,
  changes as changesStyles,
  description as descriptionStyles,
  info,
  more as moreStyles,
  title as titleStyles,
  total as totalStyles,
} from '../styles';

const getExchangeName: Record<ExchangeCode, string> = {
  binance: 'Binance',
  bybit: 'Bybit',
  okx: 'OKX',
  gateio: 'Gate.io',
  'crypto-com': 'Crypto.com',
  demo: 'Demo',
  bitmart: 'BitMart',
  htx: 'HTX',
  kucoin: 'Kucoin',
};

export const PlatformCard = (props: IPlatformCardProps) => {
  const {
    title,
    exchangeCode,
    total,
    dailyChange,
    error,
    exchangeAccountUuid,
    balances,
    onDelete,
    onUpdateApiKeys,
    isDemoAccount,
    marginMode,
    changeMarginMode,
    handleUpdateExchange,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [updatedTitle, setUpdatedTitle] = useState<string>('');

  const sortedBalances = useMemo(() => balances.slice().sort((a, b) => {
    const totalA = parseFloat(a.usdt.total);
    const totalB = parseFloat(b.usdt.total);
    return totalB - totalA;
  }), [balances]);

  const navigate = useNavigate();
  const {
    t, 
  } = useTranslation();

  const showEmptyExchangeCard = balances?.length === 0;

  const tradeHandler = () => {
    navigate(appPath.manual_trading_trading_terminal.navigate());
  };

  const viewHandler = () => {
    navigate(appPath.my_account.navigate(exchangeAccountUuid));
  };


  const getDescription = () => {
    const resultMarginMode = marginMode ? ` - ${capitalize(marginMode.toLowerCase())} Margin` : '';
    if (exchangeCode === 'crypto-com') {
      const [name, type] = exchangeCode.replace(/-(.*?)-/, ' $1 ').split(' ');
      return `${getExchangeName[name as keyof typeof getExchangeName]} ${capitalize(type || '') || 'Spot'} ${resultMarginMode}`;
    }
  
    const [name, type] = exchangeCode.split('-');
    return `${getExchangeName[name as keyof typeof getExchangeName]} ${capitalize(type || '') || 'Spot'} ${resultMarginMode}`;
  };

  const getChangesContent = () => {
    const usdtValue = dailyChange.usdt.value;
    const btcValue = dailyChange.btc.value;
    
    const left = (
      <Text
        type={getTextType(+usdtValue)}
        styles={changesRight}
      >
        {formatChangeText(formatter(usdtValue || 0), +(dailyChange.usdt.percent || 0))}
      </Text>
    );

    const right = (
      <Text
        type={getTextType(+btcValue)}
        styles={changesRight}
      >
        {formatChangeText(formatter(btcValue || 0), +(dailyChange.btc.percent || 0))}
      </Text>
    );

    return (
      <Text
        type='secondary'
        styles={changesRight}
      >
        {left} / {right}
      </Text>
    );
  };

  const getContent = () => {
    if (showEmptyExchangeCard) {
      return (
        <Error bybit={exchangeCode === 'bybit'} />
      );
    }

    return (
      <Box
        display='flex'
        flexDirection='column'
        gap={2}
      >
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Text
            type='secondary'
            styles={totalStyles}
          >
            {t('exchange.connected.total')}:
          </Text>

          <Text
            type='secondary'
            styles={totalStyles}
          >
            <strong>${formatter(+total.usd)}</strong> / {formatter(+total.btc)} BTC
          </Text>
        </Box>

        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          gap={1}
        >
          <Text
            type='secondary'
            styles={changesStyles}
          >
            {t('exchange.connected.change')}:
          </Text>

          {getChangesContent()}
        </Box>
      </Box>
    );
  };

  const onTitleEdit = () => {
    setUpdatedTitle(title);
    setIsEdit(true);
  };

  const onTitleEditCancel = () => {
    setIsEdit(false);
    setUpdatedTitle(title);
  };

  const onTitleSave = async () => {
    if (updatedTitle === title) {
      return onTitleEditCancel();
    }

    if (updatedTitle.replace(/ /g, '') === '') {
      return onTitleEditCancel();
    }

    setIsLoading(true);

    try {
      const responseForUpdatedExchange = await updateExchangeTitle(exchangeAccountUuid, updatedTitle);
      if (responseForUpdatedExchange.success) {
        await handleUpdateExchange();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    setIsEdit(false);
  };

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUpdatedTitle(e.target.value);
  };

  const renderTitle = useMemo(() => {
    if (isEdit) {
      return (
        <Box
          display='flex'
          alignItems='center'
          gap={0.5}
        >
          <Input
            placeholder=''
            value={updatedTitle}
            onChange={onTitleChange}
            autoFocus={true}
            size='middle'
            style={{
              width: '100%',
              flex: 1,
              maxWidth: '160px',
              padding: '3px 0',
            }}
          />

          <Icon
            width={16}
            action={onTitleEditCancel}
          >
            {CloseIcon}
          </Icon>

          <Icon
            width={16}
            action={onTitleSave}
          >
            {CheckIcon}
          </Icon>
        </Box>
      );
    }

    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Box
          display='flex'
          alignItems='center'
          gap={1}
        >
          <Box maxWidth={180}>
            <Title
              level={5}
              styles={titleStyles}
              overflow={{
                tooltip: title,
              }}
            >
              {title}
            </Title>
          </Box>

          <Icon
            width={16}
            action={onTitleEdit}
          >
            {EditIcon}
          </Icon>
        </Box>

        <Stack
          direction='row'
          gap='8px'
        >
          {(exchangeCode.includes('futures') && marginMode !== null) && (
            <Switch
              checkedChildren='Cross'
              unCheckedChildren='Cross'
              value={marginMode === 'CROSS'}
              onChange={changeMarginMode}
              prefixText={(
                <Tooltip title={t('exchange.connected.futures')}>
                  {SecondaryInfo}
                </Tooltip>
              )}
            />
          )}

          <Icon
            width={16}
            action={handleUpdateExchange}
          >
            {Update}
          </Icon>
        </Stack>
      </Box>
    );
  }, [title, isEdit, updatedTitle]);

  const chartRender = useMemo(() => (
    <LineChart items={sortedBalances} />
  ), [sortedBalances]);

  const getAction = () => {
    if (!isDemoAccount) {
      return (
        <Dropdown
          menu={{
            items: [{
              label: (
                <div style={item}>
                  {SecondaryEdit}

                  <Title level={5}>
                    {t('exchange.connected.actions.update')}
                  </Title>
                </div>
              ),
              key: 'update',
            }, {
              label: (
                <div style={item}>
                  {Delete}

                  <Title level={5}>
                    {t('exchange.connected.actions.delete')}
                  </Title>
                </div>
              ),
              key: 'delete',
            }],
            onClick: (event) => {
              if (event.key === 'update') {
                onUpdateApiKeys();
              }

              if (event.key === 'delete') {
                onDelete(exchangeAccountUuid);
              }
            },
          }}
          trigger={['click']}
        >
          <a onClick={(e) => e.preventDefault()}>
            <MainButton styles={moreStyles}>
              {More}
            </MainButton>
          </a>
        </Dropdown>
      );
    }

    return (
      <MainButton
        styles={action}
        onClick={() => onDelete(exchangeAccountUuid)}
        size='middle'
      >
        {t('exchange.connected.actions.reset')}
      </MainButton>
    );
  };

  const render = () => {
    if (isLoading) {
      return (
        <Loading />
      );
    }

    return (
      <>
        <Box
          display='flex'
          flexDirection='column'
          gap={3}
        >
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            flex={1}
          >
            <Box
              display='flex'
              flexDirection='column'
              gap={1}
              flex={1}
            >
              {renderTitle}

              <Text
                type='secondary'
                styles={descriptionStyles}
              >
                {getDescription()}
              </Text>
            </Box>
          </Box>

          {!showEmptyExchangeCard && (
            <Box
              display='flex'
              flexDirection='column'
              gap='8px'
            >
              {chartRender}

              <Box
                display='flex'
                alignItems='center'
                gap={1}
              >
                {+total.usd < 10 ? (
                  <>
                    {SecondaryInfo}

                    <Text
                      type='secondary'
                      styles={info}
                    >
                      {t('exchange.connected.lessBalance')}
                    </Text>
                  </>
                ) : (
                  <Box
                    sx={{
                      visibility: 'hidden',
                    }}
                  >
                    {SecondaryInfo}

                    <Text
                      type='secondary'
                      styles={info}
                    >
                      {''}
                    </Text>
                  </Box>
                )}
              </Box>

              <Box
                display='flex'
                alignItems='center'
                gap={1}
              />
            </Box>
          )}
        </Box>

        {getContent()}

        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box
            display='flex'
            alignItems='center'
            gap={1}
          >
            {!error && (
              <MainButton
                styles={action}
                onClick={tradeHandler}
                size='middle'
              >
                {t('exchange.connected.actions.trade')}
              </MainButton>
            )}

            <MainButton
              styles={action}
              onClick={viewHandler}
              size='middle'
            >
              {t('exchange.connected.actions.view')}
            </MainButton>
          </Box>

          {getAction()}
        </Box>
      </>
    );
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      sx={background}
      gap={4}
      height={306}
    >
      {render()}
    </Box>
  );
};
