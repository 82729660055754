import { Box, Stack } from '@mui/material';
import { BaseType } from 'antd/es/typography/Base';
import { useTranslation } from 'react-i18next';
import { AreaChart, BarChart, ChartWrapper } from 'widgets';
import { SecondaryInfo, Update } from 'shared/icons';
import { Loader, Text, Title, Tooltip } from 'shared/ui';
import { ChartAction } from '../components';
import { ITradingStatistic } from '../interfaces';
import {
  item,
  itemInner,
  left,
  leftInner,
  result,
  text,
  titleWrapper,
  tooltip,
  wrapper,
} from '../styles';

export const TradingStatistic = (props: ITradingStatistic) => {
  const {
    loading,
    listItems,
    chartData,
    chartAction,
    handleUpdateStatistic,
    selectedSegment,
    setSelectedSegment,
    disableTitle,
    selectedQuote,
    isCopyBot,
    dates,
    customTitle,
    exchanges,
  } = props;

  const {
    t, 
  } = useTranslation();
  
  const getChart = (selectedSegment: string) => {
    if (selectedSegment === 'summary') {
      return (
        <AreaChart
          data={chartData}
          dates={dates}
          loading={loading}
          quoteAsset={selectedQuote}
          isCopyBot={isCopyBot}
        />
      );
    }

    return (
      <BarChart
        data={chartData}
        quoteAsset={selectedQuote}
        isCopyBot={isCopyBot}
      />
    );
  };

  const renderListItem = (listItem: ITradingStatistic['listItems'][0]) => {
    let type: BaseType = 'secondary';
    const symbol = listItem.currency;
    
    let value = `${listItem.value} ${symbol}`;

    const isFundsValue = listItem.type === 'funds';
    const isPercentageValue = listItem.type === 'roi' || listItem.type === 'max-drawdown';

    if (isPercentageValue) {
      value = `${listItem.value}%`;
    }

    if (+listItem.value > 0) {
      type = 'success';
      value = `+${value}`;
    }

    if (+listItem.value < 0) {
      type = 'danger';
    }

    if (!listItem.type) {
      value = `${listItem.value}`;
      type = 'secondary';
    }
    
    if (isFundsValue) {
      type = 'secondary';
      value = `${listItem.value} ${symbol}`;
    }

    return (
      <Box
        key={listItem.title}
        sx={item}
      >
        <Box sx={itemInner}>
          <Tooltip
            title={listItem.tooltip}
            styles={tooltip}
          >
            {SecondaryInfo}
          </Tooltip>

          <Text
            styles={text}
            type='secondary'
          >
            {listItem.title}
          </Text>
        </Box>

        <Stack sx={result}>
          <Text type={type}>
            {value}
          </Text>
        </Stack>
      </Box>
    );
  };

  return (
    <Box sx={wrapper}>
      {loading && (
        <Loader isContentOverflow={true} />
      )}

      <Box sx={left}>
        {customTitle}

        {(!customTitle && !disableTitle) && (
          <Box sx={titleWrapper}>
            <Title level={5}>
              {t('tradingStatistics.title')}
            </Title>
            
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={handleUpdateStatistic}
            >
              {Update}
            </div>
          </Box>
        )}

        <Box sx={leftInner}>
          {listItems.map((listItem: ITradingStatistic['listItems'][0]) => {
            return renderListItem(listItem);
          })}
        </Box>
      </Box>

      <ChartWrapper
        segments={[{
          label: t('tradingStatistics.segments.sum'),
          value: 'summary',
        }, {
          label: t('tradingStatistics.segments.day'),
          value: 'day',
        }, {
          label: t('tradingStatistics.segments.pair'),
          value: 'pair',
        }]}
        selectedSegment={selectedSegment}
        setSelectedSegment={setSelectedSegment}
        defaultSegment='summary'
        isEmpty={!chartData?.length}
        exchanges={exchanges}
        action={chartAction || (
          <ChartAction />
        )}
      >
        {(selectedSegment: string) => getChart(selectedSegment)}
      </ChartWrapper>
    </Box>
  );
};
