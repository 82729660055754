import { AggregatedSnapshot } from 'entities/exchange/types/snapshot.types';

export function mapSnapshotsToAreaData(overallSnapshotsArray: AggregatedSnapshot[], isBtc: boolean): Array<[string, number]> {
  if (!overallSnapshotsArray?.length) {
    return [];
  }
  
  return overallSnapshotsArray.map((snapshot: AggregatedSnapshot) => [
    snapshot.createdDate,
    isBtc ? snapshot.totalBtc : snapshot.totalUsdt,
  ]);
}
