import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { formatter } from 'shared/helpers';
import { LabelInput } from 'shared/ui';
import { TRADING_AMOUNT_INPUT } from '../../../styles';

export const TradingAmount = () => {
  const {
    t, 
  } = useTranslation();
  
  const {
    control,
    setValue,
    watch,
    formState: {
      errors,
    },
  } = useFormContext();

  const exchange = watch('exchange').value;
  const market = watch('market').value;
  const leverage = watch('futures').leverage;

  const setTradingAmountHandler = (value: string) => {
    const numericValue = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
    setValue('tradingAmount', numericValue);
  };

  const renderFiled: ControllerProps['render'] = (params) => {
    const {
      field,
    } = params;
    
    return (
      <LabelInput
        label={(
          <Label
            title={t('form.configurator.amount.title', {
              total: formatter(market.balance * (exchange.code.includes('futures') ? leverage : 1)),
              market: market.code,
            })}
          />
        )}
        value={field.value || ''}
        placeholder={t('form.configurator.amount.placeholder')}
        onChange={setTradingAmountHandler}
        style={TRADING_AMOUNT_INPUT}
        error={errors?.tradingAmount?.message?.toString()}
      />
    );
  };

  return (
    <Controller
      name='tradingAmount'
      control={control}
      render={renderFiled as any}
    />
  );
};
