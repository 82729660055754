import { TLanguage, TTranslation } from 'shared/types';

export const formConfigurator = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      configurator: {
        exchange: {
          title: 'Exchange',
        },
        market: {
          title: 'Market',
        },
        amount: {
          title: 'Trading amount (available: {{total}} {{market}})',
          placeholder: 'Set amount to continue',
        },
        pairs: {
          title: 'Active trading pairs',
        },
        orderType: {
          title: 'Order type',
          items: {
            market: 'Market',
            limit: 'Limit',
          },
        },
        additional: {
          orders: 'Orders',
          ordersSize: 'Orders size scale',
          priceChange: 'Price change, %',
          priceChangeScale: 'Orders price change scale',
        },
        take: {
          orders: 'Orders',
          ordersSize: 'Orders size scale',
          priceChange: 'Price change, %',
          priceChangeScale: 'Orders price change scale',
        },
        stop: {
          priceChange: 'Price change, %',
        },
      },
    },
    ru: {
      configurator: {
        exchange: {
          title: 'Биржа',
        },
        market: {
          title: 'Рынок',
        },
        amount: {
          title: 'Торговая сумма',
          placeholder: 'Введите сумму для продолжения',
        },
        pairs: {
          title: 'Активные торговые пары',
        },
        orderType: {
          title: 'Тип ордера',
          items: {
            market: 'Маркет',
            limit: 'Лимит',
          },
        },
        additional: {
          orders: 'Ордеры',
          ordersSize: 'Размер ордера',
          priceChange: 'Изменение цены, %',
          priceChangeScale: 'Коэф. изменения цены',
        },
        take: {
          orders: 'Ордеры',
          ordersSize: 'Размер ордера',
          priceChange: 'Изменение цены, %',
          priceChangeScale: 'Коэф. изменения цены',
        },
        stop: {
          priceChange: 'Изменение цены, %',
        },
      },
    },
  };

  return transaltion[type].configurator;
};
