import { SxProps, Theme } from '@mui/material';
import { getResponsiveValue } from 'shared/helpers';

export const WRAPPER: SxProps<Theme> = {
  padding: getResponsiveValue({
    min: 8,
    max: 24,
  }),
  borderRadius: '8px',
  width: '100%',
  placeItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#F8F8F8',
};
