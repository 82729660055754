//@ts-nocheck
import { updateExchangeData } from 'entities/new-terminal/model/services/update-trading-data';
import { newTerminalActions } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { SmartTrade } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ModalType } from 'shared/hooks/use-modal-state';
import { CloseGold, Delete, EditIcon, Founds, Share } from 'shared/icons';
import { History } from 'shared/icons';
import { mapTradeToFormValues } from '../components/active-trade/helpers/map-editing-trade';
import { MenuItem } from '../components/active-trade/types/active-trade.types';

interface UseMenuItemsParams {
  trade: SmartTrade;
  tradeSymbol: CurrentSymbol;
  exchangeAccount: any;
  openModal: (params: {
    type: ModalType;
    additionalData?: Record<string, any>;
  }) => void;
  handleCancelTrade: () => Promise<void>;
  handleCloseTradeByMarket: () => Promise<void>;
  handleCloseTradeByLimit: () => Promise<void>;
  handleOpenTradeByMarket: () => Promise<void>;
  handleOpenTradeByLimit: () => Promise<void>;
  deprecatedMenuItems?: any[];
}

interface MenuItemParams {
  key: string;
  label: string;
  icon: React.ReactNode;
  actionFn: () => Promise<void>;
  disabled: boolean;
}

export const useMenuItems = (params: UseMenuItemsParams): MenuItem[] => {
  const {
    trade,
    tradeSymbol,
    exchangeAccount,
    openModal,
    handleCancelTrade,
    handleCloseTradeByMarket,
    handleCloseTradeByLimit,
    handleOpenTradeByMarket,
    handleOpenTradeByLimit,
    deprecatedMenuItems,
  } = params;

  const dispatch = useDispatch();
  const {
    t, 
  } = useTranslation();
  
  //@TODO создать утилиту для проверки статуса ордера
  const isBaseOrderCompleted = trade.orders.some(
    order => order.viewType === 'base_order' && order.status === 'completed',
  );
  
  const hasCompletedForcedOrder = trade.orders.some(
    order => order.viewType === 'force_order_step_first' && order.status === 'completed',
  );

  const createMenuItem = ({
    key,
    label,
    icon,
    actionFn,
    disabled,
    exchangeAccount,
  }: MenuItemParams): MenuItem => ({
    key,
    label,
    icon,
    disabled,
    onClick: () => {
      
      if (!disabled) {
        if (key === 'edit') {
          dispatch(newTerminalActions.setSharedTrade(null));
          const formValues = mapTradeToFormValues(trade, exchangeAccount, tradeSymbol);
          dispatch(newTerminalActions.setEditingTrade(formValues));
          dispatch(newTerminalActions.scrollToPanel());
          dispatch(newTerminalActions.setSelectedExchange(exchangeAccount));
          dispatch(updateExchangeData({
            uuid: trade.exchangeAccountUuid,
            exchangeCode: exchangeAccount.exchangeCode,
            // previousMarket: trade.previousMarket,
            selectedExchange: exchangeAccount,
            tradeSymbol: {
              quote: tradeSymbol.quoteAsset,
              base: tradeSymbol.baseAsset,
            },
          }));
          return;
        }
        if (key === 'share') {
          const currentUrl = window.location.origin + window.location.pathname;
          const shareUrl = `${currentUrl}?share=${trade.smartTradeUuid}`;
          
          navigator.clipboard.writeText(shareUrl)
            .then(() => {
              toast.success(t('success.funds.copy') as string);
            })
            .catch((error) => {
              console.error('Failed to copy:', error);
              toast.error(t('error.funds.copy') as string);
            });
          return;
        }
        openModal({
          type: 'tradeActions',
          additionalData: { 
            actionType: key,
            actionFn, 
            trade,
            tradeSymbol,
            exchangeAccount,
          },
        });
      }
    },
  });

  const getCompletedMenuItems = () => {
    const items = [
      createMenuItem({
        key: 'addFunds',
        label: t?.('terminal.trades.actions.funds.add') || 'Add funds',
        icon: Founds,
        actionFn: () => Promise.resolve(),
        disabled: false,
        exchangeAccount,
      }),
      createMenuItem({
        key: 'reduceFunds',
        label: t?.('terminal.trades.actions.funds.reduce') || 'Reduce funds',
        icon: Founds,
        actionFn: () => Promise.resolve(),
        disabled: false,
        exchangeAccount,
      }),
      createMenuItem({
        key: 'closeByMarket',
        label: t?.('terminal.trades.actions.market.close') || 'Close by Market',
        icon: CloseGold,
        actionFn: handleCloseTradeByMarket,
        disabled: false,
      }),
      createMenuItem({
        key: 'closeByLimit',
        label: t?.('terminal.trades.actions.limit.close') || 'Close by Limit',
        icon: CloseGold,
        actionFn: handleCloseTradeByLimit,
        disabled: false,
      }),
      createMenuItem({
        key: 'edit',
        label: t?.('terminal.trades.actions.edit') || 'Edit',
        icon: EditIcon,
        actionFn: () => Promise.resolve(),
        disabled: false,
        exchangeAccount,
      }),
      createMenuItem({
        key: 'share',
        label: t?.('terminal.trades.actions.share.origin') || 'Share',
        icon: Share,
        actionFn: () => Promise.resolve(),
        disabled: false,
      }),
      createMenuItem({
        key: 'share_result',
        label: t?.('terminal.trades.actions.share.result') || 'Share result',
        icon: History,
        actionFn: () => Promise.resolve(),
        disabled: false,
      }),
      createMenuItem({
        key: 'cancel',
        label: t?.('terminal.trades.actions.cancel') || 'Cancel',
        icon: Delete,
        actionFn: handleCancelTrade,
        disabled: false,
      }),
    ];

    if (deprecatedMenuItems?.length) {
      return items.filter((menuItem) => {
        return !deprecatedMenuItems.includes(menuItem.key);
      });
    }

    return items;
  };

  const getMenuItems = () => {
    const items = [
      createMenuItem({
        key: 'addFunds',
        label: t?.('terminal.trades.actions.funds.add') || 'Add funds',
        icon: Founds,
        actionFn: () => Promise.resolve(),
        disabled: false,
        exchangeAccount,
      }),
      createMenuItem({
        key: 'reduceFunds',
        label: t?.('terminal.trades.actions.funds.reduce') || 'Reduce funds',
        icon: Founds,
        actionFn: () => Promise.resolve(),
        disabled: false,
        exchangeAccount,
      }),
      createMenuItem({
        key: 'openByMarket',
        label: t?.('terminal.trades.actions.market.open') || 'Open by Market',
        icon: CloseGold,
        actionFn: handleOpenTradeByMarket,
        disabled: false,
      }),
      createMenuItem({
        key: 'openByLimit',
        label: t?.('terminal.trades.actions.limit.open') || 'Open by Limit',
        icon: CloseGold,
        actionFn: handleOpenTradeByLimit,
        disabled: false,
      }),
      createMenuItem({
        key: 'edit',
        label: t?.('terminal.trades.actions.edit') || 'Edit',
        icon: EditIcon,
        actionFn: () => Promise.resolve(),
        disabled: false,
        exchangeAccount,
      }),
      createMenuItem({
        key: 'share',
        label: t?.('terminal.trades.actions.share.origin') || 'Share',
        icon: Share,
        actionFn: () => Promise.resolve(),
        disabled: false,
      }),
      createMenuItem({
        key: 'share_result',
        label: t?.('terminal.trades.actions.share.result') || 'Share result',
        icon: History,
        actionFn: () => Promise.resolve(),
        disabled: false,
      }),
      createMenuItem({
        key: 'cancel',
        label: t?.('terminal.trades.actions.cancel') || 'Cancel',
        icon: Delete,
        actionFn: handleCancelTrade,
        disabled: false,
      }),
    ];

    if (deprecatedMenuItems?.length) {
      return items.filter((menuItem) => {
        return !deprecatedMenuItems.includes(menuItem.key);
      });
    }

    return items;
  };

  return isBaseOrderCompleted || hasCompletedForcedOrder
    ? getCompletedMenuItems()
    : getMenuItems();
};
