import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { appPath } from 'shared/config/route-config/app-routes/consts';
import { AddNewBot, Icon, MainButton, TextButton } from 'shared/ui';
import { BUTTON } from '../styles';

export const AddBot = (props: Record<'type', 'button' | 'link'>) => {
  const {
    t, 
  } = useTranslation();

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const modalHandler = () => {
    setIsOpened((prev) => !prev);
  };

  const getContent = () => {
    if (props.type === 'button') {
      return (
        <Fragment>
          <MainButton
            type='primary'
            size='large'
            icon={(
              <Icon width={16}>
                <PlusOutlined />
              </Icon>
            )}
            styles={BUTTON('button')}
            onClick={modalHandler}
          >
            {t('header.menu.bots.button')}
          </MainButton>

          <AddNewBot
            isOpen={isOpened}
            closeModal={modalHandler}
          />
        </Fragment>
      );
    }

    return (
      <NavLink to={appPath.trading_bots_configurator.path}>
        <TextButton styles={BUTTON('link')}>
          {t('header.menu.bots.button')}
        </TextButton> 
      </NavLink>
    );
  };

  return getContent();
};
