import { Stack } from '@mui/material';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { WRAPPER } from '../styles';

export const OrderType = () => {
  const {
    t, 
  } = useTranslation();

  return (
    <Stack sx={WRAPPER}>
      <Label
        title={t('form.configurator.orderType.title')}
      />

      <Segmented
        value='limit'
        options={[{
          label: t('form.configurator.orderType.items.market'),
          value: 'market',
        }, {
          label: t('form.configurator.orderType.items.limit'),
          value: 'limit',
          disabled: true,
        }]}
        block={true}
        className='custom'
        size='large'
      />
    </Stack>
  );
};
