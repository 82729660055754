import { Box } from '@mui/material';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { InfoError } from 'shared/icons';
import { LabelInput, MainButton, Text, Title, Tooltip } from 'shared/ui';
import { changePassword } from '../api/change-password';
import { IChangePassword } from '../interfaces';
import {
  button,
  buttons,
  cancelButton,
  description,
  fields,
  modal,
  modalContent,
  title,
  titleWrapper,
  wrapper,
} from '../styles';

export const ChangePasswordModal = (props: IChangePassword) => {
  const {
    isOpen,
    handleClose,
  } = props;

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const {
    t, 
  } = useTranslation();
  
  const handleChangePassword = async () => {
    const requestData = {
      oldPassword,
      newPassword,
    };
    
    const responseFromChangePassword = await changePassword(requestData);
    if (!responseFromChangePassword.success) {
      handleClose();
      toast.error(t('error.changePassword.title') as string);
      return;
    }

    handleClose();    
    toast.success(t('success.changePassword.title') as string);
  };
  
  const isFormValid = () => (
    oldPassword.length > 0 &&
    newPassword.length > 0 &&
    passwordConfirmation.length > 0 &&
    newPassword === passwordConfirmation
  );
  
  useEffect(() => {
    if (!isOpen) {
      setOldPassword('');
      setNewPassword('');
      setPasswordConfirmation('');
    }
  }, [isOpen]);

  const getSuffix = () => {
    if (passwordConfirmation === newPassword) {
      return null;
    }

    return (
      <Tooltip title={t('validation.changePassword.match')}>
        {InfoError}
      </Tooltip>
    );
  };
  
  return (
    <Modal
      open={isOpen}
      footer={null}
      closeIcon={false}
      style={modal}
      styles={modalContent}
      centered={true}
      closable={true}
      destroyOnClose
      onCancel={handleClose}
      onOk={handleClose}
      width='100%'
    >
      <Box sx={wrapper}>
        <Box sx={titleWrapper}>
          <Title styles={title}>
            {t('modal.changePassword.title')}
          </Title>

          <Text styles={description}>
            {t('modal.changePassword.description')}
          </Text>
        </Box>

        <Box sx={fields}>
          <LabelInput
            label={t('modal.changePassword.current.title')}
            placeholder={t('modal.changePassword.current.placeholder')}
            value={oldPassword}
            onChange={(value) => setOldPassword(value)}
            type='password'
          />

          <LabelInput
            label={t('modal.changePassword.new.title')}
            placeholder={t('modal.changePassword.new.placeholder')}
            value={newPassword}
            onChange={(value) => setNewPassword(value)}
            type='password'
          />

          <LabelInput
            label={t('modal.changePassword.confirm.title')}
            placeholder={t('modal.changePassword.confirm.placeholder')}
            value={passwordConfirmation}
            onChange={(value) => setPasswordConfirmation(value)}
            type='password'
            status={passwordConfirmation !== newPassword ? 'error' : undefined}
            suffix={getSuffix()}
          />
        </Box>

        <Box sx={buttons}>
          <MainButton
            ghost={true}
            type='primary'
            styles={button}
            onClick={handleChangePassword}
            disabled={!isFormValid()}
          >
            {t('modal.changePassword.button.change')}
          </MainButton>

          <MainButton
            ghost={true}
            type='link'
            styles={cancelButton}
            onClick={handleClose}
          >
            {t('modal.changePassword.button.cancel')}
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};
