import { TLanguage, TTranslation } from 'shared/types';

export const errorWithdraw = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      withdraw: {
        balance: 'Insufficient balance',
      },
    },
    ru: {
      withdraw: {
        balance: 'Недостаточный баланс',
      },
    },
  };

  return transaltion[type].withdraw;
};
