import { TLanguage, TTranslation } from 'shared/types';

export const modalAddFunds = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      addFunds: {
        wallet: {
          title: {
            buy: 'Buy',
            sell: 'Sell',
          },
          tooltip: '{{asset}} amount currently \n available on Exchange account',
        },
      },
    },
    ru: {
      addFunds: {
        wallet: {
          title: {
            buy: 'Купить',
            sell: 'Продать',
          },
          tooltip: '{{asset}} доступно на аккаунте',
        },
      },
    },
  };

  return transaltion[type].addFunds;
};
