import { TLanguage, TTranslation } from 'shared/types';

export const home = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      home: {
        statistics: {
          total: {
            title: 'Total P&L',
            tooltip: 'Total lifetime profit generated from all closed deals. Please note: this statistic is affected by any filters you have active',
          },
          upl: {
            title: 'UP&L',
            tooltip: 'Current profit or loss total for any active deals your have open. Please note, this statistic is affected by any filters you have active',
          },
          today: {
            title: 'Today P&L',
            tooltip: 'Profit taken from closed deals (resets at midnight UTC+0 each day). Please note: this statistic is affected by any filters you have active',
          },
          trades: {
            title: 'Trades',
            tooltip: 'Current quantity of active trades / Quantity of all trades in selected date range',
            value: '{{activeTrades}} active / {{totalTrades}} total',
          },
          inTrades: {
            title: 'In trades',
            tooltip: 'Total amount of funds locked in active deals or on limit orders in the exchange orderbooks for active deals. Please note: this statistic is affected by any filters you have active',
          },
          select: {
            all: 'All',
            manual: 'Manual',
            bots: 'Bots',
            tooltips: [
              'Select the statistic you wish to view: manual trades, Bot deals, or a comprehensive summary',
              'Select the market statistic you wish to view: All Markets of specific asset, like USDT',
            ],
            placeholder: 'Source',
          },
        },
        table: {
          title: 'Assets',
          columns: {
            token: 'Token',
            share: 'Share',
            change: 'Change 24h',
            price: 'Price',
            amount: 'Amount',
            total: 'Total',
          },
          search: {
            placeholder: 'Search by asset name',
          },
        },
      },
    },
    ru: {
      home: {
        statistics: {
          total: {
            title: 'Общий P&L',
            tooltip:
              'Суммарная прибыль по всем закрытым сделкам. Учитываются активные фильтры',
          },
          upl: {
            title: 'UP&L',
            tooltip:
              'Текущая совокупная прибыль/убыток по открытым сделкам. Учитываются фильтры',
          },
          today: {
            title: 'Сегодняшний P&L',
            tooltip:
              'Прибыль по закрытым сделкам за сегодня (сброс в полночь UTC+0). Учитываются фильтры',
          },
          trades: {
            title: 'Сделки',
            tooltip: 'Текущее число активных сделок / Всего сделок за выбранный период',
            value: '{{activeTrades}} активных / {{totalTrades}} всего',
          },
          inTrades: {
            title: 'В сделках',
            tooltip:
              'Сумма средств в активных сделках или лимитных ордерах. Учитываются фильтры',
          },
          select: {
            all: 'Все',
            manual: 'Ручные',
            bots: 'Боты',
            tooltips: [
              'Выберите статистику по ручным сделкам, ботам или общую',
              'Выберите рынок: все или конкретный актив (например, USDT)',
            ],
            placeholder: 'Источник',
          },
        },
        table: {
          title: 'Активы',
          columns: {
            token: 'Токен',
            share: 'Доля',
            change: 'Изменение 24ч',
            price: 'Цена',
            amount: 'Кол-во',
            total: 'Сумма',
          },
          search: {
            placeholder: 'Поиск по названию актива',
          },
        },
      },
    },
  };

  return transaltion[type].home;
};
