import { TLanguage, TTranslation } from 'shared/types';

export const pages = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      pages: {
        login: 'Login',
        register: 'Register',
        twoFactor: 'Two factor',
        recoverPassword: 'Recover Password',
        activateAccount: 'Activate account',
        home: 'Home',
        myAccounts: 'My accounts',
        connectExchange: 'Connect exchange account',
        myBot: 'My bot',
        subscriptions: 'Subscriptions',
        inviteFriends: 'Invite friends',
        tradingTerminal: 'Trading terminal',
        tradingDiary: 'Trading diary',
        manualTradingIntroduction: 'Manual trading introduction',
        myBots: 'My bots',
        marketplace: 'Marketplace',
        myPublicBots: 'My public bots',
        botsIntroduction: 'Bots introduction',
        botsConfigurator: 'Bots configurator',
      },
    },
    ru: {
      pages: {
        login: 'Вход',
        register: 'Регистрация',
        twoFactor: 'Двухфакторная аутентификация',
        recoverPassword: 'Восстановление пароля',
        activateAccount: 'Активация аккаунта',
        home: 'Главная',
        myAccounts: 'Мои аккаунты',
        connectExchange: 'Подключить аккаунт биржи',
        myBot: 'Мой бот',
        subscriptions: 'Подписки',
        inviteFriends: 'Пригласить друзей',
        tradingTerminal: 'Торговый терминал',
        tradingDiary: 'Торговый дневник',
        manualTradingIntroduction: 'Введение в ручную торговлю',
        myBots: 'Мои боты',
        marketplace: 'Маркетплейс',
        myPublicBots: 'Мои публичные боты',
        botsIntroduction: 'Введение в ботов',
        botsConfigurator: 'Конфигуратор ботов',
      },
    },
  };

  return transaltion[type].pages;
};
