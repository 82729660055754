import { TLanguage, TTranslation } from 'shared/types';

export const validationEmail = (type: TLanguage) => {
  const transaltion: TTranslation = {
    en: {
      email: {
        required: 'Please input your e-mail!',
        format: 'Email should be in a correct format.',
      },
    },
    ru: {
      email: {
        required: 'Введите e-mail!',
        format: 'Некорректный формат e-mail.',
      },
    },
  };

  return transaltion[type].email;
};
