import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BackIcon } from 'shared/icons';
import { MainButton, Text } from 'shared/ui';
import { IActionsProps } from '../interfaces';
import { BACK, BUTTON, WRAPPER } from '../styles';

export const Actions = (props: IActionsProps) => {
  const {
    t, 
  } = useTranslation();

  return (
    <Stack sx={WRAPPER}>
      {props.handleBack && (
        <Box maxWidth={80}>
          <MainButton
            type='text'
            onClick={props.handleBack}
            styles={BUTTON}
          >
            {BackIcon}

            <Text
              type='success'
              styles={BACK}
            >
              {t('configurator.button.back')}
            </Text>
          </MainButton>
        </Box>
      )}

      <Box
        width='100%'
        maxWidth={240}
      >
        <MainButton
          type='primary'
          onClick={props.button.action}
          styles={BUTTON}
          disabled={props.button.disabled}
          htmlType='submit'
        >
          {props.button.title}
        </MainButton>
      </Box>
    </Stack>
  );
};
